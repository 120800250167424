import React from 'react';
import ImageSubmit from '../../components/ImageSubmit';
import PopUpSubmit from '../../components/PopUpSubmit';
import BreadCrumb from '../../components/BreadCrumb'
import './styles.css'

function Images() {
  document.title = 'Rclub - Imagens'
  return (
    <>    
      <BreadCrumb path={['home', 'imagens']} data={['Início', 'Imagens']} />
      <div className="imagesContainer">
      <ImageSubmit title="Banner Principal (Desktop)" type="desktop" size="2880 x 900"/>
      <ImageSubmit title="Banner Principal (Mobile)" type="mobile" size="918 x 430"/>
      <PopUpSubmit title="Pop-up"/>
      {/* <div className="buttonsImage">
        <button className="buttonCancel" onClick={()=> goMain()}>Cancelar</button>
        <button className="buttonSave" onClick={()=> goSave()}>Salvar</button>
      </div> */}
      </div>
    </>
  );
}

export default Images;