import React from 'react'
import { Modal } from 'react-bootstrap'
import propTypes from 'prop-types'

const AlertModal = ({ show, onHide, message, onCancel, onSubmit, animation }) => {
    return(
        <Modal show={show} onHide={onHide} animation={animation} style={{marginTop: "15%"}}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <strong>RCLUB</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <button className='btn btn-danger' name="Não" onClick={(e) => onCancel(e)}>Não</button>
                    <button className='btn btn-success' name="Sim" style={{ marginLeft: 5 }} onClick={(e)=>onSubmit(e)}>Sim</button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

AlertModal.defaultProps = {
    onHide: () => {},
    message: '',
    onCancel: () => {},
    onSubmit: () => {},
    animation: true
}

AlertModal.propTypes = {
    show: propTypes.bool.isRequired,
    onHide: propTypes.func.isRequired,
    message: propTypes.string.isRequired,
    onCancel: propTypes.func.isRequired,
    onSubmit: propTypes.func.isRequired,
    animation: propTypes.bool
}

export default AlertModal