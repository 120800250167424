import React from 'react';
import "./styles.css";
import ButtonWhatsappColumn from '../../components/ButtonWhatsappColumn'
import RegisterForm from '../../components/RegisterForm'


function RegisterDesktop(){
    return (
        <>
            <div id='register-page'>
                <div className="register-page">
                    <h1>Receba Primeiro</h1>
                    <h2>
                        Cadastre-se, gratuitamente e sem custo algum, <br/>
                        e receba avisos, dicas e promoções sempre <br/>
                        em primeira mão.
                    </h2>
                </div>
                <div className='register-mobile-form'> 
                <RegisterForm typeContact={"Cadastro Direto"}/>
                </div>
                <ButtonWhatsappColumn/>
            </div>
        </>
    )
}

export default RegisterDesktop;