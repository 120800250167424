import "./styles.css";

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { Col, Modal, Row } from 'react-bootstrap'

import { handleChange } from '../../../../store/actions/cart'
import { cepMask, onlyNumbers } from '../../../../utils/normalize'
import companiesRepository from '../../../../repositories/Companies'
import contactsRepository from '../../../../repositories/Contacts'
import getAddressByCep from '../../../../utils/viaCep'

import Card from '../../components/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const ProductPickupLocation = () => {
  const [isNoCompaniesAtCityModalOpen, setIsNoCompaniesAtCityModalOpen] = useState(false)

  const { cartReducer } = useSelector(state => state)
  const { client, companyIdAtSelectedCity, companiesAtSelectedCity } = cartReducer
  const { cpf, phone, zipCode } = client
  const dispatch = useDispatch()

  useEffect(() => {
    window.document.title = 'Rclub - Dados Pessoais'
  }, [])

  async function handleSelectCompaniesByCity() {
    if (!zipCode) {
      return toastr.warning('Por favor, informe o CEP para prosseguir.')
    }

    const cep = onlyNumbers(zipCode)

    if (cep.length < 8 || cep === '111111111') {
      return toastr.warning('Por favor, informe um CEP válido para prosseguir.')
    }

    try {
      const addressData = await getAddressByCep(cep)

      const newClientData = {
        ...client,
        ...addressData
      }

      await dispatch(handleChange(newClientData, 'client'))
      getCompaniesByCity(addressData.city, newClientData)

    } catch (err) {
      toastr.warning(err.message)
    }
  }


  async function getCompaniesByCity(city, client) {
    try {
      const companies = await companiesRepository.getAllByCity(city)

      if (!companies.length) {
        return createContactWithNoCompaniesInCity(client)
      }

      const activeCompanies = companies.filter(companie => Boolean(companie.isActive))

      dispatch(handleChange(activeCompanies, 'companiesAtSelectedCity'))
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao buscar as empresas. Por favor, tente novamente')
    }
  }

  async function createContactWithNoCompaniesInCity(client) {
    try {
      await contactsRepository.create({
        ...client,
        cpf: onlyNumbers(cpf),
        phone: onlyNumbers(phone),
        zipCode: onlyNumbers(zipCode),
      })

    } catch (err) {
      console.log(err)

    } finally {
      setIsNoCompaniesAtCityModalOpen(true)
    }
  }

  function handleChangeClientValue(e) {
    const newClientData = {
      ...client,
      [e.target.name]: e.target.value
    }
    dispatch(handleChange(newClientData, 'client'))
  }

  function handleChangeSelectedCompany(e) {
    const selectedCompanyId = e.target.value

    const company = companiesAtSelectedCity.find(company => Number(company.id) === Number(selectedCompanyId))

    dispatch([
      handleChange(e.target.value, 'companyIdAtSelectedCity'),
      handleChange(company, 'companySelected')
    ])
  }

  return (
    <div className='productDetailsPickupLocation'>
      <h1>Local de Retirada</h1>
     <div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <label style={{fontSize:'13px'}}>Informe o CEP para escolher o local de retirada</label>
            <Col xs={12} sm={12} md={6} lg={4} style={{ padding: 0, minWidth: '290px' }}>
              <div className="input-group">
                <input
                  className='form-control foco-input'
                  type="text"
                  name='zipCode'
                  value={cepMask(zipCode)}
                  onChange={handleChangeClientValue}
                />
                <div className="input-group-append">
                  <button onClick={handleSelectCompaniesByCity} type='button' className="input-group-text">Ok</button>
                  <a href='http://www.buscacep.correios.com.br/sistemas/buscacep/' rel="noopener noreferrer" target='_blank' style={{marginTop: '5%'}}>Não sei o CEP <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                </div>
                {/* <div className='dont-know-zipcode'>
                  <a href='http://www.buscacep.correios.com.br/sistemas/buscacep/' rel="noopener noreferrer" target='_blank'>Não sei o CEP <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                </div> */}
              </div>
            </Col>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={9} lg={9}>
            <label style={{fontSize:'13px'}}>Onde deseja retirar o produto ?</label>
            <select
              name='companyIdAtSelectedCity'
              className='form-control foco-input'
              value={companyIdAtSelectedCity}
              defaultValue=''
              onChange={handleChangeSelectedCompany}
              disabled={!companiesAtSelectedCity.length}
            >
              <option value=''>Selecione</option>
              {companiesAtSelectedCity.map(company => {
                return <option key={company.id} value={company.id}>{`${company.tradingName} - ${company.address}, ${company.addressNumber} - ${company.city}/${company.state}`} </option>
              })}
            </select>
          </Col>
        </Row>
        </div>

      <Modal dialogClassName='no-companies-at-city-modal' show={isNoCompaniesAtCityModalOpen} onHide={() => setIsNoCompaniesAtCityModalOpen(false)} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>RClub</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <strong>Ops, ainda não temos ponto de retirada na sua cidade. Estamos em expansão e em breve informaremos quando o RCLUB chegar na sua região.</strong>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <button className="btn btn-danger" onClick={() => setIsNoCompaniesAtCityModalOpen(false)}>Voltar</button>
          </div>
        </Modal.Footer>
      </Modal>
 
    </div>
         
  )
}

export default ProductPickupLocation