const INITIAL_STATE = {
  step: 1,
  selectedProducts: [],
  subTotal: 0,
  discountValue: 0,
  amount: 0,
  saleId: null,
  saleIdBankSlip: '',
  bankSlipPdfBase64: '',
  paymentMethod: '',
  clientIdIndication: '',
  saleIdIndication: '',
  orderId: '',

  confirmModal: false,
  checkCompanyStockLoading: false,
  isCompanyStockAvailable: false,
  loading: false,

  creditCard: {
    cardNumber: '',
    brand: '',
    cvv: '',
    month: '',
    year: '',
    isValid: false,
    focus: ''
  },

  debitCard: {
    cardNumber: '',
    brand: '',
    cvv: '',
    month: '',
    year: '',
    isValid: false,
    focus: ''
  },

  installments: '1',

  isCouponModalOpen: false,
  validateCouponLoading: false,
  searchCoupon: '',
  couponId: null,
  coupon: {
    description: '',
    discount: ''
  },

  client: {
    cpf: '',
    name: '',
    email: '',
    phone: '',
    zipCode: '',
    address: '',
    neighborhood: '',
    state: '',
    city: ''
  },

  bankSlipAddress: {
    postalCode: '',
    bankSlipAddressInfo: '',
    bankSlipNeighborhood: '',
    bankSlipAddressNumber: '',
    bankSlipState: '',
    bankSlipCity: ''
  },

  companiesAtSelectedCity: [],
  companyIdAtSelectedCity: null,
  companySelected: {
    tradingName: '',
    street: '',
    streetNumber: '',
    city: '',
    state: ''
  },

}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'HANDLE_CHANGE':
      return { ...state, [action.payloadType]: action.payload }

    case 'HANDLE_CART':
      return {
        ...state,
        selectedProducts: action.payload.products,
        subTotal: action.payload.subTotal,
        discountValue: state.couponId ? action.payload.subTotal * (state.coupon.discount / 100) : 0,
        amount: state.couponId ? action.payload.subTotal - (action.payload.subTotal * (state.coupon.discount / 100)) : action.payload.subTotal,
        isCompanyStockAvailable: false
      }

    case 'CHECK_COMPANY_STOCK_STARTED':
      return { ...state, checkCompanyStockLoading: true }

    case 'CHECK_COMPANY_STOCK_SUCCESS':
      return { ...state, checkCompanyStockLoading: false, isCompanyStockAvailable: true, step: 3 }

    case 'CHECK_COMPANY_STOCK_FAILURE':
      return { ...state, checkCompanyStockLoading: false, isCompanyStockAvailable: false }

    case 'VALIDATE_COUPON_STARTED':
      return { ...state, validateCouponLoading: true }

    case 'VALIDATE_COUPON_FAILURE':
      return { ...state, validateCouponLoading: false }

    case 'VALIDATE_COUPON_SUCCESS':
      const couponDiscountValue = state.subTotal * (action.payload.discount / 100)

      return {
        ...state,
        validateCouponLoading: false,
        isCouponModalOpen: false,
        coupon: action.payload,
        couponId: action.payload.id,
        discountValue: couponDiscountValue,
        amount: state.subTotal - couponDiscountValue
      }

    case 'CREATE_SALE_STARTED':
      return { ...state, loading: true, confirmModal: false }

    case 'CREATE_SALE_SUCCESS':
      return { ...state, loading: false, step: 5, saleId: action.payload.id }

    case 'CREATE_SALE_FAILURE':
      return { ...state, loading: false }

    case 'CLEAR_REDUCER':
      return { ...INITIAL_STATE, selectedProducts: [] }

    default:
      return state
  }
}