import React from 'react';
import "./styles.css";
import ButtonWhatsappRow from '../../components/ButtonWhatsappRow';
import RegisterForm from '../../components/RegisterForm'


function RegisterDesktop() {
  return (
    <>
      <div id='register-page'>
        <div className="register-page">
          <h2>
            Óleo Premium por <b>R$ 11,98</b> e sem taxas. <br /> <br />
            Preencha o formulário abaixo que entraremos <br /> em contato com você.
          </h2>
        </div>
        <div className='register-desktop-form'>
          <RegisterForm typeContact={"Campanha"} />
        </div>
        <div style={{ paddingLeft: '12%', paddingBottom: '2%' }}>
          <ButtonWhatsappRow />
        </div>
      </div>
    </>
  )
}

export default RegisterDesktop;