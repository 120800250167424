import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import './styles.css'
import publicityRepository from '../../../../repositories/PublicityClick'
import axios from 'axios'

export default function CarouselHome({ items, onClick }) {

  const [ip, setIp] = useState()

  const handleBannerClick = async (redirectLink) => {

    try {
      window.open(redirectLink, "_blank")
      getIP()
      var publicity = {
        clientIp: ip,
        urlImage: redirectLink
      }
      await publicityRepository.create(publicity)
      return

    } catch (err) {
      console.log(err)
      return
    }
  }

  const getIP = () => {
    axios.get('https://api.ipify.org?format=jsonp?callback=?')
      .then(resp => setIp(resp.data))
  }

  return (
    <div className='carousel-home'>
      <Carousel>
        {items?.map(i =>
          <Carousel.Item className='carousel-home-item' key={i.id}>
            <section>
              {i.redirectLink && i.linkStatus ?
                <img src={i.urlImgBanner} alt={i.imageName} onClick={() => handleBannerClick(i.redirectLink)} />
                :
                <img src={i.urlImgBanner} alt={i.imageName} />
              }
            </section>
          </Carousel.Item>
        )}
      </Carousel>
    </div>
  )
}