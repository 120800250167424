import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import Carousel from "./Carousel";
import ProductsList from "../../components/ProductList";
import api from "../../../../services/api";
import { addCart, handleChange } from '../../../../store/actions/cart'
import { isMobile } from '../../../../utils/validation'
import ImgCard from '../../components/ImgCard';
import ProductsButton from '../../components/ButtonProducts';
import ButtonWhatsappColumn from '../../components/ButtonWhatsappColumn';
import ButtonWhatsappRow from '../../components/ButtonWhatsappRow';

import imgHr from '../../../../assets/img/home/Site_Club_15.png';
import imgHr_99 from '../../../../assets/img/home/Site_Club_15_99.png';
import imgHrMobile from '../../../../assets/img/home/Site_Club_15_Mobile.png';
import imgHrMobile_99 from '../../../../assets/img/home/Site_Club_15_Mobile_99.png';
import image99 from '../../../../assets/img/home/image002_desktop.jpg';
import image99Mobile from '../../../../assets/img/home/image001_mobile.jpg';
import "./styles.css";
import "./styles99.css";

import Card from "../../components/Card";
import PopUpDesktop from '../../components/PopUpDesktop'
import PopUpMobile from '../../components/PopUpMobile'
import s3Repository from '../../../../repositories/s3'
import ClientsRepository from '../../../../repositories/Clients'
import initGa from '../../../../utils/googleAnalytics';

import  { main }  from '../../../../push-notifications';


const Main = ({ history }) => {
  initGa(window.location.href);

  const [carouselItems, setCarouselItems] = useState([])

  const { cartReducer: { arrayServices, clientIdIndication } } = useSelector((state) => state)
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState(false)
  const [openPopUp, setOpenPopUp] = useState()
  const [openPopUpLocalStorage, setOpenPopUpLocalStorage] = useState(true)
  const [imagePopUp, setImagePopUp] = useState()
  const [activeDesktop, setActiveDesktop] = useState()
  const [activeMobile, setActiveMobile] = useState()
  const [classes, setClasses] = useState(null)
  const [showCarousel, setShowCarousel] = useState(true)
  const [rclub99, setRClub99] = useState(false)
  const [linkBannerDesktop, setLinkBannerDesktop] = useState()
  const [linkBannerMobile, setLinkBannerMobile] = useState()
  const [linkPopUpDesktop, setLinkPopUpDesktop] = useState()
  const [linkPopUpMobile, setLinkPopUpMobile] = useState()
  const [isLinkActiveBannerDesktop, setIsLinkActiveBannerDesktop] = useState(false)
  const [isLinkActiveBannerMobile, setIsLinkActiveBannerMobile] = useState(false)
  const [isLinkActivePopUpDesktop, setIsLinkActivePopUpDesktop] = useState(false)
  const [isLinkActivePopUpMobile, setIsLinkActivePopUpMobile] = useState(false)

  useEffect(() => {
    loadClasses()
    //main()
  }, [])

  const loadClasses = () => {
    if (!window.location.host.includes('21200') && !window.location.host.includes('99')) {
      setClasses('main-header-desktop')
    } else {
      setClasses('home-page-99')
      setShowCarousel(false)
      setRClub99(true)
    }
  }

  useEffect(() => {
    isMobile()
    const mobileAcess = isMobile()
    if (!mobileAcess) {
      setMobile(false)
      getIgmPopUpDesktop()
      getIgmBannerDesktop()
    } else {
      setMobile(true)
      getIgmPopUpMobile()
      getIgmBannerMobile()
    }

    getRegisterNumber()

    window.scrollTo(0, 0)
  }, []);

  // useEffect(() => {
  //   const getTopFiveServices = async () => {
  //     const res = await api.get("http://workmotorweb.com.br:21270/v1/services/top-five-services");
  //     setCarouselItems(res.data);
  //   };
  //   getTopFiveServices();
  // }, []);

  async function getRegisterNumber() {
    const url = window.location.search
    const registerNumber = url.replace('?indication=', '')

    if (registerNumber) {
      const client = await ClientsRepository.getClientByRegisterNumber(registerNumber)
      const indicationClientId = client.id

      if (indicationClientId) {
        await dispatch(handleChange(indicationClientId, 'clientIdIndication'))
      }
    }
  }

  function getIgmBannerDesktop() {
    try {
      s3Repository.getBanner(
        {
          "mobile": false
        })
        .then(resp => setCarouselItems(resp))

    } catch (err) {
      console.log(err)
      setCarouselItems(null)
    }
  }

  function getIgmBannerMobile() {
    try {
      s3Repository.getBanner(
        {
          "mobile": true
        })
        .then(resp => setCarouselItems(resp))

    } catch (err) {
      setCarouselItems(0)
    }
  }

  useEffect(() => {
    const date = localStorage.getItem('@RClub/date')
    const dateCurrent = new Date().toLocaleDateString()
    if (date === dateCurrent) {
      const count = Number(localStorage.getItem('@RClub/PopUpCount'))
      if (count > 1) {
        setOpenPopUpLocalStorage(false)
      } else {
        setOpenPopUpLocalStorage(true)
        const count = Number(localStorage.getItem('@RClub/PopUpCount'))
        localStorage.setItem('@RClub/PopUpCount', count + 1)
      }
    } else {
      const dateCurrentNew = new Date().toLocaleDateString()
      localStorage.setItem('@RClub/date', dateCurrentNew)
      localStorage.removeItem('@RClub/PopUpCount')
      const count = Number(localStorage.getItem('@RClub/PopUpCount'))
      localStorage.setItem('@RClub/PopUpCount', count + 1)
    }
  }, [openPopUp]);

  function getIgmPopUpMobile() {
    try {
      s3Repository.getPopupMobile()
        .then(resp => setImagePopUp(resp.urlImgPopup, setActiveMobile(resp.isActive), setOpenPopUp(true), setLinkPopUpMobile(resp.redirectLink), setIsLinkActivePopUpMobile(resp.linkStatus))
        )
      //setImagePopUp('https://i.ibb.co/mtpg9DQ/modal-Mobile.jpg')

    } catch (err) {
      setImagePopUp(0)
    }
  }

  function getIgmPopUpDesktop() {
    try {
      s3Repository.getPopupDesktop()
        .then(resp => setImagePopUp(resp.urlImgPopup, setActiveDesktop(resp.isActive), setOpenPopUp(true), setLinkPopUpDesktop(resp.redirectLink), setIsLinkActivePopUpDesktop(resp.linkStatus))
        )
      //setImagePopUp('https://i.ibb.co/JdLHbZn/modal-Desktop.jpg')
    } catch (err) {
      setImagePopUp(0)
    }
  }

  function closePopUp() {
    setOpenPopUp(false)
  }
  return (
    <div id="home-page">
      <div >
        {showCarousel ?
          <Carousel
            items={carouselItems}
          // onClick={(i) => {
          //   dispatch([
          //     addCart(i, arrayServices),
          //     {
          //       type: "HANDLE_CHANGE",
          //       payload: 1,
          //       payloadType: "step",
          //     },
          //   ]);
          //   history.push("/store/items");
          // }}
          />
          :
          <>
            {mobile ?
              <img src={image99Mobile} alt='99mobile' style={{ width: '100%' }} />
              :
              <img src={image99} alt='99' style={{ width: '100%' }} />
            }
          </>
        }
        {/* <img src={!mobile ? home_Desktop : home_Mobile} width={'100%'} height={'90%'} alt='HomeImage' /> */}

        {/* {mobile ?
          <div className="card-content">
            <br />
            <h5 style={{ fontWeight: 'bold' }}>PRODUTOS INCRÍVEIS A PREÇO DE FÁBRICA PARA ENTREGADORES E MOTORISTAS! </h5>
            <br />
            {rclub99 ?
              <ProductsButton history={history} text="COMPRAR AGORA" direction="products" rclub99={true} />
              :
              <ProductsButton history={history} text="COMPRAR AGORA" direction="products" />
            }
            <br /><br />
            <ImgCard />
            <br /><br />
            <img src={!rclub99 ? imgHrMobile : imgHrMobile_99} alt="" />
            <br /><br /><br />
            <h5 style={{ fontWeight: 'bold' }}>SEM MENSALIDADE, SEM TAXAS, SEM COBRANÇAS. </h5>
            <h5 style={{ fontWeight: 'normal' }}><i>O RClub traz os melhores preços para você sem precisar de assinatura. </i></h5>
            <br />
          </div>
          :
          <div className="card-content-desktop">
            <div className="card-content-desktop-row">
              <br />
              <div className="card-content-desktop-column">
                <h5 style={{ fontWeight: 'bold' }}>PRODUTOS INCRÍVEIS A PREÇO <br /> DE FÁBRICA PARA ENTREGADORES <br /> E MOTORISTAS! </h5>
                {rclub99 ?
                  <ProductsButton history={history} text="COMPRAR AGORA" direction="products" rclub99={true} />
                  :
                  <ProductsButton history={history} text="COMPRAR AGORA" direction="products" />
                }
                <br />
              </div>
              <div className="card-content-desktop-column">
                <ImgCard />
                <br /><br />
              </div>
            </div>
            <img src={!rclub99 ? imgHr : imgHr_99} alt="" />
            <br /><br />
            <h5 style={{ fontWeight: 'bold' }}>SEM MENSALIDADE, SEM TAXAS, <br /> SEM COBRANÇAS. </h5>
            <h5 style={{ fontWeight: 'normal' }}><i>O RClub traz os melhores preços para você sem <br /> precisar de assinatura. </i></h5>
            <br />
          </div>
        } */}
        <br />
        <Card title='Produtos'>
          <ProductsList hasSeeMoreLabel />
        </Card>
        <br />
        {rclub99 ? null :
          <>
            {mobile ?
              <div className="card-content">
                <br />
                <h5 style={{ fontWeight: 'bold' }}>CADASTRE-SE PARA SER O PRIMEIRO A SABER DE NOVIDADES. </h5>
                <br />
                <ProductsButton history={history} text="CADASTRE-SE" direction="register" />
                <br />
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <ButtonWhatsappColumn />
                </div>
              </div>
              :
              <div className="card-content-desktop">
                <div className="card-content-desktop-row">
                  <br />
                  <div className="card-content-desktop-column" style={{ margin: '0 0 0 12%', width: '37%' }}>
                    <div>
                      <h5 style={{ fontWeight: 'bold' }}>CADASTRE-SE PARA SER O PRIMEIRO <br /> A SABER DE NOVIDADES. </h5>
                      <ProductsButton history={history} text="CADASTRE-SE" direction="register" />
                    </div>
                  </div>
                  <div className="card-content-desktop-column" style={{ margin: '0', width: '100%' }}>
                    <div>
                      <ButtonWhatsappColumn />
                    </div>
                  </div>
                </div>
                <br />
              </div>
            }
          </>
        }
      </div>
      {openPopUp && !mobile && imagePopUp != 0 && activeDesktop && openPopUpLocalStorage ?

        <PopUpDesktop urlImg={imagePopUp} onClose={() => { closePopUp() }} redirectLink={linkPopUpDesktop} isActive={isLinkActivePopUpDesktop} />
        :
        null
      }

      {openPopUp && mobile && imagePopUp != 0 && activeMobile && openPopUpLocalStorage ?

        <PopUpMobile urlImg={imagePopUp} onClose={() => { closePopUp() }} redirectLink={linkPopUpMobile} isActive={isLinkActivePopUpMobile} />
        :
        null
      }
    </div>
  );
}

export default withRouter(Main)
