import React from 'react';
import "./styles.css";
import MasterCard from '../../../../assets/img/Cartoes/Mastercard.png'
import Visa from '../../../../assets/img/Cartoes/Visa.png'
import Boleto from '../../../../assets/img/Boleto/boleto.png'

function ProductFormOfPayment() {
  return (
    <div className="productDetailsFormOfPayment">
      <div className="InfosproductDetailsFormOfPayment">
        <h1>{'Meios de Pagamento'}</h1>
        <div className="FormPaymentContainer">
          <h2>{'Cartões de Credito'}</h2>
          <div className="FormPayment">
            <img
              src={MasterCard}
              height={25}
              width={40}
            />
            <img
              src={Visa}
              height={15}
              width={40}
            />
          </div>
          <h2>{'Boleto Bancário'}</h2>
          <div className="FormPayment">
            <img
              src={Boleto}
              height={25}
              width={40}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductFormOfPayment;