import api from "../services/api"

const singleUpload = async formData => {
  try {

    const response = await api.post('s3', formData)
    return response.data.imageURL

  } catch (err) {
    throw err
  }
}

const deleteSingleImage = async imageKey => {
  try {

    await api.delete(`s3/${imageKey}`)

  } catch (err) {
    throw err
  }
}

//------------------------------BANNER------------------------------//

//Upload image
const singleUploadBanner = async formData => {
  try {

    const response = await api.post('s3/banner', formData)
    return response.data.imageURL

  } catch (err) {
    throw err
  }
}

const getBanner = async obj => {
  try {

    const response = await api.post(`/banner/get`, obj)
    const res = response.data.map(resp => {
      let id = resp.id
      let urlImgBanner = resp.urlImgBanner
      let imageName = resp.imageName
      let linkStatus = resp.linkStatus
      let redirectLink = resp.redirectLink

      return { id, urlImgBanner, imageName, linkStatus, redirectLink }
    })

    return res

  } catch (err) {
    throw err
  }
}
//create image register in BD
const createdBanner = async dataImg => {
  try {

    const response = await api.post('banner', dataImg)
    return response.data

  } catch (err) {
    throw err
  }
}
//get all Banner
const getBannerComponent = async type => {
  try {

    const response = await api.post(`banner/get`, type)
    return response

  } catch (err) {
    throw err
  }
}

//disabled  or enabled banner

const EnableOrDisableBanner = async disableOrEnableObj => {
  try {

    const response = await api.post(`banner/enable`, disableOrEnableObj)
    return response

  } catch (err) {
    throw err
  }
}


//------------------------------POPUP------------------------------//

const singleUploadPopup = async formData => {
  try {

    const response = await api.post('s3/popup', formData)
    return response.data.imageURL

  } catch (err) {
    throw err
  }
}

const createdPopUp = async dataImg => {

  try {

    const response = await api.post('popup', dataImg)
    console.log(response)
    return response.data

  } catch (err) {
    throw err
  }
}

const deleteSingleImagebannerPopup = async imageKey => {
  try {

    await api.delete(`s3/banner/${imageKey}`)

  } catch (err) {
    throw err
  }
}

const getPopup = async obj => {
  try {

    const response = await api.post(`/popup/get`, obj)
    const imgQtd = response.data.length

    if (imgQtd === 0) {
      return 0
    } else {
      return response.data[0].urlImgPopup
    }


  } catch (err) {
    throw err
  }
}

const getPopupMobile = async () => {
  try {

    const response = await api.get(`/popup/mobile`)

    return response.data
  } catch (err) {
    throw err
  }
}

const getPopupDesktop = async () => {
  try {

    const response = await api.get(`/popup/desktop`)

    return response.data
  } catch (err) {
    throw err
  }
}

const enablePopup = async obj => {
  try {

    await api.post(`/popup/enable`, obj)

  } catch (err) {
    throw err
  }
}

//----------------------PRODUCTS IMAGES---------------------//
const saveProductImages = async dataImg => {
  try {
    await api.post('/product-images', dataImg)
      .then((response) => {
        return response.data
      })
  } catch (err) {
    return console.log(err)
  }
}

const saveProductsVideos = async dataVideo => {
  try {
    const response = await api.post('/product-videos', dataVideo)
    return response.data
  } catch (err) {
    return console.log(err)
  }
}

const getProductImages = async id => {
  try {
    const response = await api.get(`/product-images/${id}`)
    return response;
  } catch (err) {
    return console.log(err)
  }
}

const getProductVideos = async id => {
  try {
    const response = await api.get(`/product-videos/${id}`)
    return response;
  } catch (err) {
    return console.log(err)
  }
}

const updateFiles = async data => {
  try {
    await api.post('/product-images/update', data)
      .then((response) => {
        return response.data
      })
  } catch (err) {
    return console.log(err)
  }
}

export default {
  singleUpload,
  deleteSingleImage,
  singleUploadBanner,
  getBanner,
  singleUploadPopup,
  deleteSingleImagebannerPopup,
  getPopup,
  enablePopup,
  createdBanner,
  EnableOrDisableBanner,
  getBannerComponent,
  saveProductImages,
  saveProductsVideos,
  getProductImages,
  getProductVideos,
  updateFiles,
  createdPopUp,
  getPopupMobile,
  getPopupDesktop,
}