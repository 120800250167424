import api from "../services/api"

const getAll = async () => {
  try {

    const response = await api.get('products')
    return response.data

  } catch (err) {
    throw err
  }
}

const create = async product => {
  try {
    const response = await api.post('products', product)
    //console.log(response)
    return response.data

  } catch (err) {
    throw err
  }
}

const update = async (id, product) => {
  try {

    await api.put(`products/${id}`, product)

  } catch (err) {
    throw err
  }
}

const getAllActiveProducts = async () => {
  try {

    const response = await api.get('products?isActive=1')
    return response.data

  } catch (err) {
    throw err
  }
}

const getAllActiveCarProducts = async () => {
  try {

    const response = await api.get('products?isActive=1&vehicleType=2')
    return response.data

  } catch (err) {
    throw err
  }
}

const getLastCode = async () => {
  try {
    
    const response = await api.get('products')
    const lastCode = parseInt(response.data.length) + 1
    return lastCode

  } catch(err) {
    throw err
  }
}

const getById = async id => {
  try {

    const response = await api.get(`products/${id}`)

    return response.data

  } catch(err) {
    throw err
  }
}

export default {
  create,
  getAllActiveProducts,
  getAll,
  getById,
  getLastCode,
  update,
  getAllActiveCarProducts
}