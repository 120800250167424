import React, { useState, useEffect } from 'react';

import "./styles.css";
import { faChevronCircleRight, faChevronCircleLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function ImageMax({ id, UrlImage, idImg, onClose, data }) {

    const [idMap, setIdMap] = useState(0)
    const [lastPosition, setLastPosition] = useState(0)
    const [fistPosition, setFistPosition] = useState(0)
    const [arrayProduct, setArrayProduct] = useState([])
    const [colorPrevious, setColorPrevious] = useState("black")
    const [colorNext, setColorNext] = useState("black")
    const handleOutsideClick = (e) => {
        if (e.target.id === id)
            onClose();
    };

    useEffect(() => {
        selectImage(idImg)
        setArrayProduct(data)

    }, [])

    function selectImage(idImg) {
        var id = idImg
        setLastPosition(id)
        setFistPosition(id - 1)
        if (id - 1 === 0 ) {
            setColorPrevious('transparent')
        }
    }

    function nextImage() {
        if (arrayProduct.length - 1 === lastPosition) {
            setColorNext('transparent')
        }
        if (arrayProduct.length === lastPosition) {
            setColorNext('transparent')
        } else {
            var last = lastPosition + 1
            var fist = fistPosition + 1
            setLastPosition(last)
            setFistPosition(fist)
            setColorPrevious('black')

        }
    }

    function previousImage() {
        if (fistPosition === 1 ) {
            setColorPrevious('transparent')
        }
        if ((arrayProduct.length * fistPosition) === 0) {
            setColorPrevious('transparent')
        } else {
            var last = lastPosition - 1
            var fist = fistPosition - 1
            setLastPosition(last)
            setFistPosition(fist)
            setColorNext('black')
        }
    }

    return (
        <div id={id} className="ImageMaxContainer" onClick={handleOutsideClick}>
            <div className="ImageMax">

                <div className="previousImage">
                    <FontAwesomeIcon icon={faChevronCircleLeft} size='3x' saria-hidden="false" color={colorPrevious} onClick={() => previousImage()} />
                </div>
                {data?.slice(fistPosition, lastPosition).map((uploadedFile, index) => {

                    return !uploadedFile?.isVideo && (<img
                        src={uploadedFile?.imageUrl}
                    />)

                }
                )}
                {data?.slice(fistPosition, lastPosition).map((uploadedFile, index) => {

                    return uploadedFile?.isVideo && (<video controls width={890} height={500} type="video/mp4"
                        src={uploadedFile?.imageUrl}
                    ></video>)
                }
                )}
                <div className="nextImage">
                    <FontAwesomeIcon icon={faChevronCircleRight} size='3x' saria-hidden="false" color={colorNext} onClick={() => nextImage()} />

                </div>
            </div>
        </div>

    );
}

export default ImageMax;