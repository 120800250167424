import React from 'react'

import './styles.css'

const Card = ({ title, children, ...rest }) => {
  return (
    <div className='main-card' {...rest} >
      <span>{title}</span>
      <hr />
      {children}
    </div>
  )
}

export default Card