import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { clearReducer } from '../../../../../../store/actions/cart'

const Resume = () => {
  const { cartReducer: { client, saleId } } = useSelector(state => state)
  const dispatch = useDispatch()

  useEffect(() => {
    window.document.title = `Rclub - Resumo Venda #${saleId}`
    window.open(window.location.origin + `/document/${saleId}`, '_blank')

    return () => {
      dispatch(clearReducer())
    }
  }, [])

  return (
    <div id="resume-page">
      <h3>Compra Confirmada</h3>
      <p>
        O resumo da compra e o voucher para retirada dos produtos foram enviados para o e-mail <strong>{client.email}</strong>
      </p>
      <Link to='/'>Voltar para a tela inicial</Link>
    </div>
  )
}

export default Resume