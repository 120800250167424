import api from "../services/api"

const create = async productType => {
  try {
    
    const response = await api.post('product-types', productType)
    return response.data

  } catch(err) {
    throw err
  }
}

const update = async (id, productType) => {
  try {
    
    await api.put(`product-types/${id}`, productType)

  } catch(err) {
    throw err
  }
}

const getAll = async () => {
  try {

    const response = await api.get('product-types')
    return response.data

  } catch (err) {
    throw err
  }
}

const getAllSortedByDescription = async () => {
  try {

    const response = await api.get('product-types?sortBy=description')
    return response.data

  } catch (err) {
    throw err
  }
}

const getLastCode = async () => {
  try {
    
    const response = await api.get('product-types')
    const lastCode = parseInt(response.data.length) + 1
    return lastCode

  } catch(err) {
    throw err
  }
}

const getById = async id => {
  try {
    
    const response = await api.get(`product-types/${id}`)
    return response.data

  } catch(err) {
    throw err
  }
}

export default {
  getAll,
  getLastCode,
  create,
  update,
  getById,
  getAllSortedByDescription
}