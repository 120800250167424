import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CreditCard from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { handleCreditCard, handleChange } from '../../../../../../store/actions/cart'
import { onlyNumbers } from '../../../../../../utils/normalize'
import ReactPixel from 'react-facebook-pixel'

import SelectInstallments from './SelectInstallments'
import '../../styles.css'
import Card from '../../../../components/Card';

const Payment = () => {
  const { cartReducer: { creditCard } } = useSelector(state => state);
  const { cardNumber, cvv, year, month, focus } = creditCard

  const dispatch = useDispatch()
  const months = new Array(12).fill()
  const years = new Array(21).fill()
  const yearToday = parseInt(new Date().toISOString().substring(2, 4))

  useEffect(() => {
    ReactPixel.fbq('track', 'AddPaymentInfo');
    window.document.title = 'Rclub - Pagamento'
  }, [])

  const handleCallback = (type, isValid) => {
    const obj = { ...creditCard }
    obj.brand = type.issuer
    obj.isValid = isValid

    dispatch(handleChange(obj, 'creditCard'))
  }

  return (
    <Card title='Meio de Pagamento'>
      <div id='payment-wrapper'>
        <center>
          <CreditCard
            cvc={cvv}
            locale={{ valid: 'Validade' }}
            placeholders={{ name: '' }}
            expiry={month + '/' + year}
            focused={focus}
            name={''}
            acceptedCards={['visa', 'mastercard', 'elo', 'amex', 'dinersclub', 'jcb', 'aura']}
            number={cardNumber}
            callback={handleCallback}
          />
        </center>
      </div>

      <fieldset id='payment-fieldset'>
        <div>
          <label htmlFor="installments">Parcelamento</label>
          <SelectInstallments />
        </div>

        <div>
          <label htmlFor='card-number'>Número do Cartão</label>
          <input
            type='text'
            className='form-control foco-input'
            value={cardNumber}
            maxLength={16}
            style={{ maxWidth: '200px' }}
            onChange={e => dispatch(handleCreditCard(creditCard, onlyNumbers(e.target.value), 'cardNumber'))}
            onFocus={() => dispatch(handleCreditCard(creditCard, 'number', 'focus'))}
          />
        </div>

        <div>
          <label htmlFor='select-month'>Data de Expiração</label>
          <div style={{ display: 'flex' }}>
            <select
              id='select-month'
              className='form-control foco-input'
              onChange={e => dispatch(handleCreditCard(creditCard, e.target.value, 'month'))}
              onFocus={() => dispatch(handleCreditCard(creditCard, 'expiry', 'focus'))}
            >
              <option value=''>Mês</option>
              {
                months.map((item, index) => {
                  if (index < 9) {
                    return (<option key={index} value={'0' + parseInt(index + 1)}>0{index + 1}</option>)
                  } else {
                    return (<option key={index} value={index + 1}>{index + 1}</option>)
                  }
                })
              }
            </select>

            <select
              id='select-year'
              className='form-control foco-input'
              style={{ marginLeft: 5 }}
              onFocus={() => dispatch(handleCreditCard(creditCard, 'expiry', 'focus'))}
              onChange={e => dispatch(handleCreditCard(creditCard, (yearToday + e.target.value), 'year'))}
            >
              <option value=''>Ano</option>
              {
                years.map((item, index) => {
                  return (<option key={index} value={yearToday + index}>{yearToday + index}</option>)
                })
              }
            </select>
          </div>
        </div>

        <div>
          <label>Código de segurança</label>
          <input
            type='text'
            className='form-control foco-input'
            style={{ maxWidth: '150px' }}
            value={cvv} maxLength={4}
            onChange={e => dispatch(handleCreditCard(creditCard, onlyNumbers(e.target.value), 'cvv'))}
            onFocus={() => dispatch(handleCreditCard(creditCard, 'cvc', 'focus'))}
          />
        </div>
      </fieldset>
    </Card>
  )
}

export default Payment