const validate = values => {
  const errors = {}

  if (!values.password) {
    errors.password = 'Obrigatório'
  }
  if (!values.certificateEmail) {
    errors.certificateEmail = 'Obrigatório'
  }

  return errors
}

export default validate