import React, { useState, useEffect } from "react";
import './styles.css';

import { phoneMask } from '../../../../utils/normalize'
import contactsRegisterRepository from '../../../../repositories/ContactsRegister'
import ModalAlertMsg from '../../../Store/components/ModalAlertMsg'


const RegisterForm = ({ typeContact }) => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [telephone, setTelephone] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [reloadPage, setReloadPage] = useState(false)
    
    function validation(){
        if (!name|| !telephone) {
            setModalMessage('Por favor, informe ao menos o NOME e o TELEFONE para prosseguir.')
            setIsModalOpen(true);
        }
        else{
            submit()
        }
    }

    async function submit(){
        try{
            const req = {
                "name": name,
                "email": email,
                "phone": telephone,
                "typeContact": typeContact
            }

            const response = await contactsRegisterRepository.create(req)
            .then(() => {
                setModalMessage('Recebemos o seu contato, a partir de agora você receberá todas as novidades do RCLUB.')
                setReloadPage(true)
                return setIsModalOpen(true)
            })

        } catch (err) {
            console.log(err)
            setModalMessage('Ocorreu um erro ao realizar o cadastro. Por favor, tente novamente.')
            return setIsModalOpen(true)
        }
    }
  
  const handleModalClose = () => {
    setIsModalOpen(false)

    if(reloadPage){
        window.location.reload(true)
    }
  }
  
  return (
    <>
        <div className='register-form-content'>
            <div style={{paddingBottom: '10px'}}>
                <input className='register-form-input'
                    type="text"
                    placeholder="NOME"
                    value={name}
                    onChange={event => setName(event.target.value)}
                />
            </div>

            <div style={{paddingBottom: '10px'}}>
                <input className='register-form-input'
                    type="text"
                    placeholder="E-MAIL"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                />
            </div>

            <div style={{paddingBottom: '10px'}}>
                <input className='register-form-input'
                    type="text"
                    placeholder="WHATSAPP/TELEFONE"
                    value={telephone}
                    onChange={event => setTelephone(phoneMask(event.target.value))}
                />
            </div>

            <div className='register-form-button' style={{ display: 'flex', justifyContent:'center'}}> 
                <div className='register-form-button' onClick={() => validation()}>
                    <h5>CADASTRAR</h5>
                </div>
            </div>

            <div style={{paddingTop: '30px'}}> 
            </div>

        </div>

        {isModalOpen ?
            <ModalAlertMsg text={modalMessage} onClose={handleModalClose} />
            :
            null
        }
    </>
  )
}

export default RegisterForm;