import React, { useEffect, useState } from 'react'
import { clearProductTypesFilter, clearVehicleTypesFilter, changeValue, fetchProducts, fetchCarProducts } from '../../../../store/actions/list'
import { useSelector, useDispatch } from 'react-redux'
import ProductItem from '../ProductItem'
import { addCart } from '../../../../store/actions/cart'
import { withRouter } from 'react-router-dom'
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPixel from 'react-facebook-pixel'
import ModalProductsAlert from '../../../Store/components/ModalProductsAlert'


import './styles.css'

const ProductsList = ({ history, hasSeeMoreLabel }) => {
  const { listReducer, cartReducer } = useSelector(state => state)
  const { search, productsFiltered, productTypes, vehicleTypes } = listReducer
  const { selectedProducts } = cartReducer
  const [openAlertModal, setOpenAlertModal] = useState(false)
  const [vehicleType, setVehicleType] = useState(0)

  const dispatch = useDispatch()

  useEffect(() => {
    loadInfo()
  }, [])

  const loadInfo = () => {
    if (!window.location.host.includes('21200') && !window.location.host.includes('99')) {
      loadProducts(false)
    } else {
      loadProducts(true)
    }
  }

  const loadProducts = async (getCarProducts) => {
    if (getCarProducts) {
      dispatch(fetchCarProducts())
    } else {
      dispatch(fetchProducts())
    }
  }

  const handleBuyItem = product => {
    if (selectedProducts.length > 0) {
      if (product.vehicleTypeId !== selectedProducts[0].vehicleTypeId) {
        handleModalMessage(product.vehicleTypeId)
      } else {
        ReactPixel.fbq('track', 'AddToCart');
        dispatch([
          addCart(product, selectedProducts),
          clearProductTypesFilter(productTypes),
          clearVehicleTypesFilter(vehicleTypes),
          changeValue('', 'search'),
          changeValue(1, 'step'),
        ])
        history.push('/store/items')
      }
    } else {
      ReactPixel.fbq('track', 'AddToCart');
      dispatch([
        addCart(product, selectedProducts),
        clearProductTypesFilter(productTypes),
        clearVehicleTypesFilter(vehicleTypes),
        changeValue('', 'search'),
        changeValue(1, 'step'),
      ])
      history.push('/store/items')
    }

  }

  const handleBuyItemAddCart = product => {
    if (selectedProducts.length > 0) {
      if (product.vehicleTypeId !== selectedProducts[0].vehicleTypeId) {
        handleModalMessage(product.vehicleTypeId)
      } else {
        ReactPixel.fbq('track', 'AddToCart');
        dispatch([
          addCart(product, selectedProducts),
          clearProductTypesFilter(productTypes),
          clearVehicleTypesFilter(vehicleTypes),
          changeValue('', 'search'),
          changeValue(1, 'step'),
        ])
      }
    } else {
      ReactPixel.fbq('track', 'AddToCart');
      dispatch([
        addCart(product, selectedProducts),
        clearProductTypesFilter(productTypes),
        clearVehicleTypesFilter(vehicleTypes),
        changeValue('', 'search'),
        changeValue(1, 'step'),
      ])
    }
  }

  const handleModalClose = () => {
    setOpenAlertModal(false)
  }

  const handleModalMessage = (vehicleTypeId) => {
    if (vehicleTypeId === 1) {
      setVehicleType(1)
      setOpenAlertModal(true)
    } else {
      setVehicleType(2)
      setOpenAlertModal(true)
    }
  }

  return (
    <>
      <div id='product-list'>
        {
          (!!search || !!productTypes.length || !!vehicleTypes.length) && !productsFiltered.filter(x => x['description'].toString().toLowerCase().includes(search.toLowerCase())).length
            ? <center><strong>Produto não encontrado</strong></center>
            : (
              <>
                {
                  productsFiltered
                    .filter(x => x['description'].toString().toLowerCase().includes(search.toLowerCase()))
                    .map(product => <ProductItem key={product.id} product={product} onClick={() => handleBuyItem(product)} addCart={() => handleBuyItemAddCart(product)} />
                    )}

                {hasSeeMoreLabel &&
                  <footer onClick={() => history.push(window.location.href.split("store")[1] ? "products" : "store/products")}>
                    <button className='btn btn-link'  >Ver todos os produtos</button>
                    <FontAwesomeIcon icon={faAngleRight} cursor='pointer' color='#0645AD' aria-hidden="false" />
                  </footer>
                }
              </>
            )
        }
      </div>

      {
        openAlertModal ?
          <ModalProductsAlert vehicleType={vehicleType} onClose={handleModalClose} />
          :
          null
      }
    </>

  )
}

export default withRouter(ProductsList)