import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Toggle from 'react-toggle'
import { useSelector, useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import PropTypes from 'prop-types'
import { Field, reduxForm, change } from 'redux-form'

import CardForm from '../../components/CardForm'
import FormSubmitButtons from '../../components/FormSubmitButtons'
import renderField from '../../../../components/RenderField'
import validate from './validate'
import { cnpjMask, specialChar, phoneMask, cepMask, onlyNumbers, maxLength } from '../../../../utils/normalize'
import getAddressByCep from '../../../../utils/viaCep'
import vehicleTypeRepository from '../../../../repositories/VehicleTypes'

import './styles.css'
import "react-toggle/style.css"

function CompanyForm({ handleSubmit, companyId, loading, onCancel }) {
  const { values } = useSelector(state => state).form.company
  const { isActive, zipCode } = values
  const dispatch = useDispatch()
  const [vehicleTypes, setVehicleTypes] = useState([])

  useEffect(() => {
    loadOccupations()
  }, [])

  const loadOccupations = async () => {
    try {
      const vehicles = await vehicleTypeRepository.getAll()
      setVehicleTypes(vehicles)
    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar as ocupações. Por favor, tente novamente')
    }
  }

  async function handleZipCode() {
    if (!zipCode) return

    const cep = onlyNumbers(zipCode)
    if (cep.length < 8) {
      return clearAddresValues()
    }

    try {
      const addressData = await getAddressByCep(cep)

      dispatch([
        change('company', 'address', addressData.address),
        change('company', 'neighborhood', addressData.neighborhood),
        change('company', 'state', addressData.state),
        change('company', 'city', addressData.city)
      ])

    } catch (err) {
      return toastr.warning(err.message)
    }
  }

  function clearAddresValues() {
    dispatch([
      change('company', 'address', null),
      change('company', 'neighborhood', null),
      change('company', 'state', null),
      change('company', 'city', null),
    ])
  }

  return (
    <form id='admin-page-companie-form' onSubmit={handleSubmit}>
      <CardForm
        title='Empresa'
        show
      >
        <Row>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='cnpj'
              type='text'
              component={renderField}
              label='CNPJ'
              normalize={cnpjMask}
              disabled={!!companyId}
            />
          </Col>

          <Col xs={12} sm={12} md={4} lg={4}>
            <Field
              name='companyName'
              type='text'
              component={renderField}
              label='Razão Social'
              normalize={value => maxLength(specialChar(value), 100)}
            />
          </Col>

          <Col xs={12} sm={12} md={4} lg={4}>
            <Field
              name='tradingName'
              type='text'
              component={renderField}
              label='Nome Fantasia'
              normalize={value => maxLength(specialChar(value), 50)}
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <label htmlFor="isActive">Status<span>*</span></label>
            <br />
            <Toggle checked={isActive} onChange={() => dispatch(change('company', 'isActive', !isActive))} />
          </Col>

          <Col xs={12} sm={12} md={4} lg={4}>
            <Field
              name='email'
              type='text'
              component={renderField}
              label='E-mail'
              normalize={value => maxLength(value, 50)}
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='phone'
              type='text'
              component={renderField}
              label='Telefone'
              normalize={phoneMask}
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='zipCode'
              type='text'
              component={renderField}
              label='CEP'
              normalize={cepMask}
              onBlur={handleZipCode}
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='vehicleTypeId'
              type='text'
              component={renderField}
              as='select'
              label='Área de Atuação'
            >
              <option value="">Selecione</option>
              {vehicleTypes.map(vehicleType => {
                return <option key={vehicleType.id} value={vehicleType.id}>{vehicleType.description}</option>
              })}
            </Field>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={4} lg={4}>
            <Field
              name='address'
              type='text'
              component={renderField}
              label='Endereço'
              disabled
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='addressNumber'
              type='text'
              component={renderField}
              label='Número'
              normalize={value => maxLength(onlyNumbers(value), 5)}
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='neighborhood'
              type='text'
              component={renderField}
              label='Bairro'
              disabled
            />
          </Col>

          <Col xs={12} sm={12} md={1} lg={1}>
            <Field
              name='state'
              component={renderField}
              type='text'
              label='UF'
              disabled
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='city'
              component={renderField}
              type='text'
              label='Cidade'
              disabled
            />
          </Col>
        </Row>
      </CardForm>

      <FormSubmitButtons hasId={!!companyId} loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
    </form>
  )
}

CompanyForm.prototype = {
  handleSubmit: PropTypes.func.isRequired,
  companyId: PropTypes.string,
  loading: PropTypes.bool.isRequired
}

export default reduxForm({
  form: 'company',
  validate: validate
})(CompanyForm)