import api from "../services/api"

const sendSms = async (phone, message) => {
  try {

    const response = await api.post('sms/sendsms', {
      phone,
      message
    })
    return response.data

  } catch (err) {
    throw err
  }
}

export default {
  sendSms,
}