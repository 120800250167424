import React, { useEffect, useState } from 'react';
import './styles.css';
import './styles99.css';
import { useHistory } from 'react-router-dom'


const SeeLocationAvailable = () => {

  const history = useHistory()
  const [classes, setClasses] = useState(null)

  useEffect(() => {
    loadClasses()
  }, [])

  const loadClasses = () => {
    if (!window.location.host.includes('21200') && !window.location.host.includes('99')) {
      setClasses('see-location-available-button')
    } else {
      setClasses('see-location-available-button-99')
    }
  }

  function goToLocation() {
    history.push('withdraw')
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={classes} onClick={goToLocation} style={{ justifyContent: 'center' }}>
          <h5>VER LOCAIS DISPONÍVEIS</h5>
        </div>
      </div>
    </>
  )
}

export default SeeLocationAvailable;