import api from "../services/api"

const getAll = async () => {
  try {

    const response = await api.get('vehicle-types')
    return response.data

  } catch (err) {
    throw err
  }
}

const getAllSortedByDescription = async () => {
  try {

    const response = await api.get('vehicle-types?sortBy=description')
    return response.data

  } catch (err) {
    throw err
  }
}


const getLastCode = async () => {
  try {
    
    const response = await api.get('vehicle-types')
    const lastCode = parseInt(response.data.length) + 1
    return lastCode

  } catch(err) {
    throw err
  }
}


const create = async vehicleType => {
  try {

    const response = await api.post('vehicle-types', vehicleType)
    return response.data

  } catch (err) {
    throw err
  }
}

export default {
  getAll,
  getLastCode,
  create,
  getAllSortedByDescription
}