import api from "../services/api"

const getCompanyStockOverview = async companyId => {
    try {

        const response = await api.get(`companystock/overview/${companyId}`);
        return response.data
    }
    catch (err) {
        throw err
    }
}

const checkCompanyStock = async (companyId, selectedProducts) => {
    try {
        const response = await api.post('companystock/check', {
            companyId,
            selectedProducts
        })

        return response.data
    }
    catch (err) {
        throw err
    }
}

export default {
    getCompanyStockOverview,
    checkCompanyStock
}