import React, { useEffect, useState } from 'react';
import './styles.css'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import publicityRepository from '../../../../repositories/PublicityClick'
import axios from 'axios'


function PopUpMobile({ id = 'modal', urlImg, onClose, redirectLink, isActive }) {

  const [ip, setIp] = useState()

  const handleOutsideClick = (e) => {
    if (e.target.id === id)
      onClose();
  };

  const handlePopupClick = async () => {

    try {
      await getIP()
      var publicity = {
        clientIp: ip,
        urlImage: redirectLink
      }
      var response = await publicityRepository.create(publicity)
      return

    } catch (err) {
      return
    }
  }

  const getIP = async () => {
    await axios.get('https://api.ipify.org?format=jsonp?callback=?')
      .then(resp => setIp(resp.data))
  }

  return (
    <div id={id} className="ContainerModalMobile" onClick={handleOutsideClick}>
      <div className="ModalMobile" >

        <div className="closeButtonMobile" >
          <button onClick={() => onClose()}><FontAwesomeIcon icon={faTimes} size='1x' saria-hidden="false" color="white" /></button>

        </div>
        <div>
          {redirectLink && isActive ?
            <a href={redirectLink} target="_blank" onClick={() => handlePopupClick()}>
              <img

                src={urlImg}
                style={{ display: 'flex', width: '100%', height: '100%' }}
              />
            </a>
            :
            <img

              src={urlImg}
              style={{ display: 'flex', width: '100%', height: '100%' }}
            />
          }
        </div>
      </div>

    </div>

  )
}

export default PopUpMobile;