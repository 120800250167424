const validate = values => {
  const errors = {}
  if (!values.description) {
    errors.description = 'Obrigatório'
  }
  if (!values.discount) {
    errors.discount = 'Obrigatório'
  }

  return errors
}

export default validate