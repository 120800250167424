import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Toggle from 'react-toggle'
import { useSelector, useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import PropTypes from 'prop-types'
import { Field, reduxForm, change, setSubmitFailed } from 'redux-form'

import CardForm from '../../components/CardForm'
import FormSubmitButtons from '../../components/FormSubmitButtons'
import renderField from '../../../../components/RenderField'
import validate from './validate'
import { cnpjMask, specialChar, phoneMask, cepMask, onlyNumbers, maxLength, cpfMask } from '../../../../utils/normalize'
import getAddressByCep from '../../../../utils/viaCep'
import registerTypesRepository from '../../../../repositories/RegisterTypes'

import './styles.css'
import "react-toggle/style.css"

function AssignorForm({ handleSubmit, assignorId, loading, onCancel }) {
  const { values } = useSelector(state => state).form.assignor
  const { status, registerTypeId, zipCode } = values
  const dispatch = useDispatch()
  const [registerTypes, setRegisterTypes] = useState([])
  const [wrongFileType, setWrongTypeFile] = useState(false)
  const [registerType, setRegisterType] = useState()

  useEffect(() => {
    loadOccupations()
  }, [])

  const loadOccupations = async () => {
    try {
      const registers = await registerTypesRepository.getAll()
      setRegisterTypes(registers)
    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar os tipos de cadastro. Por favor, tente novamente')
    }
  }

  const handleType = selectedType => {
    console.log(selectedType)
    // setRegisterType(selectedType)
    dispatch([
      change('assignor', 'registerTypeId', Number(selectedType))
    ])

    if (Number(selectedType) === 1) {
      dispatch([
        change('assignor', 'fantasyName', null)
      ])
    }
  }

  const handleChange = selectedFile => {
    const file = selectedFile
    console.log(file);

    if (file.type !== "application/x-pkcs12") {
      setWrongTypeFile(true)
      return
    }

    setWrongTypeFile(false)

    dispatch([
      change('assignor', 'file', file),
    ])
  }

  async function handleZipCode() {
    if (!zipCode) return

    const cep = onlyNumbers(zipCode)
    if (cep.length < 8) {
      return clearAddresValues()
    }

    try {
      const addressData = await getAddressByCep(cep)

      console.log(addressData)

      dispatch([
        change('assignor', 'address', addressData.address),
        change('assignor', 'neighborhood', addressData.neighborhood),
        change('assignor', 'state', addressData.state),
        change('assignor', 'city', addressData.city)
      ])

    } catch (err) {
      return toastr.warning(err.message)
    }
  }

  function clearAddresValues() {
    dispatch([
      change('assignor', 'address', null),
      change('assignor', 'neighborhood', null),
      change('assignor', 'state', null),
      change('assignor', 'city', null),
    ])
  }

  return (
    <form id='admin-page-assignor-form' onSubmit={handleSubmit}>
      <CardForm
        title='Cedente'
        show
      >
        <Row>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='registerTypeId'
              type='text'
              component={renderField}
              as='select'
              label='Tipo de Cadastro'
              disabled={!!assignorId}
              onChange={e => handleType(e.target.value)}
            >
              {registerTypes.map(register => {
                return <option key={register.id} value={register.id}>{register.description}</option>
              })}
            </Field>
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='registerNumber'
              type='text'
              component={renderField}
              label={Number(registerTypeId) === 2 ? 'CNPJ' : 'CPF'}
              normalize={Number(registerTypeId) === 2 ? cnpjMask : cpfMask}
              disabled={!!assignorId}
            />
          </Col>

          <Col xs={12} sm={12} md={4} lg={4}>
            <Field
              name='companyName'
              type='text'
              component={renderField}
              label={Number(registerTypeId) === 2 ? 'Razão Social' : 'Nome Completo'}
              normalize={value => maxLength(specialChar(value), 80)}
            />
          </Col>

          {Number(registerTypeId) === 2 && (
            <Col xs={12} sm={12} md={4} lg={4}>
              <Field
                name='fantasyName'
                type='text'
                component={renderField}
                label='Nome Fantasia'
                normalize={value => maxLength(specialChar(value), 80)}
              />
            </Col>
          )}
        </Row>

        <Row>
          <Col xs={12} sm={12} md={2} lg={2}>
            <label htmlFor="status">Status<span>*</span></label>
            <br />
            <Toggle checked={status} onChange={() => dispatch(change('assignor', 'status', !status))} />
          </Col>

          <Col xs={12} sm={12} md={4} lg={4}>
            <Field
              name='email'
              type='text'
              component={renderField}
              label='E-mail'
              normalize={value => maxLength(value, 50)}
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='phone'
              type='text'
              component={renderField}
              label='Telefone'
              normalize={phoneMask}
            />
          </Col>
        </Row>
      </CardForm>

      <CardForm
        title='Endereço'
        show
      >
        <Row>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='zipCode'
              type='text'
              component={renderField}
              label='CEP'
              normalize={cepMask}
              onBlur={handleZipCode}
            />
          </Col>

          <Col xs={12} sm={12} md={4} lg={4}>
            <Field
              name='address'
              type='text'
              component={renderField}
              label='Endereço'
              disabled
            />
          </Col>

          <Col xs={12} sm={12} md={1} lg={1}>
            <Field
              name='number'
              type='text'
              component={renderField}
              label='Número'
              normalize={value => maxLength(value, 5)}
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='complement'
              type='text'
              component={renderField}
              label='Complemento'
              normalize={value => maxLength(value, 30)}
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='neighborhood'
              type='text'
              component={renderField}
              label='Bairro'
              normalize={value => maxLength(value, 50)}
              disabled
            />
          </Col>

          <Col xs={12} sm={12} md={1} lg={1}>
            <Field
              name='state'
              component={renderField}
              type='text'
              label='UF'
              disabled
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='city'
              component={renderField}
              type='text'
              label='Cidade'
              disabled
            />
          </Col>
        </Row>
      </CardForm>

      <FormSubmitButtons hasId={!!assignorId} loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
    </form>
  )
}

AssignorForm.prototype = {
  handleSubmit: PropTypes.func.isRequired,
  assignorId: PropTypes.string,
  loading: PropTypes.bool.isRequired
}

export default reduxForm({
  form: 'assignor',
  validate: validate
})(AssignorForm)