const validate = values => {
  const errors = {}
  if (!values.description) {
    errors.description = 'Obrigatório'
  }
  if (!values.price) {
    errors.price = 'Obrigatório'
  }
  if (!values.productTypeId) {
    errors.productTypeId = 'Obrigatório'
  }
  if (!values.vehicleTypeId) {
    errors.vehicleTypeId = 'Obrigatório'
  }
  if (!values.minimumQuantity) {
    errors.minimumQuantity = 'Obrigatório'
  }
  if (!values.maximumQuantity) {
    errors.maximumQuantity = 'Obrigatório'
  } else if(values.minimumQuantity && (parseInt(values.maximumQuantity) < values.minimumQuantity)) {
    errors.maximumQuantity = 'Valor menor que valor mínimo'
  }
  if (!values.observations) {
    errors.observations = 'Obrigatório'
  }

  return errors
}

export default validate