import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ManualCheckIn from './ManualCheckIn';
import SalesDetails from './SalesDetails'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './styles.css';

function CheckIn() {

  const [path, setPath] = useState()
  useEffect(() => {
    setPath(window.location.pathname)
  }, [window.location.pathname])
  return (
    <div>
      {path === '/partners/checkin' &&
        <>
          <div className='checkin-app-page'>
            <Header title='CHECK-IN' />
            <ManualCheckIn />
            <Footer />
          </div>
        </>
      }
      {path === '/partners/saledetails' &&
        <>
          <div className='checkin-app-page'>
          <Header title='DADOS DA COMPRA' />
          <SalesDetails />
          <Footer />
          </div>
        </>
      }
    </div>
  );
}

export default CheckIn;