import React, { useEffect, useState } from 'react'
import { faSearch, faShoppingCart, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import logo from '../../../../assets/img/icon.png'
import { changeValue } from '../../../../store/actions/list'
import './styles.css'
import './styles99.css'

const HeaderMobile = ({ history }) => {
  const { listReducer: { search }, cartReducer: { selectedProducts, step } } = useSelector(state => state)
  const dispatch = useDispatch();
  const [classes, setClasses] = useState(null)

  useEffect(() => {
    loadClasses()
  }, [])

  const loadClasses = () => {
    if (!window.location.host.includes('21200') && !window.location.host.includes('99')) {
      setClasses('main-header-mobile')
    } else {
      setClasses('main-header-mobile-99')
    }
  }

  function handleOpenModal() {
    document.getElementById('mySidenav').style.width = '85%'
    document.getElementById('mySidenavBackground').style.width = '100vw'
    document.getElementById('mySidenav').style.padding = '10px'
  }

  function handleSubmiSearch(e) {
    e.preventDefault()
    history.push('/store/products')
  }

  function handleNavigateToItems() {
    dispatch([
      changeValue('', 'search'),
      changeValue(1, 'step')
    ])
    history.push('/store/items')
  }

  function handleNavigateToHome() {
    history.push('/')
  }

  function whatsappContact() {
    window.open('https://api.whatsapp.com/send?phone=5511995024999&text=Olá,%20gostaria%20de%20mais%20informações%20sobre%20o%20RCLUB.', '_blank')

  }

  return (
    <header className={classes}>
      <section style={{ justifyContent: 'flex-start', alignItems: 'center', marginRight: '2%' }}>
        <FontAwesomeIcon icon={faBars} onClick={handleOpenModal} cursor='pointer' color='#FFFFFF' aria-hidden="false" fontWeight='bold' />
      </section>
      <img src={logo} className='mobile-header-logo' style={{ justifyContent: 'flex-start', marginLeft: '16%' }} />
      <div onClick={handleNavigateToHome}>
        <img src={logo} alt="Rclub" />
      </div>
      <section style={{ paddingLeft: '16%', justifyContent: 'flex-start', alignItems: 'center' }}>
        <FontAwesomeIcon icon={faWhatsapp} onClick={whatsappContact} cursor='pointer' color='#FFFFFF' aria-hidden="false" />
      </section>
      <section style={{ paddingLeft: '3%', justifyContent: 'flex-start', alignItems: 'center' }}>
        <FontAwesomeIcon
          icon={faShoppingCart}
          cursor='pointer'
          onClick={handleNavigateToItems}
          color='white'
          aria-hidden="false"
        />
        <span id='badge-itens' onClick={handleNavigateToItems}>{step === 4 ? 0 : selectedProducts.length}</span>
      </section>
    </header>
  )
}

export default withRouter(HeaderMobile)