import api from "../services/api"

const create = async contact => {
  try {

    const response = await api.post('contacts', contact)
    return response.data

  } catch (err) {
    throw err
  }
}

const getAll = async () => {
  try {

    const response = await api.get('contacts')
    return response.data

  } catch (err) {
    throw err
  }
}

export default {
  create,
  getAll
}