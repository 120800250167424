import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit, faChartBar } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import ReactTable from 'react-table-6';
import { cnpjMask, phoneMask } from '../../../../utils/normalize';
import { useHistory, Link } from 'react-router-dom';
import companiesRepository from '../../../../repositories/Companies'
import { Modal } from 'react-bootstrap';
import CompanyDetails from './CompanyDetails'

function Companies() {

  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [companies, setCompanies] = useState([])
  const [company, setCompany] = useState([])
  const [isCompanyDetailsModalOpen, setIsCompanyDetailsModalOpen] = useState(false)


  const history = useHistory()

  useEffect(() => {
    loadCompanies()
    document.title = 'Rclub - Empresas'
  }, [])

  const loadCompanies = async () => {
    setLoading(true)

    try {
      const companies = await companiesRepository.getAll()
      setCompanies(companies)

    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar as empresas. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenStockDetailsModal = company => {
    setCompany(company)
    setIsCompanyDetailsModalOpen(true)
  }

  return (
    <div id='admin-page-companies'>
      <header>
        <BreadCrumb path={['home', null]} data={['Início', 'Empresas']} />
        <section>
          <button className='btn btn-success' onClick={() => history.push('company')}>+ Nova Empresa</button>
          <div>
            <FontAwesomeIcon icon={faSearch} />
            <input
              className='form-control foco-input'
              title='Pesquisar por CNPJ, Razão Social e Cidade'
              placeholder='Pesquisar por CNPJ, Razão Social e Cidade'
              type='text'
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        </section>
      </header>

      <br />
      <ReactTable
        style={{ textAlign: 'center' }}
        data={!!query
          ? companies.filter(x =>
            x['companyName'].toString().toLowerCase().includes(query.toLowerCase()) ||
            x['city'].toString().toLowerCase().includes(query.toLowerCase()) ||
            x['cnpj'].toString().toLowerCase().includes(query.toLowerCase())
          )
          : companies
        }
        columns={[
          {
            Header: 'CNPJ',
            accessor: 'cnpj',
            Cell: props => <>{cnpjMask(props.value)}</>
          },
          {
            Header: 'Razão Social',
            accessor: 'companyName'
          },
          {
            Header: 'Telefone',
            accessor: 'phone',
            Cell: props => <>{phoneMask(props.value)}</>
          },
          {
            Header: 'Cidade',
            accessor: 'city',
          },
          {
            Header: 'Status',
            accessor: 'isActive',
            Cell: props => (
              Boolean(props.value)
                ? <button className='btn btn-success'>Ativo</button>
                : <button className='btn btn-danger'>Inativo</button>
            )
          },
          {
            Header: 'Ações',
            accessor: 'id',
            Cell: props => (
              <div id='companies-table-actions-wrapper'>
                <Link to={{ pathname: `company#${props.value}` }}>
                  <FontAwesomeIcon icon={faEdit} title='Editar' color='black' />
                </Link>
                <FontAwesomeIcon
                  icon={faChartBar}
                  title='Detalhes'
                  color='black'
                  cursor='pointer'
                  onClick={() => handleOpenStockDetailsModal(props.original)}
                />
              </div>
            )
          }
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
      {isCompanyDetailsModalOpen &&
        (<Modal show={isCompanyDetailsModalOpen} onHide={() => setIsCompanyDetailsModalOpen(false)} animation={true} size="lg" >
          <Modal.Header closeButton>
            <div id='company-details-modal-header'>
              <h5><strong>{company.companyName}</strong></h5>
              <h6><strong>CNPJ: </strong>{cnpjMask(company.cnpj)}</h6>
              <h6><strong>E-mail: </strong>{company.email}</h6>
              <h6><strong>Telefone: </strong>{phoneMask(company.phone)}</h6>
            </div>
          </Modal.Header>
          <Modal.Body>
            <CompanyDetails company={company} />
          </Modal.Body>
        </Modal>
        )}
    </div>
  )
}

export default Companies