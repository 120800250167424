import api from "../services/api"

const getAll = async () => {
  try {

    const response = await api.get('sales')
    return response.data

  } catch (err) {
    throw err
  }
}

const getAllSalesNotCanceled = async () => {
  try {

    const response = await api.get('sales?notCanceled=true')
    return response.data

  } catch (err) {
    throw err
  }
}

const create = async sale => {
  try {

    const response = await api.post('sales', sale)
    return response.data

  } catch (err) {
    throw err
  }
}

const createBankSlip = async sale => {
  try {

    const response = await api.post('sales/bankSlip', sale)
    return response.data

  } catch (err) {
    throw err
  }
}

const createBankSlipBB = async sale => {
  try {

    const response = await api.post('sales/bankSlipBB', sale)
    return response.data

  } catch (err) {
    throw err
  }
}

const createDebitCard = async sale => {
  try {

    const response = await api.post('sales/debit-card', sale)
    return response.data

  } catch (err) {
    throw err
  }
}

const createPix = async sale => {
  try {

    const response = await api.post('sales/pixpayment', sale)
    return response.data

  } catch (err) {
    throw err
  }
}

const confirmPix = async paymentId => {
  try {
    await api.put(`sales/confirmpixpayment`, {
      paymentId
    })
  } catch (err) {
    throw err
  }
}

const getOverviewByCompany = async companyId => {

  try {
    const response = await api.get(`sales/overview/${companyId}`)
    return response.data

  } catch (err) {
    throw err
  }
}

const cancel = async id => {
  try {
    await api.put(`sales/${id}/cancel`)
  } catch (err) {
    throw err
  }
}

const createFromAdmin = async sale => {
  try {

    const response = await api.post('sales/admin', sale)
    return response.data

  } catch (err) {
    throw err
  }
}

const getSalePDF = async id => {
  try {
    const response = await api.get(`sales/${id}/pdf`)
    return response.data
  } catch (err) {
    throw err
  }
}

const confirm = async paymentId => {
  try {
    await api.put(`sales/confirmBankSlip`, {
      paymentId
    })
  } catch (err) {
    throw err
  }
}

const consultBankSlip = async paymentId => {
  try {

    const response = await api.get(`sales/bankslipbyidintegration?idIntegration=${paymentId}`,)
    return response.data

  } catch (err) {
    throw err
  }
}

const resendEmail = async obj => {
  try {

    const response = await api.post('sales/resendemail', obj)
    return response.data

  } catch (err) {
    throw err
  }
}

const resendPendingPaymentEmail = async obj => {
  try {

    const response = await api.post('email/pendingPayment', obj)
    return response.data

  } catch (err) {
    throw err
  }
}

const getBankSlipBySaleId = async saleId => {
  try {

    const response = await api.get(`bankSlipInfo/${saleId}`)
    return response.data

  } catch (err) {
    throw err
  }
}

const getAwaitingPaymentSlip = async () => {
  try {

    const response = await api.get('sales/bankslip-awaitingpayment')
    return response.data

  } catch (err) {
    throw err
  }
}

const getByCompany = async (companyId) => {
  try {

    const response = await api.get(`sales?companyId=${companyId}&saleStatusId=4`)
    return response.data

  } catch (err) {
    throw err
  }
}

export default {
  getAll,
  create,
  createFromAdmin,
  getOverviewByCompany,
  cancel,
  getSalePDF,
  getAllSalesNotCanceled,
  createBankSlip,
  createBankSlipBB,
  confirm,
  consultBankSlip,
  resendEmail,
  resendPendingPaymentEmail,
  getBankSlipBySaleId,
  getAwaitingPaymentSlip,
  createDebitCard,
  createPix,
  confirmPix,
  getByCompany,
}