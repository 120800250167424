import api from "../services/api"

const create = async listoptions => {
  try {
    
    await api.post('/dropouts', listoptions)

  } catch(err) {
    throw err
  }
}

export default {
    create,
  }