import React, { useState, useEffect } from 'react';
import './styles.css';
import './styles99.css';

const SeeProducts = ({history, text, direction, rclub99}) => {

  function handleProductsPage() {
    history.push(window.location.href.split("store")[1] ? direction : "store/" + direction)
  }
  
  return (
    <>
      { rclub99 ? 
        <>
          <div className="see-products-99-row">
            <div className="see-products-99" onClick={handleProductsPage}>
              <h5>{text}</h5>
            </div>
          </div>
        </>
          : 
        <>
          <div className="see-products-row">
            <div className="see-products" onClick={handleProductsPage}>
              <h5>{text}</h5>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default SeeProducts;