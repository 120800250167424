import React, { useEffect, useState } from 'react';
import { setCookie } from '../../../../utils/cookies';
import { useHistory } from 'react-router-dom';
import { validadeEmail } from '../../../../utils/validation';
import emailRepository from '../../../../repositories/Email';
import AlertModal from '../../../Store/components/ModalAlertMsg';
import LoadingScreen from '../../components/Loading';
import PasswordModal from './ModalSuccess';

import './styles.css';

function PasswordRecovery() {

  const [email, setEmail] = useState('');
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [sendedEmail, setSendedEmail] = useState(false);

  const history = useHistory();

  const handleRecovery = async () => {
    if (!validadeEmail(email)) {
      setMessage('Ops, o campo de e-mail está inválido.');
      setIsAlertModalOpen(true);
      return;
    }

    setLoading(true);
    try {
      await emailRepository.recovery(email);
      setSendedEmail(true);
      return;
    } catch (err) {
      if (err.response.status === 404) {
        setMessage('Ops, não encontramos o e-mail cadastrado em nossa base de dados.');
        setIsAlertModalOpen(true);
        return;
      }

      if (err.response.status === 500 || err.response.status === 400) {
        setMessage('Ops, ocorreu um erro ao recuperar a senha, tente novamente mais tarde.');
        setIsAlertModalOpen(true);
        return;
      }

      setMessage('err.response.data.title');
      setIsAlertModalOpen(true);
      return;
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <div className='password-container'>
        <div className='password-info'>
          <div className="password-card">
            <div className='password-text'>
              <label htmlFor="text">Insira seu e-mail que enviaremos sua senha de acesso.</label>
            </div>

            <div className="password-form">
              <div className="password-field">
                <input
                  type="email"
                  value={email}
                  placeholder="E-mail"
                  onChange={e => setEmail(e.target.value)}
                />
              </div>

              <div className="password-form"
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center"
                }}>
                <button className='btn btn-sucesso' onClick={e => handleRecovery()} disabled={!email}>RECUPERAR SENHA</button>
              </div>

            </div>
          </div>
        </div>
      </div>

      {isAlertModalOpen && (
        <AlertModal text={message} onClose={e => setIsAlertModalOpen(false)} />
      )
      }

      {loading && (
        <LoadingScreen />
      )
      }

      {sendedEmail && (
        <PasswordModal />
      )
      }

    </div>
  );
}

export default PasswordRecovery;