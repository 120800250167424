import React, { useState, useEffect } from 'react';
import "./styles.css";
import { faChevronRight, faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function ProductImagesGalleryMobile({ data, information }) {
  const [lastPosition, setLastPosition] = useState(0)
  const [fistPosition, setFistPosition] = useState(0)
  const [arrayProduct, setArrayProduct] = useState([])
  const [colorPrevious, setColorPrevious] = useState("black")
  const [colorNext, setColorNext] = useState("black")
  useEffect(() => {
    selectImage(1)
    setArrayProduct(data)

  }, [])

  function selectImage(idImg) {
    var id = idImg
    setLastPosition(id)
    setFistPosition(id - 1)
    if (id - 1 === 0) {
      setColorPrevious('transparent')
    }
  }

  function nextImage() {
    if (data.length - 1 === lastPosition) {
      setColorNext('transparent')
    }
    if (data.length === lastPosition) {
      setColorNext('transparent')
    } else {
      var last = lastPosition + 1
      var fist = fistPosition + 1
      setLastPosition(last)
      setFistPosition(fist)
      setColorPrevious('black')

    }
  }

  function previousImage() {
    if (fistPosition === 1) {
      setColorPrevious('transparent')
    }
    if (((Number(arrayProduct.length) + 1) * fistPosition) === 0) {
      setColorPrevious('transparent')
    } else {
      var last = lastPosition - 1
      var fist = fistPosition - 1
      setLastPosition(last)
      setFistPosition(fist)
      setColorNext('black')
    }
  }

  return (
    
    
    <div className="ImageMaxContainerMobile">
      <h2>{information?.description}</h2>
      <div className="ImageMaxMobile">
        <h1>{fistPosition+1} / {data?.length}</h1>
        <div className="previousImageMobile">
          <FontAwesomeIcon icon={faChevronLeft} size='3x' saria-hidden="false" color={colorPrevious} onClick={() => previousImage()} />
        </div>
        {data?.slice(fistPosition, lastPosition).map((uploadedFile, index) => {

          return !uploadedFile?.isVideo && (<img
            src={uploadedFile?.imageUrl}
            width={500} height={200}
          />)
        }
        )}
        {data?.slice(fistPosition, lastPosition).map((uploadedFile, index) => {

          return uploadedFile?.isVideo && (<video controls width={250} height={200} type="video/mp4"
            src={uploadedFile?.imageUrl}
          ></video>)
        }
        )}
        <div className="nextImageMobile">
          <FontAwesomeIcon icon={faChevronRight} size='3x' saria-hidden="false" color={colorNext} onClick={() => nextImage()} />

        </div>
      </div>
    </div>

  )
}