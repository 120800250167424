import React from 'react'

const RenderField = ({
  input,
  label,
  type,
  placeholder,
  rest,
  disabled,
  required,
  maxLength,
  as,
  children,
  style,
  meta: { touched, error, warning }
}) => (
  <div>
    <label htmlFor={label}>{label}{required && <span style={{ color: 'red' }}>*</span>}</label>
    <div>

      {as === 'select' 
      ? <select {...input} style={style} id={label} disabled={disabled} {...rest} className='form-control foco-input'>
        {children}
      </select>
      : as === 'textarea'
      ? <textarea {...input} style={style} maxLength={maxLength} id={label} disabled={disabled} {...rest} className='form-control foco-input' placeholder={placeholder} />
      : <input {...input} style={style} maxLength={maxLength} id={label} disabled={disabled} {...rest} className='form-control foco-input' placeholder={placeholder} type={type} />
      }
      
      {touched &&
        ((error && <span style={{ color: 'red' }}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
)

export default RenderField