import React, { useState, useEffect } from 'react'
import { Modal, Col, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import AlertModal from '../../../../components/AlertModal'
import { Field } from 'redux-form'
import './styles.css'
import { toastr } from 'react-redux-toastr'
import smsRepository from '../../../../repositories/SMS'

import { onlyNumbers } from '../../../../utils/normalize'

const SmsModal = ({ show, onHide, listSales }) => {
  const [isConfirmSmsModalOpen, setIsConfirmSmsModalOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [isDisable, setIsDisable] = useState(true)
  toastr.info.fadeOut = 5000
  const handleMessage = sendMessage => {
    setMessage(sendMessage)
  }

  useEffect(() => {
    handleDisableButton()
  }, [message])

  const handleDisableButton = () => {
    if (message.length <= 0) {
      setIsDisable(true)
    }
    else {
      setIsDisable(false)
    }
  }

  const handleOpenSendSmsModal = () => {
    setIsConfirmSmsModalOpen(true)
  }

  const sendSms = async () => {
    try {
      setIsConfirmSmsModalOpen(false)

      const hasChecked = listSales.filter(sale => sale.checked === true)
      if (hasChecked.length >= 1 && hasChecked[0] !== undefined) {
        hasChecked.map(async client => {
          let finalMessage = message
            .replace("{Nome}" || "{nome}", (client.clientName || client.Name))
            .replace("{LinhaDigitavel}" || "{linhadigitavel}", client.digitableLine)

          try {
            await smsRepository.sendSms(onlyNumbers(client.clientPhone ||client.phone), finalMessage)
            .then(() => toastr.success('SMS enviado para a Comtele.'))
          } catch (error) {
            toastr.error('Erro ao enviar SMS. Erro: ' + error)
          }
        })
      } else {
        listSales.map(async client => {
          let finalMessage = message
            .replace("{Nome}" || "{nome}", (client.clientName || client.Name))
            .replace("{LinhaDigitavel}" || "{linhadigitavel}", client.digitableLine)

          try {
            await smsRepository.sendSms(onlyNumbers(client.clientPhone ||client.phone), finalMessage)
            .then(() => toastr.success('SMS enviado para a Comtele.'))
          } catch (error) {
            toastr.error('Erro ao enviar SMS. Erro: ' + error)
          }
        })
      }

      onHide()

    } catch (error) {
      toastr.error('Erro ao enviar SMS. Erro: ' + error)
    }
  }

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Rclub</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id='sms-modal-options'>
          <div>
            <label>
              Digite a mensagem que deseja enviar no SMS
            </label>
            <textarea
              className="form-control"
              rows="3"
              maxLength={140}
              onChange={(e) => handleMessage(e.target.value)}
              style={{ marginBottom: '2%' }}
            />
            <span>{140 - message.length} caracteres restantes</span>
          </div>
          <div>
            <button
              className='btn btn-success'
              onClick={() => handleOpenSendSmsModal()}
              disabled={isDisable}
              style={isDisable ? { cursor: 'default' } : null}
            >
              <span>Enviar SMS</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {
        isConfirmSmsModalOpen ?
          <AlertModal
            show={isConfirmSmsModalOpen}
            message='Deseja realmente enviar a SMS para os clientes?'
            onHide={() => setIsConfirmSmsModalOpen(false)}
            onCancel={() => setIsConfirmSmsModalOpen(false)}
            onSubmit={sendSms}
          />
          :
          null
      }
    </>
  )
}

SmsModal.defaultProps = {
  onHide: () => { }
}

SmsModal.propTypes = {
  show: propTypes.bool.isRequired,
  onHide: propTypes.func.isRequired,
}

export default SmsModal