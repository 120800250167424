import React from 'react'
import './styles.css'

const FormSubmitButtons = ({ onCancel, onSubmit, hasId, disabled, loading }) => {
  return(
    <div id='form-submit-buttons'>
      <button className='btn btn-danger' type='button' disabled={loading} onClick={onCancel}>Cancelar</button>
      <button className='btn btn-success' type='submit' onClick={onSubmit} disabled={disabled || loading}>
        <span className={loading ? "fa fa-spinner fa-pulse fa-1x" : ''} style={{ marginRight: loading ? "5px" : '' }} />
        {hasId ? 'Atualizar' : 'Salvar'}
      </button>
    </div>
  )
}

export default FormSubmitButtons