import React from 'react'
import { Col, Row, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Field, reduxForm, change } from 'redux-form'

import CardForm from '../../components/CardForm'
import FormSubmitButtons from '../../components/FormSubmitButtons'
import renderField from '../../../../components/RenderField'
import validate from './validate'
import { useSelector, useDispatch } from 'react-redux'
import Toggle from 'react-toggle'

import './styles.css'
import { maxLength, description, onlyNumbers } from '../../../../utils/normalize'
import { useEffect } from 'react'
import { useState } from 'react'

import productTypesRepository from '../../../../repositories/ProductTypes'
import vehicleTypesRepository from '../../../../repositories/VehicleTypes'
import DropPhotoContainer from './DropPhotoContainer'
import ListImages from './ListImages'
import ListVideos from './ListVideos'
import { toastr } from 'react-redux-toastr'
import VehicleTypeForm from '../../components/VehicleTypeForm'
import { createNumberMask } from 'redux-form-input-masks';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDropzone } from 'react-dropzone'

import s3Repository from '../../../../repositories/s3'
import { faCreativeCommonsSamplingPlus } from '@fortawesome/free-brands-svg-icons';

function ProductForm({ handleSubmit, productId, loading, onCancel }) {
  const [productTypes, setProductTypes] = useState([])
  const [vehicleTypes, setVehicleTypes] = useState([])
  const [productImagesAndVideos, setProductImagesAndVideos] = useState([]);
  const [isAddVehicleTypeModalOpen, setIsAddVehicleTypeModalOpen] = useState(false);
  const [productsImagesPreview, setProductsImagesPreview] = useState([]);
  const [productsVideosPreview, setProductsVideosPreview] = useState([]);
  const [midiaToUpload, setMidiaToUpload] = useState([]);

  const { values } = useSelector(state => state).form.product
  const { isActive, hasPrize, observations, filesToUpload } = values

  const { getRootProps, getInputProps } = useDropzone({ accept: 'image/*' | 'video/*' })

  const currencyMask = createNumberMask({
    prefix: 'R$ ',
    decimalPlaces: 2,
    locale: 'pt-BR'
  })

  const dispatch = useDispatch()

  const uuidv4 = require("uuid/v4");

  const createMidiaFile = file => {
    var video = false;
    var ext = file.name.split('.').pop();
    var id = uuidv4()

    if(ext === 'mp4'){
      video = true;
    } else{
      video = false;
    }

    const midiaFile = ({
      id: id,
      productId: values.code,
      imageName: file.name,
      imageUrl: URL.createObjectURL(file),
      isVideo: video,
      notUploadedYet: true,
      createdAt: null,
      updatedAt: null
    })

    if(ext === 'mp4'){
      productsVideosPreview.push(midiaFile)
    } else {
      productsImagesPreview.push(midiaFile)
    }
  }

  useEffect(() => {
    if(values.code != undefined)
      loadImages(productId)
      loadVideos(productId)
  }, [values.code])

  useEffect(() => {
    loadProductTypes()
  }, [])

  useEffect(() => {
    loadVehicleTypes()
  }, [])

 useEffect(() => {
    if(productImagesAndVideos.length > 0){
      midia(productImagesAndVideos)
    }
 }, [productImagesAndVideos])

  const loadProductTypes = async () => {

    try {
      const productTypes = await productTypesRepository.getAllSortedByDescription()
      setProductTypes(productTypes)

    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar os tipos de produto. Por favor, tente novamente')
    }
  }

  async function loadImages(code){
    try{
      const response = await s3Repository.getProductImages(code)
      .then(response => {
        if(response.data.length !== 0){
          return setProductsImagesPreview(response.data)
        }
        else{
          return 0
        }
      })
    } catch(err){
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar as imagens. Por favor, tente novamente')
    }
  }

  async function loadVideos(code){
    try{
      const response = await s3Repository.getProductVideos(code)
      .then(response => {
        if(response.data.length !== 0){
          return setProductsVideosPreview(response.data)
        }
        else{
          return 0
        }
      })
    } catch(err){
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar os vídeos. Por favor, tente novamente')
    }
  }

  function midia(files){
    var i = 0;
    while(i < files.length ){
      
      createMidiaFile(files[i])
      midiaToUpload.push(files[i])

      const newArray = [...midiaToUpload]

      dispatch(change('product', 'filesToUpload', newArray))

      i++;
    }
    setProductImagesAndVideos([])
  }

  async function uploadMidia (midiaUpload){

    var ext = midiaUpload.name.split('.').pop();
    const formData = new FormData();
    if(ext === 'mp4'){
      formData.append('image', midiaUpload, midiaUpload.name)
    } else {
      formData.append('image', midiaUpload, midiaUpload.name)
    }

    try {
      await s3Repository.singleUploadBanner(formData)
      .then((resp) => {
        if(ext === 'mp4'){
          const response = saveProductsVideos(values.code, resp, midiaUpload.name)
        } else {
          const response = saveProductsImage(values.code, resp, midiaUpload.name)
        }
      })
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao gravar a mídia. Por favor, tente novamente')
    }
  }

  async function saveProductsImage(productId, urlImg, nameImg) {
    try {
      await s3Repository.saveProductImages({
          "productId": productId,
          "imageName": nameImg,
          "imageUrl": urlImg
      })
      .then((response) => {
        return toastr.success('A mídia foi salva com sucesso.')})

    } catch (err) {
      console.log(err.response)
      toastr.error('Ocorreu um erro ao gravar a imagem. Por favor, tente novamente')
    }
  }

  async function saveProductsVideos(productId, urlVideo, nameImg) {
    try {
      s3Repository.saveProductsVideos({
        "productId": productId,
        "videoName": nameImg,
        "videoUrl": urlVideo
      })
      .then((resp) => {
        return toastr.success('A mídia foi salva com sucesso.')
      })
    } catch (err) {
      console.log(err.response)
      toastr.error('Ocorreu um erro ao gravar o vídeo. Por favor, tente novamente')
    }
  }

  const loadVehicleTypes = async () => {
    try {
      const vehicleTypes = await vehicleTypesRepository.getAllSortedByDescription()
      setVehicleTypes(vehicleTypes)

    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar as os tipos de veículo. Por favor, tente novamente')
    }
  }

  const handleSubmitVehicleTypeModal = vehicleType => {
    dispatch(change('product', 'vehicleTypeId', vehicleType.id))
    loadVehicleTypes()
    setIsAddVehicleTypeModalOpen(false)
  }

  return (
    <form id='admin-page-product-form' onSubmit={handleSubmit}>
      <CardForm
        title='Produto'
        show
      >
        <Row id='admin-page-product-form-grid-container'>
          <DropPhotoContainer />
          <Col id='admin-page-product-form-display-flex'>
            <Col xs={12} sm={12} md={2} lg={2}>
              <Field
                name='code'
                type='text'
                component={renderField}
                label='Código'
                disabled
              />
            </Col>

            <Col xs={12} sm={12} md={5} lg={4}>
              <Field
                name='description'
                type='text'
                component={renderField}
                label='Descrição'
                required
                normalize={value => maxLength(description(value), 80)}
              />
            </Col>

            <Col xs={12} sm={12} md={2} lg={2}>
              <Field
                name='price'
                type='text'
                component={renderField}
                label='Preço'
                {...currencyMask}
                required
              />
            </Col>

            <Col xs={12} sm={12} md={3} lg={3, 4} style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ marginRight: '12px' }}>
                <label htmlFor="isActive">Status<span style={{ color: 'red' }}>*</span></label>
                <br />
                <Toggle checked={isActive} onChange={() => dispatch(change('product', 'isActive', !isActive))} />
              </div>
              <div>
                <label htmlFor="hasPrize">Adic. Brinde ?<span style={{ color: 'red' }}>*</span></label>
                <br />
                <Toggle checked={hasPrize} onChange={() => dispatch(change('product', 'hasPrize', !hasPrize))} />
              </div>

            </Col>


            <Col xs={12} sm={12} md={3} lg={7} /*className='col-has-prize'*/>
              <Field
                name='complementaryDescription'
                type='text'
                component={renderField}
                label='Descrição Complementar'
                normalize={value => maxLength(description(value), 80)}
                onChange={() => dispatch(change('product', 'complementaryDescription'))}
              />
            </Col>


            <Col xs={12} sm={12} md={1} lg={1} className='col-maximum-minimum'>
              <Field
                name='minimumQuantity'
                type='text'
                component={renderField}
                label='Qtde. Minima'
                required
                normalize={value => maxLength(onlyNumbers(value), 2)}
              />
            </Col>

            <Col xs={12} sm={12} md={1} lg={1} className='col-maximum-minimum'>
              <Field
                name='maximumQuantity'
                type='text'
                component={renderField}
                label='Qtde. Máxima'
                required
                normalize={value => maxLength(onlyNumbers(value), 2)}
              />
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} className='col-product-types'>
              <Field
                name='productTypeId'
                type='text'
                component={renderField}
                as='select'
                label='Tipo de Produto'
                required
              >
                <option value="">Selecione</option>
                {productTypes.map(productType => {
                  return <option key={productType.id} value={productType.id}>{productType.description}</option>
                })}
              </Field>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} id='form-product-vehicle-type-container' >
              <Field
                name='vehicleTypeId'
                type='text'
                component={renderField}
                as='select'
                label='Tipo de Veículo'
                required
              >
                <option value="">Selecione</option>
                {vehicleTypes.map(vehicleType => {
                  return <option key={vehicleType.id} value={vehicleType.id}>{vehicleType.description}</option>
                })}
              </Field>
              <center>
                <button type='button' onClick={() => setIsAddVehicleTypeModalOpen(true)} className='btn btn-link' style={{ marginLeft: '15%', width: '100%' }}>Adicionar tipo de veículo</button>
              </center>
            </Col>
          </Col>
        </Row>
      </CardForm>
      <CardForm
        title='Informações'
        show
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} id='columnd'>
            <Field
              name='observations'
              component={renderField}
              label='Informações (Máximo de 1000 caracteres)'
              required
              as='textarea'
              style={{ resize: 'vertical', height: '200px' }}
              maxLength='1000'
            />
          </Col>
        </Row>
        <span><strong>{1000 - observations.length} Caracteres restantes</strong></span>
      </CardForm>
      <CardForm
        title='Imagens e Vídeos'
        show
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className="submit-images" style={{ resize: 'vertical', height: '200px', display: 'flex', paddingLeft: '5px'}}>
                <div {...getRootProps()} className='submit-product-images' >
                  <FontAwesomeIcon icon={faPlusCircle} size="2x" color='#7A7A7A' />
                  <h1 style={{fontSize: '13px', fontWeight: '600', color: '#7a7a7a'}}>Adicionar Imagem/Vídeo</h1>
                  <input {...getInputProps()} disabled={false} onChange={value => setProductImagesAndVideos( value?.target.files)} multiple={true} />
                </div>
                <div style={{paddingLeft: '20px'}}>
                  <h3 style={{fontSize: '14px'}}><strong>Imagens e vídeos adicionais do produto*</strong></h3>
                </div>
            </div>
            <div className ='images-miniatures'>
              {productsImagesPreview ?
              <>
              <ListImages filesArray={productsImagesPreview} />
              </>
              : null
              }
            </div>
            <div className ='videos-miniatures'>
              {productsVideosPreview ?
              <>
              <ListVideos filesArray={productsVideosPreview} />
              </>
              : null
              }
            </div>
          </Col>
        </Row>
      </CardForm>

      <FormSubmitButtons hasId={!!productId} loading={loading} onSubmit={handleSubmit} onCancel={onCancel}/>

      {isAddVehicleTypeModalOpen && (<Modal show={isAddVehicleTypeModalOpen} onHide={() => setIsAddVehicleTypeModalOpen(false)} animation={true} size="lg" >
        <Modal.Header closeButton >
          <h6><strong>Tipo de Veículo</strong></h6>
        </Modal.Header>
        <Modal.Body>
          <VehicleTypeForm onSubmit={handleSubmitVehicleTypeModal} onCancel={() => setIsAddVehicleTypeModalOpen(false)} />
        </Modal.Body>
      </Modal>
      )}
    </form>
  )
}

ProductForm.prototype = {
  handleSubmit: PropTypes.func.isRequired,
  productId: PropTypes.string,
  loading: PropTypes.bool.isRequired
}

export default reduxForm({
  form: 'product',
  validate: validate
})(ProductForm)