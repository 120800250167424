import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

function ModalAlertMsg({ text, onClose }) {

    return (
        <div>
            <div className='modal-alert-msg'>
                <Modal style={{ marginTop: "15%", display: 'flex', alignItems: 'center', justifyContent: 'center' }} show={true} onHide={() => onClose()} animation={true}  >
                    <div class="modal-content" style={{}}>
                        <Modal.Header closeButton style={{}}>
                            <div id='company-details-modal-header'>
                                <h5><strong>RCLUB</strong></h5>
                            </div>
                        </Modal.Header >
                        <Modal.Body style={{}}>
                            <h1 style={{ fontSize: "15px", fontWeight: "normal" }}>{text}</h1>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <button className='btn btn-success' name="Sim" style={{ marginLeft: 5 }} onClick={() => onClose()}>Ok</button>
                            </div>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default ModalAlertMsg;
