import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Col, Modal, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { toastr } from 'react-redux-toastr'
import ModalAlertMsg from '../../../../components/ModalAlertMsg'


import '../../styles.css'
import Card from '../../../../components/Card';
import getAddressByCep from '../../../../../../utils/viaCep'
import { cpfMask, phoneMask, cepMask, onlyNumbers } from '../../../../../../utils/normalize'
import { handleChange } from '../../../../../../store/actions/cart'


const BankSlipPayment = () => {

  const [isNoCompaniesAtCityModalOpen, setIsNoCompaniesAtCityModalOpen] = useState(false)
  const { cartReducer } = useSelector(state => state)
  const { bankSlipAddress } = cartReducer
  const { postalCode, bankSlipAddressInfo, bankSlipNeighborhood, bankSlipState, bankSlipCity, bankSlipAddressNumber } = bankSlipAddress
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalInfoOpen, setIsModaInfolOpen] = useState(false)

  const [addressNumber, setAddressNumber] = useState('')
  const [cep, setCep] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    window.document.title = 'Rclub - Dados de Endereço'
    setIsModaInfolOpen(true)
  }, [])

  async function updateAddressNumberInfo(number) {

    setAddressNumber(number)

    const addressData = {
      postalCode: postalCode,
      bankSlipAddressInfo: bankSlipAddressInfo,
      bankSlipNeighborhood: bankSlipNeighborhood,
      bankSlipState: bankSlipState,
      bankSlipAddressNumber: number,
      bankSlipCity: bankSlipCity
    }

    await dispatch(handleChange(addressData, 'bankSlipAddress'))

  }

  async function handleZipCodeAddressSearch() {

    if (!cep) {
      return toastr.warning('Por favor, informe o CEP para prosseguir.')
    }

    const zipcode = onlyNumbers(cep)

    if (zipcode.length < 8 || zipcode === '111111111') {
      return toastr.warning('Por favor, informe um CEP válido para prosseguir.')
    }

    try {
      const addressData = await getAddressByCep(zipcode)

      const bankSlipAddress = {
        postalCode: cep,
        bankSlipAddressInfo: addressData.address,
        bankSlipNeighborhood: addressData.neighborhood,
        bankSlipState: addressData.state,
        bankSlipAddressNumber: addressNumber,
        bankSlipCity: addressData.city
      }

      await dispatch(handleChange(bankSlipAddress, 'bankSlipAddress'))

    } catch (err) {
      toastr.warning(err.message)
    }
  }

  return (
    <>
      <div id='client-wrapper'>
        <Card title='Dados de Endereço'>

          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <label>CEP</label>
              <Col xs={12} sm={12} md={6} lg={4} style={{ padding: 0, minWidth: '290px' }}>
                <div className="input-group">
                  <input
                    className='form-control foco-input'
                    type="text"
                    name='zipCode'
                    value={cepMask(cep)}
                    onChange={e => setCep(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button onClick={() => handleZipCodeAddressSearch()} type='button' className="input-group-text">Ok</button>
                  </div>
                  <div className='dont-know-zipcode'>
                    <a href='http://www.buscacep.correios.com.br/sistemas/buscacep/' rel="noopener noreferrer" target='_blank'>Não sei o CEP <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <label>Endereço</label>
              <input
                className='form-control foco-input'
                type="text"
                name='address'
                value={bankSlipAddressInfo}
                disabled={true}
              />
            </Col>

            <Col xs={12} sm={12} md={2} lg={2}>
              <label>Número</label>
              <input
                className='form-control foco-input'
                type="text"
                name='addressNumber'
                value={addressNumber}
                onChange={e => updateAddressNumberInfo(e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={4} lg={4}>
              <label>Bairro</label>
              <input
                className='form-control foco-input'
                type="text"
                name='neighborhood'
                value={bankSlipNeighborhood}
                disabled={true}
              />
            </Col>

            <Col xs={12} sm={12} md={3} lg={3}>
              <label>Cidade</label>
              <input
                className='form-control foco-input'
                type="text"
                name='city'
                value={bankSlipCity}
                disabled={true}
              />
            </Col>

            <Col xs={12} sm={12} md={3} lg={3}>
              <label>Estado</label>
              <input
                className='form-control foco-input'
                type="text"
                name='state'
                value={bankSlipState}
                disabled={true}
              />
            </Col>
          </Row>
        </Card>
      </div>
      {isModalInfoOpen ?
        <ModalAlertMsg text="Para a geração do boleto precisamos dos dados dos seu endereço, por favor, informe o seu CEP e depois complemente as informações com o número da sua residência." onClose={() => { setIsModaInfolOpen(false) }} />
        :
        null
      }
    </>
  )
}

export default BankSlipPayment