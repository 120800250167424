import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { clearReducer } from '../../../../../../store/actions/cart'
import { identificNav } from '../../../../../../utils/FindNavigator'
import { toastr } from 'react-redux-toastr'

const ResumeBankSlip = () => {
  const { cartReducer: { client, saleId, bankSlipPdfBase64, saleIdBankSlip } } = useSelector(state => state)
  const dispatch = useDispatch()

  var navigator = ''

  useEffect(() => {
    window.document.title = `Rclub - Resumo Venda #${saleIdBankSlip}`

    // navigator = identificNav()
    // downloadPDF(bankSlipPdfBase64)

    return () => {
      dispatch(clearReducer())
    }
  }, [])

  // function downloadPDF(pdf) {

  //   if (navigator === 'Firefox') {
  //     const linkSource = `data:application/pdf;base64,${pdf}`;
  //     window.open(linkSource, '_blank');
  //   } else {
  //     let pdfWindow = window.open("")
  //     pdfWindow.document.write(
  //       "<iframe style='width: 100%; height: 100%; border: none;' src='data:application/pdf;base64, " +
  //       encodeURI(pdf) + "'></iframe>"
  //     )
  //   }
  // }


  function downloadPDF() {
    var a = document.createElement("a");
    a.href = "data:application/pdf;base64," + bankSlipPdfBase64;
    a.download = `Boleto_Pedido_#${saleIdBankSlip}.pdf`;
    a.click();
  }

  return (
    <div id="resume-page-bankSlip">
      <h3>Compra Aguardando Pagamento do Boleto</h3>
      {<p>
        O boleto para pagamento foi enviado para o e-mail <strong>{client.email}</strong>
      </p>}
      <button className="btn btn-link" onClick={downloadPDF}><p>Clique aqui e faça o Download do Boleto</p></button>

      <Link to='/'>Voltar para a tela inicial</Link>
    </div>
  )
}

export default ResumeBankSlip