import React, { useState, useEffect, useCallback } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faComment } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import ReactTable from 'react-table-6';
import contactsRegisterRepository from '../../../../repositories/ContactsRegister'
import { phoneMask } from '../../../../utils/normalize'
import DownloadXls from '../../components/DownloadXlsButton';
import SmsModal from '../../components/SmsModal'

function RegisterContact() {
  const [loading, setLoading] = useState(false)
  const [contacts, setContacts] = useState([])
  const [contactsToExport, setContactsToExport] = useState([])
  const [contactsFiltered, setContactsFiltered] = useState([])
  const [contactName, setContactName] = useState('')
  const [isOpenSmsModal, setIsOpenSmsModal] = useState(false)
  const [textButton, setTextButton] = useState("Selecionar Todos")
  const [isEnabledButtonSms, setIsEnabledButtonSms] = useState(true)

  useEffect(() => {
    loadContacts()
    document.title = 'Rclub - Relatórios'
  }, [])

  useEffect(() => {
    let countTrue = 0
    for (let i in contacts) {
      if (contacts[i].checked === true) {
        countTrue = countTrue + 1
        if (!countTrue) {
          setIsEnabledButtonSms(true)
        } else {
          setIsEnabledButtonSms(false)
        }
      }
      if (countTrue === 0) {
        setIsEnabledButtonSms(true)
      }
    }
  }
    , [contacts])

  const xlsColumns = [
    {
      name: 'Código',
      acessor: 'code'
    },
    {
      name: 'Data',
      acessor: 'date'
    },
    {
      name: 'Nome',
      acessor: 'name'
    },
    {
      name: 'E-mail',
      acessor: 'email'
    },
    {
      name: 'Celular',
      acessor: 'phone'
    },
    {
      name: 'Tipo',
      acessor: 'type'
    }
  ]

  const updateXlsDataToDownload = useCallback(() => {
    setLoading(true)
    const xlsData = contactsFiltered.map(contact => ({
      code: contact.id,
      date: new Date(contact.createdAt).toLocaleString().split(' ')[0],
      name: contact.Name,
      email: contact.email,
      phone: phoneMask(contact.phone),
      type: contact.typeContact
    }))

    setContactsToExport(xlsData)
    setLoading(false)
  }, [contactsFiltered])

  const setContactsFilteredByName = useCallback(() => {
    let contactsFilteredByName = contacts

    contactsFilteredByName = contactsFilteredByName
      .filter(contact => contact.Name.toLowerCase().includes(contactName.toLowerCase()))

    setContactsFiltered(contactsFilteredByName)
  }, [contactName, contacts])

  useEffect(() => {
    updateXlsDataToDownload()
  }, [contactsFiltered, updateXlsDataToDownload])

  useEffect(() => {
    setContactsFilteredByName()
  }, [contactName, setContactsFilteredByName])

  const loadContacts = async () => {
    setLoading(true)

    try {
      const responseContacts = await contactsRegisterRepository.getAll()

      setContacts(responseContacts.map(item => ({ ...item, checked: false })))
      setContactsFiltered(responseContacts)
    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar os contatos. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenSmsModal = () => {
    setIsOpenSmsModal(true)
  }

  function checkedAll() {
    let checkboxes = contacts
    for (let i in checkboxes) {
      if (textButton === 'Selecionar Todos') {
        checkboxes[i].checked = true;
        setTextButton("Desselecionar Todos")
      } else {
        checkboxes[i].checked = false;
        setTextButton("Selecionar Todos")
      }
    }
  }

  function handleCheckReactTable(item) {

    setContacts(contacts.map(n => {
      if (n.id === item.id) return { ...n, checked: !item.checked }
      return { ...n };
    }));
  };

  return (
    <div id='admin-page-contacts-reports'>
      <header>
        <BreadCrumb path={['home', 'registerContact']} data={['Início', 'Contatos']} />
        <section >
          <div>
            <input
              type="text"
              id='initialDate'
              className='form-control foco-input'
              onChange={e => setContactName(e.target.value)}
              value={contactName}
              placeholder="Pesquisar por Nome"
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'flex-end', marginRight: '0.5%' }}>
            <DownloadXls
              archiveName={`consultasContatosRegistrados${new Date().toLocaleDateString()}`}
              tablesNames={['Consulta Registros']}
              data={[contactsToExport]}
              className='btn btn-export'
              disabled={loading}
              columns={[xlsColumns]}
            >
              <FontAwesomeIcon color='white' icon={faCloudDownloadAlt} /> Exportar .xsl
            </DownloadXls>

          </div>
          <button
            className='btn btn-success'
            onClick={handleOpenSmsModal}
            disabled={isEnabledButtonSms}
          >
            <FontAwesomeIcon icon={faComment} style={{ width: '25px' }}></FontAwesomeIcon>
              Enviar SMS
              </button>
          <button
            className="button-select-all"
            onClick={() => checkedAll()}
            name='all'
          >{textButton}</button>
        </section>
      </header>

      <br />
      <ReactTable
        style={{ textAlign: 'center' }}
        data={contactsFiltered}
        columns={[
          {
            Header: 'Selecionar',
            accessor: 'checked',
            width: 120,
            Cell: props => (
              <input
                type="checkbox"
                checked={props.value}
                onChange={() => handleCheckReactTable(props.original)}
              />
            )
          },
          {
            Header: 'Código',
            accessor: 'id',
            width: 130,
          },
          {
            Header: 'Data',
            accessor: 'createdAt',
            width: 130,
            Cell: props => <>{new Date(props.value).toLocaleString().split(' ')[0]}</>
          },
          {
            Header: 'Nome',
            accessor: 'Name'
          },
          {
            Header: 'E-mail',
            accessor: 'email',
            Cell: props => <>{!(props.value) ? '---' : props.value}</>,
          },
          {
            Header: 'Celular',
            accessor: 'phone',
            Cell: props => <>{phoneMask(props.value)}</>,
            width: 200
          },
          {
            Header: 'Tipo',
            accessor: 'typeContact',
            width: 200,
            Cell: props => <button
              className='btn btn-primary'
              style={props.value === 'Campanha' ? { backgroundColor: 'purple', width: '70%' }
                :
                { backgroundColor: 'blue', width: '70%' }}>
              {props.value}
            </button>
          }
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
      {isOpenSmsModal &&
        (
          <SmsModal show={isOpenSmsModal} onHide={() => setIsOpenSmsModal(false)} listSales={contacts} />
        )
      }
    </div>
  )
}

export default RegisterContact