import React, { useState, useEffect } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import salesRepository from '../../../../repositories/Sales'
import { toastr } from 'react-redux-toastr';
import LoadingScreen from '../../components/Loading';
import { getCookie } from '../../../../utils/cookies';
import { isAppLogged } from '../../../../utils/validation';
import AlertModal from '../../../Store/components/ModalAlertMsg';

import './styles.css'

const CompanyDetails = () => {
  const [loading, setLoading] = useState(false);
  const [soldProductsQuantity, setSoldProductsQuantity] = useState(0)
  const [soldProductsPercentage, setSoldProductsPercentage] = useState(0)
  const [productsCheckedPercentage, setProductsCheckedPercentage] = useState(0)
  const [productsCheckedQuantity, setProductsCheckedQuantity] = useState(0)
  const [stockQuantity, setStockQuantity] = useState(0)
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (isAppLogged()) {
      getSalesOverviewByCompany()
    }
  }, [])

  const getSalesOverviewByCompany = async () => {
    let id = getCookie('APP_COMPANYID')
    try {
      setLoading(true)
      const salesOverview = await salesRepository.getOverviewByCompany(id)
      const { productsCheckedPercentage, salesItemChecked, soldProductsPercentage, soldProductsQuantity, stockQuantity } = salesOverview

      setSoldProductsPercentage(soldProductsPercentage)
      if (productsCheckedPercentage === undefined) {
        setProductsCheckedPercentage(0)
      } else {
        setProductsCheckedPercentage(productsCheckedPercentage)
      }
      if (soldProductsQuantity === undefined) {
        setSoldProductsQuantity(0)
      } else {
        setSoldProductsQuantity(soldProductsQuantity)
      }
      if (salesItemChecked === undefined) {
        setProductsCheckedQuantity(0)
      } else {
        setProductsCheckedQuantity(salesItemChecked)
      }
      if (stockQuantity === undefined) {
        setStockQuantity(0)
      } else {
        setStockQuantity(stockQuantity)
      }
    } catch (err) {
      setLoading(false)
      setMessage('Ocorreu um erro ao buscar o estoque da empresa. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }


  return (
    <div className='container-progressbar'>
      <div className='title-progressbar'>
        <h1 >Total de Check-Ins Feitos</h1>
      </div>
      <div className='content-progressbar'>
        <div className='progressbar'>
          <CircularProgressbar
            value={productsCheckedPercentage}
            text={`${productsCheckedPercentage}%`}
          ></CircularProgressbar>
        </div>
        <div className='text-progressbar'>
          <h1 >{productsCheckedQuantity + ' produtos retirados'}</h1>
          <h1 >{soldProductsQuantity + ' produtos vendidos'}</h1>
        </div>
      </div>
      {loading && (
        <LoadingScreen />
      )
      }
      {isAlertModalOpen && (
        <AlertModal text={message} onClose={e => setIsAlertModalOpen(false)} />
      )
      }
    </div>
  )
}

export default CompanyDetails