const { onlyNumbers } = require("./normalize")

const getAddressByCep = async cep => {
  try {
    const response = await (await fetch(`https://viacep.com.br/ws/${onlyNumbers(cep)}/json/`))

    if(!response.ok) {
      throw new Error('CEP não encontrado, por favor, realize a pesquisa novamente.')
    }

    const data = await response.json()

    if(data.erro) {
      throw new Error('CEP não encontrado, por favor, realize a pesquisa novamente.')
    }

    return {
      address: data.logradouro,
      neighborhood: data.bairro,
      state: data.uf,
      city: data.localidade
    }

  } catch(err) {
    throw new Error('CEP não encontrado, por favor, realize a pesquisa novamente.')
  }
}

export default getAddressByCep