import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import StockDetails from './StockDetails';
import { isAppLogged } from '../../../../utils/validation';
import { useHistory } from 'react-router-dom';

function Stock() {

  const history = useHistory()

  useEffect(() => {
    if (!isAppLogged()) {
      history.push('/partners/login');
    }
  }, [])

  return (
    <div className='stock-app-page'>
        <Header title='ESTOQUE' />
        <StockDetails />
        <Footer />
    </div>
  );
}

export default Stock;