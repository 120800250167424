import React from 'react'
import { Collapse } from 'react-bootstrap'
import './styles.css'

const CardForm = ({ style, children, show, title, onClick }) => (
  <div style={style} className="card-form-container col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <section>
      <div onClick={onClick}>
        <span className={`fa fa-angle-${show ? 'down' : 'right'}`} aria-hidden="true" style={{ marginRight: 5 }} />
        <span>{title}</span>
      </div>
    </section>
    <Collapse in={show}>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        {children}
      </div>
    </Collapse>
  </div>
)

export default CardForm