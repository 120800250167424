import React, { useState, useCallback, useEffect } from 'react';

import './styles.css'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDropzone } from 'react-dropzone'
import crypto from "crypto";
import FileListImage from '../FileListImage'
import api from '../../../../services/api';
import s3Repository from '../../../../repositories/s3'


function ImageSubmit({ title, type, size }) {

  const [imageDesktop, setImageDesktop] = useState([]);
  const [uploadDesktop, setUploadDesktop] = useState(false);
  const [allBannersDesktop, setAllBannersDesktop] = useState([]);
  const [inputDisabledDesktop, setInputDisabledDesktop] = useState(false)
  const [urlLocalDesktop, setUrlLocalDesktop] = useState()
  const [isOpen, setIsOpen] = useState(true);

  const [imageMobile, setImageMobile] = useState([]);
  const [uploadMobile, setUploadMobile] = useState(false);
  const [allBannersMobile, setAllBannersMobile] = useState([]);
  const [inputDisabledMobile, setInputDisabledMobile] = useState(false)
  const [urlLocalMobile, setUrlLocalMobile] = useState()

  const { getRootProps, getInputProps } = useDropzone({ accept: 'image/*' })

  //-------------------- DESKTOP -------------------- //

  useEffect(() => {
    getAllBannerDesktop()

  }, [])

  useEffect(() => {
    if (allBannersDesktop.length >= 3)
      setInputDisabledDesktop(true)

  }, [allBannersDesktop])

  function goSaveDesktop() {
    setUploadDesktop(true)
  }

  useEffect(() => {
    if (imageDesktop.length != 0) {
      const urlImage = URL.createObjectURL(imageDesktop)
      setUrlLocalDesktop(urlImage)
    }
  }, [imageDesktop])

  useEffect(() => {
    if (uploadDesktop) {
      if (imageDesktop.length != 0) {
        createFileObjectDesktop(imageDesktop)
      }
    }
  }, [uploadDesktop])

  const createFileObjectDesktop = file => {
    const imageUpload = ({
      file: file,
      name: `${crypto.randomBytes(16).toString('hex')}-${file.name}`,
      preview: URL.createObjectURL(file)
    })
    setUrlLocalDesktop(imageUpload.preview)
    processUploadDesktop(imageUpload)
  }

  async function processUploadDesktop(imageUpload) {

    const formData = new FormData();
    formData.append('image', imageUpload.file, imageUpload.name)
    // for (var p of formData) {
    //     console.log(p);
    // }

    try {
      s3Repository.singleUploadBanner(formData)

        .then(resp => createdBannerDesktop(resp, imageUpload.name))

    } catch (err) {
      console.log(err.response)

      throw err
    }
  }

  function createdBannerDesktop(urlImg, nameImg) {

    try {
      s3Repository.createdBanner({
        "imageName": nameImg,
        "imageURL": urlImg,
        "enable": true,
        "mobile": false
      }
      )
      window.location.reload()
      getAllBannerDesktop()

    } catch (err) {
      console.log(err.response)

      throw err
    }
  }

  async function getAllBannerDesktop() {
    try {
      await s3Repository.getBannerComponent({
        mobile: false
      })

        .then(resp => setAllBannersDesktop(resp.data))

    } catch (err) {

      throw err
    }
  }

  //-------------------- MOBILE -------------------- //

  useEffect(() => {
    getAllBannerMobile()

  }, [])

  useEffect(() => {
    if (allBannersMobile.length >= 3)
      setInputDisabledMobile(true)

  }, [allBannersMobile])

  function goSaveMobile() {
    setUploadMobile(true)
  }

  useEffect(() => {
    if (imageMobile.length != 0) {
      const urlImage = URL.createObjectURL(imageMobile)
      setUrlLocalMobile(urlImage)
    }
  }, [imageMobile])

  useEffect(() => {
    if (uploadMobile) {
      if (imageMobile.length != 0) {
        createFileObjectMobile(imageMobile)
      }
    }
  }, [uploadMobile])

  const createFileObjectMobile = file => {
    const imageUpload = ({
      file: file,
      name: `${crypto.randomBytes(16).toString('hex')}-${file.name + 'mobile'}`,
      preview: URL.createObjectURL(file)
    })
    processUploadMobile(imageUpload)
  }

  async function processUploadMobile(imageUpload) {

    const formData = new FormData();
    formData.append('image', imageUpload.file, imageUpload.name)

    try {
      s3Repository.singleUploadBanner(formData)

        .then(resp => createdBannerMobile(resp, imageUpload.name))

    } catch (err) {
      console.log(err.response)

      throw err
    }
  }

  function createdBannerMobile(urlImg, nameImg) {

    try {
      s3Repository.createdBanner({
        "imageName": nameImg,
        "imageURL": urlImg,
        "enable": true,
        "mobile": true
      }
      )
      window.location.reload()
      getAllBannerMobile()

    } catch (err) {
      console.log(err.response)

      throw err
    }
  }

  async function getAllBannerMobile() {
    try {
      await s3Repository.getBannerComponent({
        mobile: true
      })

        .then(resp => setAllBannersMobile(resp.data))


    } catch (err) {

      console.log(err.response)

      throw err
    }
  }

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="imageContainer">
      <div className="title">
        <span className={`fa fa-angle-down`} aria-hidden="true" style={{ marginRight: 5 }} />
        <span>{title}</span>
      </div>
      <div className="submiteImage">
        {type === "desktop" ?
          <div className="submitImput">
            {!urlLocalDesktop ?
              <div {...getRootProps()} className='submitImputButton' >
                <FontAwesomeIcon icon={faPlusCircle} size="2x" color='#7A7A7A' />
                <h1>Adicionar imagem</h1>
                <input {...getInputProps()} disabled={inputDisabledDesktop} onChange={value => setImageDesktop(value?.target.files[0])} multiple={false} />
                {inputDisabledDesktop ?
                  <h2>Número de banners atingido, altere algum dos banners ao lado</h2>
                  : null
                }
              </div>
              :
              <div {...getRootProps()} className='submitImputButton' >

                <img
                  src={urlLocalDesktop}
                  height={135}
                  width={164}
                />

              </div>
            }

            <label style={{ fontSize: '16px', paddingLeft: '2%', width: '32%', textAlign: 'center' }}>Imagem principal do site({type})<br />Tamanho: {size} px</label>
            {allBannersDesktop ?
              <FileListImage filesArray={allBannersDesktop} type={type} />
              : null
            }
            <div className="buttonsImage">
              <button className="btn btn-success" onClick={() => goSaveDesktop()}>Salvar</button>
            </div>
          </div>
          :
          <div className="submitImput">
            {!urlLocalMobile ?
              <div {...getRootProps()} className='submitImputButton' >
                <FontAwesomeIcon icon={faPlusCircle} size="2x" color='#7A7A7A' />
                <h1>Adicionar imagem</h1>
                <input {...getInputProps()} disabled={inputDisabledMobile} onChange={value => setImageMobile(value?.target.files[0])} multiple={false} />
                {inputDisabledMobile ?
                  <h2>Número de banners atingido, altere algum dos banners ao lado</h2>
                  : null
                }
              </div>
              :
              <div {...getRootProps()} className='submitImputButton' >

                <img
                  src={urlLocalMobile}
                  height={135}
                  width={164}
                />

              </div>
            }
            <h1 style={{ fontSize: '16px', fontFamily: 'sans-serif', paddingLeft: '2%', width: '32%', textAlign: 'center' }}>Imagem principal do site({type})<br />Tamanho: {size} px</h1>
            {allBannersMobile ?
              <FileListImage filesArray={allBannersMobile} type={type} />
              : null
            }
            <div className="buttonsImage">
              <button className="btn btn-success" onClick={() => goSaveMobile()}>Salvar</button>
            </div>
          </div>
        }

      </div>

    </div>
  );
}

export default ImageSubmit;