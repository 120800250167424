import api from "../services/api"

const getAll = async () => {
    try {
  
      const response = await api.get('contact-register')
      return response.data
  
    } catch (err) {
      throw err
    }
}

const create = async contact => {
    try {
        const response = await api.post('contact-register', contact)
        return response.data
    } catch (err) {
        throw err
    }
}

export default {
    create,
    getAll
}