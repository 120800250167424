import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap';

import MobileFilters from './MobileFilters'
import DesktopFilters from './DesktopFilters'
import ProductsList from '../../components/ProductList'

import './styles.css'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import Card from '../../components/Card';

import initGa from '../../../../utils/googleAnalytics';

const Products = () => {
  initGa(window.location.href);
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);

  const handleOpenFilterModal = () => {
    setIsOpenFilterModal(true)
  }

  return (
    <div id='products-page'>
      <div id="filter">
        <button style={{ border: 'none', backgroundColor: '#FFF', outline: 'none' }} onClick={handleOpenFilterModal} >Filtrar <FontAwesomeIcon icon={faAngleDown} /></button>
        <br/>
      </div>

      <div>
        <Card title='Filtros' id='filters-wrapper'>
          <DesktopFilters />
        </Card>
      </div>

      <div>
        <Card title='Produtos'>
          <ProductsList />
        </Card>
      </div>
      {
        isOpenFilterModal && (
          <Modal show={isOpenFilterModal} onHide={() => setIsOpenFilterModal(false)} animation={true}>
            <Modal.Header closeButton>
              <Modal.Title>
                <strong>Filtrar por:</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <MobileFilters />
            </Modal.Body>
            <Modal.Footer>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <button className="btn btn-danger" onClick={() => setIsOpenFilterModal(false)}>Voltar</button>
              </div>
            </Modal.Footer>
          </Modal>
        )
      }
    </div>
  )
}

export default Products