import React, { useEffect, useState } from 'react'
import { faSearch, faShoppingCart, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap';

import icon from '../../../../assets/img/icon.png'
import { changeValue } from '../../../../store/actions/list'
import './styles.css'
import './styles99.css'

const Header = ({ history }) => {
  const { listReducer: { search }, cartReducer: { selectedProducts, step } } = useSelector(state => state)
  const [classes, setClasses] = useState(null)
  const [dropdownClasses, setDropdownClasses] = useState(null)
  const [open, setOpen] = useState(false)

  const dispatch = useDispatch();

  useEffect(() => {
    loadClasses()
  }, [])

  const loadClasses = () => {
    if (!window.location.host.includes('21200') && !window.location.host.includes('99')) {
      setClasses('main-header-desktop')
      setDropdownClasses('submenu-header-items')
    } else {
      setClasses('main-header-desktop-99')
      setDropdownClasses('submenu-header-items-99')
    }
  }

  function handleOpenModal() {
    document.getElementById('mySidenav').style.width = '300px'
    document.getElementById('mySidenavBackground').style.width = '100vw'
    document.getElementById('mySidenav').style.padding = '10px'
  }

  function handleSubmiSearch(e) {
    e.preventDefault()
    history.push('/store/products')
  }

  function handleNavigateToItems() {
    dispatch([
      changeValue('', 'search'),
      changeValue(1, 'step')
    ])
    history.push('/store/items')
  }

  function handleNavigateToHome() {
    history.push('/')
  }

  function handleNavigateToAbout() {
    history.push(window.location.href.split("store")[1] ? "about" : "store/about")
  }

  function handleNavigateToHowItWorks() {
    history.push(window.location.href.split("store")[1] ? "how-it-works" : "store/how-it-works")
  }

  function handleNavigateToPartners() {
    history.push(window.location.href.split("store")[1] ? "partners" : "store/partners")
  }

  function handleNavigateToWithdraw() {
    history.push(window.location.href.split("store")[1] ? "withdraw" : "store/withdraw")
  }

  function handleNavigateToRegister() {
    history.push(window.location.href.split("store")[1] ? "register" : "store/register")
  }

  function handleNavigateToResendQrCode() {
    history.push(window.location.href.split("store")[1] ? "resendqrcode" : "store/resendqrcode")
  }

  function handleNavigateToResendBankSlip() {
    history.push(window.location.href.split("store")[1] ? "resend" : "store/resend")
  }

  function handleNavigateToWhatsApp() {
    window.open('https://api.whatsapp.com/send?phone=5511995024999&text=Olá,%20gostaria%20de%20mais%20informações%20sobre%20o%20RCLUB.', '_blank')
  }

  return (
    <header className={classes}>
      {/* <FontAwesomeIcon icon={faBars} onClick={handleOpenModal} cursor='pointer' color='#FFFFFF' aria-hidden="false" /> */}
      <div onClick={handleNavigateToHome}>
        <img src={icon} alt="Rclub" />
        {/* <p>Clube da Reparação</p> */}
      </div>
      {/* <form className='containt-header' onSubmit={handleSubmiSearch}>
        <div>
          <input
            type='text'
            placeholder='Pesquise por produto'
            className='form-control foco-input'
            value={search}
            onChange={event => dispatch(changeValue(event.target.value, 'search'))}
          />
          {!search
            ? <FontAwesomeIcon icon={faSearch}
              value={search}
              onClick={() => history.push('/store/products')}
              cursor='pointer'
              color='gray'
              aria-hidden='false'
            />
            : <FontAwesomeIcon icon={faTimes}
              color='gray'
              cursor='pointer'
              onClick={() => dispatch(changeValue('', 'search'))}
            />
          }
        </div>
      </form> */}

      <div className="right-border" onClick={handleNavigateToHome}>
        <p>Início</p>
      </div>
      <div className="right-border" onClick={handleNavigateToAbout}>
        <p>Quem Somos</p>
      </div>
      <div className="right-border" onClick={handleNavigateToWithdraw}>
        <p>Onde Retirar</p>
      </div>
      <div className="right-border" onMouseOver={() => setOpen(true)} onClick={() => setOpen(true)}>
        <div>
          <p>Atendimento</p>
        </div>
        {open &&
          <div className={dropdownClasses} onMouseLeave={() => setOpen(false)}>
            <div className="submenu-header-item" onClick={handleNavigateToWhatsApp} style={{ borderBottom: '#fff solid 2px' }}>
              <p><FontAwesomeIcon icon={faWhatsapp} cursor='pointer' color='#34af20' aria-hidden="false" size="lg" style={{ marginRight: '5px' }} /> WhatsApp</p>
            </div>
            <div className="submenu-header-item" onClick={handleNavigateToResendBankSlip} style={{ borderBottom: '#fff solid 2px' }}>
              <p>2ª Via Boleto</p>
            </div>
            <div className="submenu-header-item" onClick={handleNavigateToResendQrCode}>
              <p>2ª Via QR-Code</p>
            </div>
          </div>
        }
      </div>
      <div className="right-border" onClick={handleNavigateToHowItWorks}>
        <p>Como Funciona</p>
      </div>
      <div onClick={handleNavigateToPartners}>
        <p>Parceiros</p>
      </div>

      {!window.location.host.includes('21200') && !window.location.host.includes('99') ?
        <div className="right-border" onClick={handleNavigateToRegister}>
          <p>Cadastre-se</p>
        </div>
        :
        null
      }

      <section>
        <FontAwesomeIcon
          icon={faShoppingCart}
          cursor='pointer'
          onClick={handleNavigateToItems}
          color='white'
          aria-hidden="false"
        />
        <span id='badge-itens' onClick={handleNavigateToItems}>{step === 4 ? 0 : selectedProducts.length}</span>
      </section>
    </header>
  )
}

export default withRouter(Header)