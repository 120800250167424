import React, { useEffect, useState } from 'react';
import "./styles.css";
import ImageMax from './ImageMax'
import { useParams } from "react-router-dom";
import api from "../../../../services/api";
import config from '../../../../config';

function ProductImagesGallery({ information }) {

  const { id } = useParams();
  const [urlImage, setUrlImage] = useState('')
  const [openImageMax, setOpenImageMax] = useState(false)
  const [idImg, setIdImg] = useState('')
  const [dataImageVideo, setDataImageVideo] = useState([])
  useEffect(() => {

    const getAllImageVideo = async () => {
      const resp = await api.get('product-images/getAll/' + id);
      setDataImageVideo(resp.data)
      setUrlImage(resp.data[0]?.imageUrl)
      setIdImg(1)

    };
    getAllImageVideo()
  }, [])


  function imageShow(urlImg, index) {

    setUrlImage(urlImg)
    setIdImg(index)
  }
  function OpenImageMax(urlImg, index) {

    setUrlImage(urlImg)
    setIdImg(index)
    setOpenImageMax(true)
  }

  return (
    <div className="productDetailsGallery">
      <div className="InfosproductDetailsListImage">

        {dataImageVideo.length >= 9 ?
          <>
            <div className="imageMini">

              {dataImageVideo?.slice(0, 7).map((uploadedFile, index) => {

                return !uploadedFile?.isVideo && (<img onClick={() => imageShow(uploadedFile?.imageUrl, (Number(index) + 1))}
                  src={uploadedFile?.imageUrl}
                />)
              })}

              {dataImageVideo?.slice(0, 7).map((uploadedFile, index) => {
                return uploadedFile?.isVideo && (<video width={50} height={50} type="video/mp4"
                  onClick={() => imageShow(uploadedFile?.imageUrl, (Number(index) + 1))}

                  src={uploadedFile?.imageUrl}
                ></video>)
              })}

              <div className="imageMiniCount">

                {dataImageVideo?.slice(7, 8).map((uploadedFile, index) => {
                  return !uploadedFile?.isVideo && (<><img onClick={() => OpenImageMax(uploadedFile?.imageUrl, 8)}
                    src={uploadedFile?.imageUrl}
                  />
                    <h1 onClick={() => OpenImageMax(uploadedFile?.imageUrl, 8)}>
                      +{Number(dataImageVideo.length) - Number(7)}
                    </h1>
                  </>)
                })}
                {dataImageVideo?.slice(7, 8).map((uploadedFile, index) => {

                  return uploadedFile?.isVideo && (<><video width={50} height={50} type="video/mp4"
                    onClick={() => OpenImageMax(uploadedFile?.imageUrl, 8)}
                    src={uploadedFile?.imageUrl}
                  ></video>
                    <h1 onClick={() => OpenImageMax(uploadedFile?.imageUrl, 8)}>
                      +{Number(dataImageVideo.length) - Number(7)}
                    </h1>
                  </>)
                })}
              </div>

            </div>
          </>
          :
          <div className="imageMini">

            {dataImageVideo?.map((uploadedFile, index) => {

              return !uploadedFile?.isVideo && (<img
                onClick={() => imageShow(uploadedFile?.imageUrl, (Number(index) + 1))}
                src={uploadedFile?.imageUrl}
              />)
            }
            )}
            {dataImageVideo?.map((uploadedFile, index) => {

              return uploadedFile?.isVideo && (<video width={50} height={50} type="video/mp4"
                onClick={() => imageShow(uploadedFile?.imageUrl, (Number(index) + 1))}
                src={uploadedFile?.imageUrl}
              ></video>)
            }
            )}

          </div>


        }

      </div>
      <div className="InfosproductDetailsImage">
        {urlImage?.split('.').pop() != 'mp4' ?
          <img onClick={() => setOpenImageMax(true)}
            width={500} height={100}
            src={urlImage}
          />
          :
          <video controls
            width={400} height={300} type="video/mp4"
            src={urlImage}
            onClick={() => setOpenImageMax(true)}>

          </video>
        }
      </div>

      {openImageMax ?
        <ImageMax id={'modal'} UrlImage={urlImage} idImg={idImg} onClose={() => setOpenImageMax(false)} data={dataImageVideo} />
        :
        null
      }
    </div>

  );
}

export default ProductImagesGallery;