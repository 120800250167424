import React, { useEffect, useState } from "react";
import './styles.css'
import { useDropzone } from 'react-dropzone'
import s3Repository from '../../../../repositories/s3'
import crypto from "crypto";
import RedirectModal from '../RedirectLinkModal';



function FileListImage({ filesArray, type }) {

  const [imageDesktop, setImageDesktop] = useState([]);
  const [idDesktop, setIdDesktop] = useState('')

  const [imageMobile, setImageMobile] = useState([]);
  const [idMobile, setIdMobile] = useState('')
  const [fileUploaded, setFileUploaded] = useState({})

  const [isRedirectLinkModaleOpen, setIsRedirectLinkModaleOpen] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({ accept: 'image/*' })

  //-------------------- DESKTOP -------------------- //

  useEffect(() => {

    if (imageDesktop.length != 0) {
      createFileObjectDesktop(imageDesktop)
    }

  }, [imageDesktop])

  const createFileObjectDesktop = file => {
    const imageUpload = ({
      file: file,
      name: `${crypto.randomBytes(16).toString('hex')}-${file.name}`,
      preview: URL.createObjectURL(file)
    })
    processUploadDesktop(imageUpload)
  }

  async function processUploadDesktop(imageUpload) {

    const formData = new FormData();
    formData.append('image', imageUpload.file, imageUpload.name)
    //  for (var p of formData) {
    //      console.log(p);
    //  }

    try {
      s3Repository.singleUploadBanner(formData)

        .then(resp => createdBannerDesktop(resp, imageUpload.name))

    } catch (err) {

      throw err
    }
  }

  function createdBannerDesktop(urlImg, nameImg) {

    try {
      s3Repository.createdBanner({
        "imageName": nameImg,
        "imageURL": urlImg,
        "enable": true,
        "mobile": false
      }
      )

      disableImageDesktop()


    } catch (err) {

      throw err
    }
  }

  function disableImageDesktop() {
    try {
      s3Repository.EnableOrDisableBanner({
        "imageId": idDesktop,
        "enable": false
      })
        .then(() => window.location.reload())

    } catch (err) {

      throw err
    }
  }

  //-------------------- MOBILE -------------------- //

  useEffect(() => {

    if (imageMobile.length != 0) {
      createFileObjectMobile(imageMobile)
    }

  }, [imageMobile])

  const createFileObjectMobile = file => {
    const imageUpload = ({
      file: file,
      name: `${crypto.randomBytes(16).toString('hex')}-${file.name + 'mobile'}`,
      preview: URL.createObjectURL(file)
    })
    processUploadMobile(imageUpload)
  }

  async function processUploadMobile(imageUpload) {

    const formData = new FormData();
    formData.append('image', imageUpload.file, imageUpload.name)
    //  for (var p of formData) {
    //      console.log(p);
    //  }

    try {
      s3Repository.singleUploadBanner(formData)

        .then(resp => createdBannerMobile(resp, imageUpload.name))

    } catch (err) {

      throw err
    }
  }

  function createdBannerMobile(urlImg, nameImg) {

    try {
      s3Repository.createdBanner({
        "imageName": nameImg,
        "imageURL": urlImg,
        "enable": true,
        "mobile": true
      }
      )

      disableImageMobile()


    } catch (err) {

      throw err
    }
  }

  function disableImageMobile() {
    try {
      s3Repository.EnableOrDisableBanner({
        "imageId": idMobile,
        "enable": false
      })

        .then(() => window.location.reload())

    } catch (err) {

      throw err
    }
  }

  function handleOpenRedirectLinkModal(uploadedFile) {
    setFileUploaded(uploadedFile)
    setIsRedirectLinkModaleOpen(true)
  }

  return (
    <>
      <div className="fileListContainer">

        {filesArray?.map(uploadedFile => (
          /*.slice(0, 3)*/
          <li style={{ display: 'flex', height: '100%', width: "100%" }} key={uploadedFile?.id}>
            <div className="fileInfo">
              <div className="preview">
                <img
                  src={uploadedFile?.urlImgBanner}
                  height={100}
                  width={120}
                />
                {type === 'desktop' ?
                  <div className="submitImputUpdate">

                    <div {...getRootProps()} className='submitImputButtonUpdate' >

                      <h1 onClick={() => setIdDesktop(uploadedFile?.id)} style={{ fontSize: '13px', width: '100%' }}>Alterar imagem</h1>

                      <input {...getInputProps()} onChange={value => setImageDesktop(value?.target.files[0])} multiple={false} />
                    </div>
                    <div className='submitImputButtonUpdate' >
                      <h1 onClick={e => handleOpenRedirectLinkModal(uploadedFile)} style={{ fontSize: '13px', width: '100%', marginTop: '1%' }}>Alterar informações de link</h1>
                    </div>

                  </div>
                  :
                  <div className="submitImputUpdate">

                    <div {...getRootProps()} className='submitImputButtonUpdate' >

                      <h1 onClick={() => setIdMobile(uploadedFile?.id)} style={{ fontSize: '13px', width: '100%' }}>Alterar imagem</h1>

                      <input {...getInputProps()} onChange={value => setImageMobile(value?.target.files[0])} multiple={false} />
                    </div>
                    <div className='submitImputButtonUpdate' >
                      <h1 onClick={e => handleOpenRedirectLinkModal(uploadedFile)} style={{ fontSize: '13px', width: '100%', marginTop: '1%' }}>Alterar informações de link</h1>
                    </div>

                  </div>
                }
              </div>
            </div>
          </li>
        ))}
      </div>

      {isRedirectLinkModaleOpen ?
        <RedirectModal
          onHide={() => setIsRedirectLinkModaleOpen(false)}
          file={fileUploaded}
          show={isRedirectLinkModaleOpen}
          type='banner'
        />
        :
        null
      }

    </>
  )
};

export default FileListImage;
