import React, { useState, useEffect, useCallback } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import AlertModal from '../../../../components/AlertModal'
import ModalAlertMsg from '../../../Store/components/ModalAlertMsg'

import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faLink } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import ReactTable from 'react-table-6';
import clientsRepository from '../../../../repositories/Clients'
import { phoneMask } from '../../../../utils/normalize'
import DownloadXls from '../../components/DownloadXlsButton';

function ConsultClient() {
  const [loading, setLoading] = useState(false)
  const [contacts, setContacts] = useState([])
  const [contactsToExport, setContactsToExport] = useState([])
  const [contactsFiltered, setContactsFiltered] = useState([])
  const [contactName, setContactName] = useState('')
  const [hasLinkModal, setHasLinkModal] = useState(false)
  const [createLinkModal, setCreateLinkModal] = useState(false)
  const [message, setMessage] = useState('')
  const [client, setClient] = useState([])

  const xlsColumns = [
    {
      name: 'Data Primeira Compra',
      acessor: 'firstBuy'
    },
    {
      name: 'Nome',
      acessor: 'name'
    },
    {
      name: 'Celular',
      acessor: 'phone'
    },
    {
      name: 'E-mail',
      acessor: 'email'
    },
    {
      name: 'Qtde. de Compras',
      acessor: 'buys'
    },
    {
      name: 'Qtde. de Indicações',
      acessor: 'indications'
    }
  ]

  useEffect(() => {
    loadContacts()
    document.title = 'Rclub - Clientes'
  }, [])

  const updateXlsDataToDownload = useCallback(() => {
    setLoading(true)
    console.log(contactsFiltered)
    const xlsData = contactsFiltered.map(contact => ({
      firstBuy: new Date(contact.firstBuy).toLocaleString().split(' ')[0],
      name: contact.name,
      phone: phoneMask(contact.phone),
      email: contact.email,
      buys: contact.buys,
      indications: contact.indications,
    }))

    setContactsToExport(xlsData)
    setLoading(false)
  }, [contactsFiltered])

  const setContactsFilteredByName = useCallback(() => {
    let contactsFilteredByName = contacts

    contactsFilteredByName = contactsFilteredByName
      .filter(contact => contact.name.toLowerCase().includes(contactName.toLowerCase()))

    setContactsFiltered(contactsFilteredByName)
  }, [contactName, contacts])

  useEffect(() => {
    updateXlsDataToDownload()
  }, [contactsFiltered, updateXlsDataToDownload])

  useEffect(() => {
    setContactsFilteredByName()
  }, [contactName, setContactsFilteredByName])

  const handleCreateLinkModal = (client) => {
    setClient(client)
    setCreateLinkModal(true)
  }

  const handleCreateLink = async () => {
    try {
      const link = await clientsRepository.update(client.id)
      setCreateLinkModal(false)
      loadContacts()
      handleHasLinkModal(link.name, link.indicationLink)
    } catch (error) {
      toastr.error('Ocorreu um erro ao gera link para o cliente. Por favor, tente novamente')
    }
  }

  const handleHasLinkModal = (client, link) => {
    let url = window.location.origin
    let text = `O link do cliente ${client} é ${url + link}`
    setMessage(text)
    setHasLinkModal(true)
  }

  const loadContacts = async () => {
    setLoading(true)

    try {
      const responseContacts = await clientsRepository.getAll()
      console.log(responseContacts)

      setContacts(responseContacts)
      setContactsFiltered(responseContacts)
    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar os contatos. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div id='admin-page-contacts-reports'>
        <header>
          <BreadCrumb path={['home', 'ConsultClient']} data={['Início', 'Clientes']} />
          <section>
            <div>
              <input
                type="text"
                id='initialDate'
                className='form-control foco-input'
                onChange={e => setContactName(e.target.value)}
                value={contactName}
                placeholder="Pesquisar por Nome"
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <DownloadXls
                archiveName={`consultasClientes${new Date().toLocaleDateString()}`}
                tablesNames={['Consulta Clientes']}
                data={[contactsToExport]}
                className='btn btn-export'
                disabled={loading}
                columns={[xlsColumns]}
              >
                <FontAwesomeIcon color='white' icon={faCloudDownloadAlt} /> Exportar .xsl
            </DownloadXls>
            </div>
          </section>
        </header>

        <br />
        <ReactTable
          style={{ textAlign: 'center' }}
          data={contactsFiltered}
          columns={[
            {
              Header: 'Data Primeira Compra',
              accessor: 'firstBuy',
              width: 200,
              Cell: props => <>{new Date(props.value).toLocaleString().split(' ')[0]}</>
            },
            {
              Header: 'Nome',
              accessor: 'name',
            },
            {
              Header: 'Celular',
              accessor: 'phone',
              Cell: props => <>{phoneMask(props.value)}</>,
              width: 200
            },
            {
              Header: 'Qtde. de Compras',
              accessor: 'buys',
              Cell: props => <>{!(props.value) ? '---' : props.value}</>,
            },
            {
              Header: 'Qtde. de Indicações',
              accessor: 'indications',
              Cell: props => <>{!(props.value) ? '---' : props.value}</>,
            },
            {
              Header: 'Detalhes',
              width: 100,
              accessor: 'indicationLink',
              Cell: props => {

                if (props.value) {
                  return <FontAwesomeIcon
                    icon={faLink}
                    cursor='pointer'
                    onClick={() => handleHasLinkModal(props.original.name, props.value)}
                    color='black'
                  />
                }
                return <FontAwesomeIcon
                  icon={faLink}
                  cursor='pointer'
                  onClick={() => handleCreateLinkModal(props.original)}
                  color='black'
                />

              }
            }
          ]}
          defaultPageSize={10}
          loading={loading}
          showPagination={true}
          sortable={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          previousText='Anterior'
          nextText='Próximo'
          loadingText='Carregando...'
          noDataText='Não há informação'
          pageText='Página'
          ofText='de'
          rowsText='linhas'
        />
      </div>
      {
        createLinkModal &&
        <AlertModal
          show={createLinkModal}
          message='Deseja realmente gerar um link para o cliente?'
          onHide={() => setCreateLinkModal(false)}
          onCancel={() => setCreateLinkModal(false)}
          onSubmit={async () => handleCreateLink()}
        />
      }

      {hasLinkModal ?
        <ModalAlertMsg text={message} onClose={() => { setHasLinkModal(false) }} />
        :
        null
      }
    </>
  )
}

export default ConsultClient