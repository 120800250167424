import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

export default function BreadCrumb(props) {
  return (
    <h5 id="bc">
      {props.data.map((p, i) => (
        <div key={p}>
          {i === props.path.length - 1 ? (
            <strong> {p}</strong>
          ) : (
            <>
              {props.path[i] ? (
                <Link to={props.path[i]}>{p}</Link>
              ) : (
                <span>{p}</span>
              )}
              <span> > </span>
            </>
          )}
        </div>
      ))}
    </h5>
  );
}
