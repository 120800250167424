import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { currency } from '../../../../utils/normalize'
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { isMobile } from '../../../../utils/validation';

import './styles.css'

const ProductItem = ({ product, onClick, addCart }) => {
  const history = useHistory()
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false)
  const [mobile, setMobile] = useState(false)

  const handleOpenInfoModal = () => {
    setIsOpenInfoModal(true)
  }

  useEffect(() => {
    isMobile()
    const mobileAcess = isMobile()
    if (!mobileAcess) {
      setMobile(false)
    } else {
      setMobile(true)
    }
    window.scrollTo(0, 0)
  }, []);

  return (
    
    // <Link to='store/product-details' style={{color: 'inherit', textDecoration: 'none'}}>
    <div id='product-item'> 
    {!mobile? 
    <>
      <div>
        <img src={!!product.imageURL ? product.imageURL : null} alt={product.description} />
      </div>
      <main>
        <center>
          <h6 title={product.description}
            onClick={() => history.push(window.location.href.split("store")[1] ?
              "product-details/" + product.id : "store/product-details/" + product.id)}
          >
            {product.description}
          </h6>
        </center>
        {product.complementaryDescription != null || product.complementaryDescription !== '' ?
          <span>{product.complementaryDescription}</span> : null
        }
        {/* <button className='btn btn-link' onClick={handleOpenInfoModal}>Ver mais informações</button> */}
        <strong>{currency(product.price)}</strong>
        <button className='btn btn-success' id="buy-btn" onClick={onClick}>Comprar Agora</button>
        <button className='btn btn-success' className="add-cart" onClick={addCart}>Adicionar ao Carrinho</button>
        {/* <FontAwesomeIcon icon={faInfoCircle} color='black' onClick={handleOpenInfoModal} /> */}
      </main>
      </>
      :
      <>
      <div>
      <img src={!!product.imageURL ? product.imageURL : null} alt={product.description} />
    </div>
    <main>
      <center>
        <h6 title={product.description}
          onClick={() => history.push(window.location.href.split("store")[1] ?
            "product-details/" + product.id : "store/product-details/" + product.id)}
        >
          {product.description}
        </h6>
      </center>
      {product.complementaryDescription != null || product.complementaryDescription !== '' ?
        <span>{product.complementaryDescription}</span> : null
      }
      {/* <button className='btn btn-link' onClick={handleOpenInfoModal}>Ver mais informações</button> */}
      <strong>{currency(product.price)}</strong>
      <button className='btn btn-success' id="buy-btn" onClick={onClick}>Comprar Agora</button>
      <button className='btn btn-success' className="add-cart" onClick={addCart}>Adicionar ao Carrinho</button>
      {/* <FontAwesomeIcon icon={faInfoCircle} color='black' onClick={handleOpenInfoModal} /> */}
    </main>
    </>
}

      {
        isOpenInfoModal && (
          <Modal className='info-product-modal' size='lg' show={isOpenInfoModal} onHide={() => setIsOpenInfoModal(false)} animation={true}>
            <Modal.Header closeButton>
              <Modal.Title>
                <strong>RClub</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                name={product.description}
                id={product.id}
                cols="30"
                rows="10"
                readOnly
              >
                {product.observations}
              </textarea>
            </Modal.Body>
            <Modal.Footer>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <button className="btn btn-danger" onClick={() => setIsOpenInfoModal(false)}>Voltar</button>
              </div>
            </Modal.Footer>
          </Modal>
        )
      }
    </div>
   
    // </Link>
  )
}

export default ProductItem