import React, { useState, useEffect, useCallback } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import ReactTable from 'react-table-6';
import logsRepository from '../../../../repositories/Logs'
import { phoneMask } from '../../../../utils/normalize'
import DownloadXls from '../../components/DownloadXlsButton';

function TransactionsReports() {
  const [loading, setLoading] = useState(false)
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [logs, setLogs] = useState([])
  const [logsToExport, setLogsToExport] = useState([])
  const [logsFiltered, setLogsFiltered] = useState([])

  const xlsColumns = [
    {
      name: 'Data',
      acessor: 'date'
    },
    {
      name: 'Tipo',
      acessor: 'paymentType'
    },
    {
      name: 'Cartão de Crédito',
      acessor: 'creditCardNumber'
    },
    {
      name: 'Código de Retorno',
      acessor: 'returnCode'
    },
    {
      name: 'Mensagem',
      acessor: 'returnMessage'
    },
    {
      name: 'Status',
      acessor: 'result'
    }
  ]

  useEffect(() => {
    loadLogs()
    document.title = 'Rclub - Relatórios'
  }, [])

  const updateXlsDataToDownload = useCallback(() => {
    setLoading(true)
    const xlsData = logsFiltered.map(log => ({
      date: new Date(log.createdAt).toLocaleString().split(' ')[0],
      paymentType: log.paymentType,
      creditCardNumber: log.creditCardNumber,
      returnCode: log.returnCode,
      returnMessage: log.returnMessage,
      result: log.result
    }))

    setLogsToExport(xlsData)
    setLoading(false)
  }, [logsFiltered])

  const isDateInvalid = useCallback(() => new Date(finalDate).getTime() < new Date(initialDate).getTime(), [initialDate, finalDate])

  const setLogsFilteredByDate = useCallback(() => {
    let logsFilteredByDate = logs

    if (Boolean(initialDate) && Boolean(finalDate)) {
      if (isDateInvalid()) {
        setLogsFiltered(logs)
        return toastr.warning('Data inválida')
      }
    }

    if (Boolean(initialDate)) {
      logsFilteredByDate = logsFilteredByDate
        .filter(log => new Date(new Date(log.createdAt).setHours(0, 0, 0, 0)).getTime() > new Date(new Date(initialDate).setHours(0, 0, 0, 0)).getTime())
    }

    if (Boolean(finalDate)) {
      const finalDatePlusOneDay = new Date(new Date().setDate(new Date(finalDate).getDate() + 2)).getTime()
      logsFilteredByDate = logsFilteredByDate
        .filter(log => new Date(new Date(log.createdAt).setHours(0, 0, 0, 0)).getTime() < new Date(new Date(finalDatePlusOneDay).setHours(0, 0, 0, 0)).getTime())
    }

    /*
    if (Boolean(finalDate)) {
      const finalDatePlusOneDay = new Date(new Date().setDate(new Date(finalDate).getDate() + 1)).getTime()
      logsFilteredByDate = logsFilteredByDate
        .filter(log => new Date(log.createdAt).getTime() < new Date(finalDatePlusOneDay).getTime())
    }*/

    setLogsFiltered(logsFilteredByDate)
  }, [initialDate, finalDate, logs, isDateInvalid])

  useEffect(() => {
    updateXlsDataToDownload()
  }, [logsFiltered, updateXlsDataToDownload])

  useEffect(() => {
    if (Boolean(initialDate) || Boolean(finalDate)) {
      setLogsFilteredByDate()
    }
  }, [initialDate, finalDate, setLogsFilteredByDate])

  const loadLogs = async () => {
    setLoading(true)

    try {
      const responseLogs = await logsRepository.getAll()

      setLogs(responseLogs)
      setLogsFiltered(responseLogs)

    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar os contatos. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div id='admin-page-contacts-reports'>
      <header>
        <BreadCrumb path={['/admin/home', null, null]} data={['Início', 'Relatórios', 'Relatório de Transações PAGTOS']} />
        <section>
          <div>
            <label htmlFor='initialDate'>Data Inicial:</label>
            <input
              type="date"
              id='initialDate'
              className='form-control foco-input'
              onChange={e => setInitialDate(e.target.value)}
              value={initialDate}
            />
          </div>

          <div>
            <label htmlFor='finalDate'>Data Final:</label>
            <input
              type="date"
              id='finalDate'
              className='form-control foco-input'
              value={finalDate}
              onChange={e => setFinalDate(e.target.value)}
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <DownloadXls
              archiveName={`transacoesPagtos${new Date().toLocaleDateString()}`}
              tablesNames={['Transações Pagtos']}
              data={[logsToExport]}
              className='btn btn-export'
              disabled={loading}
              columns={[xlsColumns]}
            >
              <FontAwesomeIcon color='white' icon={faCloudDownloadAlt} /> Exportar .xsl
            </DownloadXls>
          </div>
        </section>
      </header>

      <br />
      <ReactTable
        style={{ textAlign: 'center' }}
        data={logsFiltered}
        columns={[
          {
            Header: 'Data',
            accessor: 'createdAt',
            width: 120,
            Cell: props => <>{new Date(props.value).toLocaleString().split(' ')[0]}</>
          },
          {
            Header: 'Tipo',
            accessor: 'paymentType',
            Cell: props => {
              // if (props.value === 'Cartão de Crédito' || props.value === 'Boleto' || props.value === 'Cartão de Débito'){
              if (['Cartão de Crédito', 'Boleto', 'Cartão de Débito', 'Pix'].includes(props.value)) {
                return <button className='btn btn-primary' style={props.value === 'Cartão de Crédito' ? { backgroundColor: '#6959CD' } :
                  props.value === 'Boleto' ? { backgroundColor: '#0000FF' } :
                    props.value === 'Pix' ? { backgroundColor: '#836FFF' } : { backgroundColor: '#483D8B' }}
                >
                  {props.value}
                </button>
              }

              return <> {'---'} </>
            },
            width: 140
          },
          {
            Header: 'Cartão de Crédito',
            accessor: 'creditCardNumber',
            Cell: props => {
              if (props.value) {
                return <> {props.value}</>
              }
              return <> {'---'} </>
            },
            width: 165
          },
          {
            Header: 'Código de Retorno',
            accessor: 'returnCode',
            Cell: props => {
              if (props.value) {
                return <> {props.value}</>
              }
              return <> {'---'} </>
            },
            width: 160
          },
          {
            Header: 'Mensagem',
            accessor: 'returnMessage',
            Cell: props => {
              if (props.value) {
                return <> {props.value}</>
              }
              return <> {'---'} </>
            }
          },
          {
            Header: 'Status',
            accessor: 'result',
            Cell: props => (
              Boolean(props.value === "Success")
                ? <button className='btn btn-success'>Sucesso</button>
                : <button className='btn btn-danger'>Erro</button>
            ),
            width: 100
          }
        ]}
        defaultSorted={[
          {
            id: 'createdAt',
            desc: true
          }
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
    </div>
  )
}

export default TransactionsReports