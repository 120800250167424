import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import CompanyForm from './Company'
import BreadCrumb from '../../components/BreadCrumb'
import { onlyNumbers, cnpjMask, phoneMask, cepMask } from '../../../../utils/normalize'
import companiesRepository from '../../../../repositories/Companies'


function BundleFormCompany({ match }) {
  const [loading, setLoading] = useState(false)
  const [companyId, setCompanyId] = useState(null)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!match.params.id) {
      setCompanyId(match.params.id)
    }
  }, [])

  useEffect(() => {
    if (!!companyId) {
      loadCompany()
    } else {
      document.title = 'Rclub - Nova Empresa'
    }
  }, [companyId])

  async function loadCompany() {
    setLoading(true)

    try {
      const company = await companiesRepository.getById(companyId)

      dispatch([
        change('company', 'cnpj', cnpjMask(company.cnpj)),
        change('company', 'companyName', company.companyName),
        change('company', 'tradingName', company.tradingName),
        change('company', 'isActive', company.isActive),
        change('company', 'email', company.email),
        change('company', 'phone', phoneMask(company.phone)),
        change('company', 'zipCode', cepMask(company.zipCode)),
        change('company', 'address', company.address),
        change('company', 'addressNumber', company.addressNumber),
        change('company', 'neighborhood', company.neighborhood),
        change('company', 'state', company.state),
        change('company', 'city', company.city),
        change('company', 'vehicleTypeId', company.vehicleTypeId)
      ])

      document.title = `Rclub - ${company.tradingName}`
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao carregar a empresa')
    } finally {
      setLoading(false)
    }
  }

  function handleCancel() {
    history.push('companies')
  }

  function handleSubmit(values) {
    if (!companyId) {
      create(values)
    } else {
      update(values)
    }
  }

  async function create(values) {
    const { address, addressNumber, city, cnpj, companyName, email, isActive, neighborhood, phone,
      state, tradingName, zipCode, vehicleTypeId } = values

    setLoading(true)

    try {
      await companiesRepository.create({
        address,
        addressNumber,
        city,
        cnpj: onlyNumbers(cnpj),
        companyName,
        email,
        isActive,
        neighborhood,
        phone: onlyNumbers(phone),
        state,
        tradingName,
        zipCode: onlyNumbers(zipCode),
        vehicleTypeId,
      })

      toastr.success('Empresa cadastrada com sucesso.')
      history.push('companies')

    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao salvar a empresa. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  async function update(values) {
    const { address, addressNumber, city, companyName, email, isActive, neighborhood, phone,
      state, tradingName, zipCode, vehicleTypeId } = values

    setLoading(true)

    try {
      await companiesRepository.update(companyId, {
        address,
        addressNumber,
        city,
        companyName,
        email,
        isActive,
        neighborhood,
        phone: onlyNumbers(phone),
        state,
        tradingName,
        zipCode: onlyNumbers(zipCode),
        vehicleTypeId,
      })

      toastr.success('Empresa atualizada com sucesso.')
      history.push('companies')

    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao salvar a empresa. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const initialValues = {
    zipCode: '',
    isActive: true
  }

  return (
    <>
      <BreadCrumb path={['home', 'companies', null]} data={['Início', 'Empresas', !companyId ? 'Nova Empresa' : 'Editar Empresa']} />

      <CompanyForm
        initialValues={initialValues}
        companyId={companyId}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        loading={loading}
      />
    </>
  )
}

export default BundleFormCompany