import api from "../services/api"

const getAll = async () => {
  try {

    const response = await api.get('client/getBySales')
    return response.data

  } catch (err) {
    throw err
  }
}

const update = async clientId => {
  try {
    const response = await api.put(`clients/${clientId}`)
    return response.data
  } catch (err) {
    throw err
  }
}

const getClientByRegisterNumber = async registerNumber => {
  try {

    const response = await api.get(`clients/${registerNumber}`)
    return response.data

  } catch (err) {
    throw err
  }
}

export default {
  update,
  getAll,
  getClientByRegisterNumber
}