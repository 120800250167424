import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table-6';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { change } from 'redux-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import productsRepository from '../../../../repositories/Products'
import { onlyNumbers } from '../../../../utils/normalize'

import SearchInput from '../../components/SearchInput';


const Items = ({ loading }) => {
  const [query, setQuery] = useState('')
  const [products, setProducts] = useState([])
  const [suggestionToProducts, setSuggestionToProducts] = useState([])

  const { items } = useSelector(state => state.form.sale.values)

  const dispatch = useDispatch()

  useEffect(() => {
    loadProducts()
  }, [])

  const loadProducts = async () => {
    try {
      const products = await productsRepository.getAllActiveProducts()

      const suggestionToProducts = products.map(product => product.description)
      setProducts(products)
      setSuggestionToProducts(suggestionToProducts)

    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar os produtos. Por favor, tente novamente')
    }
  }

  const handleChangeSearchInput = e => {
    const selectedProductDescription = e.target.value
    setQuery(selectedProductDescription)

    getProductByDescription(selectedProductDescription)
  }

  const getProductByDescription = decription => {
    const selectedProduct = products.find(product => product.description === decription)

    if (!selectedProduct) return

    addProduct(selectedProduct)
    setQuery('')
  }

  const addProduct = product => {
    let exist = false
    const newItems = [...items]

    newItems.forEach(item => {
      if (item.id === product.id) {
        item.quantity++
        exist = true
      }
    })

    if (!exist) {
      product.quantity = 1
      product.hasPrize = false
      newItems.push(product)
    }

    dispatch(change('sale', 'items', newItems))
  }

  const handleRemoveProduct = id => {
    const selectedProducts = [
      ...items
    ]

    const filteredProducts = selectedProducts.filter(product => product.id !== id)
    dispatch(change('sale', 'items', filteredProducts))
  }

  const handleChangeProductQuantity = async (value, index) => {
    const selectedProducts = [
      ...items
    ]
    if (!value || value === '0') {
      value = 1
    }

    selectedProducts[index].quantity = Math.min(parseInt(value), 999)
    await dispatch(change('sale', 'items', selectedProducts))

    document.getElementById(`input-quantity-react-table-index-${index}`).focus()
  }

  return (
    <>
      <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4'>
        <SearchInput
          value={query}
          placeholder='Pesquisar por descrição do produto'
          suggestions={suggestionToProducts}
          onChange={handleChangeSearchInput}
        />
      </div>
      <br />
      <ReactTable
        style={{ textAlign: 'center' }}
        data={items}
        columns={[
          {
            Header: 'Código',
            accessor: 'code',
            width: 150
          },
          {
            Header: 'Descrição',
            accessor: 'description'
          },
          {
            Header: 'Tipo de Produto',
            accessor: 'ProductTypes.description'
          },
          {
            Header: 'Tipo de Veículo',
            accessor: 'VehicleTypes.description'
          },
          {
            Header: 'Quantidade',
            accessor: 'quantity',
            Cell: props => (
              <>
                <div>
                  <FontAwesomeIcon id='button-qtd' icon={faMinusCircle} aria-hidden="false" onClick={() => handleChangeProductQuantity(props.value - 1, props.index)} />
                </div>
                <input
                  id={`input-quantity-react-table-index-${props.index}`}
                  className='form-control foco-input input-quantity-react-table'
                  type='text'
                  value={props.value}
                  onChange={(e) => handleChangeProductQuantity(onlyNumbers(e.target.value), props.index)}
                />
                <div>
                  <FontAwesomeIcon id='button-qtd' icon={faPlusCircle} aria-hidden="false" onClick={() => handleChangeProductQuantity(props.value + 1, props.index)} />
                </div>
              </>
            )
          },
          {
            Header: 'Ações',
            accessor: 'id',
            Cell: props => <FontAwesomeIcon icon={faTrashAlt} color='red' cursor='pointer' onClick={() => handleRemoveProduct(props.value)} />
          },
        ]}
        defaultPageSize={5}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
    </>
  )
}

export default Items