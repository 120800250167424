import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import renderCheckedDate from '../../../../utils/renderCheckedDate';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import QRCodeRepository from '../../../../repositories/QRCode';
import salesRepository from '../../../../repositories/Sales';
import LoadingScreen from '../../components/Loading';
import AlertModal from '../../../Store/components/ModalAlertMsg';
import QRCodeScanSuccessModal from './Modals/QRCodeScanSuccess';

import './styles.css';

function SalesDatails() {

  const [sale, setSale] = useState();
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isManualCheckinSuccess, setIsManualCheckinSuccess] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setSale(location.state.saleClient);
  }, [location])

  const handleConfirmManualCheckin = (item, client, companies, index) => {
    confirmAlert({
      title: 'RCLUB',
      message: 'Confirma a realização do check-in ?',
      buttons: [
        {
          label: 'Não',
          onClick: () => ('Click No')
        },
        {
          label: 'Sim',
          onClick: () => { handleSubmitManualCheckin(item, client, companies, index) }
        }
      ]
    });
  }

  const handleSubmitManualCheckin = async (item, client, companies, index) => {
    setLoading(true)

    try {

      const Validation = {
        companyId: Number(companies.id),
        saleId: item.saleId,
        salesItemId: Number(item.id),
        clientId: Number(client.id)
      }
      const QRCodeValidation = await QRCodeRepository.validate(Validation)
      if (!QRCodeValidation.status) {
        setMessage(QRCodeValidation.message)
        setIsAlertModalOpen(true)
      } else {
        setIsManualCheckinSuccess(true)
        sale.SalesItems[index].isChecked = true
        sale.SalesItems[index].checkedDate = new Date()
      }
    } catch (err) {
      setMessage('2 Ocorreu um erro ao realizar o check-in. Por favor, tente novamente')
      setIsAlertModalOpen(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='sale-details-container'>
      <div className='sale-details-title'>
        <h1>{sale?.Clients.name}</h1>
        <label>{sale?.Clients.email}</label>
      </div>
      <div className='sale-details-items'>
        {sale?.SalesItems.map((item, index) => (
          <div className='sale-details-items-content'>
            <h1>{item.Products.description}</h1> {item.price === 0 ? <button className='button-prize'>BRINDE</button>
              :
              null
            }

            <label>{'Item #' + item.id}</label>
            {
              item.isChecked
                ? <label className='sale-details-items-checked'>{renderCheckedDate(item.checkedDate)}</label>
                : <button onClick={() => { handleConfirmManualCheckin(item, sale?.Clients, sale?.Companies, index) }}>
                  Check-in
            </button>
            }
          </div>
        ))}
      </div>
      {loading && (
        <LoadingScreen />
      )
      }
      {isAlertModalOpen && (
        <AlertModal text={message} onClose={e => setIsAlertModalOpen(false)} />
      )
      }
      {isManualCheckinSuccess && <QRCodeScanSuccessModal onCancel={() => setIsManualCheckinSuccess(false)} isQrCode={false} />}
    </div>
  );
}

export default SalesDatails;