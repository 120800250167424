import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { currency } from '../../../../../../utils/normalize'
import { handleChange } from '../../../../../../store/actions/cart';
import { useCallback } from 'react';

const SelectInstallments = () => {
  const { cartReducer: { installments, amount } } = useSelector(state => state);
  const dispatch = useDispatch();

  const renderInstallmentsOptions = useCallback(() => {
    let paymentsOptions = 1

    if (amount < 100) {
      paymentsOptions = 1
    } else if (amount >= 100 && amount < 300) {
      paymentsOptions = 2
    } else if (amount >= 300 && amount < 400) {
      paymentsOptions = 3
    } else {
      paymentsOptions = 4
    }
  
    return new Array(paymentsOptions)
    .fill()
    .map((_, i) => i + 1)
    .map(value => <option value={value}>{value}x de {currency(amount / value)} sem juros</option>)
  }, [amount])

  return (
    <select
      id='select-installments'
      className='form-control foco-input'
      value={installments}
      style={{ maxWidth: '250px' }}
      onChange={e => dispatch(handleChange(e.target.value, 'installments'))}
    >
      {renderInstallmentsOptions()}
    </select>
  )
}

export default SelectInstallments