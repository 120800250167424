import React from 'react';
import "./styles.css";
import {description} from '../../../../utils/normalize'

function ProductInformation({ information }) {
  
  return (
    <div className="productDetailsInformation">
      <h1>{"Informações do Produto"}</h1>
      <div className="InfosproductDetailsInformation">
        
        <span>{information?.observations}</span>
      </div>
    </div>
  );
}

export default ProductInformation;