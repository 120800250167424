import React, { useState, useEffect, useCallback } from 'react'
import { toastr } from 'react-redux-toastr';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { currency } from '../../../../utils/normalize';
import { getTimeFromDateWithoutHours, getDateDayMonthAndYearSeparated, isInitialDateAndFinalDateMoreThanAMonth, renderCheckedDate } from '../../../../utils/formatDates';

import salesRepository from '../../../../repositories/Sales'
import companiesRepository from '../../../../repositories/Companies'

import BreadCrumb from '../../components/BreadCrumb'
import LineChart from './LineChart';
import DownloadXls from '../../components/DownloadXlsButton';

import './styles.css'
import { handleSalesToExport, xlsSalesColumns, xlsSalesItemsColumns } from './excelHelpers';

const SalesReport = () => {
  const [loading, setLoading] = useState(false)
  const [noChartDataInfo, setNoChartDataInfo] = useState(false)
  const [isSalesSeparatedByMonth, setIsSalesSeparatedByMonth] = useState(false)
  const [companies, setCompanies] = useState([])
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [companyIdSelected, setCompanyIdSelected] = useState('')
  const [sales, setSales] = useState([])
  const [salesFiltered, setSalesFiltered] = useState([])
  const [chartInfoSalesToExport, setChartInfosToExport] = useState([])
  const [chartInfoSalesItemsToExport, setChartInfoSalesItemsToExport] = useState([])
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    loadSales()
    loadCompanies()
    document.title = 'Rclub - Relatórios'
  }, [])

  const getSalesFilteredByCompany = useCallback(() => {
    let salesFilteredByCompany = sales

    if (Boolean(companyIdSelected)) {
      salesFilteredByCompany = sales.filter(sale => sale.companyId.toString() === companyIdSelected)
    }

    return salesFilteredByCompany
  }, [sales, companyIdSelected])

  const setSalesFilteredByDate = useCallback(salesFilteredByCompany => {
    let salesFilteredByDate = salesFilteredByCompany

    if (Boolean(initialDate) && Boolean(finalDate)) {
      if (isDateInvalid()) {
        setSalesFiltered(salesFilteredByDate)
        return toastr.warning('Data inválida')
      }
    }

    if (Boolean(initialDate)) {
      salesFilteredByDate = salesFilteredByDate
        .filter(sale => new Date(sale.date).getTime() > new Date(initialDate).getTime())
    }

    if (Boolean(finalDate)) {
      const finalDatePlusOneDay = new Date(new Date().setDate(new Date(finalDate).getDate() + 1)).getTime()

      salesFilteredByDate = salesFilteredByDate
        .filter(sale => getTimeFromDateWithoutHours(sale.date) <= getTimeFromDateWithoutHours(finalDatePlusOneDay))
    }

    setSalesFiltered(salesFilteredByDate)

  }, [initialDate, finalDate])

  useEffect(() => {
    const salesFilteredByCompany = getSalesFilteredByCompany()

    setSalesFilteredByDate(salesFilteredByCompany)
  }, [sales, initialDate, finalDate, companyIdSelected, getSalesFilteredByCompany, setSalesFilteredByDate])

  useEffect(() => {
    handleCharDataInfo()
  }, [salesFiltered])

  useEffect(() => {
    setLoading(true)
    const excelTableInfos = handleSalesToExport(salesFiltered)
    const { sales, items } = excelTableInfos

    setChartInfosToExport(sales)
    setChartInfoSalesItemsToExport(items)
    setLoading(false)

  }, [chartData])

  const loadSales = async () => {
    setLoading(true)

    try {
      const sales = await salesRepository.getAllSalesNotCanceled()
      setSales(sales)
      setSalesFiltered(sales)

    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar as vendas. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const loadCompanies = async () => {
    setLoading(true)

    try {
      const companies = await companiesRepository.getAll()
      setCompanies(companies)

    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar as empresas. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const isDateInvalid = () => new Date(finalDate).getTime() < new Date(initialDate).getTime()

  const handleCharDataInfo = useCallback(() => {
    const serializedSales = salesFiltered.map(sale => ({
      amount: sale.amount,
      date: new Date(new Date(sale.date).setHours(0, 0, 0, 0))
    }))

    let salesMatrix = []

    if (isInitialDateAndFinalDateMoreThanAMonth(initialDate, finalDate)) {
      salesMatrix = getSalesMatrixSeparatedByMonth([], serializedSales)
      handleCharDataByMonth(salesMatrix)
      setIsSalesSeparatedByMonth(true)
    } else {
      salesMatrix = getSalesMatrixSeparatedByDay([], serializedSales)
      handleCharDataByDay(salesMatrix)
      setIsSalesSeparatedByMonth(false)
    }
  }, [salesFiltered])

  const handleCharDataByMonth = sales => {
    if (sales.length === 1) {
      return handleNoChartDataInfo(sales)
    }


    const serializedChartData = sales.map((salesOfMonth, index) => {

      if (index === 0) {
        return salesOfMonth
      }

      const amountOfSales = salesOfMonth
        .map(sale => sale.amount)
        .reduce((ac, act) => ac + act)

      return [
        new Date(salesOfMonth[0].date).getMonth(),
        amountOfSales,
        currency(amountOfSales)
      ]
    })

    setChartData(serializedChartData)
    setNoChartDataInfo(false)
  }

  const handleCharDataByDay = sales => {
    if (sales.length === 1) {
      return handleNoChartDataInfo(sales)
    }

    const serializedChartData = sales.map((salesOfDay, index) => {

      if (index === 0) {
        return salesOfDay
      }

      const amountOfSales = salesOfDay
        .map(sale => sale.amount)
        .reduce((ac, act) => ac + act)

      return [
        convertDate(salesOfDay[0].date),
        amountOfSales,
        currency(amountOfSales)
      ]
    })


    setChartData(serializedChartData)
    setNoChartDataInfo(false)
  }

  const convertDate = date => {
    const newDate = new Date(date).toLocaleString().split('/')
    return newDate[0] + '/' + newDate[1]
  }
  const getSalesMatrixSeparatedByMonth = (salesMatrixSeparatedByMonth, sales) => {
    if (!salesMatrixSeparatedByMonth.length) {
      let legendToChart = 'Todas Empresas'

      if (Boolean(companyIdSelected)) {
        const company = companies.find(company => company.id.toString() === companyIdSelected)
        legendToChart = company.companyName
      }
      salesMatrixSeparatedByMonth.push(['x', legendToChart, { role: "tooltip", type: "string" }])
    }

    if (!sales.length) {
      return salesMatrixSeparatedByMonth
    }

    const firstSaleDay = getTimeFromDateWithoutHours(sales[0].date)
    const dateOfSale = getDateDayMonthAndYearSeparated(firstSaleDay)


    const salesFromTheSameMonth = sales.filter(sale => {
      const thisDateOfSale = getDateDayMonthAndYearSeparated(sale.date)

      return (thisDateOfSale.month === dateOfSale.month && thisDateOfSale.year === dateOfSale.year)
    })

    const salesFromAnotherMonth = sales.filter(sale => {
      const thisDateOfSale = getDateDayMonthAndYearSeparated(sale.date)

      return (thisDateOfSale.month !== dateOfSale.month && thisDateOfSale.year === dateOfSale.year)
    })

    salesMatrixSeparatedByMonth.push(salesFromTheSameMonth)
    return getSalesMatrixSeparatedByMonth(salesMatrixSeparatedByMonth, salesFromAnotherMonth)
  }

  const getSalesMatrixSeparatedByDay = (salesMatrixSeparatedByDate, sales) => {

    if (!salesMatrixSeparatedByDate.length) {
      let legendToChart = 'Todas Empresas'

      if (Boolean(companyIdSelected)) {
        const company = companies.find(company => company.id.toString() === companyIdSelected)
        legendToChart = company.companyName
      }
      salesMatrixSeparatedByDate.push(['x', legendToChart, { role: "tooltip", type: "string" }])
    }

    if (!sales.length) {
      return salesMatrixSeparatedByDate
    }

    const firstSaleDay = getTimeFromDateWithoutHours(sales[0].date)
    const salesFromTheSameDay = sales.filter(sale => getTimeFromDateWithoutHours(sale.date) === firstSaleDay)
    const salesFromAnotherDay = sales.filter(sale => getTimeFromDateWithoutHours(sale.date) !== firstSaleDay)

    salesMatrixSeparatedByDate.push(salesFromTheSameDay)
    return getSalesMatrixSeparatedByDay(salesMatrixSeparatedByDate, salesFromAnotherDay)
  }

  const handleNoChartDataInfo = (salesMatrix) => {
    salesMatrix.push([convertDate(new Date()), 1, 'Sem Vendas'])
    setNoChartDataInfo(true)
    setIsSalesSeparatedByMonth(false)
    setChartData(salesMatrix)
  }

  return (
    <div id='admin-page-sales-reports'>
      <header>
        <BreadCrumb path={['/admin/home', null, null]} data={['Início', 'Relatórios', 'Relatório de Venda']} />
        <section>
          <div>
            <label htmlFor='initialDate'>Data Inicial:</label>
            <input
              type="date"
              id='initialDate'
              className='form-control foco-input'
              onChange={e => setInitialDate(e.target.value)}
              value={initialDate}
            />
          </div>

          <div>
            <label htmlFor='finalDate'>Data Final:</label>
            <input
              type="date"
              id='finalDate'
              className='form-control foco-input'
              value={finalDate}
              onChange={e => setFinalDate(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor='company'>Empresa:</label>
            <select
              id='company'
              className='form-control foco-input'
              value={companyIdSelected}
              onChange={e => setCompanyIdSelected(e.target.value)}
            >
              <option value="">Todos</option>
              {companies.map(companie => {
                return <option key={companie.id} value={companie.id}>{companie.companyName}</option>
              })}
            </select>
          </div>

          <div>
            <DownloadXls
              archiveName={`relatoriovendas${new Date().toLocaleDateString()}`}
              tablesNames={['Vendas', 'Itens']}
              data={[chartInfoSalesToExport, chartInfoSalesItemsToExport]}
              className='btn btn-export'
              disabled={loading || noChartDataInfo}
              columns={[xlsSalesColumns, xlsSalesItemsColumns]}
            >
              <FontAwesomeIcon color='white' icon={faCloudDownloadAlt} /> Exportar .xsl
            </DownloadXls>
          </div>


        </section>
      </header>

      <LineChart data={chartData} isSalesSeparatedByMonth={isSalesSeparatedByMonth} />
    </div>
  )
}

export default SalesReport