import React, { useEffect, useState } from "react";
import './styles.css'
import { useDropzone } from 'react-dropzone'
import { useHistory } from 'react-router-dom'
import s3Repository from '../../../../repositories/s3'
import crypto from "crypto";
import { toastr } from 'react-redux-toastr'

function ListImages({ filesArray }) {

  const history = useHistory()

  const [imageDesktop, setImageDesktop] = useState([]);
  const [idDesktop, setIdDesktop] = useState('')
  const [imageToUpload, setImageToUpload] = useState([])
  const [imageInfo, setImageInfo] = useState([])

  const { getRootProps, getInputProps } = useDropzone({ accept: 'image/*' | 'video/*' })
  
  useEffect(() => {
    if (imageDesktop.length != 0) {
      createFileObjectDesktop(imageDesktop)
    }
  }, [imageDesktop])

  useEffect(() => {
    if(imageToUpload.length !== 0){
      uploadMidia(imageToUpload, imageInfo)
      setImageToUpload([])
    }
  }, [imageToUpload])

  const createFileObjectDesktop = file => {
    const imageUpload = ({
      file: file,
      name: `${crypto.randomBytes(16).toString('hex')}-${file.name}`,
      preview: URL.createObjectURL(file)
    })
    //processUploadDesktop(imageUpload)
  }

  async function uploadMidia (newImage, currentImage){

    var file = newImage[0]

    const formData = new FormData();
    formData.append('image', file, file.name)
    
    try {
      await s3Repository.singleUploadBanner(formData)
      .then((resp) => {
        
        var url = resp

        const updateInfo = {
            id: imageInfo.id,
            isVideo: false,
            name: file.name,
            url: resp
        }
        
        const updateArchive = s3Repository.updateFiles(updateInfo)
        .then((updateArchive) => {

          //var index = filesArray.findIndex(x => x.id === currentVideo.id);
          toastr.success('Imagem alterada com sucesso.')

        })
        return history.push('products')
      })
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao atualizar a mídia. Por favor, tente novamente')
    }
  }

  return (
    
    <div>
      <h3 style={{fontSize: '14px', paddingLeft: '2px', marginBottom:'25px'}}><strong>Imagens</strong></h3>
    <div className="listImagesContainer">
      {(filesArray.length === 0) ? 
        <div>
          <h1 style={{fontSize: '14px', paddingLeft: '10px'}}>Nenhum arquivo selecionado</h1>
        </div>
      :
      <>
        {filesArray?.map(images => (
          <li style={{display: 'flex'}} key={images?.id}>
            <div className="listImage-info">
              <div className="images-preview">
                <img
                  src={images?.imageUrl}
                  height={120}
                  width={140}
                />
                  <div className="submitInputButton">
                    { images?.notUploadedYet 
                      ? 
                      <div className='submitInputUpdate' style={{ justifyContent: 'center', paddingLeft: '30px'}} >
                      <h1 style={{color: 'red'}}>Não registrado</h1>
                      </div>

                      :

                      <div {...getRootProps()} className='submitInputUpdate' style={{ justifyContent: 'center', paddingLeft: '30px'}} >
                        <h1 onClick={() => setImageInfo(images)}>Alterar imagem</h1>
                      <input {...getInputProps()} onChange={value => setImageToUpload(value?.target.files)} multiple={false} />
                      </div>
                    }
                  </div>
              </div>
            </div>
          </li>
        ))}
      </>
    }
    </div>
  </div>
  )
};

export default ListImages;
