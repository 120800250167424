import api from "../services/api"

const getAll = async () => {
  try {

    const response = await api.get('stockentries')
    return response.data

  } catch (err) {
    throw err
  }
}

const create = async stockentry => {
  try {

    const response = await api.post('stockentries', stockentry)
    return response.data

  } catch (err) {
    throw err
  }
}

const getById = async id => {
  try {

    const response = await api.get(`stockentries/${id}`)
    return response.data

  } catch (err) {
    throw err
  }
}

export default {
  getAll,
  create,
  getById
}