import api from "../services/api"

const validate = async (QRCode) => {
  try {
    const response = await api.post('qrcode/validate', QRCode)
    return response.data

  } catch (err) {
    throw err
  }
}

const resend = async (cpf, email) => {
  try {
    const response = await api.get(`qrcode/resend?cpf=${cpf}&email=${email}`)
    return response.data
  } catch (err) {
    throw err
  }
}

export default {
  validate,
  resend
}