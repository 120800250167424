const convertBufferToUint = buffer => {
  const arrayBuffer = new ArrayBuffer(buffer.length);
  const arrayUint = new Uint8Array(arrayBuffer);

  buffer.forEach((buff, i) => {
    arrayUint[i] = buff
  })
  
  return arrayUint
}

export default convertBufferToUint