import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Toggle from 'react-toggle'
import { useSelector, useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import PropTypes from 'prop-types'
import { Field, reduxForm, change } from 'redux-form'

import CardForm from '../../components/CardForm'
import FormSubmitButtons from '../../components/FormSubmitButtons'
import renderField from '../../../../components/RenderField'
import validate from './validate'
import { onlyNumbers, maxLength } from '../../../../utils/normalize'

import './styles.css'
import "react-toggle/style.css"

function BankAccountForm({ handleSubmit, assignorsList, bankAccountId, loading, onCancel }) {
  const { values } = useSelector(state => state).form.account
  const { status, activeValidationAccount, updatedPrintingAccount } = values
  const dispatch = useDispatch()

  useEffect(() => {
  }, [])

  return (
    <form id='admin-page-companie-form' onSubmit={handleSubmit}>
      <CardForm
        title='Conta'
        show
      >
        <Row>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='registerNumber'
              type='text'
              component={renderField}
              as='select'
              label='Cedente'
              disabled={!!bankAccountId}
            >
              <option disabled="true" value="">Selecione</option>
              {assignorsList.map(assignor => {
                return <option key={assignor.id} value={assignor.registerNumber}>{assignor.companyName}</option>
              })}
            </Field>
          </Col>

          <Col xs={12} sm={12} md={4} lg={2}>
            <Field
              name='bankCode'
              type='text'
              component={renderField}
              as='select'
              label='Banco'
            >
              <option disabled="true" value="">Selecione</option>
              <option value="001">Banco do Brasil</option>
              <option value="237">Bradesco</option>
            </Field>
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='bankBranch'
              type='text'
              component={renderField}
              label='Agência'
              normalize={value => maxLength(onlyNumbers(value), 4)}
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='bankBranchVd'
              type='text'
              component={renderField}
              label='Digito Agência'
              normalize={value => maxLength(value, 1)}
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='accountNumber'
              type='text'
              component={renderField}
              label='Conta'
              normalize={value => maxLength(onlyNumbers(value), 9)}
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='accountNumberVd'
              type='text'
              component={renderField}
              label='Digito Conta'
              normalize={value => maxLength(value, 1)}
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='accountType'
              type='text'
              component={renderField}
              label='Tipo da conta'
              as='select'
            >
              <option disabled="true" value="">Selecione</option>
              <option value="CORRENTE">CORRENTE</option>
              <option value="POUPANÇA">POUPANÇA</option>
            </Field>
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='accountBeneficiaryCode'
              type='text'
              component={renderField}
              label='Codigo Beneficiário'
              normalize={value => maxLength(onlyNumbers(value), 9)}
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='accountCompanyCode'
              type='text'
              component={renderField}
              format={null}
              label='Codigo da Empresa'
              normalize={value => maxLength(onlyNumbers(value), 9)}
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <label htmlFor="isActive">Status Conta Cedente<span>*</span></label>
            <br />
            <Toggle checked={status} onChange={() => dispatch(change('account', 'status', !status))} />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <label htmlFor="isActive">Validação da Conta do Cedente</label>
            <br />
            <Toggle checked={activeValidationAccount} onChange={() => dispatch(change('account', 'activeValidationAccount', !activeValidationAccount))} />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <label htmlFor="isActive">Impressão Atualizada de Segunda Via</label>
            <br />
            <Toggle checked={updatedPrintingAccount} onChange={() => dispatch(change('account', 'updatedPrintingAccount', !updatedPrintingAccount))} />
          </Col>
        </Row>
      </CardForm>
      <FormSubmitButtons hasId={!!bankAccountId} loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
    </form>
  )
}

BankAccountForm.prototype = {
  handleSubmit: PropTypes.func.isRequired,
  accountId: PropTypes.string,
  loading: PropTypes.bool.isRequired
}

export default reduxForm({
  form: 'account',
  validate: validate
})(BankAccountForm)