import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Field, change } from 'redux-form'
import { toastr } from 'react-redux-toastr'
import { useDispatch, useSelector } from 'react-redux'

import companiesRepository from '../../../../repositories/Companies'

import RenderField from '../../../../components/RenderField'
import SearchInput from '../../components/SearchInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { createTextMask } from 'redux-form-input-masks'
import { cepMask, cpfMask, onlyNumbers, phoneMask } from '../../../../utils/normalize'

import getAddressByCep from '../../../../utils/viaCep'

const PersonalData = () => {
  const [companies, setCompanies] = useState([])

  const { client } = useSelector(state => state.form.sale.values)
  const { zipCode } = client
  const dispatch = useDispatch()

  async function handleSelectCompaniesByCity() {
    if (!zipCode) {
      return toastr.warning('Por favor, informe o CEP para prosseguir.')
    }

    const cep = onlyNumbers(zipCode)

    if (cep.length < 8 || cep === '111111111') {
      return toastr.warning('Por favor, informe um CEP válido para prosseguir.')
    }

    try {
      const addressData = await getAddressByCep(cep)

      const newClientData = {
        ...client,
        ...addressData
      }

      await dispatch(change('sale', 'client', newClientData))
      getCompaniesByCity(addressData.city)
    } catch (err) {
      toastr.warning(err.message)
    }
  }

  async function getCompaniesByCity(city) {
    try {
      const companies = await companiesRepository.getAllByCity(city)
      const activeCompanies = companies.filter(company => Boolean(company.isActive))

      setCompanies(activeCompanies)
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao buscar as empresas. Por favor, tente novamente')
    }
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={2} lg={2}>
          <Field
            name='client.cpf'
            type='text'
            component={RenderField}
            label='CPF'
            normalize={cpfMask}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12} md={4} lg={3}>
          <Field
            name='client.name'
            type='text'
            component={RenderField}
            label='Nome'
          />
        </Col>

        <Col xs={12} sm={12} md={4} lg={4}>
          <Field
            name='client.email'
            type='text'
            component={RenderField}
            label='E-mail'
            maxLength={50}
          />
        </Col>

        <Col xs={12} sm={12} md={2} lg={2}>
          <Field
            name='client.phone'
            type='text'
            component={RenderField}
            label='Celular'
            normalize={phoneMask}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <label>Informe o CEP para escolher o local de retirada</label>
          <Col xs={12} sm={12} md={3} lg={3} style={{ padding: 0, minWidth: '290px' }}>
            <div className="input-group">
              <input
                className='form-control foco-input'
                type="text"
                name='client.zipCode'
                value={cepMask(zipCode)}
                onChange={e => dispatch(change('sale', 'client.zipCode', e.target.value))}
              />
              <div className="input-group-append">
                <button onClick={handleSelectCompaniesByCity} type='button' className="input-group-text">Ok</button>
              </div>
              <div className='dont-know-zipcode'>
                <a href='http://www.buscacep.correios.com.br/sistemas/buscacep/' rel="noopener noreferrer" target='_blank'>Não sei o CEP <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
              </div>
            </div>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12} md={9} lg={9}>
          <Field
            name='companyId'
            as='select'
            component={RenderField}
            label='Onde deseja retirar o produto ?'
            disabled={!companies.length}
          >
            <option value=''>Selecione</option>
            {companies.map(company => {
              return <option key={company.id} value={company.id}>{`${company.tradingName} - ${company.address}, ${company.addressNumber} - ${company.city}/${company.state}`} </option>
            })}
          </Field>
        </Col>
      </Row>
    </>
  )
}

export default PersonalData