import api from "../services/api";

const create = async agreementData => {
  try {

    const response = await api.post('bankAgreements/create', agreementData)
    return response
  } catch (err) {
    throw err
  }
}

const getAll = async () => {
  try {

    const response = await api.get('bankAgreements/getAll')
    return response.data

  } catch (err) {
    throw err
  }
}

const getById = async (id) => {
  try {
    const response = await api.get(`bankAgreements/getById/${id}`)
    return response.data
    
  } catch (err) {
    throw err
  }
}

const exclude = async (agreementId) => {
  try {
    const response = await api.post(`bankAgreements/delete/`, agreementId)
    console.log(response)
    return response.data
  
  } catch (err) {
    console.log(err)
    throw err
  }
}

const update = async (idAgreement, agreementData) => {
  try {
    const response = await api.put(`bankAgreements/update/${idAgreement}`, agreementData)
    console.log(response)
    return response.cata
  } catch (err) {
    throw err
  }
}
      
const getAllActive = async (accountId) => {
  try {

    const response = await api.get(`bankAgreements-active/${accountId}`)
    return response.data
  } catch (err) {
    throw err
  }
}

export default {
  create,
  getAll,
  getById,
  exclude,
  update,
  getAllActive
} 