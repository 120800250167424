import React, { useEffect, useState } from 'react';
import { setCookie } from '../../../../utils/cookies';
import { useHistory } from 'react-router-dom';
import { isAppLogged, validadeEmail } from '../../../../utils/validation';
import companiesRepository from '../../../../repositories/Companies';
import usersRepository from '../../../../repositories/Users';
// import AlertModal from '../../../Store/components/ModalAlertMsg';
import AlertModal from '../../components/AlertModal';
import LoadingScreen from '../../components/Loading';

import logo from "../../../../assets/img/app-login/app-logo.png";

import './styles.css';

function Login() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [hasLogin, setHasLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});

  const history = useHistory();

  useEffect(() => {
    if (isAppLogged()) {
      history.push('/partners/dashboard');
    }
  }, [])

  const submit = () => {
    if (password !== user.password) {
      setMessage('Ops, a senha informada está incorreta.');
      setIsAlertModalOpen(true);
      return;
    }

    if (email && password) {
      setCookie('APP_ID', email);
      setCookie('APP_NAME', password);
      history.push('/partners/dashboard');
    }
  }

  const handleBlurEmail = async () => {
    if (!validadeEmail(email)) {
      setMessage('Ops, o campo de e-mail está inválido.');
      setIsAlertModalOpen(true);
      return;
    }

    if (!email) {
      setMessage('Ops, informe o e-mail para prosseguir.');
      setIsAlertModalOpen(true);
      return;
    }

    try {
      setLoading(true)
      var returnedCompany = await companiesRepository.findByEmail(email);
      setCompany(returnedCompany);
      await getUserByCompanyId(returnedCompany.id);
      setCookie('APP_COMPANYID', returnedCompany.id)
      return;
    } catch (err) {
      if (err.response.status === 404) {
        setMessage('Ops, o e-mail informado não está cadastrado na nossa base de dados.');
        setIsAlertModalOpen(true);
        return;
      } else {
        setMessage('Ocorreu um erro ao buscar o cadastro. Por favor, tente novamente.');
        setIsAlertModalOpen(true);
        return;
      }
    } finally {
      setLoading(false)
    }
  }

  const getUserByCompanyId = async (id) => {
    try {
      var returnedUser = await usersRepository.getByCompanyId(id);
      setUser(returnedUser);
      setHasLogin(true);
      return;
    } catch (err) {
      if (err.response.status === 404) {
        history.push({
          pathname: '/partners/createaccount',
          state: {
            companyId: id,
            companyEmail: email
          }
        });
      } else {
        setMessage('Ocorreu um erro ao buscar o cadastro. Por favor, tente novamente.');
        setIsAlertModalOpen(true);
        return;
      }
    }
  }

  const handleRecoveryPassword = () => {
    history.push('/partners/passwordrecovery');
  }

  return (
    <>
      <div className='app-login-container'>
        <div className='app-login'>
          <div className="app-login-card">
            <div style={{ display: 'flex', height: '200px', width: '200px', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
              <img className="logo-img" src={logo} alt="Loja WorkMotor" />
            </div>

            <div className="app-login-form">
              <div className="app-login-field">
                {!hasLogin ?
                  <input
                    type="email"
                    value={email}
                    placeholder="E-mail"
                    onChange={e => setEmail(e.target.value)}
                  />
                  :
                  <input
                    type="password"
                    value={password}
                    placeholder="Senha"
                    onChange={e => setPassword(e.target.value)}
                  />
                }
              </div>

              <div className="app-login-form"
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center"
                }}>
                {!hasLogin ?
                  <button className='btn btn-sucesso' onClick={e => handleBlurEmail()}>AVANÇAR</button>
                  :
                  <button className='btn btn-sucesso' onClick={e => submit()} disabled={!password}>FAZER LOGIN</button>
                }
              </div>

              <div className='app-recovery-password'>
                {hasLogin && (
                  <label htmlFor="recoveryPassword" onClick={e => handleRecoveryPassword()}>Esqueci a senha.</label>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>

      {isAlertModalOpen && (
        <AlertModal text={message} onClose={e => setIsAlertModalOpen(false)} />
      )
      }

      {loading && (
        <LoadingScreen />
      )
      }

    </>
  );
}

export default Login;