import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'

import CardForm from '../../components/CardForm'
import FormSubmitButtons from '../../components/FormSubmitButtons'

import validate from './validate'
import PersonalData from './PersonalData';
import Items from './Items'

const SaleForm = ({ handleSubmit, loading, onCancel }) => {

  return (
    <form onSubmit={handleSubmit}>
      <CardForm
        title='Dados Pessoais'
        show
      >
        <PersonalData />
      </CardForm>

      <CardForm
        title='Itens'
        show
      >
        <Items loading={loading} />
      </CardForm>

      <FormSubmitButtons loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
    </form>
  )
}

SaleForm.defaultProps = {
  handleSubmit: () => { },
  loading: false,
  onCancel: () => { },
}


SaleForm.prototype = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'sale',
  validate: validate
})(SaleForm)