import React, { useState, useEffect } from 'react';

import './styles.css'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDropzone } from 'react-dropzone'
import crypto from "crypto";
import s3Repository from '../../../../repositories/s3'
import Toggle from 'react-toggle'
import RedirectModal from '../RedirectLinkModal';

function PopUpInputDesktop({ upload }) {

  const [popUpDesktop, setPopUpDesktop] = useState([]);
  const [activedDesktop, setActivedDesktop] = useState();
  const [idDesktop, setIdDesktop] = useState();
  const [popUpDesktopReceivedImg, setPopUpDesktopReceivedImg] = useState('');
  const [fileUploaded, setFileUploaded] = useState({})
  const [popUp, setPopUp] = useState({})

  const [isRedirectLinkModaleOpen, setIsRedirectLinkModaleOpen] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({ accept: 'image/*' })


  //-------------------- DESKTOP -------------------- //

  useEffect(() => {
    getPopUpDesktop()

  }, [])


  useEffect(() => {
    if (popUpDesktop?.length != 0 && popUpDesktop != undefined) {
      const urlImage = URL.createObjectURL(popUpDesktop)
      setPopUpDesktopReceivedImg(urlImage)

    }
  }, [popUpDesktop])

  useEffect(() => {
    if (upload) {
      if (popUpDesktop?.length != 0) {
        createFileObjectDesktop(popUpDesktop)
      }
    }
  }, [upload])

  useEffect(() => {
    if (upload && idDesktop) {
      saveCheckBoxOptionDesktop()
    }
  }, [upload])

  const createFileObjectDesktop = file => {
    const imageUpload = ({
      file: file,
      name: `${crypto.randomBytes(16).toString('hex')}-${file.name + 'mobile'}`,
      preview: URL.createObjectURL(file)
    })
    processUploadDesktop(imageUpload)
  }

  async function processUploadDesktop(imageUpload) {

    const formData = new FormData();
    formData.append('image', imageUpload.file, imageUpload.name)
    // for (var p of formData) {
    //   console.log(p);
    // }

    try {
      s3Repository.singleUploadPopup(formData)

        .then(resp => createdPopUpDesktop(resp, imageUpload.name))

    } catch (err) {

      throw err
    }
  }

  async function createdPopUpDesktop(urlImg, nameImg) {

    try {
      await s3Repository.createdPopUp({
        "imageName": nameImg,
        "imageURL": urlImg,
        "enable": true,
        "mobile": false
      }
      )
        .then(getPopUpDesktop())
      window.location.reload()

    } catch (err) {
      console.log(err.response)

      throw err
    }
  }

  async function getPopUpDesktop() {
    try {
      await s3Repository.getPopupDesktop()
        .then(resp =>
          setPopUp(resp,
            setPopUpDesktopReceivedImg(resp.urlImgPopup,
              setIdDesktop(resp.id),
              setActivedDesktop(resp.isActive)))
        )
    } catch (err) {

      throw err
    }
  }

  function validationCheckedDesktop() {
    if (activedDesktop) {
      setActivedDesktop(false)
    } else {
      setActivedDesktop(true)
    }
  }

  async function saveCheckBoxOptionDesktop() {
    try {
      await s3Repository.enablePopup({
        "imageId": idDesktop,
        "enable": activedDesktop,
        "mobile": false
      })

    } catch (err) {

      throw err
    }
  }

  async function saveCheckBoxOptionDesktop() {
    try {
      await s3Repository.enablePopup({
        "imageId": idDesktop,
        "enable": activedDesktop,
        "mobile": false
      })

    } catch (err) {

      throw err
    }
  }

  function handleOpenRedirectLinkModal(uploadedFile) {
    setFileUploaded(uploadedFile)
    setIsRedirectLinkModaleOpen(true)
  }

  return (
    <>
      <div className="inputPopUpDesktop">
        {popUpDesktopReceivedImg ?
          <>
            <div className='submitImputButtonPopUpDesktop'>
              <img
                src={popUpDesktopReceivedImg}
                height={135}
                width={164}
              />
              <div {...getRootProps()} className='submitImputButtonUpdate' >

                <h1 style={{ fontSize: '13px', width: '100%' }}>Alterar imagem</h1>

                <input {...getInputProps()} onChange={value => setPopUpDesktop(value?.target.files[0])} multiple={false} />
              </div>
              <div className='submitImputButtonUpdate' >
                <h1 onClick={e => handleOpenRedirectLinkModal(popUp)} style={{ fontSize: '13px', width: '100%', marginTop: '1%' }}>Alterar informações de link</h1>
              </div>
            </div>
          </>
          :
          <>
            <div {...getRootProps()} className='submitImputButtonPopUpDesktop' >
              <FontAwesomeIcon icon={faPlusCircle} size="2x" color='#7A7A7A' />
              <br />
              <h1>Adcionar imagem</h1>

              <input {...getInputProps()} onClick={value => setPopUpDesktop(value?.target.files[0])} onChange={value => setPopUpDesktop(value?.target.files[0])} multiple={false} />
            </div>
          </>
        }
        <div style={{ marginLeft: '3%' }}>
          <label style={{ fontSize: '16px' }}>Imagem pop-up do site(desktop)</label>
          <br />
          <Toggle checked={activedDesktop} onClick={() => validationCheckedDesktop()} />

        </div>
      </div>

      {isRedirectLinkModaleOpen ?
        <RedirectModal
          onHide={() => setIsRedirectLinkModaleOpen(false)}
          file={fileUploaded}
          show={isRedirectLinkModaleOpen}
          type='popup'
        />
        :
        null
      }

    </>
  )
}

export default PopUpInputDesktop;