import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { useSelector } from 'react-redux'

import CardForm from '../../components/CardForm'
import FormSubmitButtons from '../../components/FormSubmitButtons'

import validate from './validate'
import CompanyData from './CompanyData';
import StockItems from './StockItems'

function StockEntryForm({ handleSubmit, loading, onCancel }) {  
  const { visualizationMode } = useSelector(state => state).form.stockentry.values

  return (
    <form onSubmit={handleSubmit}>
      <CardForm
        title='Dados da Empresa'
        show
      >
        <CompanyData/>
      </CardForm>

      <CardForm
        title='Itens'
        show
      >
        <StockItems loading={loading}/>
      </CardForm>

      <FormSubmitButtons loading={loading} disabled={visualizationMode} onSubmit={handleSubmit} onCancel={onCancel} />
    </form>
  )
}

StockEntryForm.prototype = {
  handleSubmit: PropTypes.func.isRequired, 
  stockEntryId: PropTypes.string, 
  loading: PropTypes.bool.isRequired
}

export default reduxForm({
  form: 'stockentry',
  validate: validate
})(StockEntryForm)