import api from "../services/api"

const getAll = async () => {
    try {
        const response = await api.get('logs/payment-logs/')

        return response.data
    } catch (err) {
        throw err
    }
}

export default {
    getAll
  }