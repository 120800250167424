const port = process.env.REACT_APP_PORT;
const api = process.env.REACT_APP_APIRCLUB; 

const config = {
  endpoint: api,
  port: port,
}

console.log(process.env)

export default config