import React, { useState, useEffect } from 'react';
import productRepository from '../../../../repositories/Products';
import { useParams } from "react-router-dom";
import "./styles.css";
import ProductImagesGallery from './ProductImagesGallery'
import ProductInformation from './ProductInformation'
import ProductBuyInformation from './ProductBuyInformation'
import ProductFormOfPayment from './ProductFormOfPayment'
import ProductPickupLocation from './ProductPickupLocation'
import ProductImagesGalleryMobile from './ProductImagesGalleryMobile'
import { isMobile } from '../../../../utils/isMobile'
import api from "../../../../services/api";
import config from '../../../../config';

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState()
  const [mobile, setMobile] = useState(false)
  const [dataImageVideo, setDataImageVideo] = useState([])
  useEffect(() => {
    getProduct()
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {

    const getAllImageVideo = async () => {
      const resp = await api.get('product-images/getAll/' + id);
      setDataImageVideo(resp.data)

    };
    getAllImageVideo()
  }, [])

  useEffect(() => {
    isMobile()
    const mobileAcess = isMobile()
    if (!mobileAcess) {
      setMobile(false)
    } else {
      setMobile(true)
    }
  }, []);

  async function getProduct() {
    try {
      const response = await productRepository.getById(id);
      setProduct(response);

    } catch (err) {
      console.log(err)
      setProduct(null)
    }
  }

  return (
    <div>
      {!mobile ?
        <div className="productDetailsContainer">
          <div className="productDetails">

            <div className="productDetailsColumn1">
              <ProductImagesGallery information={product} />
              <ProductInformation information={product} />
            </div>

            <div className="productDetailsColumn2">
              <ProductBuyInformation information={product} isMobile={mobile} />
              <div className="line"></div>
              <ProductFormOfPayment information={product} />
              <ProductPickupLocation information={product} />
            </div>
          </div>
        </div>
        :
        <div className="productDetailsContainerMobile">
          <div className="productDetailsMobile">
            <ProductImagesGalleryMobile data={dataImageVideo} information={product} />
            <ProductBuyInformation information={product} />
            <ProductInformation information={product} />
            <ProductFormOfPayment information={product} />
            <ProductPickupLocation information={product} />
          </div>
        </div>
      }
    </div>
  )

}

export default ProductDetails; 