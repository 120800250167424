import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import "./styles.css";
import Companies from '../../components/Companies';
import CompanyAddress from '../../components/CompanyAddress';
import companiesRepository from '../../../../repositories/Companies';
import { isMobile } from '../../../../utils/validation';
import ModalAlertMsg from '../../components/ModalAlertMsg'
import ButtonWhatsappColumn from '../../components/ButtonWhatsappColumn'

import initGa from '../../../../utils/googleAnalytics';

function WithdrawMobile({ listCompanies }) {
  initGa(window.location.href);

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    setIsModalOpen(true)
  }, [])

  return (
    <>
      <div className="withdraw-page-mobile">
        <h1 style={window.location.host.includes('21200') ? { width: '100%' } : null}>Veja Abaixo os Locais de Retirada</h1>
        <Companies listCompanies={listCompanies} height={25} width={85} />
        {/* <Companies listCompanies={listCompanies} height={35} width={50} /> */}
        <br />
        <h1>Endereço</h1>
        {listCompanies.sort((a, b) => a.city.localeCompare(b.city)).map(c =>
          <CompanyAddress company={c} width={28} />
        )}
        <br />
        <br />
        {!window.location.host.includes('21200') && !window.location.host.includes('99') ?
          < ButtonWhatsappColumn />
          :
          <div style={{ marginTop: '40%' }} />
        }
        {isModalOpen ?
          <ModalAlertMsg text="As lojas não vendem o produto no balcão, o produto deve ser comprado pelo site e retirado na loja através do QRCODE ou informando seu CPF." onClose={() => { setIsModalOpen(false) }} />
          :
          null
        }
      </div>
    </>
  )
}

export default WithdrawMobile;