import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import dropOutRepository from '../../../../repositories/DropOut'

function ModalDropOut({ onClose, msgModalOpen }) {
    const [option1, SetOption1] = useState(false)
    const [option2, SetOption2] = useState(false)
    const [option3, SetOption3] = useState(false)
    const [option4, SetOption4] = useState(false)
    const [option5, SetOption5] = useState(false)
    const [option6, SetOption6] = useState(false)
    const [enable, SetEnable] = useState(true)
    const [textOption6, SetTextOption6] = useState('')
    const [textRequired, SetTextRequired] = useState('')

    useEffect(() => {
        if (option6) {
            SetEnable(false)
        }
        if (!option6) {
            SetEnable(true)
            SetTextOption6('')
            SetTextRequired('')
        }
    }, [option6])

    useEffect(() => {
        if (textOption6) {
            SetTextRequired('')
        }
    }, [textOption6])

    useEffect(() => {
        if (option1 || option2 || option3 || option4 || option5 || option6) {
            SetTextRequired('')
        }
    }, [option1, option2, option3, option4, option5, option6])

    function verification() {
        if (option6 && !textOption6) {
         return SetTextRequired('Campo de preenchimento obrigatório.')
        } 
        if (!option1 && !option2 && !option3 && !option4 && !option5 && !option6) {
            return SetTextRequired('Por favor, para prosseguir, selecione ao menos uma opção.')
        }
        return submit()
    }

    async function submit() {

        const list = {
            "dropoutList":[
                {
                    "code":1,
                    "description":"Pagamento em PagSeguro/MercadoPago/Paypal",
                    "otherDescription":null,
                    "selected":option1
                },
                {
                    "code":2,
                    "description":"Oficinas mais próximas",
                    "otherDescription":null,
                    "selected":option2
                },
                {
                    "code":3,
                    "description":"Outras marcas de óleo",
                    "otherDescription":null,
                    "selected":option3
                },
                {
                    "code":4,
                    "description":"Outras formas de pagamento",
                    "otherDescription":null,
                    "selected":option4
                },
                {
                    "code":5,
                    "description":"Entrega a domicilio",
                    "otherDescription":null,
                    "selected":option5
                },
                {
                    "code":6,
                    "description":"Outros",
                    "otherDescription":textOption6.trim(),
                    "selected":option6
                }
            ]
        }
        try {
            await dropOutRepository.create(list)
              await onClose()
              msgModalOpen()
          } catch(err) {
              console.log(err.response)
          }
    }

    return (
        <div>
            <div className='modal-alert-msg'>
                <Modal style={{ marginTop: "5%", display: 'flex', alignItems: 'center', justifyContent: 'center' }} show={true} onHide={() => onClose()} animation={true} size="lg" backdrop="static" >
                    <div class="modal-content" style={{}}>
                        <Modal.Header >
                            <div id='company-details-modal-header'>
                                <h5><strong>RCLUB</strong></h5>
                            </div>
                        </Modal.Header >
                        <Modal.Body style={{}}>
                            <h5><strong>NOS AJUDE A ATENDÊ-LO MELHOR.</strong></h5>
                            <h6>O que falta para você comprar?</h6>
                            <Form>
                                <Form.Check type="checkbox" label="Pagamento em PagSeguro/MercadoPago/Paypal" checked={option1} onChange={value => SetOption1(value?.target.checked)} />
                                <Form.Check type="checkbox" label="Oficinas mais próximas" checked={option2} onChange={value => SetOption2(value?.target.checked)} />
                                <Form.Check type="checkbox" label="Outras marcas de óleo" checked={option3} onChange={value => SetOption3(value?.target.checked)} />
                                <Form.Check type="checkbox" label="Outras formas de pagamento" checked={option4} onChange={value => SetOption4(value?.target.checked)} />
                                <Form.Check type="checkbox" label="Entrega a domicilio" checked={option5} onChange={value => SetOption5(value?.target.checked)} />
                                <Form.Check type="checkbox" label="Outros" checked={option6} onChange={value => SetOption6(value?.target.checked)} />
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows={3} disabled={enable} value={textOption6} onChange={value => SetTextOption6(value?.target.value)} maxLength={1024} />
                                    <label style={{ color: 'red' }}>{textRequired}</label>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <button className='btn btn-success' name="Sim" style={{ marginLeft: 5 }} onClick={() => verification()}>Enviar</button>
                            </div>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default ModalDropOut;
