import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Field, change } from 'redux-form'
import { toastr } from 'react-redux-toastr'
import { useDispatch, useSelector } from 'react-redux'

import companiesRepository from '../../../../repositories/Companies'

import RenderField from '../../../../components/RenderField'
import SearchInput from '../../components/SearchInput'

const CompanyData = () => {
  const [query, setQuery] = useState('')
  const [companies, setCompanies] = useState([])
  const [suggestionsToCompanies, setSuggestionsToCompanies] = useState([])

  const { visualizationMode } = useSelector(state => state).form.stockentry.values
  const dispatch = useDispatch()

  useEffect(() => {
    loadCompanies()
  }, [])

  const loadCompanies = async () => {
    try {
      const companies = await companiesRepository.getAllActiveCompanies()

      const suggestionsToCompanies = companies.map(company => 'Razão Social: ' + company.companyName + ', CNPJ:' + company.cnpj)
      setCompanies(companies)
      setSuggestionsToCompanies(suggestionsToCompanies)

    } catch(err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar as empresas. Por favor, tente novamente')
    }
  }

  const handleChangeSearchInput = e => {
    const selectedCompany = e.target.value
    setQuery(selectedCompany)
    const companyCnpj = selectedCompany.split('CNPJ:')[1]
    
    if(!companyCnpj) return

    
    getCompanyByCnpj(companyCnpj)
  }

  const getCompanyByCnpj = cnpj => {
    const company = companies.find(company => company.cnpj === cnpj)

    if(!company) return

    dispatch([
      change('stockentry', 'companyId', company.id),
      change('stockentry', 'companyName', company.companyName),
      change('stockentry', 'companyCnpj', company.cnpj),
      change('stockentry', 'companyCity', company.city),
      change('stockentry', 'companyState', company.state),
    ])

    setQuery('')
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={5} lg={5}>
          <SearchInput
            placeholder='Pesquisar por CNPJ ou Razão Social'
            name='search-input'
            value={query}
            suggestions={suggestionsToCompanies}
            onChange={handleChangeSearchInput}
            disabled={visualizationMode}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={12} md={2} lg={2}>
          <Field
            name='companyCnpj'
            type='text'
            component={RenderField}
            label='CNPJ'
            disabled
          />
        </Col>

        <Col xs={12} sm={12} md={4} lg={4}>
          <Field
            name='companyName'
            type='text'
            component={RenderField}
            label='Razão Social'
            disabled
          />
        </Col>

        <Col xs={12} sm={12} md={2} lg={2}>
          <Field
            name='companyCity'
            type='text'
            component={RenderField}
            label='Cidade'
            disabled
          />
        </Col>

        <Col xs={12} sm={12} md={1} lg={1}>
          <Field
            name='companyState'
            type='text'
            component={RenderField}
            label='UF'
            disabled
          />
        </Col>
      </Row>
    </>
  )
}

export default CompanyData