import React from 'react';
import "./styles.css";
import ButtonWhatsappColumn from '../../components/ButtonWhatsappColumn'
import RegisterForm from '../../components/RegisterForm'


function RegisterDesktop() {
  return (
    <>
      <div id='register-page'>
        <div className="register-page">
          <h2>
            Óleo Premium por <b>R$ 11,98</b> e sem taxas. <br /> <br />
            Preencha o formulário abaixo que entraremos <br /> em contato com você.
          </h2>
        </div>
        <div className='register-mobile-form'>
          <RegisterForm typeContact={"Campanha"} />
        </div>
        <ButtonWhatsappColumn />
      </div>
    </>
  )
}

export default RegisterDesktop;