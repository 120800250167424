import React, { useState, useEffect } from 'react'
import { faFileInvoiceDollar, faUser, faBoxOpen, faBarcode } from '@fortawesome/free-solid-svg-icons'
import { toastr } from 'react-redux-toastr'

import BreadCrumb from '../../components/BreadCrumb'
import DashBoardCard from './DashBoardCard'

import companiesRepository from '../../../../repositories/Companies'
import productsRepository from '../../../../repositories/Products'
import salesRepository from '../../../../repositories/Sales'
import bankSlipRepository from '../../../../repositories/BankSlip'

import './styles.css'

function Main({ history }) {
  const [activeProductsCount, setActiveProductsCount] = useState(0)
  const [salesCount, setSalesCount] = useState(0)
  const [activeCompaniesCount, setActiveCompaniesCount] = useState(0)
  const [bankSlipDelayedCount, setBankSlipDelayedCount] = useState(0)
  const [bankSlipReceivableCount, setBankSlipReceivableCount] = useState(0)
  const [bankSlipReceivableTodayCount, setBankSlipReceivableTodayCount] = useState(0)

  useEffect(() => {
    getActiveProductsCount()
    document.title = 'Rclub - Início'
  }, [])

  useEffect(() => {
    getSalesCount()
  }, [])

  useEffect(() => {
    getActiveCompaniesCount()
  }, [])

  useEffect(() => {
    getbankSlipDelayedCount()
  }, [])

  useEffect(() => {
    getbbankSlipReceivableCount()
  }, [])

  useEffect(() => {
    getbankSlipReceivableTodayCount()
  }, [])

  async function getActiveProductsCount() {
    try {
      const products = await productsRepository.getAllActiveProducts()

      setActiveProductsCount(products.length)
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao buscar os contadores de produtos')
    }
  }

  async function getSalesCount() {
    try {
      const sales = await salesRepository.getAll()

      setSalesCount(sales.length)
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao buscar os contadores de vendas')
    }
  }


  async function getActiveCompaniesCount() {
    try {
      const companies = await companiesRepository.getAllActiveCompanies()
      setActiveCompaniesCount(companies.length)
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao buscar os contadores de empresas')
    }
  }

  async function getbankSlipDelayedCount() {
    try {
      const BankSlip = await bankSlipRepository.getBankSlipDelayed()

      setBankSlipDelayedCount(BankSlip)
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao buscar os contadores dos Boletos Atrasados')
    }
  }

  async function getbbankSlipReceivableCount() {
    try {
      const BankSlip = await bankSlipRepository.getBankSlipReceivable()

      setBankSlipReceivableCount(BankSlip)
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao buscar os contadores dos Boletos a Receber')
    }
  }

  async function getbankSlipReceivableTodayCount() {
    try {
      const BankSlip = await bankSlipRepository.getBankSlipReceivableToday()

      setBankSlipReceivableTodayCount(BankSlip)
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao buscar os contadores dos Boletos a Receber Hoje')
    }
  }

  return (
    <div>
      <BreadCrumb data={['Início']} path={[null]} />
      <div className='admin-main-content-cards'>
        <DashBoardCard
          onClick={() => history.push('products')}
          title='Produtos'
          counter={activeProductsCount}
          icon={faBoxOpen}
          circleColor='#3f51b5'
        />

        <DashBoardCard
          onClick={() => history.push('sales')}
          title='Vendas'
          counter={salesCount}
          icon={faFileInvoiceDollar}
          circleColor='green'
        />

        <DashBoardCard
          onClick={() => history.push('companies')}
          title='Empresas'
          counter={activeCompaniesCount}
          icon={faUser}
          circleColor='gray'
        />

        <DashBoardCard
          onClick={() => history.push({
            pathname: `bankslips`,
            state: { obj: bankSlipDelayedCount },
          })}
          title='Boletos Atrasados'
          counter={bankSlipDelayedCount.length}
          icon={faBarcode}
          circleColor='red'
        />

        <DashBoardCard
          onClick={() => history.push({
            pathname: `bankslips`,
            state: { obj: bankSlipReceivableCount },
          })}
          title='Boletos a Receber'
          counter={bankSlipReceivableCount.length}
          icon={faBarcode}
          circleColor='green'
        />

        <DashBoardCard
          onClick={() => history.push({
            pathname: `bankslips`,
            state: { obj: bankSlipReceivableTodayCount },
          })}
          title='Boletos a Receber Hoje'
          counter={bankSlipReceivableTodayCount.length}
          icon={faBarcode}
          circleColor='orange'
        />
      </div>
    </div>
  )
}

export default Main