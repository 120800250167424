import React, { useState, useEffect } from 'react';
import "./styles.css";
import './styles99.css';
import { useHistory } from 'react-router-dom'


const SeeOptions = () => {
  const [classes, setClasses] = useState(null)
  const history = useHistory()

  useEffect(() => {
    loadClasses()
  }, [])

  const loadClasses = () => {
    if (!window.location.host.includes('21200') && !window.location.host.includes('99')) {
      setClasses('see-options-button')
    } else {
      setClasses('see-options-button-99')
    }
  }

  function goToProducts() {
    history.push('products')
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={classes} onClick={goToProducts} style={{ justifyContent: 'center' }}>
          <h5>VER OPÇÕES</h5>
        </div>
      </div>
    </>
  )
}

export default SeeOptions;