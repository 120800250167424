import api from "../services/api"

const create = async coupon => {
  try {
    
    const response = await api.post('coupons', coupon)
    return response.data

  } catch(err) {
    throw err
  }
}

const update = async (id, coupon) => {
  try {
    
    await api.put(`coupons/${id}`, coupon)

  } catch(err) {
    throw err
  }
}

const getAll = async () => {
  try {

    const response = await api.get('coupons')
    return response.data

  } catch (err) {
    throw err
  }
}

const validate = async coupon => {
  try {

    const response = await api.post('coupons/search', {
      description: coupon
    })
    return response.data

  } catch (err) {
    throw err
  }
}

const getLastCode = async () => {
  try {
    
    const response = await api.get('coupons')
    const lastCode = parseInt(response.data.length) + 1
    return lastCode

  } catch(err) {
    throw err
  }
}

const getById = async id => {
  try {
    
    const response = await api.get(`coupons/${id}`)
    return response.data

  } catch(err) {
    throw err
  }
}

export default {
  getAll,
  getLastCode,
  create,
  getById,
  update,
  validate
}