import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { change } from 'redux-form'

import AgreementForm from './CreateBankAgreement'
import BreadCrumb from '../../components/BreadCrumb'
import { onlyNumbers, cnpjMask, phoneMask, cepMask } from '../../../../utils/normalize'
import bankAccountRepository from '../../../../repositories/BankAccount'
import AssignorsRepository from '../../../../repositories/Assignors'
import bankAgreementRepository from '../../../../repositories/BankAgreements'

function BundleFormAgreement({ match }) {
  const [loading, setLoading] = useState(false)
  const [agreementId, setAgreementId] = useState(null)
  const [assignorsList, setAssignorsList] = useState([])
  const [agreementsList, setAgreementsList] = useState([])
  const [assignor, setAssignor] = useState(null)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!match.params.id) {
      setAgreementId(match.params.id)
    }
  }, [])

  useEffect(() => {
    if (!!agreementId) {
      loadAgreement()
      loadAssignors()
    } else {
      document.title = 'Rclub - Novo Convênio'
      loadAssignors()
    }
  }, [agreementId])

  async function loadAgreement() {
    setLoading(true)

    try {
      const agreement = await bankAgreementRepository.getById(agreementId)

      dispatch([
        change('agreement', 'assignor', agreement.BankAssignorAccounts.Assignors.id),
        change('agreement', 'assignorId', agreement.BankAssignorAccounts.Assignors.id),
        change('agreement', 'assignorName', agreement.BankAssignorAccounts.Assignors.companyName),
        change('agreement', 'account', agreement.BankAssignorAccounts.id),
        change('agreement', 'accountId', agreement.accountId),
        change('agreement', 'agreementNumber', agreement.agreementNumber),
        change('agreement', 'agreementDescription', agreement.agreementDescription),
        change('agreement', 'agreementBankBook', agreement.agreementBankBook),
        change('agreement', 'agreementType', agreement.agreementType),
        change('agreement', 'agreementCNABPattern', agreement.agreementCNABPattern),
        change('agreement', 'agreementDailyRestart', agreement.agreementDailyRestart),
        change('agreement', 'agreementOurNumberBank', agreement.agreementOurNumberBank),
        change('agreement', 'agreementDeliveryNumber', agreement.agreementDeliveryNumber),
        change('agreement', 'agreementInstantRegister', agreement.agreementInstantRegister),
        change('agreement', 'agreementTecnoId', agreement.agreementId),
        change('agreement', 'assignorRegisterNumber', agreement.BankAssignorAccounts.Assignors.registerNumber)
      ])

      setAssignor(agreement.BankAssignorAccounts.Assignors.id)

      document.title = `Rclub - Editar ${agreement.id}`
    } catch (err) {
      toastr.warning('Ocorreu um erro ao carregar o convênio')
    } finally {
      setLoading(false)
    }
  }

  async function loadAssignors() {
    setLoading(true)

    try {
      const assignors = await AssignorsRepository.getAll()
      setAssignorsList(assignors)
    } catch (err) {
      toastr.warning('Ocorreu um erro ao carregar o cedentes')
    } finally {
      setLoading(false)
    }
  }

  function handleCancel() {
    history.push('configAgreements')
  }

  function handleSubmit(values) {
    if (!agreementId) {
      create(values)
    } else {
      update(values)
    }
  }

  async function create(values) {
    const { account, agreementNumber, agreementDescription, agreementBankBook, agreementType, agreementCNABPattern, 
      agreementDailyRestart, agreementInstantRegister, agreementOurNumberBank, agreementDeliveryNumber } = values

    setLoading(true)

    try {
    const response = await bankAgreementRepository.create({
        accountId: account, 
        agreementNumber, 
        agreementDescription, 
        agreementBankBook, 
        agreementType,
        agreementCNABPattern,
        agreementDailyRestart,
        agreementInstantRegister,
        agreementOurNumberBank,
        agreementDeliveryNumber
      })
      toastr.success(response.data.mensagem)
      history.push('configAgreements')

    } catch (err) {
      toastr.warning('Ocorreu um erro ao criar o convênio. Por favor, tente novamente')

    } finally {
      setLoading(false)
    }
  }

  async function update(values) {
    const { accountId, agreementNumber, agreementDescription, agreementBankBook, agreementType, agreementCNABPattern, 
      agreementDailyRestart, agreementInstantRegister, agreementOurNumberBank, agreementTecnoId, assignorRegisterNumber, agreementDeliveryNumber} = values

    setLoading(true)

    try {
      await bankAgreementRepository.update(agreementId, {
        agreementId: agreementTecnoId, 
        agreementNumber, 
        agreementDescription, 
        agreementBankBook, 
        agreementType,
        agreementCNABPattern,
        agreementDailyRestart,
        agreementInstantRegister,
        agreementOurNumberBank,
        agreementDeliveryNumber
      })

      toastr.success('Convênio atualizado com sucesso.')
      history.push('configAgreements')

    } catch (err) {
      toastr.warning('Ocorreu um erro ao atualizar o convênio. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const initialValues = {
    status: true,
    agreementDailyRestart: false,
    agreementInstantRegister: false,
    agreementOurNumberBank: false
  }

  return (
    <>
      <BreadCrumb path={['home', 'configAgreements', null]} data={['Início', 'Convênios', !agreementId ? 'Novo Convênio' : 'Editar Convênio']} />

      <AgreementForm
        initialValues={initialValues}
        assignorsList={assignorsList}
        assignor={assignor}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        loading={loading}
      />
    </>
  )
}

export default BundleFormAgreement