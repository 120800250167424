import api from "../services/api"

const getSaleById = async (saleId) => {
    try {
  
      const response = await api.get(`pix/${saleId}`)
      return response.data
  
    } catch (err) {
      throw err
    }
  }

  export default {
    getSaleById
  }  