import api from "../services/api"

const getBankSlipDelayed = async () => {
  try {

    const response = await api.get('bankSlipDelayed?paymentType=boleto&saleStatusId=4')
    return response.data

  } catch (err) {
    throw err
  }
}

const getBankSlipReceivable = async () => {
  try {

    const response = await api.get('bankSlipReceivable?paymentType=boleto&saleStatusId=4')
    return response.data

  } catch (err) {
    throw err
  }
}

const getBankSlipReceivableToday = async () => {
  try {

    const response = await api.get('bankSlipReceivableToday?paymentType=boleto&saleStatusId=4')
    return response.data

  } catch (err) {
    throw err
  }
}

const getBankSlipInfo = async (cpf) => {
  try {

    const response = await api.get(`resendbankslip/${cpf}`)
    return response.data

  } catch (err) {
    throw err
  }
}

export default {
  getBankSlipDelayed,
  getBankSlipReceivable,
  getBankSlipReceivableToday,
  getBankSlipInfo,
}