import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import ReactTable from 'react-table-6';
import { useHistory, Link } from 'react-router-dom';
import stockEntriesRepository from '../../../../repositories/StockEntries'
import { cnpjMask } from '../../../../utils/normalize';

const StockEntries = () => {
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [stockEntries, setStockEntries] = useState([])

  const history = useHistory()

  useEffect(() => {
    loadStockEntries()
    document.title = 'Rclub - Entradas'
  }, [])

  const loadStockEntries = async () => {
    setLoading(true)

    try {
      const stockEntries = await stockEntriesRepository.getAll()

      const serializedStockEntries = stockEntries.map(stockEntry => ({
        ...stockEntry,
        companyName: stockEntry.Companies.companyName,
        companyCnpj: stockEntry.Companies.cnpj,
        amountOfItemsQuantity: getAmountOfItemsQuantity(stockEntry.StockEntryItems),
        stockEntryType: stockEntry.StockEntryTypes.description
      }))

      setStockEntries(serializedStockEntries)

    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar as entradas de estoque. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const getAmountOfItemsQuantity = stockEntryItems => {
    let quantity = 0

    let stockEntryArrayOfQuantity = stockEntryItems.map(stockEntryItem => stockEntryItem.quantity)

    if (Boolean(stockEntryArrayOfQuantity.length)) {
      quantity = stockEntryArrayOfQuantity.reduce((accumulated, actual) => accumulated + actual)
    }

    return quantity
  }

  return (
    <div id='admin-page-stock-entries'>
      <header>
        <BreadCrumb path={['home', null]} data={['Início', 'Entradas']} />
        <section>
          <button className='btn btn-success' onClick={() => history.push('stockentry')}>+ Nova Entrada</button>
          <div>
            <FontAwesomeIcon icon={faSearch} />
            <input
              className='form-control foco-input'
              title='Pesquisar por Razão Social'
              placeholder='Pesquisar por Razão Social'
              type='text'
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        </section>
      </header>

      <br />
      <ReactTable
        style={{ textAlign: 'center' }}
        data={stockEntries.filter(x => x['companyName'].toString().toLowerCase().includes(query.toLowerCase()))}
        columns={[
          {
            Header: 'Data',
            accessor: 'date',
            width: 150,
            Cell: props => new Date(props.value).toLocaleString().split(' ')[0]
          },
          {
            Header: 'CNPJ',
            accessor: 'companyCnpj',
            width: 180,
            Cell: props => cnpjMask(props.value)
          },
          {
            Header: 'Razão Social',
            accessor: 'companyName'
          },
          {
            Header: 'Quantidade',
            accessor: 'amountOfItemsQuantity',
            width: 100
          },
          {
            Header: 'Tipo',
            accessor: 'stockEntryTypeId',
            width: 150,
            Cell: props => <button className={`btn btn-${props.value === 1 ? 'success' : 'danger'}`}>{props.original.stockEntryType}</button>
          },
          {
            Header: 'Ações',
            accessor: 'id',
            width: 150,
            Cell: props => (
              <Link to={{ pathname: `stockentry#${props.value}` }}>
                <FontAwesomeIcon icon={faEdit} color='black' />
              </Link>
            )
          }
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
    </div>
  )
}

export default StockEntries