import { onlyNumbers } from "../../../../utils/normalize"

const validate = values => {
  const errors = {}
  if (!values.cnpj) {
    errors.cnpj = 'Obrigatório'
  } else if (onlyNumbers(values.cnpj).length < 14) {
    errors.cnpj = 'CNPJ incompleto'
  }
  if (!values.companyName) {
    errors.companyName = 'Obrigatório'
  }
  if (!values.tradingName) {
    errors.tradingName = 'Obrigatório'
  }
  if (!values.phone) {
    errors.phone = 'Obrigatório'
  } else if (onlyNumbers(values.phone).length < 11) {
    errors.phone = 'Celular incompleto'
  }
  if (!values.zipCode) {
    errors.zipCode = 'Obrigatório'
  } else if (onlyNumbers(values.zipCode).length < 8) {
    errors.zipCode = 'CEP incompleto'
  }
  if (!values.address) {
    errors.address = 'Obrigatório'
  }
  if (!values.addressNumber) {
    errors.addressNumber = 'Obrigatório'
  }
  if (!values.state) {
    errors.state = 'Obrigatório'
  }
  if (!values.city) {
    errors.city = 'Obrigatório'
  }
  if (!values.neighborhood) {
    errors.neighborhood = 'Obrigatório'
  }
  if (!values.email) {
    errors.email = 'Obrigatório'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'E-mail inválido'
  }
  if (!values.vehicleTypeId) {
    errors.vehicleTypeId = 'Obrigatório'
  }
  return errors
}

export default validate