import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import renderCheckedDate from '../../../../../utils/renderCheckedDate';

import './styles.css'

function QRCodeAlreadyChecked({ date }) {
  const history = useHistory();

  const handleNavigation = () => {
    history.push('/partners/checkin')
  }

  return (
    <div className='modal-success-container'>
      <div className='modal-success-contant'>
        <h1>{renderCheckedDate(date)}</h1>
        <FontAwesomeIcon color='red' size='10x' className='icon-button-modal' icon={faWindowClose} />
        <button onClick={() => handleNavigation()}>
          Ok
        </button>
      </div>
    </div>
  )
}

export default QRCodeAlreadyChecked;