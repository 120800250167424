const INITIAL_STATE = {
  search: '',
  productTypes: [],
  vehicleTypes: [],
  products: [],
  productsFiltered: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case 'CHANGE_VALUE':
          return { ...state, [action.payloadType]: action.payload }

      case 'FETCH_PRODUCTS_SUCCESS':
        return { ...state, products: action.payload, productsFiltered: action.payload }

      case 'FETCH_PRODUCT_TYPES_SUCCESS':
        return { ...state, productTypes: action.payload }

      case 'FETCH_VEHICLE_TYPES_SUCCESS':
        return { ...state, vehicleTypes: action.payload }
      case 'FINISH_FILTER_PRODUCTS':
        return { ...state, productsFiltered: action.payload }

      case 'CLEAR_PRODUCT_TYPES_FILTERS':
        return { ...state, productTypes: action.payload }

        case 'CLEAR_VEHICLE_TYPES_FILTERS':
          return { ...state, vehicleTypes: action.payload }
      default:
          return { ...state }
  }
}