import React, { useState } from "react";
import HeaderNav from '../HeaderNav'
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { faHome, faImages, faFileInvoiceDollar, faUser, faListUl, faBoxOpen, faChartPie, faTruckLoading, faFunnelDollar, faAddressBook, faUserClock, faComment, faBarcode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './SideNav.css'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

export default function Menu({ expanded, onToggle, location, history }) {
    const [path, setPath] = useState('')

    return (
        <div >
            <HeaderNav />
            <SideNav
                onToggle={() => onToggle(!expanded)}
                style={{ position: 'fixed' }}
                expanded={expanded}
                onSelect={(selected) => {
                    const to = '/' + selected;
                    if (location.pathname !== to && selected !== 'fixed') {
                        history.push(to);
                        setPath(selected)
                    }
                }}
            >
                <SideNav.Nav defaultSelected={'admin/home'} style={{height:'100vh'}}>
                    <NavItem eventKey='fixed'>
                        <NavIcon>
                            <SideNav.Toggle id="side-toggle" onClick={() => onToggle(!expanded)} />
                        </NavIcon>
                    </NavItem>

                    <NavItem eventKey="admin/home" title="Visão Geral" active={path === 'admin/home'} >
                        <NavIcon >
                            <FontAwesomeIcon icon={faHome} color='blue' className="iconeMenu" style={{ backgroundColor: 'yellow !important' }} aria-hidden="false" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Visão Geral
                        </NavText>
                    </NavItem>

                    <NavItem eventKey="admin/products" title="Produtos" active={path === 'admin/products'}>
                        <NavIcon>
                            <FontAwesomeIcon icon={faBoxOpen} className="iconeMenu" aria-hidden="false" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Produtos
                        </NavText>
                    </NavItem>

                    <NavItem eventKey="admin/sales" title="Vendas" active={path === 'admin/sales'}>
                        <NavIcon>
                            <FontAwesomeIcon icon={faFileInvoiceDollar} className="iconeMenu" aria-hidden="false" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Vendas
                        </NavText>
                    </NavItem>

                    <NavItem eventKey="admin/companies" title="Empresas" active={path === 'admin/companies'}>
                        <NavIcon>
                            <FontAwesomeIcon icon={faUser} className="iconeMenu" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Empresas
                        </NavText>
                    </NavItem>

                    <NavItem eventKey="admin/product-types" title="Tipos de Produto" active={path === 'admin/product-types'}>
                        <NavIcon>
                            <FontAwesomeIcon icon={faListUl} className="iconeMenu" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Tipos de Produto
                        </NavText>
                    </NavItem>

                    <NavItem eventKey="admin/stockentries" title="Entradas de Estoque" active={path === 'admin/stockentries'}>
                        <NavIcon>
                            <FontAwesomeIcon icon={faTruckLoading} className="iconeMenu" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Entradas
                        </NavText>
                    </NavItem>

                    <NavItem eventKey="charts3" active={path.includes('admin/reports')}>
                        <NavIcon>
                            <FontAwesomeIcon icon={faChartPie} className="iconeMenu" aria-hidden="false" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Relatórios
                        </NavText>

                        <NavItem eventKey='admin/reports/contacts' style={{ width: '250px' }}>
                            <NavText style={{ color: 'white' }}>
                                Relatório de Pesquisa de CEP
                            </NavText>
                        </NavItem>

                        <NavItem eventKey='admin/reports/sales' style={{ width: '250px' }}>
                            <NavText style={{ color: 'white' }}>
                                Relatório de Vendas
                            </NavText>
                        </NavItem>

                        <NavItem eventKey='admin/reports/pagtos-transactions' style={{ width: '250px' }}>
                            <NavText style={{ color: 'white' }}>
                                Relatório de Transações PAGTOS
                            </NavText>
                        </NavItem>
                    </NavItem>

                    <NavItem eventKey="admin/coupons" title="Cupons" active={path === 'admin/cupons'}>
                        <NavIcon>
                            <FontAwesomeIcon icon={faFunnelDollar} className="iconeMenu" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Cupons
                        </NavText>
                    </NavItem>

                    <NavItem eventKey='admin/image' >
                        <NavIcon>
                            <FontAwesomeIcon icon={faImages} className="iconeMenu" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Imagens
                            </NavText>
                    </NavItem>

                    <NavItem eventKey='admin/register-contacts' title="Contatos" active={path === 'admin/register-contacts'}>
                        <NavIcon>
                            <FontAwesomeIcon icon={faAddressBook} className="iconeMenu" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Contatos
                            </NavText>
                    </NavItem>

                    <NavItem eventKey='admin/clients-consultation' title="Clientes" active={path === 'admin/clients-consultation'}>
                        <NavIcon>
                            <FontAwesomeIcon icon={faUserClock} className="iconeMenu" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                        Clientes
                            </NavText>
                    </NavItem>

                    {/*<NavItem eventKey='admin/bankslips' title="Boletos" active={path === 'admin/bankslips'}>
                        <NavIcon>
                            <FontAwesomeIcon icon={faBarcode} className="iconeMenu" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Boletos
                            </NavText>
                    </NavItem>*/}


                </SideNav.Nav>
            </SideNav>
        </div>
    )
}
