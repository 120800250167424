import api from "../services/api";

const getByCompanyId = async (companyId) => {
  try {
    const response = await api.get(`users/by-company/${companyId}`)
    return response.data
  } catch (err) {
    throw err
  }
}

const create = async (user) => {
  try {
    const response = await api.post('users', user)
    return response.data
  } catch (err) {
    throw err
  }
}

export default {
  getByCompanyId,
  create,
}