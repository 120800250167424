import React from 'react';
import './styles.css'
import { faHome, faTimes, faProjectDiagram, faHandshake, faWindowRestore, faUserPlus, faBoxOpen, faBarcode, faQrcode } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import icon from '../../../../assets/img/icon.png'

import './styles.css'
import color from '@material-ui/core/colors/amber';

const SideNav = () => {
  function handleCloseModal() {
    document.getElementById('mySidenav').style.width = '0px'
    document.getElementById('mySidenavBackground').style.width = '0px'
    document.getElementById('mySidenav').style.padding = '0px'
  }

  return (
    <>
      <div id='mySidenavBackground' onClick={handleCloseModal} />
      <div id="mySidenav" className="sidenav" onBlur={handleCloseModal}>
        {/* <FontAwesomeIcon icon={faTimes} onClick={handleCloseModal} cursor='pointer' color='#000720' aria-hidden="false" size="1x" width='5%'/> */}

        <main>
          <Link to='/store/' style={{ paddingTop: '10px' }}>
            <div>
              <div>
                <FontAwesomeIcon icon={faHome} onClick={handleCloseModal} cursor='pointer' color='black' aria-hidden="false" size="lg" width='5%' />
              </div>
              <text onClick={handleCloseModal} className="textbutton">Início</text>
            </div>
          </Link >

          <hr style={{ width: '100%', borderTop: '1px solid rgba(0,0,0,.2)' }} />

          <Link to='/store/about'>
            <div>
              <div>
                <FontAwesomeIcon icon={faWindowRestore} onClick={handleCloseModal} cursor='pointer' color='black' aria-hidden="false" size="lg" width='5%' />
              </div>
              <text onClick={handleCloseModal} className="textbutton" >Quem Somos</text>
            </div>
          </Link >

          <hr style={{ width: '100%', borderTop: '1px solid rgba(0,0,0,.2)' }} />

          <Link to='/store/withdraw'>
            <div>
              <div>
                <FontAwesomeIcon icon={faBoxOpen} onClick={handleCloseModal} cursor='pointer' color='black' aria-hidden="false" size="lg" width='5%' />
              </div>
              <text onClick={handleCloseModal} className="textbutton">Onde Retirar</text>
            </div>
          </Link >

          <hr style={{ width: '100%', borderTop: '1px solid rgba(0,0,0,.2)' }} />

          <a rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=5511995024999&text=Olá,%20gostaria%20de%20mais%20informações%20sobre%20o%20RCLUB." target="_blank">
            <div>
              <div>
                <FontAwesomeIcon icon={faWhatsapp} onClick={handleCloseModal} cursor='pointer' color='black' aria-hidden="false" size="lg" width='5%' />
              </div>
              <text onClick={handleCloseModal} className="textbutton" >Central de Atendimento</text>
            </div>
          </a >

          <hr style={{ width: '100%', borderTop: '1px solid rgba(0,0,0,.2)' }} />

          <Link to='/store/how-it-works'>
            <div>
              <div>
                <FontAwesomeIcon icon={faProjectDiagram} onClick={handleCloseModal} cursor='pointer' color='black' aria-hidden="false" size="lg" width='5%' />
              </div>
              <text onClick={handleCloseModal} className="textbutton" >Como Funciona</text>
            </div>
          </Link >

          <hr style={{ width: '100%', borderTop: '1px solid rgba(0,0,0,.2)' }} />

          <Link to='/store/resendqrcode'>
            <div>
              <div>
                <FontAwesomeIcon icon={faQrcode} onClick={handleCloseModal} cursor='pointer' color='black' aria-hidden="false" size="lg" width='5%' />
              </div>
              <text onClick={handleCloseModal} className="textbutton" >2ª Via QR-Code</text>
            </div>
          </Link >

          <hr style={{ width: '100%', borderTop: '1px solid rgba(0,0,0,.2)' }} />

          <Link to='/store/resend'>
            <div>
              <div>
                <FontAwesomeIcon icon={faBarcode} onClick={handleCloseModal} cursor='pointer' color='black' aria-hidden="false" size="lg" width='5%' />
              </div>
              <text onClick={handleCloseModal} className="textbutton" >2ª Via Boleto</text>
            </div>
          </Link >

          <hr style={{ width: '100%', borderTop: '1px solid rgba(0,0,0,.2)' }} />

          <Link to='/store/partners'>
            <div>
              <div>
                <FontAwesomeIcon icon={faHandshake} onClick={handleCloseModal} cursor='pointer' color='black' aria-hidden="false" size="lg" width='5%' />
              </div>
              <text onClick={handleCloseModal} className="textbutton" >Parceiros</text>
            </div>
          </Link >


          {!window.location.host.includes('21200') && !window.location.host.includes('99') ?
            <>
              <hr style={{ width: '100%', borderTop: '1px solid rgba(0,0,0,.2)' }} />
              <Link to='/store/register'>
                <div>
                  <div>
                    <FontAwesomeIcon icon={faUserPlus} onClick={handleCloseModal} cursor='pointer' color='black' aria-hidden="false" size="lg" width='5%' />
                  </div>
                  <text onClick={handleCloseModal} className="textbutton" >Cadastre-se</text>
                </div>
              </Link >
            </>
            :
            null
          }
        </main>
      </div>
    </>
  )
}

export default SideNav