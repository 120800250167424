import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import routes from "./routes";

import Header from "./components/Header";
import HeaderMobile from "./components/HeaderMobile";
import SideNav from "./components/SideNav";
import { isMobile } from '../../utils/validation';

function StoreLayout() {
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    isMobile()
    const mobileAcess = isMobile()
    if (!mobileAcess) {
      setMobile(false)
    } else {
      setMobile(true)
    }
  }, []);

  function getRoutes(routes) {
    return routes.map((prop, key) => (
      <Route
        exact
        path={prop.layout + prop.path}
        render={(props) => {
          window.document.title = `Rclub - ${prop.name}`
          return <prop.component {...props} />}}
        key={key}
      />
    ));
  }

  return (
    <>
      {mobile ? <HeaderMobile /> :
      < Header />}
      
      {mobile ? < SideNav /> :
      null}

      <div className="content" style={{ paddingTop: '60px' }}>
        <Switch>{getRoutes(routes)}</Switch> 
      </div>
    </>
  );
}

export default StoreLayout;