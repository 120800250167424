import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import AssignorCertificateForm from './AssignorCertificate'
import BreadCrumb from '../../components/BreadCrumb'
import assignorsRepository from '../../../../repositories/Assignors'
import AlertModal from '../../../Store/components/ModalAlertMsg'


function BundleFormAssignorCertificate({ match }) {
  const [loading, setLoading] = useState(false)
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const [assignorId, setAssignorId] = useState(null)
  const [message, setMessage] = useState(null)
  const [returnedFile, setReturnedFile] = useState(null)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!match.params.id) {
      setAssignorId(match.params.id)
    }
  }, [])

  useEffect(() => {
    if (!!assignorId) {
      loadCompany()
    } else {
      document.title = 'Rclub - Novo Certificado'
    }
  }, [assignorId])

  async function loadCompany() {
    setLoading(true)

    try {
      const assignor = await assignorsRepository.getById(assignorId)

      if (assignor.file) {
        setReturnedFile(assignor.file)
      }

      dispatch([
        change('assignorCertificate', 'password', assignor.password),
        change('assignorCertificate', 'nickname', assignor.nickname),
        change('assignorCertificate', 'certificateEmail', assignor.certificateEmail),
        change('assignorCertificate', 'expireAt', assignor.expireAt ? new Date(assignor.expireAt).toLocaleDateString() : ''),
        change('assignorCertificate', 'file', assignor.file),
        change('assignorCertificate', 'registerNumber', assignor.registerNumber),
      ])

      document.title = `Rclub - ${assignor.fantasyName !== 'null' ? assignor.fantasyName : assignor.companyName}`
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao carregar cedente')
    } finally {
      setLoading(false)
    }
  }

  function handleCancel() {
    history.push('configAssignors')
  }

  function handleSubmit(values) {
    if (!values.file) {
      setMessage("Escolha um certificado para emissão.")
      setIsAlertModalOpen(true)
      return
    }

    if (!values.expireAt) {
      create(values)
    } else {
      update(values)
    }
  }

  async function create(values) {
    const { file, certificateEmail, password, nickname, registerNumber } = values

    const formData = new FormData()

    formData.append('id', assignorId)
    formData.append('file', file);
    formData.append('certificateEmail', certificateEmail);
    formData.append('password', password);
    formData.append('registerNumber', registerNumber);

    if (nickname) {
      formData.append('nickname', nickname);
    }

    setLoading(true)

    try {
      await assignorsRepository.createCertificate(formData);

      toastr.success('Certificado cadastrado com sucesso.')
      history.push('configAssignors')

    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao cadastrar certificado. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  async function update(values) {
    const { file, certificateEmail, password, nickname, registerNumber } = values

    const formData = new FormData()

    formData.append('file', file);
    formData.append('certificateEmail', certificateEmail);
    formData.append('password', password);
    formData.append('registerNumber', registerNumber);

    if (nickname) {
      formData.append('nickname', nickname);
    }

    if (returnedFile !== file) {
      formData.append('isNewCertificate', true)
    } else {
      formData.append('isNewCertificate', false)
    }

    setLoading(true)

    try {
      await assignorsRepository.updateCertificate(assignorId, formData)

      toastr.success('Cedente atualizado com sucesso.')
      history.push('configAssignors')

    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao atualizar certificado. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const initialValues = {
    certificateEmail: '',
    password: '',
    expireAt: ''
  }

  return (
    <>
      <BreadCrumb path={['home', 'configAssignors', null]} data={['Início', 'Cedentes', 'Certificado']} />

      <AssignorCertificateForm
        initialValues={initialValues}
        assignorId={assignorId}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        loading={loading}
      />
      {isAlertModalOpen && (
        <AlertModal text={message} onClose={() => setIsAlertModalOpen(false)} />
      )
      }
    </>
  )
}

export default BundleFormAssignorCertificate