import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import ReactTable from 'react-table-6';
import { useHistory, Link } from 'react-router-dom';
import productTypesRepository from '../../../../repositories/ProductTypes'

const ProductTypes = () => {
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [productTypes, setProductTypes] = useState([])

  const history = useHistory()

  useEffect(() => {
    loadProductTypes()
    document.title = 'Rclub - Tipos de Produto'
  }, [])

  const loadProductTypes = async () => {
    setLoading(true)

    try {
      const productTypes = await productTypesRepository.getAll()
      setProductTypes(productTypes)

    } catch(err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar os tipos de produto. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div id='admin-page-productTypes'>
      <header>
        <BreadCrumb path={['home', null]} data={['Início', 'Tipos de Produto']} />
        <section>
          <button className='btn btn-success' onClick={() => history.push('product-type')}>+ Novo Tipo de Produto</button>
          <div>
            <FontAwesomeIcon icon={faSearch} />
            <input
              className='form-control foco-input'
              title='Pesquisar por Descrição'
              placeholder='Pesquisar por Descrição'
              type='text'
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        </section>
      </header>

      <br />
      <ReactTable
        style={{ textAlign: 'center', maxWidth: '750px' }}
        data={productTypes.filter(x => x['description'].toString().toLowerCase().includes(query.toLowerCase()))}
        columns={[
          {
            Header: 'Código',
            accessor: 'id',
            width: 150
          },
          {
            Header: 'Tipo de Produto',
            accessor: 'description'
          },
          {
            Header: 'Ações',
            accessor: 'id',
            width: 150,
            Cell: props => (
              <Link to={{ pathname: `product-type#${props.value}` }}>
                <FontAwesomeIcon icon={faEdit} color='black' />
              </Link>
            )
          }
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
    </div>
  )
}

export default ProductTypes