import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { faTrash, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from '../../../../components/Card'
import { removeItemCart, handleQuantity } from '../../../../../../store/actions/cart'
import { currency } from "../../../../../../utils/normalize";

import '../../styles.css'
import { useState } from "react";
import { Modal } from "react-bootstrap";
import color from "@material-ui/core/colors/amber";

const ItemsData = () => {
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false)
  const [value, setValue] = useState(0)
  const { cartReducer } = useSelector(state => state)
  const { selectedProducts } = cartReducer
  const dispatch = useDispatch()

  useEffect(() => {
    window.document.title = 'Rclub - Carrinho'
  }, [])

  const handleOpenInfoModal = () => {
    setIsOpenInfoModal(true)
  }

  function changeQuantity(value, id) {
    setValue(value)
    dispatch(handleQuantity(value, selectedProducts[id], selectedProducts))
  }
  
  const doNothing = () => {
    
  }

  const Item = ({ product, id }) => (
    <>
      <>
        <div className='item-description'>
            <span>{product.description}</span>
            { product.complementaryDescription != null || product.complementaryDescription !== '' ?
              <h5 style={{margin: '0', fontSize: 'small', color: 'gray'}}>{product.complementaryDescription}</h5> : null
            }
        </div>
        <div id='item'>
          <section>
            <img src={product.imageURL} alt={product.description} />
          </section>
          {/*
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() => dispatch(removeItemCart(selectedProducts[id], selectedProducts))}
              color='red'
              cursor='pointer'
            />
          */}
          <div>
            {/*<span>{product.description}</span>*/}
            {/* <button className='btn btn-link' onClick={handleOpenInfoModal}>Ver mais informações</button> */}
            {/* product.vehicleTypeId === 1 ? 
              <h5>Óleo para Motos</h5>
              :
              <h5>Óleo para Carros</h5>
              /*product.complementaryDescription != null || product.complementaryDescription !== '' ?
              <h5>{product.complementaryDescription}</h5> : null*/
            }
            <h4>{currency(product.price)}</h4>
            <select
              className='form-control foco-input'
              name="select-items"
              value={selectedProducts[id].quantity}
              onChange={value => changeQuantity(value?.target.value, id)}>
              {Array(1 + product.maximumQuantity - product.minimumQuantity).fill().map((_, i) => i).map(value => {
                return <option value={value + product.minimumQuantity}>{value + product.minimumQuantity} </option>
              })}
            </select>
          </div>
        </div>
        {<hr style={{color: 'black'}}></hr>}
      </>

      <Modal className='info-product-modal' size='lg' show={isOpenInfoModal} onHide={() => setIsOpenInfoModal(false)} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>RClub</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            name={product.description}
            id={product.id}
            cols="30"
            rows="10"
            readOnly
          >
            {product.observations}
          </textarea>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <button className="btn btn-danger" onClick={() => setIsOpenInfoModal(false)}>Voltar</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )

  if (!selectedProducts.length) return (
    <div id='no-products-wrapper'>
      <h5>O seu carrinho está vazio</h5>
      <Link to='products'>Ver todos os produtos</Link>
    </div>
  )

  return (
    <>
      <Card title='Carrinho' id='items-wrapper'>
        {selectedProducts.map((item, i) => <Item product={item} id={i} key={i} />)}
      
        <footer>
          <Link to='products'>Ver todos os produtos</Link>
        </footer>
      </Card>
    </>

    
  )
}

export default ItemsData
