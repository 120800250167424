import React, { useState } from "react";
import { Spinner, Modal } from 'react-bootstrap'
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { toastr } from 'react-redux-toastr'

import { handleChange, createSale, createSaleBankSlip, checkCompanyStock, validateCoupon, createDebitSale, createPixSale } from '../../../../store/actions/cart'
import AlertModal from '../../../../components/AlertModal'
import SecurePaymentMessage from '../../components/SecurePaymentMessage'

import ItemsData from './steps/ItemsData'
import BankSlipPayment from './steps/BankSlipPayment'
import CreditCardPayment from './steps/CreditCardPayment'
import DebitCardPayment from './steps/DebitCardPayment'
import PixPayment from './steps/PixPayment'
import ClientData from './steps/ClientData'
import PaymentMethods from './steps/PaymentMethods'

import './styles.css'
import PurchaseFooter from "./PurchaseFooter";
import Resume from "./steps/Resume";
import ResumeBankSlip from "./steps/ResumeBankSlip";

import initGa from '../../../../utils/googleAnalytics';
import ModalAddressListCompanies from "../../components/ModalAddressListCompanies";

import companiesRepository from '../../../../repositories/Companies'


const Items = () => {
  initGa(window.location.href);

  const dispatch = useDispatch();
  const { cartReducer } = useSelector(state => state)
  const { step, confirmModal, searchCoupon, selectedProducts, loading, validateCouponLoading,
    isCouponModalOpen, client, companyIdAtSelectedCity, paymentMethod, bankSlipAddress, companiesAtSelectedCity } = cartReducer
  const { cpf, name, email, phone, zipCode, city } = client
  const { postalCode, bankSlipAddressInfo, bankSlipNeighborhood, bankSlipAddressNumber, bankSlipState, bankSlipCity } = bankSlipAddress
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [companiesList, setCompaniesList] = useState([])

  const nextPage = () => {
    dispatch(handleChange(step + 1, 'step'))
  }

  const previousPage = () => {
    dispatch(handleChange(step - 1, 'step'))
  }

  const handleNextPage = () => {
    if ((!cpf || !name || !email || !phone || !client || !companyIdAtSelectedCity) && step === 2) {
      return toastr.warning('Insira todos os campos obrigatórios')
    }

    if ((!bankSlipAddressNumber || !postalCode) && step === 4 && paymentMethod === 'bankSlip') {
      return toastr.warning('Insira todos os campos obrigatórios')
    }

    switch (step) {
      case 1: {
        if (!!selectedProducts.length) {
          nextPage()
        }
        break
      }

      case 2: {
        dispatch(checkCompanyStock(companyIdAtSelectedCity, selectedProducts))
        break;
      }

      case 3: {
        if (paymentMethod !== 'pix') {
          nextPage()
        } else {
          //nextPage()
          dispatch(handleChange(true, 'confirmModal'))
        }
        break;
      }

      case 4: {
        dispatch(handleChange(true, 'confirmModal'))
        break;
      }

      default: {
        nextPage()
        break
      }
    }
  }

  const openModal = async () => {
    dispatch(handleChange(true, 'checkCompanyStockLoading'));
    let obj = {
      "city": client.city,
      "vehicleTypeId": selectedProducts[0].vehicleTypeId
    };
    const companies = await companiesRepository.findCompaniesByCityAndVehicleType(obj);
    setCompaniesList(companies);
    dispatch(handleChange(companies, 'companiesAtSelectedCity'));
    setIsModalOpen(true);
    dispatch(handleChange(false, 'checkCompanyStockLoading'));
  }


  const handleCouponModal = () => {
    dispatch([
      handleChange(!isCouponModalOpen, 'isCouponModalOpen'),
      handleChange('', 'searchCoupon')
    ])
  }

  const handleSearchCoupon = () => {
    dispatch(validateCoupon(searchCoupon))
  }

  return (
    <div id='handle-buy-page'>
      {
        loading &&
        <div className="div-loading">
          <Spinner animation="border" role="status" variant="success" />
          <FontAwesomeIcon icon={faDollarSign} color='green' className="animated-money bounce-money" />
        </div>
      }

      <main>
        {step === 1 && <ItemsData />}
        {step === 2 && <ClientData />}
        {step === 3 && <PaymentMethods />}
        {step === 4 && paymentMethod === 'creditCard' ?
          <CreditCardPayment />
          :
          null
        }

        {step === 4 && paymentMethod === 'bankSlip' ?
          <BankSlipPayment />
          :
          null
        }

        {step === 4 && paymentMethod === 'debitCard' ?
          <DebitCardPayment />
          :
          null
        }

        {step === 5 && paymentMethod === 'pix' ?
          <PixPayment />
          :
          null
        }

        {step === 5 && paymentMethod === 'bankSlip' ?
          <ResumeBankSlip />
          :
          null
        }

        {step === 5 && (paymentMethod === 'creditCard' || paymentMethod === 'debitCard') ?
          <Resume />
          :
          null
        }

        {step === 4 && paymentMethod === 'creditCard' ?
          <SecurePaymentMessage typePayment="Cartão de Crédito" />
          : null
        }

        {step === 4 && paymentMethod === 'debitCard' ?
          <SecurePaymentMessage typePayment="Cartão de Débito" />
          : null
        }
      </main>

      <PurchaseFooter previousPage={previousPage} handleCouponModal={handleCouponModal} handleNextPage={handleNextPage} openModal={openModal} />

      <AlertModal
        show={confirmModal}
        message='Deseja confirmar o pagamento ?'
        onHide={() => dispatch(handleChange(false, 'confirmModal'))}
        onCancel={() => dispatch(handleChange(false, 'confirmModal'))}
        onSubmit={() => {
          switch (paymentMethod) {
            case 'creditCard': {
              dispatch(createSale(cartReducer))
              break
            }

            case 'debitCard': {
              dispatch(createDebitSale(cartReducer))
              break
            }

            case 'pix': {
              dispatch(createPixSale(cartReducer))
              break
            }

            case 'bankSlip': {
              dispatch(createSaleBankSlip(cartReducer))
              break
            }

            default:
              break;
          }
          // if (paymentMethod === 'creditCard') {
          //   dispatch(createSale(cartReducer))
          // } else if (paymentMethod === 'bankSlip') {
          //   dispatch(createSaleBankSlip(cartReducer))
          // } else {
          //   dispatch(createDebitSale(cartReducer))
          // }
        }
        }
      />

      <Modal show={isCouponModalOpen} onHide={handleCouponModal} animation={true}>
        <Modal.Header closeButton>
          <strong>Informe o cupom</strong>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className='form-control foco-input'
            placeholder='Cupom de desconto'
            value={searchCoupon}
            onChange={e => dispatch(handleChange(e.target.value, 'searchCoupon'))}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-success' onClick={handleSearchCoupon} disabled={!searchCoupon || validateCouponLoading} style={{ width: '100%' }}>
            <span className={validateCouponLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />{' '}
            Aplicar Cupom
          </button>
        </Modal.Footer>
      </Modal>

      {isModalOpen &&
        <ModalAddressListCompanies list={companiesList} onClose={() => { setIsModalOpen(false) }} city={client.city} />
      }

    </div >
  )
}

export default withRouter(Items)
