import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ProductForm from './Product'
import BreadCrumb from '../../components/BreadCrumb'
import productsRepository from '../../../../repositories/Products'
import s3Repository from '../../../../repositories/s3'

function BundleFormProduct({ match }) {
  const [loading, setLoading] = useState(false)
  const [productId, setProductId] = useState(null)
  const [midiaFiles, setMidiaFiles] = useState([])

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if(!!match.params.id) {
      setProductId(match.params.id)
    }
    
  }, [])
  
  useEffect(() =>{
    if(!productId) {
      loadLastCode()
      document.title = 'Rclub - Novo Produto'
    } else {
      loadProduct()
    }
  }, [productId])

  async function loadLastCode() {
    try {
      const code = await productsRepository.getLastCode()

      dispatch(change('product', 'code', code))
    } catch(err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao buscar o código do produto. Por favor, tente novamente')
    }
  }

  async function loadProduct() {
    setLoading(true)

    try {
      const product = await productsRepository.getById(productId)

      dispatch([
        change('product', 'code', product.code),
        change('product', 'description', product.description),
        change('product', 'productTypeId', product.productTypeId),
        change('product', 'hasPrize', product.hasPrize),
        change('product', 'isActive', product.isActive),
        change('product', 'maximumQuantity', product.maximumQuantity),
        change('product', 'minimumQuantity', product.minimumQuantity),
        change('product', 'observations', product.observations),
        change('product', 'price', product.price),
        change('product', 'vehicleTypeId', product.vehicleTypeId),
        change('product', 'complementaryDescription', product.complementaryDescription),
        change('product', 'imageName', product.imageName),
        change('product', 'imageURL', product.imageURL),
      ])

      document.title = `Rclub - ${product.description}`
    } catch(err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao carregar o produto')
    } finally {
      setLoading(false)
    }
  }

  function handleCancel() {
    history.push('products')
  }

  async function handleSubmit(values) {
    const { imageNotUploaded, imageToDelete, image } = values
    let imagePathAndName = {}

    setLoading(true)

    if(imageToDelete) {
      await s3Repository.deleteSingleImage(imageToDelete)

      imagePathAndName = {
        imageURL: '',
        imageName: ''
      }
    }

    if(imageNotUploaded) {
      try {
        imagePathAndName = await uploadAndGetImageURLAndName(image)
      } catch(err) {
        console.log(err)
        setLoading(false)
        return toastr.warning('Ocorreu um erro ao salvar a imagem. Por favor, tente novamente')
      }
    }

    if(!productId) {
      create(values, imagePathAndName, values.filesToUpload)
    } else {
      update(values, imagePathAndName, values.filesToUpload)
    }
  }

  async function uploadAndGetImageURLAndName(image) {
    const formData = new FormData()
    formData.append('image', image.file, image.name)

    try {
      const imageURL = await s3Repository.singleUpload(formData)

      return {
        imageURL: imageURL,
        imageName: image.name
      }

    } catch(err) {
      throw err
    }
  }

  async function create(values, imagePathAndName, midiaToUpload) {
    const { productTypeId, description, hasPrize, isActive, code, maximumQuantity, minimumQuantity, 
      observations, price, vehicleTypeId, complementaryDescription } = values

    try {
      await productsRepository.create({ 
        code,
        productTypeId,
        description,
        hasPrize, 
        isActive,
        maximumQuantity, 
        minimumQuantity, 
        observations, 
        price, 
        vehicleTypeId,
        complementaryDescription,
        ...imagePathAndName
      })
      .then((response) => {
        toastr.success('Produto cadastrado com sucesso.')
        const upload = processMidia(response.id, midiaToUpload)
      })
    
      history.push('products')
    } catch(err) {
      toastr.warning('Ocorreu um erro ao salvar o produto. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  async function update(values, imagePathAndName, midiaToUpload) {
    const { productTypeId, description, hasPrize, isActive, maximumQuantity, minimumQuantity, 
      observations, price, vehicleTypeId, complementaryDescription } = values

    try {
      await productsRepository.update(productId, { 
        productTypeId, 
        description, 
        hasPrize, 
        isActive, 
        maximumQuantity, 
        minimumQuantity, 
        observations, 
        price, 
        vehicleTypeId,
        complementaryDescription,
        ...imagePathAndName
      })
      .then(() => {
        toastr.success('Produto cadastrado com sucesso.')
        const upload = processMidia(productId, midiaToUpload)
      })

      history.push('products')
    } catch(err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao atualizar o produto. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const initialValues = {
    isActive: true,
    hasPrize: true,
    observations: '',
    filesToUpload: []
  }

  function processMidia(code, files){
    var i = 0;
    while(i < files.length ){
      uploadMidia(files[i], code)
      i++;
    }
    setMidiaFiles([])
  }

  async function uploadMidia (midiaUpload, code){

    var ext = midiaUpload.name.split('.').pop();
    const formData = new FormData();
    if(ext === 'mp4'){
      formData.append('image', midiaUpload, midiaUpload.name)
    } else {
      formData.append('image', midiaUpload, midiaUpload.name)
    }

    try {
      await s3Repository.singleUploadBanner(formData)
      .then((resp) => {
        if(ext === 'mp4'){
          const response = saveProductsVideos(code, resp, midiaUpload.name)
        } else {
          const response = saveProductsImage(code, resp, midiaUpload.name)
        }
      })
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao gravar a mídia. Por favor, tente novamente')
    }
  }

  async function saveProductsImage(productId, urlImg, nameImg) {
    try {
      await s3Repository.saveProductImages({
          "productId": productId,
          "imageName": nameImg,
          "imageUrl": urlImg
      })
      .then((response) => {
          return toastr.success('A mídia foi salva com sucesso.')
      })
    } catch (err) {
      console.log(err.response)
      toastr.error('Ocorreu um erro ao gravar a imagem. Por favor, tente novamente')
    }
  }

  async function saveProductsVideos(productId, urlVideo, nameImg) {
    try {
      s3Repository.saveProductsVideos({
        "productId": productId,
        "videoName": nameImg,
        "videoUrl": urlVideo
      })
      .then((resp) => {
          return toastr.success('A mídia foi salva com sucesso.')
      })
    } catch (err) {
      console.log(err.response)
      toastr.error('Ocorreu um erro ao gravar o vídeo. Por favor, tente novamente')
    }
  }

  return (
    <>
      <BreadCrumb path={['home', 'products', null]} data={['Início', 'Produtos', !productId ? 'Novo Produto' : 'Editar Produto' ]} />
     
      <ProductForm
        productId={productId} 
        onSubmit={handleSubmit} 
        onCancel={handleCancel}
        loading={loading}
        initialValues={initialValues}
      />
    </>
  )
}

export default BundleFormProduct