const validate = values => {
  const errors = {}
  if (!values.registerNumber) {
    errors.registerNumber = 'Obrigatório'
  }
  if (!values.bankCode) {
    errors.bankCode = 'Obrigatório'
  }
  if (!values.bankBranch) {
    errors.bankBranch = 'Obrigatório'
  }
  if (!values.bankBranchVd) {
    errors.bankBranchVd = 'Obrigatório'
  }
  if (!values.accountNumber) {
    errors.accountNumber = 'Obrigatório'
  }
  if (!values.accountNumberVd) {
    errors.accountNumberVd = 'Obrigatório'
  }
  if (!values.accountType) {
    errors.accountType = 'Obrigatório'
  }
  if (!values.accountBeneficiaryCode) {
    errors.accountBeneficiaryCode = 'Obrigatório'
  }
  if (values.bankCode === "237") {
    if(!values.accountCompanyCode) {
    errors.accountCompanyCode = 'Obrigatório'
  }
  }

  return errors
}

export default validate