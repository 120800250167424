import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table-6';
import { useHistory, Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit } from '@fortawesome/free-solid-svg-icons'

import BreadCrumb from '../../components/BreadCrumb'
import couponsRepository from '../../../../repositories/Coupons'
import { percentage } from '../../../../utils/normalize';

import './styles.css'

const Coupons = () => {
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [coupons, setCoupons] = useState([])

  const history = useHistory()

  useEffect(() => {
    loadCoupons()
    document.title = 'Rclub - Cupons'
  }, [])

  const loadCoupons = async () => {
    setLoading(true)

    try {
      const coupons = await couponsRepository.getAll()
      setCoupons(coupons)

    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar os cupons. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div id='admin-page-coupons'>
      <header>
        <BreadCrumb path={['home', null]} data={['Início', 'Cupons']} />
        <section>
          <button className='btn btn-success' onClick={() => history.push('coupon')}>+ Novo Cupom</button>
          <div>
            <FontAwesomeIcon icon={faSearch} />
            <input
              className='form-control foco-input'
              title='Pesquisar por Descrição'
              placeholder='Pesquisar por Descrição'
              type='text'
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        </section>
      </header>

      <br />
      <ReactTable
        style={{ textAlign: 'center' }}
        data={coupons.filter(x => x['description'].toString().toLowerCase().includes(query.toLowerCase()))}
        columns={[
          {
            Header: 'Código',
            accessor: 'code',
            width: 150
          },
          {
            Header: 'Código do Cupom',
            accessor: 'description'
          },
          {
            Header: 'Desconto',
            accessor: 'discount',
            Cell: props => percentage(props.value / 100)
          },
          {
            Header: 'Utilizações',
            accessor: 'usage',
            Cell: props => !props.value ? '---' : props.value
          },
          {
            Header: 'Status',
            accessor: 'isActive',
            Cell: props => (
              Boolean(props.value)
                ? <button className='btn btn-success'>Ativo</button>
                : <button className='btn btn-danger'>Inativo</button>
            )
          },
          {
            Header: 'Ações',
            accessor: 'id',
            width: 150,
            Cell: props => (
              <Link to={{ pathname: `coupon#${props.value}` }}>
                <FontAwesomeIcon icon={faEdit} color='black' />
              </Link>
            )
          }
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
    </div>
  )
}

export default Coupons