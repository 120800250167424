import React, { useState, useEffect } from 'react';

import './styles.css'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDropzone } from 'react-dropzone'
import crypto from "crypto";
import s3Repository from '../../../../repositories/s3'
import Toggle from 'react-toggle'
import { toastr } from 'react-redux-toastr';
import RedirectModal from '../RedirectLinkModal';


function PopUpInputMobile({ upload }) {

  const [popUpMobile, setPopUpMobile] = useState([]);
  const [activedMobile, setActivedMobile] = useState();
  const [idMobile, setIdMobile] = useState();
  const [popUpMobileReceivedImg, setPopUpMobileReceivedImg] = useState('');
  const [fileUploaded, setFileUploaded] = useState({})
  const [popUp, setPopUp] = useState({})

  const [isRedirectLinkModaleOpen, setIsRedirectLinkModaleOpen] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({ accept: 'image/*' })

  //-------------------- MOBILE -------------------- //

  useEffect(() => {
    getPopUpMobile()

  }, [])


  useEffect(() => {
    if (popUpMobile.length != 0) {
      const urlImage = URL.createObjectURL(popUpMobile)
      setPopUpMobileReceivedImg(urlImage)

    }
  }, [popUpMobile])

  useEffect(() => {
    if (upload) {
      if (popUpMobile?.length != 0 && popUpMobile != undefined) {
        createFileObjectMobile(popUpMobile)
      }
    }
  }, [upload])

  useEffect(() => {
    if (upload) {
      saveCheckBoxOptionMbile()
    }
  }, [upload])

  const createFileObjectMobile = file => {
    const imageUpload = ({
      file: file,
      name: `${crypto.randomBytes(16).toString('hex')}-${file.name + 'mobile'}`,
      preview: URL.createObjectURL(file)
    })
    processUploadMobile(imageUpload)
  }

  async function processUploadMobile(imageUpload) {

    const formData = new FormData();
    formData.append('image', imageUpload.file, imageUpload.name)
    // for (var p of formData) {
    //   console.log(p);
    // }

    try {
      s3Repository.singleUploadPopup(formData)

        .then(resp => createdPopUpMobile(resp, imageUpload.name))
    } catch (err) {
      toastr.error('Erro ao realizar o upload do pop up(Mobile). Por favor, tente novamente')

      throw err
    }
  }

  async function createdPopUpMobile(urlImg, nameImg) {

    try {
      await s3Repository.createdPopUp({
        "imageName": nameImg,
        "imageURL": urlImg,
        "enable": true,
        "mobile": true
      }
      )
        .then(getPopUpMobile())

      window.location.reload()

    } catch (err) {
      toastr.error('Erro ao cadastrar pop up(Mobile). Por favor, tente novamente')

      throw err
    }
  }

  async function getPopUpMobile() {
    try {
      await s3Repository.getPopupMobile()
        .then(resp =>
          setPopUp(resp,
            setPopUpMobileReceivedImg(resp.urlImgPopup,
              setIdMobile(resp.id),
              setActivedMobile(resp.isActive)))
        )
    } catch (err) {

      throw err
    }
  }

  function validationCheckedMobile() {
    if (activedMobile) {
      setActivedMobile(false)
    } else {
      setActivedMobile(true)
    }
  }

  async function saveCheckBoxOptionMbile() {
    try {
      await s3Repository.enablePopup({
        "imageId": idMobile,
        "enable": activedMobile,
        "mobile": true
      })

    } catch (err) {

      throw err
    }
  }

  function handleOpenRedirectLinkModal(uploadedFile) {
    setFileUploaded(uploadedFile)
    setIsRedirectLinkModaleOpen(true)
  }

  return (
    <>
      <div className="inputPopUpMobile">
        {popUpMobileReceivedImg ?
          <>
            <div className='submitImputButtonPopUpMobile'>
              <img
                src={popUpMobileReceivedImg}
                height={135}
                width={164}
              />
              <div {...getRootProps()} className='submitImputButtonUpdate' >

                <h1 style={{ fontSize: '13px', width: '100%' }}>Alterar imagem</h1>

                <input {...getInputProps()} id="InputMobile" onChange={value => setPopUpMobile(value?.target.files[0])} multiple={false} />
              </div>
              <div className='submitImputButtonUpdate' >
                <h1 onClick={e => handleOpenRedirectLinkModal(popUp)} style={{ fontSize: '13px', width: '100%', marginTop: '1%' }}>Alterar informações de link</h1>
              </div>
            </div>
          </>
          :
          <>
            <div {...getRootProps()} className='submitImputButtonPopUpMobile' >
              <FontAwesomeIcon icon={faPlusCircle} size="2x" color='#7A7A7A' />
              <br />
              <h1>Adicionar imagem</h1>

              <input {...getInputProps()} id="InputMobile" onChange={value => setPopUpMobile(value?.target.files[0])} multiple={false} />
            </div>
          </>
        }
        <div style={{ marginLeft: '3%' }}>
          <label style={{ fontSize: '16px' }}>Imagem pop-up do site(mobile)</label>
          <br />
          <Toggle checked={activedMobile} onClick={() => validationCheckedMobile()} />
        </div>


      </div>

      {isRedirectLinkModaleOpen ?
        <RedirectModal
          onHide={() => setIsRedirectLinkModaleOpen(false)}
          file={fileUploaded}
          show={isRedirectLinkModaleOpen}
          type='popup'
        />
        :
        null
      }

    </>
  );
}

export default PopUpInputMobile;