import React, { useState, useEffect } from 'react';
import "./styles.css";
import './styles99.css';

import initGa from '../../../../utils/googleAnalytics';

import img from '../../../../assets/img/how-it-works/Site_Club_Mobile_2021_Separados-01.png'
import SubHeader from '../../components/SubHeader'
import ButtonWhatsappRow from '../../components/ButtonWhatsappRow'
import ButtonWhatsappColumn from '../../components/ButtonWhatsappColumn'
import HowItWorksMobile from './HowItWorksMobile'
import HowItWorksDesktop from './HowItWorksDesktop';
import { isMobile } from '../../../../utils/validation'


const HowItWorks = () => {
  initGa(window.location.href);

  const [mobile, setMobile] = useState(false)
  const [isiPhone, setIsiPhone] = useState(false)
  const [classes, setClasses] = useState(null)

  useEffect(() => {
    isMobile()
    loadClasses()
    const mobileAcess = isMobile()
    if (!mobileAcess) {
      setMobile(false)
    } else {
      setMobile(true)
    }
  }, []);

  const loadClasses = () => {
    if (!window.location.host.includes('21200') && !window.location.host.includes('99')) {
      setClasses("how-it-works-page")
    } else {
      setClasses("how-it-works-page-99")
    }
  }

  useEffect(() => {
    handleiPhone()
  }, [mobile])

  const handleiPhone = () => {
    if (navigator.userAgent.match(/iPhone/i)) {
      setIsiPhone(true)
    }
  }

  return (
    <>
      {/* <div id="how-it-works-header">
      <h2 className='page-titles'>COMO FUNCIONA</h2>
    </div> */}

      <div id="how-it-works-page">
        <SubHeader title="COMO FUNCIONA" subTitle="O melhor, mais perto de você!" position='left' img={img} />
        {!mobile ?
          <main>
            <HowItWorksDesktop />
          </main>
          :
          isiPhone ?
            <main style={{ marginBottom: '170px' }}>
              <HowItWorksMobile />
            </main>
            :
            <main>
              <HowItWorksMobile />
            </main>
        }
        <br />
      </div>
    </>
  )
}

export default HowItWorks;