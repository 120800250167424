import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import CouponForm from './Coupon'
import BreadCrumb from '../../components/BreadCrumb'
import couponsRepository from '../../../../repositories/Coupons'

function BundleFormCoupon({ match }) {
  const [loading, setLoading] = useState(false)
  const [couponId, setCouponId] = useState(null)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!match.params.id) {
      setCouponId(match.params.id)
    }
  }, [])

  useEffect(() => {
    if (!couponId) {
      loadLastCode()
      document.title = 'Rclub - Novo Cupom'
    } else {
      loadCoupon()
    }
  }, [couponId])

  async function loadLastCode() {
    try {
      const code = await couponsRepository.getLastCode()

      dispatch(change('coupon', 'code', code))
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao buscar o código do cupom. Por favor, tente novamente')
    }
  }

  async function loadCoupon() {
    setLoading(true)

    try {
      const coupon = await couponsRepository.getById(couponId)
      const { code, description, discount, isActive, observations } = coupon

      dispatch([
        change('coupon', 'code', code),
        change('coupon', 'description', description),
        change('coupon', 'discount', discount),
        change('coupon', 'isActive', isActive),
        change('coupon', 'observations', observations),
      ])

      document.title = `Rclub - ${description}`

    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao carregar o cupom. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  function handleCancel() {
    history.push('coupons')
  }

  function handleSubmit(values) {
    if (!couponId) {
      create(values)
    } else {
      update(values)
    }
  }

  async function create(values) {
    const { code, description, discount, isActive, observations } = values

    setLoading(true)

    try {
      await couponsRepository.create({
        code,
        description,
        discount,
        isActive,
        observations
      })

      toastr.success('Cupom cadastrado com sucesso.')
      history.push('coupons')

    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao salvar o cupom. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  async function update(values) {
    const { description, discount, isActive, observations } = values

    setLoading(true)

    try {
      await couponsRepository.update(couponId, {
        description,
        discount,
        isActive,
        observations
      })

      toastr.success('Cupom atualizada com sucesso.')
      history.push('coupons')

    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao atualizar o cupom. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <BreadCrumb path={['home', 'coupons', null]} data={['Início', 'Cupons', !couponId ? 'Novo Cupom' : 'Editar Cupom']} />

      <CouponForm
        couponId={couponId}
        onSubmit={handleSubmit}
        initialValues={{
          isActive: true,
          observations: '',
          discount: 0
        }}
        onCancel={handleCancel}
        loading={loading}
      />
    </>
  )
}

export default BundleFormCoupon