import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from './routes'
import { isAppLogged } from '../../utils/validation'
import Menu from '../Admin/components/Menu'
import MainCard from '../Admin/components/MainCard'

function GaragePanelLayout(props) {
  const [expanded, setExpanded] = useState(false)

  function getRoutes(routes) {
    return routes.map((prop, key) => (
      <Route
        path={prop.layout + prop.path}
        render={props => <prop.component {...props} />}
        key={key}
      />
    ));
  };

    // useEffect(() => {
    //     if (!isAppLogged()) window.location.href='/partners/login'
    // }, [])

  return (
    <>

      <>
        {isAppLogged &&
          <Switch>{getRoutes(routes)}</Switch>
        }
      </>

    </>
  )
}

export default GaragePanelLayout;