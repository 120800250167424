import React from 'react';
import "./styles.css";
import ButtonWhatsappRow from '../../components/ButtonWhatsappRow';
import ResendForm from '../../components/ResendBankSlipForm'


function ResendDesktop() {
  return (
    <>
      <div id='resend-page'>
        <div className="resend-page">
          <h1>Segunda via de Boleto</h1>
          <h2>Preencha abaixo seu cpf</h2>
        </div>
        <div className='resend-desktop-form'>
          <ResendForm />
        </div>
        {!window.location.host.includes('21200') && !window.location.host.includes('99') ?
          <div style={{ paddingLeft: '12%', paddingBottom: '2%' }}>
            <ButtonWhatsappRow />
          </div>
          :
          null
        }
      </div>
    </>
  )
}

export default ResendDesktop;