import React from 'react';

import './styles.css';
import { faTelegram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ButtonWhatsappRow() {

  function handleNavigateToTelegram() {
    window.open('https://t.me/rcluboficial')
  }

  function handleNavigateToTelegram() {
    window.open('https://t.me/rcluboficial')
  }

  return (
    <div className="container-whatsapp">

      <div className="title-whatsapp">
        <h1>Grupo de Motoboys</h1>
        <div className="subTile-whatsapp">
          <span style={{ fontWeight: 'normal' }}>Quer ser lembrado sempre de novidades, receber dicas de reparação e ter ainda mais desconto? </span>
          <span style={{ fontWeight: 'bold' }}>Participe do nosso grupo no Telegram. Clique no botão ao lado!</span>
        </div>
      </div>

      <div className="body-whatsapp">

        <div className="icon-whatsapp">
        </div>
        <div className="button-whatsapp">
          <FontAwesomeIcon icon={faTelegram} size='3x' saria-hidden="false" color='#0088cc' onClick={() => { }} />
          <button onClick={() => handleNavigateToTelegram()}>ENTRAR NO GRUPO</button>
        </div>
      </div>
    </div>
  );
}

export default ButtonWhatsappRow;