import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import PropTypes from 'prop-types'
import { Field, reduxForm, change, } from 'redux-form'
import Toggle from 'react-toggle'

import CardForm from '../../components/CardForm'
import FormSubmitButtons from '../../components/FormSubmitButtons'
import renderField from '../../../../components/RenderField'
import validate from './validate'
import assignorsRepository from '../../../../repositories/Assignors'
import assignorAccountRepository from '../../../../repositories/BankAccount'
import accountAgreementRepository from '../../../../repositories/BankAgreements'

import './styles.css'
import "react-toggle/style.css"

function ConfigForm({ handleSubmit, infoId, loading, onCancel }) {
  const { values } = useSelector(state => state).form.config
  const dispatch = useDispatch()
  const [assignors, setAssignors] = useState([])
  const [assignorId, setAssignorId] = useState(null)
  const [accounts, setAccounts] = useState([])
  const [accountId, setAccountId] = useState(null)
  const [agreements, setAgreements] = useState([])
  const [agreementId, setAgreementId] = useState(null)
  const { useBbIntegration } = values

  useEffect(() => {
    loadAssignors()
  }, [])

  useEffect(() => {
    loadAccounts()
  }, [assignorId])

  useEffect(() => {
    loadAgreements()
  }, [accountId])

  const loadAssignors = async () => {
    try {
      const returnerdAssignors = await assignorsRepository.getAllActive()
      setAssignors(returnerdAssignors)
    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar os cedentes. Por favor, tente novamente')
    }
  }

  const loadAccounts = async () => {
    try {
      const returnerdAccounts = await assignorAccountRepository.getAllActive(assignorId)
      setAccounts(returnerdAccounts)
    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar as contas do cedente. Por favor, tente novamente')
    }
  }

  const loadAgreements = async () => {
    try {
      const returnerdAgreements = await accountAgreementRepository.getAllActive(accountId)
      setAgreements(returnerdAgreements)
    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar os convênios da conta. Por favor, tente novamente')
    }
  }

  const handleAssignor = selectedAssignor => {
    setAssignorId(selectedAssignor)
    dispatch([
      change('config', 'assignorId', Number(selectedAssignor))
    ])
  }

  const handleAccount = selectedAccount => {
    setAccountId(selectedAccount)
    dispatch([
      change('config', 'accountId', Number(selectedAccount))
    ])
  }

  const handleAgreement = selectedAgreement => {
    dispatch([
      change('config', 'agreementId', Number(selectedAgreement))
    ])
  }

  return (
    <form id='admin-page-config-form' onSubmit={handleSubmit}>
      <CardForm
        title='Configuração de Emissão de Boleto'
        show
      >
        <Row>
          <Col xs={12} sm={12} md={3} lg={3}>
            <Field
              name='setectedAssignorId'
              type='text'
              component={renderField}
              as='select'
              label='Cedente'
              onChange={e => handleAssignor(e.target.value)}
            >
              <option value="">Selecione</option>
              {assignors.map(assignor => {
                return <option key={assignor.id} value={assignor.id}>{assignor.companyName}</option>
              })}
            </Field>
          </Col>

          <Col xs={12} sm={12} md={3} lg={3}>
            <Field
              name='setectedAccountId'
              type='text'
              component={renderField}
              as='select'
              label='Conta'
              onChange={e => handleAccount(e.target.value)}
            >
              <option value="">Selecione</option>
              {accounts.map(account => {
                return <option key={account.id} value={account.id}>{account.bankName} - {account.bankBranch} - {account.accountNumber}</option>
              })}
            </Field>
          </Col>

          <Col xs={12} sm={12} md={3} lg={3}>
            <Field
              name='setectedAgreementId'
              type='text'
              component={renderField}
              as='select'
              label='Convênio'
              onChange={e => handleAgreement(e.target.value)}
            >
              <option value="">Selecione</option>
              {agreements.map(agreement => {
                return <option key={agreement.id} value={agreement.id}>{agreement.agreementDescription} - {agreement.agreementNumber} - CNAB {agreement.agreementCNABPattern}</option>
              })}
            </Field>
          </Col>
        </Row>
      </CardForm>

      <CardForm
        title='Configuração Ativa'
        show
      >
        <Row>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='companyName'
              type='text'
              component={renderField}
              label='Cedente'
              disabled
            />
          </Col>

          <Col xs={12} sm={12} md={3} lg={3}>
            <Field
              name='bankName'
              type='text'
              component={renderField}
              label='Banco'
              disabled
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='bankBranch'
              type='text'
              component={renderField}
              label='Agência'
              disabled
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='accountNumber'
              type='text'
              component={renderField}
              label='Conta'
              disabled
            />
          </Col>

          <Col xs={12} sm={12} md={3} lg={3}>
            <Field
              name='agreementDescription'
              type='text'
              component={renderField}
              label='Convenio atual'
              disabled
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <label htmlFor="isActive">Utilizar Integração BB API</label>
            <br />
            <Toggle checked={useBbIntegration} onChange={() => dispatch(change('config', 'useBbIntegration', !useBbIntegration))} />

          </Col>
        </Row>
      </CardForm>

      <FormSubmitButtons hasId={null} loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
    </form>
  )
}

ConfigForm.prototype = {
  handleSubmit: PropTypes.func.isRequired,
  assignorId: PropTypes.string,
  loading: PropTypes.bool.isRequired
}

export default reduxForm({
  form: 'config',
  validate: validate
})(ConfigForm)