const validate = values => {
  const errors = {}
  if (!values.assignorId) {
    errors.assignorId = 'Obrigatório'
  }
  if (!values.account) {
    errors.account = 'Obrigatório'
  }
  if (!values.agreementNumber) {
    errors.agreementNumber = 'Obrigatório'
  }
  if (!values.agreementDescription) {
    errors.agreementDescription = 'Obrigatório'
  }
  if (!values.agreementBankBook) {
    errors.agreementBankBook = 'Obrigatório'
  }
  if (!values.agreementType) {
    errors.agreementType = 'Obrigatório'
  }
  if (!values.agreementCNABPattern) {
    errors.agreementCNABPattern = 'Obrigatório'
  }
   
  return errors

}

export default validate