import React, { useEffect, useState } from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { cepMask } from '../../../../utils/normalize'
import './styles.css'
import './styles99.css'

const CompanyAddress = ({ company, height, width }) => {
  const [classes, setClasses] = useState(null)

  useEffect(() => {
    loadClasses()
  }, [])

  const loadClasses = () => {
    if (!window.location.host.includes('21200') && !window.location.host.includes('99')) {
      setClasses("company-address")
    } else {
      setClasses("company-address-99")
    }
  }

  const defaultMapOptions = {
    fullscreenControl: false,
    disableDefaultUI: true
  };

  const SingleCompanyMap = withGoogleMap((props) => (
    <GoogleMap
      defaultCenter={{ lat: props.lat, lng: props.lng }}
      defaultZoom={15}
      defaultOptions={defaultMapOptions}
    >
      <Marker position={{ lat: props.lat, lng: props.lng }} />
    </GoogleMap>
  ));

  return (
    <div className={!window.location.host.includes('21200') && !window.location.host.includes('99') ? "company-container" : "company-container-99"}>
      <div className={classes} style={{ width: `${width}%` }}>
        <SingleCompanyMap
          containerElement={<div style={{ height: `100%`, width: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          lat={parseFloat(company.latitude)}
          lng={parseFloat(company.longitude)}
        />
      </div>
      <div className={classes}>
        <p>{company.tradingName}</p>
        <span>{company.address}, {company.addressNumber}</span>
        <span>{company.neighborhood}</span>
        <span>{company.city}/{company.state} - CEP: {cepMask(company.zipCode)}</span>
      </div>
    </div>
  )
}

export default CompanyAddress;