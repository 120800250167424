import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import propTypes from 'prop-types'
import AlertModal from '../../../../components/AlertModal'
import { toastr } from 'react-redux-toastr'
import Toggle from 'react-toggle'
import bannerRepository from '../../../../repositories/Banner'
import popupRepository from '../../../../repositories/Popup'

import './styles.css';

const RedirectModal = ({ show, onHide, file, type }) => {
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false)
  const [link, setLink] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(true)

  toastr.info.fadeOut = 5000

  useEffect(() => {
    if (file) {
      setLink(file.redirectLink)
      setIsActive(file.linkStatus)
      setIsModalOpen(show)
    }
  }, [])

  const handleOpenLinkModal = () => {
    setIsLinkModalOpen(true)
  }

  const handleLinkStatus = () => {
    if (isActive) {
      setIsActive(false)
    } else {
      setIsActive(true)
    }
  }

  const handleUpdate = async () => {
    var params;
    var response;

    if (type === 'banner') {
      params = {
        redirectLink: link,
        linkStatus: isActive
      }

      try {

        response = await bannerRepository.updateLink(file.id, params);
        toastr.success('Informações inseridas / atualizadas com sucesso')
        setIsLinkModalOpen(false)
        setIsModalOpen(false)
        return window.location.reload()

      } catch (err) {
        toastr.error('Ocorreu um erro ao atualizar as informações da imagem. Por favor, tente novamente')
        return;
      }
    } else {
      params = {
        redirectLink: link,
        linkStatus: isActive
      }

      try {

        response = await popupRepository.updateLink(file.id, params);
        toastr.success('Informações inseridas / atualizadas com sucesso')
        setIsLinkModalOpen(false)
        setIsModalOpen(false)
        return window.location.reload()

      } catch (err) {
        toastr.error('Ocorreu um erro ao atualizar as informações da imagem. Por favor, tente novamente')
        return;
      }
    }
  }

  return (
    <>
      <Modal show={isModalOpen} onHide={onHide} style={{ marginTop: "10%" }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Rclub</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id='redirect-modal-options'>
          <div>
            <label>
              Insira abaixo o link do clique da imagem
            </label>
            <input
              className="form-control"
              type='text'
              value={link}
              onChange={(e) => setLink(e.target.value)}
              style={{ marginBottom: '2%' }}
            />
          </div>
          <div>
            <label htmlFor="isActive">Status</label>
            <br />
            <Toggle checked={isActive} onChange={e => handleLinkStatus()} />
          </div>
          <div>
            <button
              className='btn btn-success'
              onClick={() => handleOpenLinkModal()}
            >
              <span>Salvar</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {
        isLinkModalOpen ?
          <AlertModal
            show={isLinkModalOpen}
            message='Tem certeza que quer inserir ou ativar link da imagem?'
            onHide={() => setIsLinkModalOpen(false)}
            onCancel={() => setIsLinkModalOpen(false)}
            onSubmit={() => handleUpdate()}
          />
          :
          null
      }
    </>
  )
}

RedirectModal.defaultProps = {
  onHide: () => { }
}

RedirectModal.propTypes = {
  show: propTypes.bool.isRequired,
  onHide: propTypes.func.isRequired,
  type: propTypes.string.isRequired
}

export default RedirectModal