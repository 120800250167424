import React, { useState, useEffect } from "react";
import "./styles.css";
import { isMobile } from '../../../../utils/validation'

import about_Header from '../../../../assets/img/about/about_Header.png';
import about_Header_99 from '../../../../assets/img/about/about_Header_99.png';

import initGa from '../../../../utils/googleAnalytics';
import AboutMobile from './AboutMobile';
import AboutDesktop from './AboutDesktop';
import SubHeader from '../../components/SubHeader'



const About = () => {
  initGa(window.location.href);

  const [mobileUser, setMobileUser] = useState(false)

  useEffect(() => {
    const mobileAcess = isMobile()
    if (!mobileAcess) {
      setMobileUser(false)
    } else {
      setMobileUser(true)
    }
  }, []);

  return (
    <>
      <div id="about-page">
        <SubHeader
          title="QUEM SOMOS"
          subTitle="Inovação e Tecnologia"
          position='right'
          img={!window.location.host.includes('21200') && !window.location.host.includes('99') ? about_Header : about_Header_99} />
        {!mobileUser ? <AboutDesktop /> : <AboutMobile />}
      </div>
    </>
  )
}

export default About;