const validate = values => {
  const errors = {}
  if (!values.companyId) {
    errors.companyId = 'Selecione uma empresa'
  }

  if (!!values.client) {
    errors.client = {}
    if(!values.client.cpf) {
      errors.client.cpf = 'Digite o CPF'
    }
    if(!values.client.name) {
      errors.client.name = 'Digite o Nome'
    }
    if(!values.client.email) {
      errors.client.email = 'Digite o E-mail'
    }
    if(!values.client.phone) {
      errors.client.phone = 'Digite o Celular'
    }
    if(!values.client.zipCode) {
      errors.client.zipCode = 'Digite o CEP'
    }
  }

  return errors
}

export default validate