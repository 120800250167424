import { toastr } from "react-redux-toastr";
import { onlyNumbers } from "../../utils/normalize";
import salesRepository from '../../repositories/Sales';
import salesIndicationRepository from '../../repositories/SalesIndications';
import companyStockRepository from '../../repositories/CompanyStock';
import couponsRepository from '../../repositories/Coupons';
import bankSlipConfigRepository from '../../repositories/BankSlipConfigs';
import ReactPixel from 'react-facebook-pixel';

import { format, utcToZonedTime } from 'date-fns-tz';

export const handleChange = (value, type) => {
  return {
    type: 'HANDLE_CHANGE',
    payload: value,
    payloadType: type
  }
}

export const addCart = (product, products) => dispatch => {
  const { id, description, price, productTypeId, vehicleTypeId, ProductTypes, imageURL, maximumQuantity,
    hasPrize, minimumQuantity, observations, complementaryDescription } = product
  let exist = false

  products.forEach((c, index) => {
    if (c.id === id) {
      products[index].quantity = Math.min(products[index].quantity++ + minimumQuantity, maximumQuantity)
      products[index].amount = products[index].price * products[index].quantity
      exist = true
    }
  })

  if (!exist) {
    products.push({
      id,
      description,
      price,
      quantity: minimumQuantity,
      amount: price * minimumQuantity,
      productTypeId,
      vehicleTypeId,
      ProductTypes,
      minimumQuantity,
      maximumQuantity,
      imageURL,
      hasPrize,
      observations,
      complementaryDescription
    })
  }

  dispatch(calculateTotal(products))
}

export function handleCreditCard(creditCard, value, type) {
  const obj = { ...creditCard }
  obj[type] = value

  return {
    type: 'HANDLE_CHANGE',
    payload: obj,
    payloadType: 'creditCard'
  }

}

export function handleDebitCard(debitCard, value, type) {
  const obj = { ...debitCard }
  obj[type] = value

  return {
    type: 'HANDLE_CHANGE',
    payload: obj,
    payloadType: 'debitCard'
  }

}

export const createSale = cartReducer => async dispatch => {
  const {
    selectedProducts,
    subTotal,
    discountValue,
    amount,
    client,
    companyIdAtSelectedCity,
    installments,
    couponId,
    creditCard: {
      cardNumber,
      brand,
      month,
      year,
      cvv
    },
    clientIdIndication,
    paymentMethod
  } = cartReducer

  ReactPixel.fbq('track', 'Purchase', { value: amount, currency: 'BRL' });

  dispatch({ type: 'CREATE_SALE_STARTED' })
  try {
    const sale = await salesRepository.create({
      date: new Date(),
      subTotal,
      discount: discountValue,
      amount,
      items: selectedProducts,
      numberOfInstallments: installments,
      installmentValue: amount / installments,
      companyId: companyIdAtSelectedCity,
      couponId,
      client: {
        ...client,
        cpf: onlyNumbers(client.cpf),
        phone: onlyNumbers(client.phone),
        zipCode: onlyNumbers(client.zipCode)
      },
      creditCard: {
        amount,
        cardNumber,
        month,
        installments,
        year,
        cvv,
        brand
      }
    })

    dispatch(createdSaleSuccess(sale, clientIdIndication, paymentMethod))

  } catch (err) {
    dispatch(createSaleFailure(err))
  }
}

export const createSaleBankSlip = cartReducer => async dispatch => {
  const {
    selectedProducts,
    subTotal,
    discountValue,
    amount,
    client,
    companyIdAtSelectedCity,
    installments,
    couponId,
    bankSlipAddress,
    clientIdIndication,
    paymentMethod
  } = cartReducer

  dispatch({ type: 'CREATE_SALE_STARTED' })
  try {

    const today = new Date();
    //     const timeZone = 'America/New_York';
    //     const timeInUS = utcToZonedTime(today, timeZone);
    //     console.log(`
    //   Time in BR: ${format(today, 'yyyy-MM-dd HH:mm:ss')}
    //   Time in US: ${format(timeInUS, 'yyyy-MM-dd HH:mm:ss')}
    // `);
    const config = await bankSlipConfigRepository.getAll();

    if (config.useBbIntegration) {
      const sale = await salesRepository.createBankSlipBB({
        date: today,
        subTotal,
        discount: discountValue,
        amount,
        items: selectedProducts,
        companyId: companyIdAtSelectedCity,
        couponId,
        client: {
          cpf: onlyNumbers(client.cpf),
          name: client.name,
          email: client.email,
          phone: onlyNumbers(client.phone),
          zipCode: onlyNumbers(bankSlipAddress.postalCode),
          address: bankSlipAddress.bankSlipAddressInfo,
          number: bankSlipAddress.bankSlipAddressNumber,
          complement: '---',
          neighborhood: bankSlipAddress.bankSlipNeighborhood,
          city: bankSlipAddress.bankSlipCity,
          state: bankSlipAddress.bankSlipState
        },
        instruction01: "https://rclub.com.br",
      })

      await dispatch(handleChange(sale.sale.code, 'saleIdBankSlip'))
      await dispatch(handleChange(sale.boletoBase64, 'bankSlipPdfBase64'))
      dispatch(createdSaleSuccess(sale, clientIdIndication, paymentMethod))

    } else {
      const sale = await salesRepository.createBankSlip({
        date: today,
        subTotal,
        discount: discountValue,
        amount,
        items: selectedProducts,
        numberOfInstallments: installments,
        installmentValue: amount / installments,
        companyId: companyIdAtSelectedCity,
        couponId,
        client: {
          cpf: onlyNumbers(client.cpf),
          name: client.name,
          email: client.email,
          phone: onlyNumbers(client.phone),
          zipCode: onlyNumbers(bankSlipAddress.postalCode),
          address: bankSlipAddress.bankSlipAddressInfo,
          number: bankSlipAddress.bankSlipAddressNumber,
          complement: '---',
          neighborhood: bankSlipAddress.bankSlipNeighborhood,
          city: bankSlipAddress.bankSlipCity,
          state: bankSlipAddress.bankSlipState
        }
      })

      await dispatch(handleChange(sale.sale.code, 'saleIdBankSlip'))
      await dispatch(handleChange(sale.boletoBase64, 'bankSlipPdfBase64'))

      dispatch(createdSaleSuccess(sale, clientIdIndication, paymentMethod))
    }

  } catch (err) {
    console.log(err)
    dispatch(createSaleFailure(err))
  }
}

export const createDebitSale = cartReducer => async dispatch => {
  const {
    selectedProducts,
    subTotal,
    discountValue,
    amount,
    client,
    companyIdAtSelectedCity,
    couponId,
    debitCard: {
      cardNumber,
      brand,
      month,
      year,
      cvv
    },
    clientIdIndication,
    paymentMethod
  } = cartReducer

  ReactPixel.fbq('track', 'Purchase', { value: amount, currency: 'BRL' });

  dispatch({ type: 'CREATE_SALE_STARTED' })
  try {
    const sale = await salesRepository.createDebitCard({
      date: new Date(),
      subTotal,
      discount: discountValue,
      amount,
      items: selectedProducts,
      companyId: companyIdAtSelectedCity,
      couponId,
      client: {
        ...client,
        cpf: onlyNumbers(client.cpf),
        phone: onlyNumbers(client.phone),
        zipCode: onlyNumbers(client.zipCode)
      },
      debitCard: {
        amount,
        cardNumber,
        month,
        year,
        cvv,
        brand
      }
    })

    dispatch(createdSaleSuccess(sale, clientIdIndication, paymentMethod))

  } catch (err) {
    dispatch(createSaleFailure(err))
  }
}

export const createPixSale = cartReducer => async dispatch => {
  const {
    selectedProducts,
    subTotal,
    discountValue,
    amount,
    client,
    companyIdAtSelectedCity,
    installments,
    couponId,
    clientIdIndication,
    paymentMethod
  } = cartReducer

  // ReactPixel.fbq('track', 'Purchase', { value: amount, currency: 'BRL' });

  dispatch({ type: 'CREATE_SALE_STARTED' })
  try {
    const sale = await salesRepository.createPix({
      date: new Date(),
      subTotal,
      discount: discountValue,
      amount,
      items: selectedProducts,
      numberOfInstallments: installments,
      installmentValue: amount / installments,
      companyId: companyIdAtSelectedCity,
      couponId,
      client: {
        ...client,
        cpf: onlyNumbers(client.cpf),
        phone: onlyNumbers(client.phone),
        zipCode: onlyNumbers(client.zipCode)
      },
    })

    dispatch(createdSaleSuccess(sale, clientIdIndication, paymentMethod))
  } catch (err) {
    dispatch(createSaleFailure(err))
  }
}

export const clearReducer = () => {
  return { type: 'CLEAR_REDUCER' }
}

async function createdSaleSuccess(sale, indication, paymentMethod) {
  toastr.success('Sucesso')

  var saleId = ''

  if (paymentMethod === 'bankSlip') {
    saleId = sale.sale.id
  } else {
    saleId = sale.id
  }

  if (indication) {
    try {
      const body = {
        clientId: indication,
        salesId: saleId
      }

      const response = await salesIndicationRepository.create(body)
    } catch (err) {
      console.log(err)
    }
  }

  return { type: 'CREATE_SALE_SUCCESS', payload: sale }
}

function createSaleFailure(err) {
  if(err.response.data.message) {
    if (err.response.data.message.erros[0].mensagem) {
      const message = `${err.response.data.message.erros[0].mensagem}`
      toastr.warning('Ops, ocorreu um problema no pagamento: ' + message);
      return { type: 'CREATE_SALE_FAILURE' }
    } else {
      if (err.response.data.code !== undefined) {
        const message = `Código - ${err.response.data.code} Mensagem - ${err.response.data.message}`
        toastr.warning('Ops, ocorreu um problema no pagamento: ', err.response.data.type === 'PAGTOS_ERROR' ? message : " ");
        return { type: 'CREATE_SALE_FAILURE' }
      } else {
        const message = `${err.response.data.message}`
        toastr.warning('Ops, ocorreu um problema no pagamento: ', message);
        return { type: 'CREATE_SALE_FAILURE' }
      }
    }
  } else {
    toastr.warning('Ops, ocorreu um problema no pagamento. Por favor tente novamente.');
    return { type: 'CREATE_SALE_FAILURE' }
  }
}

export const handleQuantity = (value, product, products) => dispatch => {
  products.forEach((c, index) => {
    if (c.id === product.id) {
      products[index].quantity = value
      products[index].amount = products[index].price * value
    }
  })
  dispatch(calculateTotal(products))
}

export const removeItemCart = (product, products) => dispatch => {
  const productsFiltered = products.filter(s => s.id !== product.id)
  dispatch(calculateTotal(productsFiltered))
}

export const checkCompanyStock = (companyIdAtSelectedCity, selectedProducts) => async dispatch => {
  dispatch({ type: 'CHECK_COMPANY_STOCK_STARTED' })

  try {
    const response = await companyStockRepository.checkCompanyStock(companyIdAtSelectedCity, selectedProducts)

    if (!response.status) {
      //toastr.warning(`Ops, infelizmente estamos sem estoque para o produto ${response.productDescription}`)
      //return dispatch({ type: 'CHECK_COMPANY_STOCK_FAILURE' })
    }

    dispatch({ type: 'CHECK_COMPANY_STOCK_SUCCESS' })
  } catch (err) {

    toastr.warning('Ocorreu um erro ao checar o estoque da empresa. Por favor, tente novamente')
    console.log(err);
    dispatch({ type: 'CHECK_COMPANY_STOCK_FAILURE' })

  }
}

export const validateCoupon = coupon => async dispatch => {
  dispatch({ type: 'VALIDATE_COUPON_STARTED' })

  try {

    const response = await couponsRepository.validate(coupon)

    if (!response.status) {
      toastr.warning('Ops, código de cupom não encontrado.')
      return dispatch({ type: 'VALIDATE_COUPON_FAILURE' })
    } else if (!response.coupon.isActive) {
      toastr.warning('Ops, código de cupom expirado.')
      return dispatch({ type: 'VALIDATE_COUPON_FAILURE' })
    }

    toastr.success('Cupom de desconto aplicado com sucesso.')
    dispatch({
      type: 'VALIDATE_COUPON_SUCCESS',
      payload: response.coupon
    })

  } catch (err) {
    console.log(err);
    toastr.warning('Ocorreu um erro ao validar o cupom. Por favor, tente novamente')
    dispatch({ type: 'VALIDATE_COUPON_FAILURE' })
  }
}

const calculateTotal = products => {
  let totalAmountProducts = products.map(x => x.amount)
  let subTotal = 0

  if (Boolean(totalAmountProducts.length)) {
    subTotal = totalAmountProducts.reduce((acumulado, valoratual) => acumulado + valoratual)
  }

  return {
    type: 'HANDLE_CART',
    payload: {
      products,
      subTotal
    }
  }
}