import api from "../services/api";

const getAll = async () => {
  try {

    const response = await api.get('assignors')
    return response.data

  } catch (err) {
    throw err
  }
}

const getById = async (id) => {
  try {

    const response = await api.get(`assignors/${id}`)
    return response.data

  } catch (err) {
    throw err
  }
}

const create = async assignor => {
  try {

    const response = await api.post('assignors', assignor)
    return response.data

  } catch (err) {
    throw err
  }
}

const createCertificate = async formData => {
  try {

    const response = await api.post('assignors-certificate', formData)
    return response.data

  } catch (err) {
    throw err
  }
}

const update = async (id, assignor) => {
  try {

    const response = await api.put(`assignors/${id}`, assignor)
    return response.data

  } catch (err) {
    throw err
  }
}

const updateCertificate = async (id, formData) => {
  try {

    const response = await api.put(`assignors-certificate/${id}`, formData)
    return response.data

  } catch (err) {
    throw err
  }
}

const disable = async (params) => {
  try {

    const response = await api.post('assignors/disable', params)
    return response.data

  } catch (err) {
    throw err
  }
}

const getAllActive = async () => {
  try {

    const response = await api.get('assignors-active')
    return response.data

  } catch (err) {
    throw err
  }
}

export default {
  getAll,
  getById,
  create,
  createCertificate,
  update,
  updateCertificate,
  disable,
  getAllActive
} 