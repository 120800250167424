import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import "./styles.css";
import Companies from '../../components/Companies';
import CompanyAddress from '../../components/CompanyAddress';
import companiesRepository from '../../../../repositories/Companies';
import { isMobile } from '../../../../utils/validation';
import ModalAlertMsg from '../../components/ModalAlertMsg'
import ButtonWhatsappRow from '../../components/ButtonWhatsappRow'

import initGa from '../../../../utils/googleAnalytics';

function WithdrawDesktop({ listCompanies }) {
  initGa(window.location.href);

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    setIsModalOpen(true)
  }, [])

  return (
    <>
      <div className="withdraw-page-desktop">
        <h2>Veja Abaixo os Locais de Retirada</h2>
        <div className="withdraw-page-desktop-content">
          <div className="withdraw-page-desktop-content-map">
            <Companies listCompanies={listCompanies} height={50} width={50} />
          </div>
          {/* <Companies listCompanies={listCompanies} height={35} width={50} /> */}
          <div className="withdraw-page-desktop-content-cards">
            {listCompanies.sort((a, b) => a.city.localeCompare(b.city)).map(c =>
            (<CompanyAddress company={c} width={25} />
            ))}
          </div>
        </div>
        <div className="withdraw-page-desktop-content-whatsapp-button">
          {!window.location.host.includes('21200') && !window.location.host.includes('99') ?
            < ButtonWhatsappRow />
            :
            null
          }
        </div>
        <br />
        <br />
      </div>
      {isModalOpen ?
        <ModalAlertMsg text="As lojas não vendem o produto no balcão, o produto deve ser comprado pelo site e retirado na loja através do QRCODE ou informando seu CPF." onClose={() => { setIsModalOpen(false) }} />
        :
        null
      }
    </>
  )
}

export default WithdrawDesktop;