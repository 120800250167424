import { toastr } from "react-redux-toastr"
import productsRepository from '../../repositories/Products'
import productTypesRepository from '../../repositories/ProductTypes'
import vehicleTypesRepository from '../../repositories/VehicleTypes'


export const fetchVehicleTypes = () => async dispatch => {
    try {
        const vehicleTypes = await vehicleTypesRepository.getAllSortedByDescription()

        dispatch(fetchVehicleTypesSuccess(vehicleTypes))

    } catch (err) {
        console.log(err)
        toastr.warning('Ocorreu um erro ao carregar os tipos de veículo. Por favor, tente novamente')

        dispatch({
            type: 'FETCH_VEHICLE_TYPES_FAILURE'
        })
    }
}

const fetchVehicleTypesSuccess = vehicleTypes => {
    const serializedVehicleTypes = vehicleTypes.map(vehicleType => ({
        ...vehicleType,
        checked: false
    }))

    return {
        type: 'FETCH_VEHICLE_TYPES_SUCCESS',
        payload: serializedVehicleTypes
    }
}

export const fetchProductTypes = () => async dispatch => {
    try {
        const productTypes = await productTypesRepository.getAllSortedByDescription()

        dispatch([
            fetchProductTypesSuccess(productTypes),
        ])

    } catch (err) {
        console.log(err)
        fetchProductTypesFailure(err)
    }
}


const fetchProductTypesSuccess = productTypes => {
    const serializedProductTypes = productTypes.map(productType => ({
        ...productType,
        checked: false
    }))

    return {
        type: 'FETCH_PRODUCT_TYPES_SUCCESS',
        payload: serializedProductTypes
    }
}

const fetchProductTypesFailure = err => {
    toastr.warning('Ocorreu um erro ao carregar os tipos de produto. Por favor, tente novamente')

    return {
        type: 'FETCH_PRODUCT_TYPES_FAILURE'
    }
}

export const fetchProducts = () => async dispatch => {
    try {
        const products = await productsRepository.getAllActiveProducts()
        dispatch(fetchProductsSuccess(products))
    } catch (err) {
        console.log(err)
        dispatch(fetchProductsFailure(err))
    }
}

export const fetchCarProducts = () => async dispatch => {
    try {
        const products = await productsRepository.getAllActiveCarProducts()
        console.log(products)
        dispatch(fetchProductsSuccess(products))
    } catch (err) {
        console.log(err)
        dispatch(fetchProductsFailure(err))
    }
}

const fetchProductsSuccess = products => {
    return {
        type: 'FETCH_PRODUCTS_SUCCESS',
        payload: products
    }
}

const fetchProductsFailure = err => {
    toastr.warning('Ocorreu um erro ao carregar os produtos. Por favor, tente novamente')

    return {
        type: 'FETCH_PRODUCTS_FAILED'
    }
}

export function changeValue(payload, payloadType) {
    return {
        type: 'CHANGE_VALUE',
        payload: payload,
        payloadType: payloadType
    }
}

export const filterProductsByProductTypesAndVehicleTypes = (products, productTypes, vehicleTypes) => dispatch => {
    let productsFiltered = products

    const productTypesId = productTypes
        .filter(productType => productType.checked)
        .map(productType => productType.id)

    if (!!productTypesId.length) {
        productsFiltered = products.filter(product => productTypesId.includes(product.productTypeId))
    }

    dispatch(filterProductsByVehicleTypes(productsFiltered, vehicleTypes))
}

export const filterProductsByVehicleTypes = (products, vehicleTypes) => {
    let productsFiltered = products

    const vehicleTypesIds = vehicleTypes
        .filter(vehicleType => vehicleType.checked)
        .map(vehicleType => vehicleType.id)

    if (!!vehicleTypesIds.length) {
        productsFiltered = products.filter(product => vehicleTypesIds.includes(product.vehicleTypeId))
    }

    return {
        type: 'FINISH_FILTER_PRODUCTS',
        payload: productsFiltered
    }
}

export function clearProductTypesFilter(productTypes) {
    const productTypesCleared = productTypes.map(productType => ({
        ...productType,
        checked: false
    }))


    return {
        type: 'CLEAR_PRODUCT_TYPES_FILTERS',
        payload: productTypesCleared
    }
}

export function clearVehicleTypesFilter(vehicleTypes) {
    const vehicelTypesCleared = vehicleTypes.map(vehicleType => ({
        ...vehicleType,
        checked: false
    }))

    return {
        type: 'CLEAR_VEHICLE_TYPES_FILTERS',
        payload: vehicelTypesCleared
    }
}