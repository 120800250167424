const getTimeFromDateWithoutHours = date => new Date(new Date(date).setHours(0, 0, 0, 0)).getTime()

const getDateDayMonthAndYearSeparated = date => {
  const newDate = new Date(date)

  return {
    day: newDate.getDate(),
    month: newDate.getMonth(),
    year: newDate.getFullYear(),
  }
}

const isInitialDateAndFinalDateMoreThanAMonth = (initialDate, finalDate) => {
  const today = getDateDayMonthAndYearSeparated(new Date())
  if (!initialDate && !finalDate) return false

  if (!!initialDate && !finalDate) {
    const initialDateSeparated = getDateDayMonthAndYearSeparated(initialDate)

    return initialDateSeparated.month < today.month
  }

  if (!!initialDate && !!finalDate) {
    const finalDateSeparated = getDateDayMonthAndYearSeparated(finalDate)
    const initialDateSeparated = getDateDayMonthAndYearSeparated(initialDate)

    return initialDateSeparated.month < finalDateSeparated.month
  }
}

const renderCheckedDate = (date) => {
  let checkedDate = '---'

  if (date) {
    const checkedFullDate = new Date(date)
    const checkedFullDateOffset = new Date(checkedFullDate.getTime() + checkedFullDate.getTimezoneOffset() * 60000)

    const day = checkedFullDateOffset.getDate()
    const month = checkedFullDateOffset.getMonth() + 1
    const year = checkedFullDateOffset.getFullYear()
    const hours = checkedFullDateOffset.getHours() - 3
    const minutes = checkedFullDateOffset.getMinutes()

    checkedDate = `${day}/${month < 10 ? '0' + month : month}/${year}  ${hours}:${minutes}`
    
    if (checkedDate === 'NaN/NaN/NaN  NaN:NaN') {
      const checkedDate ='---'
      return checkedDate
    } else {
      return checkedDate
    }
  }
  
}

export {
  getTimeFromDateWithoutHours,
  getDateDayMonthAndYearSeparated,
  isInitialDateAndFinalDateMoreThanAMonth,
  renderCheckedDate
}