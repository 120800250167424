import api from "../services/api";

const updateLink = async (id, params) => {
  try {
    const response = await api.put(`banner/${id}`, params)
    return response.data
  } catch (err) {
    throw err
  }
}

export default {
  updateLink,
}