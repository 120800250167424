import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import ReactTable from 'react-table-6';
import bankAccountRepository from '../../../../repositories/BankAccount'
import bankAgreementRepository from '../../../../repositories/BankAgreements'
import { Modal } from 'react-bootstrap';
import AlertModal from '../../../../components/AlertModal'
import { useHistory, Link, withRouter } from 'react-router-dom';
import InfoModal from '../../components/InfoModal'
import { withWidth } from '@material-ui/core';

const BankAgreements = ({ }) => {
  const [loading, setLoading] = useState(false)
  const [isBankAgreementDetailsModalOpen, setIsBankAgreementDetailsModalOpen] = useState(false)
  const [bankAgreements, setBankAgreements] = useState([])
  const [bankAgreement, setBankAgreement] = useState([])
  const [bankAgreementInfo, setBankAgreementInfo] = useState([])
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const history = useHistory()

  useEffect(() => {
    loadBankAgreements()
    document.title = 'Rclub - Convênios'
  }, [])

  const loadBankAgreements = async () => {
    setLoading(true)

    try {
      const responseBankAgreements = await bankAgreementRepository.getAll()

      setBankAgreements(responseBankAgreements)

    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar os convênios. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenBankAgreementDetailsModal = bankAgreement => {
    setBankAgreement([bankAgreement])
    setIsBankAgreementDetailsModalOpen(true)
  }

  const handleOpenBankAgreementExcludeModal = bankAgreement => {
    setBankAgreementInfo(bankAgreement)
    setIsCancelModalOpen(true)
  }

  const handleExcludeBankAgreement = async () => {
    setIsCancelModalOpen(false)
    setLoading(true)

    try {

      const response = await bankAgreementRepository.exclude({
        agreementId: bankAgreementInfo.agreementId,
      })
      if (response.status === 'sucesso') {
        toastr.success('Convênio excluido com sucesso.')
        loadBankAgreements()
      }

    } catch (err) {
      console.log(err.response)
      toastr.warning('Ocorreu um erro ao excluir o convênio. ' + err.response.data.dados.mensagem)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='admin-page-bank-account'>
      <header>
        <BreadCrumb path={['home', null]} data={['Início', 'Convênios']} />
        <section>
          <button className='btn btn-success' onClick={() => history.push('agreement')}>+ Novo Convênio</button>
        </section>
      </header>

      <br />
      <ReactTable
        style={{ textAlign: 'center' }}
        data={bankAgreements}
        columns={[
          {
            Header: 'Cód.',
            accessor: 'id',
            Cell: props => Number(props.value),
            width: 80
          },
          {
            Header: 'Cedente',
            accessor: 'assignor',
            width: 250
          },
          {
            Header: 'Conta',
            accessor: 'accountNumber',
            width: 100
          },
          {
            Header: 'Nº Convênio',
            accessor: 'agreementNumber',
            width: 140
          },
          {
            Header: 'Descrição',
            accessor: 'agreementDescription',
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: props => (
              Boolean(props.value)
                ? <button className='btn btn-success'>Ativo</button>
                : <button className='btn btn-danger'>Inativo</button>
            ),
            width: 90
          },
          {
            Header: 'Detalhes',
            accessor: 'id',
            Cell: props => (
              <>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  cursor='pointer'
                  onClick={() => handleOpenBankAgreementDetailsModal(props.original)}
                  color='black'
                />
                <Link to={{ pathname: `editAgreement#${props.value}`}}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    cursor='pointer'
                    color='black'
                    style={{ marginLeft: 5 }}
                  />
                </Link>
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  cursor= 'pointer'
                  color='red'
                  style={{ marginLeft: 5 }}
                  onClick={() => handleOpenBankAgreementExcludeModal(props.original)}
                />
              </>
            ),
            width: 120
          }
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        defaultSorted={[{
          id: 'code',
          desc: false,
        }]}
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />

      {isBankAgreementDetailsModalOpen &&
        (<Modal show={isBankAgreementDetailsModalOpen} onHide={() => setIsBankAgreementDetailsModalOpen(false)} animation={true} >
          <Modal.Header closeButton >
            <div className='bank-account-details-modal-header'>
              <h6><strong>Cód.: </strong>{bankAgreement[0].id}</h6>
              <h6><strong>Cedente: </strong>{bankAgreement[0].assignor}</h6>
              <h6><strong>Conta: </strong>{bankAgreement[0].accountNumber}</h6>
              <h6><strong>N° Convênio </strong>{bankAgreement[0].agreementNumber}</h6>
              <h6><strong>Descr. Convênio: </strong>{bankAgreement[0].agreementDescription}</h6>
              <h6><strong>Carteira: </strong>{bankAgreement[0].agreementBankBook}</h6>
              <h6><strong>Espécie: </strong>{bankAgreement[0].agreementType}</h6>
              <h6><strong>Padrão CNAB: </strong>{bankAgreement[0].agreementCNABPattern}</h6>
              <h6><strong>Reiniciar N° Remessa: </strong>{[0].agreementDailyRestart ? 'Sim' : 'Não'}</h6>
              <h6><strong>Carteira Escritural: </strong>{bankAgreement[0].agreementOurNumberBank ? 'Sim' : 'Não'}</h6>
              <h6><strong>Registro Instantâneo: </strong>{bankAgreement[0].agreementInstantRegister ? 'Sim' : 'Não'}</h6>
            </div>
          </Modal.Header>
        </Modal>
        )}
      {
        isCancelModalOpen &&
        <AlertModal
          show={isCancelModalOpen}
          message='Deseja excluir o Convênio?'
          onHide={() => setIsCancelModalOpen(false)}
          onCancel={() => setIsCancelModalOpen(false)}
          onSubmit={handleExcludeBankAgreement}
        />
      }
    </div>
  )
}

export default withRouter(BankAgreements)