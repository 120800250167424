import { renderCheckedDate } from "../../../../utils/formatDates"
import { currency, phoneMask } from "../../../../utils/normalize"

export const xlsSalesColumns = [
  {
    name: 'Data',
    acessor: 'date'
  },
  {
    name: 'Nr. Venda',
    acessor: 'saleCode'
  },
  {
    name: 'CPF',
    acessor: 'clientCpf'
  },
  {
    name: 'Nome do Cliente',
    acessor: 'clientName'
  },
  {
    name: 'E-mail',
    acessor: 'clientEmail'
  },
  {
    name: 'Telefone do Cliente',
    acessor: 'clientPhone'
  },
  {
    name: 'Valor (R$)',
    acessor: 'amount'
  },
  {
    name: 'Forma Pgto.',
    acessor: 'type'
  },
  {
    name: 'Cupom',
    acessor: 'couponDescription'
  },
  {
    name: 'Status',
    acessor: 'statusDescription'
  },
  {
    name: 'Local de Retirada',
    acessor: 'companyStreet'
  },
]

export const xlsSalesItemsColumns = [
  {
    name: 'N. Venda',
    acessor: 'code'
  },
  {
    name: 'Item',
    acessor: 'index'
  },
  {
    name: 'Produto',
    acessor: 'description'
  },
  {
    name: 'Valor Unitário',
    acessor: 'price'
  },
  {
    name: 'Check-in',
    acessor: 'checkedDate'
  }
]

export const handleSalesToExport = sales => {
  const excludedSaleCanceled = sales.filter(sale => sale.saleStatusId !== 3)
  const serializedSalesToExport = excludedSaleCanceled.map(sale => {
    const companyStreet = sale.Companies.tradingName + ' - ' + sale.Companies.address + ', ' + sale.Companies.addressNumber + ' - ' + sale.Companies.neighborhood + '/' + sale.Companies.state

    const saleCode = sale.code
    const amount = currency(sale.amount)
    const date = new Date(sale.date).toLocaleString().split(' ')[0]

    const clientPhone = phoneMask(sale.Clients.phone)
    const clientCpf = sale.Clients.cpf
    const clientName = sale.Clients.name
    const clientEmail = sale.Clients.email
    const couponDescription = sale.couponId ? sale.Coupons.description : null
    const type = sale.type
    const statusDescription = sale.SaleStatus.description

    return {
      saleCode,
      clientCpf,
      clientEmail,
      date,
      amount,
      clientName,
      clientPhone,
      companyStreet,
      couponDescription,
      type,
      statusDescription
    }
  })

  const salesItemsToExport = handleSalesItemsFromSales(sales)

  return {
    sales: serializedSalesToExport,
    items: salesItemsToExport
  }
}

const handleSalesItemsFromSales = sales => {
  let serializedSalesItemsToExport = []

  if (!!sales.length) {
    serializedSalesItemsToExport = sales
      .map(sale => sale.SalesItems.map((salesItem, i) => ({
        checkedDate: salesItem.checkedDate ? renderCheckedDate(salesItem.checkedDate) : null,
        price: currency(salesItem.price),
        index: i + 1,
        description: salesItem.Products.description,
        code: sale.code
      })))
      .reduce((prev, curr) => prev.concat(curr))
  }
  return serializedSalesItemsToExport
}