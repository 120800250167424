import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faWindowRestore, faCheckSquare, faWindowClose, faShareAltSquare } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import ReactTable from 'react-table-6';
import { phoneMask, cpfMask, currency, percentage } from '../../../../utils/normalize';
import salesRepository from '../../../../repositories/Sales'
import { Modal } from 'react-bootstrap';
import AlertModal from '../../../../components/AlertModal'
import SaleDetails from './SaleDetails'
import { withRouter } from 'react-router-dom';
import InfoModal from '../../components/InfoModal'

const Sales = ({ history }) => {
  const [loading, setLoading] = useState(false)
  const [isSaleDetailsModalOpen, setIsSaleDetailsModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [sales, setSales] = useState([])
  const [sale, setSale] = useState({})
  const [message, setMessage] = useState('')
console.log(sales)
  useEffect(() => {
    loadSales()
    document.title = 'Rclub - Vendas'
  }, [])

  const loadSales = async () => {
    setLoading(true)

    try {
      const responseSales = await salesRepository.getAll()

      const serializeSales = responseSales.map(sale => ({
        ...sale,
        clientName: sale.clientName,
        clientPhone: sale.Clients.phone,
        clientCpf: sale.Clients.cpf,
        clientEmail: sale.Clients.email,
        //coupon: sale.Coupons ? sale.Coupons.description : '---',
        companyName: sale.Companies.tradingName,
        saleStatus: sale.SaleStatus.description
      }))
      setSales(serializeSales)

    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar as vendas. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenSaleDetailsModal = sale => {
    setSale(sale)
    setIsSaleDetailsModalOpen(true)
  }

  const handleOpenCancelSaleModal = sale => {
    const hasCheckedProducts = sale.SalesItems.some(saleItem => !!saleItem.isChecked)

    if (hasCheckedProducts) {
      return toastr.error('Ops, cancelamento não permitido, o cliente já foi retirar o produto.')
    }

    setSale(sale)
    setIsCancelModalOpen(true)
  }

  const handleCancelSale = async () => {
    setIsCancelModalOpen(false)
    setLoading(true)

    try {

      await salesRepository.cancel(sale.id)
      toastr.success('Venda cancelada com sucesso.')
      loadSales()

    } catch (err) {
      if (err.response.data.type === 'PAGTOS_BB_ERROR') {
        toastr.warning('Ocorreu um erro ao cancelar a venda: ' + err.response.data.message);
      } else if (err.response.data.message) {
        toastr.warning('Ocorreu um erro ao cancelar a venda: ' + err.response.data.message);
      } else {
        toastr.warning('Ocorreu um erro ao cancelar a venda. Por favor, tente novamente')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleOpenConfirmSaleModal = sale => {
    setSale(sale)
    console.log(sale)
    if (sale.type === 'Pix') {
      setMessage('Deseja realmente confirmar o pagamento com PIX?')
    } else {
      setMessage('Deseja realmente confirmar o pagamento para o boleto?')
    }
    setIsConfirmModalOpen(true)
  }

  const handleConfirmSale = async () => {
    setIsConfirmModalOpen(false)
    setLoading(true)

    try {

      await salesRepository.confirm(sale.paymentId)
      toastr.success('A compra foi confirmada com sucesso.')
      loadSales()

    } catch (err) {
      if (err.response.data.type === 'PAGTOS_ERROR') {
        toastr.warning('Ocorreu um erro ao confirmar a venda: ' + err.response.data.message.data);
      } else {
        toastr.warning('Ocorreu um erro ao confirmar a venda. Por favor, tente novamente')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleConfirmPixSale = async () => {
    setIsConfirmModalOpen(false)
    setLoading(true)

    try {

      await salesRepository.confirmPix(sale.paymentId)
      toastr.success('A compra foi confirmada com sucesso.')
      loadSales()

    } catch (err) {
      if (err.response.data.type === 'PAGTOS_ERROR') {
        toastr.warning('Ocorreu um erro ao confirmar a venda: ' + err.response.data.message.data);
      } else {
        toastr.warning('Ocorreu um erro ao confirmar a venda. Por favor, tente novamente')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleOpenInfoModal = sale => {
    setSale(sale)
    setIsInfoModalOpen(true)
  }

  return (
    <div id='admin-page-sales'>
      <header>
        <BreadCrumb path={['home', null]} data={['Início', 'Vendas']} />
        <section>
          <button className='btn btn-success' onClick={() => history.push('sale')}>+ Nova Venda</button>
          <div>
            <FontAwesomeIcon icon={faSearch} />
            <input
              className='form-control foco-input'
              title='Pesquisar por Venda, N° Boleto, CNPJ ou Razão Social'
              placeholder='Pesquisar por Venda, N° Boleto, CNPJ ou Razão Social'
              type='text'
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        </section>
      </header>

      <br />
      <ReactTable
        style={{ textAlign: 'center' }}
        data={!!query
          ? sales.filter(x =>
            x['code'].toString().toLowerCase().includes(query.toLowerCase()) ||
            x['clientName'].toString().toLowerCase().includes(query.toLowerCase()) ||
            x['clientCpf'].toString().toLowerCase().includes(query.toLowerCase()) ||
            x['paymentId'].toString().toLowerCase().includes(query.toLowerCase())
          )
          : sales
        }
        columns={[
          {
            Header: 'N.Venda',
            accessor: 'code',
            width: 90,
            Cell: props => Number(props.value)
          },
          {
            Header: 'Data',
            accessor: 'date',
            width: 100,
            Cell: props => <>{new Date(props.value).toLocaleDateString()}</>
          },
          {
            Header: 'Total',
            accessor: 'amount',
            width: 150,
            Cell: props => currency(props.value)
          },
          {
            Header: 'Empresa',
            accessor: 'companyName'
          },
          {
            Header: 'Nome',
            accessor: 'clientName'
          },
          {
            Header: 'Forma Pgto.',
            accessor: 'type',
            width: 150,
            Cell: props => <button className='btn btn-primary'
              style={props.value === 'Cartão de Crédito' ? { backgroundColor: '#6959CD' } :
                props.value === 'Boleto' ? { backgroundColor: '#0000FF' } :
                  props.value === 'Pix' ? { backgroundColor: '#836FFF' } : { backgroundColor: '#483D8B' }}>
              {props.value}
            </button>
          },
          {
            Header: 'Status',
            accessor: 'saleStatusId',
            width: 150,
            Cell: props => <button className={`btn btn-${props.value === 1 || props.value === 5 ? 'success' : props.value === 2 ? 'primary' : 'danger'}`}
              style={(props.original.type === 'Boleto' || props.original.type === 'Pix') && props.value === 4 ? { backgroundColor: '#FF8C00', border: 'none' } : {}}>
              {(props.original.type === 'Boleto' || props.original.type === 'Pix') && props.value === 4 ? "Pendente" : props.original.saleStatus
              }
            </button>
          },
          {
            Header: 'Detalhes',
            width: 100,
            accessor: 'id',
            Cell: props => (
              <>
                <FontAwesomeIcon
                  icon={faWindowRestore}
                  cursor='pointer'
                  onClick={() => handleOpenSaleDetailsModal(props.original)}
                  color='black'
                />
                <FontAwesomeIcon
                  icon={faShareAltSquare}
                  cursor='pointer'
                  onClick={() => handleOpenInfoModal(props.original)}
                  color='black'
                  style={{ marginLeft: 5 }}
                />
                {props.original.type === 'Cartão de Crédito' || props.original.type === 'Cartão de Débito' ?
                  <FontAwesomeIcon
                    icon={faCheckSquare}
                    cursor={'not-allowed'}
                    color='#008000'
                    style={{ marginLeft: 5 }}
                  />
                  :
                  <FontAwesomeIcon
                    icon={faCheckSquare}
                    cursor={props.original.saleStatusId !== 4 ? 'not-allowed' : 'pointer'}
                    onClick={() => props.original.saleStatusId === 4 && handleOpenConfirmSaleModal(props.original)}
                    color='#008000'
                    style={{ marginLeft: 5 }}
                  />
                }
                <FontAwesomeIcon
                  icon={faWindowClose}
                  cursor={props.original.saleStatusId === 3 ? 'not-allowed' : 'pointer'}
                  color='red'
                  style={{ marginLeft: 5 }}
                  onClick={() => props.original.saleStatusId !== 3 && handleOpenCancelSaleModal(props.original)}
                />
              </>
            )
          }
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        defaultSorted={[{
          id: 'code',
          desc: true,
        }]}
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />

      {isSaleDetailsModalOpen &&
        (<Modal show={isSaleDetailsModalOpen} onHide={() => setIsSaleDetailsModalOpen(false)} animation={true} size="lg" >
          <Modal.Header closeButton >
            <div id='sale-details-modal-header'>
              <h5><strong>{sale.clientName}</strong></h5>
              <h6><strong>CPF: </strong>{cpfMask(sale.clientCpf)}</h6>
              <h6><strong>E-mail: </strong>{sale.clientEmail}</h6>
              <h6><strong>Telefone: </strong>{phoneMask(sale.clientPhone)}</h6>
              <footer>
                <h6><strong>Subtotal: </strong>{currency(sale.subTotal)}</h6>
                <h6><strong>Cupom: </strong>{sale.couponId ? sale.Coupons.description : '---'}</h6>
                <h6><strong>Desconto: </strong> {sale.couponId ? <span style={{ color: 'green' }}>-{currency(sale.discount)} ({percentage(sale.Coupons.discount / 100)})</span> : '---'}</h6>
                <h6><strong>Total: </strong>{currency(sale.amount)}</h6>
                {
                sale.type === "Boleto"?<h6><strong>N° Boleto: </strong>{ sale.paymentId}</h6>
                :
                null
                }

              </footer>
            </div>
          </Modal.Header>
          <Modal.Body>
            <SaleDetails items={sale.SalesItems} />
          </Modal.Body>
        </Modal>
        )}

      {
        isCancelModalOpen &&
        <AlertModal
          show={isCancelModalOpen}
          message='Deseja realmente realizar o cancelamento da venda ?'
          onHide={() => setIsCancelModalOpen(false)}
          onCancel={() => setIsCancelModalOpen(false)}
          onSubmit={handleCancelSale}
        />
      }

      {
        isConfirmModalOpen &&
        <AlertModal
          show={isConfirmModalOpen}
          message={message}
          onHide={() => setIsConfirmModalOpen(false)}
          onCancel={() => setIsConfirmModalOpen(false)}
          onSubmit={sale.type === 'Boleto' ? handleConfirmSale : handleConfirmPixSale}
        />
      }

      {
        isInfoModalOpen &&
        <InfoModal
          show={isInfoModalOpen}
          message='Selecione uma das opções abaixo'
          onHide={() => setIsInfoModalOpen(false)}
          sale={sale}
        />
      }
    </div>
  )
}

export default withRouter(Sales)