const validate = values => {
  const errors = {}

  if(!values.description) {
    errors.description = 'Obrigatório'
  }

  return errors
}

export default validate