import React, { useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import crypto from "crypto";
import { change } from 'redux-form'


const DropPhotoContainer = () => {
  const { values } = useSelector(state => state).form.product
  const { image, imageURL, imageName } = values
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(image)
    }
  }, [])
  

  const onDrop = useCallback(async acceptedFiles => {
    if (!acceptedFiles.length) return

    const file = createFileObject(acceptedFiles[0])

    await dispatch([
      change('product', 'image', file),
      change('product', 'imageNotUploaded', true),
    ])
  }, [])

  useEffect(() => {
    handleCheckIfHasImageUploaded()
  }, [image])

  const createFileObject = file => ({
    file: file,
    name: `${crypto.randomBytes(16).toString('hex')}-${file.name}`,
    preview: URL.createObjectURL(file)
  })

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ onDrop, accept: 'image/*' })

  const renderDragMessage = () => {
    if (!isDragActive) return <span>Adicionar imagem</span>

    if (isDragReject) return <span style={{ color: 'red' }}>Arquivo não Suportado</span>

    return <span style={{ color: 'green' }}>Arquivo suportado</span>
  }

  const handleRemoveImage = () => {
    URL.revokeObjectURL(image)

    dispatch(change('product', 'image', null))
    dispatch(change('product', 'imageNotUploaded', false))
  }

  const handleCheckIfHasImageUploaded = () => {
    const imagekey = imageName
    if(hasImageUploaded) {
      dispatch(change('product', 'imageURL', null))
      dispatch(change('product', 'imageName', null))
      dispatch(change('product', 'imageToDelete', imagekey))
    }
  }

  const hasImageUploaded = imageURL && imageName

  return (
    <div id='product-photo-container'>
      {!image?.preview && !imageURL
        ? <div {...getRootProps()} className='container-add-image'>
          <FontAwesomeIcon icon={faPlusCircle} />
          <br />
          {renderDragMessage()}

          <input {...getInputProps()} />
        </div>
        : <div className='container-show-image'>
          <FontAwesomeIcon icon={faTimes} onClick={handleRemoveImage} />
          <img src={image?.preview || imageURL} alt={image?.name || imageName} height='140px' width='180px' />
        </div>
      }
      {(image?.preview || imageURL) && (
        <center>
          <button {...getRootProps()} type='button' className='btn btn-link'>
            <input {...getInputProps()} />
        Alterar
      </button>
        </center>
      )}
    </div>
  )
}

export default DropPhotoContainer