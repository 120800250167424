import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import VehicleTypeForm from './VehicleTypeForm'
import vehicleTypesRepository from '../../../../repositories/VehicleTypes'

function BundleFormVehicleType({ onCancel, onSubmit }) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    loadLastCode()
  }, [])

  async function loadLastCode() {
    try {
      const code = await vehicleTypesRepository.getLastCode()

      dispatch(change('vehicleType', 'code', code))
    } catch(err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao buscar o código do tipo de veículo. Por favor, tente novamente')
    }
  }

  async function create(values) {
    const { code, description } = values

    setLoading(true)

    try {
      const vehicleTypes = await vehicleTypesRepository.create({
        code,
        description
      })

      toastr.success('Tipo de veículo cadastrado com sucesso.')
      onSubmit(vehicleTypes)

    } catch(err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao salvar a Tipo de veículo. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  return (
      <VehicleTypeForm
        onSubmit={create}
        onCancel={onCancel}
        loading={loading}
      />
  )
}

export default BundleFormVehicleType