import React, { useEffect } from 'react'
import { clearProductTypesFilter, clearVehicleTypesFilter, changeValue, fetchProductTypes, filterProductsByProductTypesAndVehicleTypes, fetchVehicleTypes } from '../../../../store/actions/list'
import { useSelector, useDispatch } from 'react-redux'

const DesktopFilters = () => {
  const { listReducer } = useSelector(state => state)
  const { productTypes, products, vehicleTypes } = listReducer
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(filterProductsByProductTypesAndVehicleTypes(products, productTypes, vehicleTypes))
  }, [productTypes, vehicleTypes, products])

  useEffect(() => {
    loadProductTypes()
  }, [])

  useEffect(() => {
    loadVehicleTypes()
  }, [])

  const loadProductTypes = () => {
    dispatch(fetchProductTypes())
  }

  const loadVehicleTypes = () => {
    dispatch(fetchVehicleTypes())
  }

  const handleClearFilters = () => {
    dispatch([
      clearProductTypesFilter(productTypes),
      changeValue('', 'search')
    ])
  }

  const handleChangeProductType = i => {
    const productTypesArray = [...productTypes]
    productTypesArray[i].checked = !productTypesArray[i].checked

    dispatch([
      changeValue(productTypesArray, 'productTypes'),
      changeValue('', 'search')
    ])
  }

  const handleChangeVehicleTypes = i => {
    const vehicleTypesArray = [...vehicleTypes]
    vehicleTypesArray[i].checked = !vehicleTypesArray[i].checked

    dispatch([
      changeValue(vehicleTypesArray, 'vehicleTypes'),
      changeValue('', 'search')
    ])
  }

  const handleClearVehicleTypesFilters = () => {
    dispatch([
      clearVehicleTypesFilter(vehicleTypes),
      changeValue('', 'search')
    ])
  }

  return (
    <div id='filters-container'>
      <section>
        <strong><span>Tipos de Produto ({productTypes.filter(productType => productType.checked).length})</span></strong>
        <button className='btn btn-link' onClick={handleClearFilters}>Limpar todos</button>

        <main>
          {productTypes.map((productType, i) => {
            return (
              <section key={productType.id}>
                <input
                  id={`filter-item${productType.id}`}
                  checked={productType.checked}
                  onChange={() => handleChangeProductType(i)}
                  type="checkbox"
                />
                <label htmlFor={`filter-item${productType.id}`}>{productType.description}</label>
              </section>
            )
          })}
        </main>
      </section>

      {!window.location.host.includes('21200') && !window.location.host.includes('99') && (
        <section>
          <strong><span>Tipos de Veículo ({vehicleTypes.filter(vehicleType => vehicleType.checked).length})</span></strong>
          <button className='btn btn-link' onClick={handleClearVehicleTypesFilters}>Limpar todos</button>

          <main>
            {
              vehicleTypes.map((vehicleType, i) => {
                return (
                  <section key={vehicleType.id}>
                    <input
                      id={`sub-filter-item${vehicleType.id}`}
                      checked={vehicleType.checked}
                      onChange={() => handleChangeVehicleTypes(i)}
                      type="checkbox"
                    />
                    <label htmlFor={`sub-filter-item${vehicleType.id}`}>{vehicleType.description}</label>
                  </section>
                )
              })
            }
          </main>
        </section>
      )}
    </div>
  )
}

export default DesktopFilters