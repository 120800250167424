import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

function ModalProductsAlert({ vehicleType, onClose }) {

    return (
        <div>
            <div className='modal-alert-msg'>
                <Modal style={{ marginTop: "15%", display: 'flex', alignItems: 'center', justifyContent: 'center' }} show={true} onHide={() => onClose()} animation={true}  >
                    <div class="modal-content" style={{}}>
                        <Modal.Header closeButton style={{}}>
                            <div id='company-details-modal-header'>
                                <h5><strong>RCLUB</strong></h5>
                            </div>
                        </Modal.Header >
                        <Modal.Body style={{}}>
                            { vehicleType === 1 ? 
                                <h1 style={{ fontSize: "15px", fontWeight: "normal" }}>Não é possível adicionar um item do tipo de veículo <strong>MOTO</strong>, realize a compra em pedidos separados.</h1>
                                : 
                                <h1 style={{ fontSize: "15px", fontWeight: "normal" }}>Não é possível adicionar um item do tipo de veículo <strong>CARRO</strong>, realize a compra em pedidos separados.</h1>
                            }
                            
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <button className='btn btn-success' name="Sim" style={{ marginLeft: 5 }} onClick={() => onClose()}>Ok</button>
                            </div>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default ModalProductsAlert;
