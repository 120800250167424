import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Field, reduxForm, change } from 'redux-form'

import CardForm from '../../components/CardForm'
import FormSubmitButtons from '../../components/FormSubmitButtons'
import renderField from '../../../../components/RenderField'
import validate from './validate'
import { useSelector, useDispatch } from 'react-redux'
import Toggle from 'react-toggle'
import { createNumberMask } from 'redux-form-input-masks'
import { description } from '../../../../utils/normalize'

const CouponForm = ({ handleSubmit, couponId, loading, onCancel }) => {
  const { values } = useSelector(state => state).form.coupon
  const { isActive, observations, discount } = values
  const dispatch = useDispatch()

  const percentMask = createNumberMask({
    suffix: '%',
    decimalPlaces: 2,
    locale: 'pt-BR'
  })

  useEffect(() => {
    if(discount > 100) {
      dispatch(change('coupon', 'discount', 0))
    }
  }, [discount])

  return (
    <form id='admin-page-coupon-form' onSubmit={handleSubmit}>
      <CardForm
        title='Cupons'
        show
      >
        <Row>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='code'
              type='text'
              component={renderField}
              label='Código'
              disabled
            />
          </Col>

          <Col xs={12} sm={12} md={4} lg={4}>
            <Field
              name='description'
              type='text'
              component={renderField}
              label='Código do Cupom'
              required
              maxLength={20}
              normalize={value => description(value.trim())}
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2} style={{ maxWidth: '120px' }}>
            <Field
              name='discount'
              type='text'
              component={renderField}
              label='Desconto'
              {...percentMask}
              required
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <label htmlFor="isActive">Status<span style={{ color: 'red' }}>*</span></label>
            <br />
            <Toggle checked={isActive} onChange={() => dispatch(change('coupon', 'isActive', !isActive))} />
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Field
              name='observations'
              component={renderField}
              label='Observações (Máximo de 500 caracteres)'
              as='textarea'
              style={{ resize: 'vertical' }}
              maxLength='500'
            />
          </Col>
        </Row>
        <span><strong>{500 - observations.length} Caracteres restantes</strong></span>
      </CardForm>

      <FormSubmitButtons hasId={!!couponId} loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
    </form>
  )
}

CouponForm.prototype = {
  handleSubmit: PropTypes.func.isRequired,
  couponId: PropTypes.string,
  loading: PropTypes.bool.isRequired
}

export default reduxForm({
  form: 'coupon',
  validate: validate
})(CouponForm)