import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CompanyDetails from './CompanyDetails'
import { isAppLogged } from '../../../../utils/validation';
import { useHistory } from 'react-router-dom';

function Dashboard() {

  const history = useHistory()

  useEffect(() => {
    if (!isAppLogged()) {
      history.push('/partners/login');
    }
  }, [])
  
  return (
    <div className='dashboard-app-page'>
      <Header title='PAINEL'/>
      <CompanyDetails />
      <Footer />
    </div>
  );
}

export default Dashboard;