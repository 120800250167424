import React, { useState, useEffect } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Tabs, Tab } from 'react-bootstrap';

import salesRepository from '../../../../repositories/Sales'
import companyStockRepository from '../../../../repositories/CompanyStock'
import { toastr } from 'react-redux-toastr';

const CompanyDetails = ({ company }) => {

  const [soldProductsQuantity, setSoldProductsQuantity] = useState(0)
  const [soldProductsPercentage, setSoldProductsPercentage] = useState(0)

  const [productsCheckedPercentage, setProductsCheckedPercentage] = useState(0)
  const [productsCheckedQuantity, setProductsCheckedQuantity] = useState(0)

  const [stockQuantity, setStockQuantity] = useState(0)
  const [stockOverview, setStockOverview] = useState([])

  useEffect(() => {
    getSalesOverviewByCompany()
  }, [])

  useEffect(() => {
    getCompanyStockOverview()
  }, [])

  const getSalesOverviewByCompany = async () => {
    try {

      const salesOverview = await salesRepository.getOverviewByCompany(company.id)

      const { productsCheckedPercentage, salesItemChecked, soldProductsPercentage, soldProductsQuantity, stockQuantity } = salesOverview

      if (productsCheckedPercentage == undefined || salesItemChecked == undefined || soldProductsPercentage == null
        || soldProductsQuantity == undefined || stockQuantity == undefined) {
        if (productsCheckedPercentage == undefined) {
          setProductsCheckedPercentage(0)
        }
        if (salesItemChecked == undefined) {
          setProductsCheckedQuantity(0)
        }
        if (soldProductsPercentage == undefined) {
          setSoldProductsPercentage(0)
        }
        if (soldProductsQuantity == undefined) {
          setSoldProductsQuantity(0)
        }
        if (stockQuantity == undefined) {
          setStockQuantity(0)
        }
      } else {
        setSoldProductsQuantity(soldProductsQuantity)
        setSoldProductsPercentage(soldProductsPercentage)
        setProductsCheckedPercentage(productsCheckedPercentage)
        setProductsCheckedQuantity(salesItemChecked)
        setStockQuantity(stockQuantity)
      }

    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao buscar o estoque da empresa. Por favor, tente novamente')
    }
  }

  const getCompanyStockOverview = async () => {
    try {

      const stockByCompany = await companyStockRepository.getCompanyStockOverview(company.id)
      setStockOverview(stockByCompany)
    } catch (err) {
      toastr.warning('Ocorreu um erro ao buscar o estoque da empresa. Por favor, tente novamente')
    }
  }

  const CompanyPanel = () => (
    <div id='company-panel'>
      {/* <h4>Total de Produtos Vendidos</h4>
      <section>
        <ProgressProvider valueStart={0} valueEnd={soldProductsPercentage}>
          {value => <CircularProgressbar className='circular-progress-bar' value={(value != null) ? value : 100} text={`${(value != null) ? value : 100}%`} />}
        </ProgressProvider>

        <div>
          <h6>{soldProductsQuantity} produtos vendidos</h6>
          <h6>{stockQuantity} produtos em estoque</h6>
          <h6>{stockQuantity} produtos em estoque</h6>
        </div>
      </section>
      <br /> */}

      <h4>Total de Check-ins Realizados</h4>
      <section>
        <ProgressProvider valueStart={0} valueEnd={productsCheckedPercentage}>
          {value => <CircularProgressbar className='circular-progress-bar' value={value} text={`${value}%`} styles={buildStyles({ trailColor: '#d6d6d6', textColor: '#3e98c7' })} />}
        </ProgressProvider>
        <div>
          <h6>{productsCheckedQuantity} produtos retirados</h6>
          <h6>{soldProductsQuantity} produtos vendidos</h6>
        </div>
      </section>
    </div>
  )

  const CompanyStock = () => (
    <div id='company-stock'>
      {stockOverview.map((product, i) => (
        <section key={`product#${i}`}>
          <h5>{product.description}</h5>
          <span>{product.soldAmount + ' produtos vendidos / ' + product.quantity + ' produtos em estoque'}</span>
          {/* <div className="progress" style={{ height: '20px' }}>
            <div id='progress-bar' className="progress-bar" role="progressbar" style={{ width: product.productStockPercentage + '%' }} aria-valuenow={product.productStockPercentage} aria-valuemin="0" aria-valuemax="1">{product.productStockPercentage}%</div>
          </div> */}

          <br />
          <br />

          <span>{product.checkins + ' produtos retirados / ' + product.soldAmount + ' produtos para retirar'}</span>
          {/* <div className="progress" style={{ height: '20px' }}>
            <div id='progress-bar' className="progress-bar" role="progressbar" style={{ width: product.checkedPercentage + '%' }} aria-valuenow={product.checkedPercentage} aria-valuemin="0" aria-valuemax="1">{product.checkedPercentage}%</div>
          </div> */}
        </section>
      ))}
    </div>
  )

  const ProgressProvider = ({ valueStart, valueEnd, children }) => {
    const [value, setValue] = useState(valueStart)

    useEffect(() => {
      setValue(valueEnd);
    }, [valueEnd]);

    return children(value);
  }

  return (
    <div id='company-details-modal'>
      <Tabs defaultActiveKey="panel" id="uncontrolled-tab-example">
        <Tab eventKey="panel" title="Painel">
          <CompanyPanel />
        </Tab>
        <Tab eventKey="stock" title="Estoque">
          <CompanyStock />
        </Tab>
      </Tabs>
    </div>
  )
}

export default CompanyDetails