import React, { useEffect } from 'react'
import { clearProductTypesFilter, clearVehicleTypesFilter, changeValue, filterProductsByProductTypesAndVehicleTypes } from '../../../../store/actions/list'
import { useSelector, useDispatch } from 'react-redux'
import { Accordion, Card } from 'react-bootstrap'

const MobileFilters = () => {
  const { listReducer } = useSelector(state => state)
  const { productTypes, products, vehicleTypes } = listReducer
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(filterProductsByProductTypesAndVehicleTypes(products, productTypes, vehicleTypes))
  }, [productTypes, vehicleTypes, products])

  const handleClearFilters = () => {
    dispatch([
      clearProductTypesFilter(productTypes),
      changeValue('', 'search')
    ])
  }

  const handleChangeProductType = i => {
    const productTypesArray = [...productTypes]
    productTypesArray[i].checked = !productTypesArray[i].checked

    dispatch([
      changeValue(productTypesArray, 'productTypes'),
      changeValue('', 'search')
    ])
  }

  const handleChangeVehicleTypes = i => {
    const vehicleTypesArray = [...vehicleTypes]
    vehicleTypesArray[i].checked = !vehicleTypesArray[i].checked

    dispatch([
      changeValue(vehicleTypesArray, 'vehicleTypes'),
      changeValue('', 'search')
    ])
  }

  const handleClearVehicleTypesFilter = () => {
    dispatch([
      clearVehicleTypesFilter(vehicleTypes),
      changeValue('', 'search')
    ])
  }

  return (
    <Accordion>
      <Card style={{ border: 'none' }}>
        <Card.Header style={{ backgroundColor: '#FFF' }}>
          <Accordion.Toggle className="product-type-button" variant="link" eventKey="0">
            Tipos de Produto
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {productTypes.map((productType, i) => {
              return (
                <section key={productType.id}>
                  <input
                    id={`filter-item${productType.id}`}
                    checked={productType.checked}
                    onChange={() => handleChangeProductType(i)}
                    type="checkbox"
                    className="pull-right"
                    style={{ marginTop: '7px', fontSize: '20px' }}
                  />
                  <label htmlFor={`filter-item${productType.id}`} style={{ fontSize: '20px' }}>{productType.description}</label>
                </section>
              )
            })}
            <button className="clear-button btn-link" onClick={handleClearFilters}>Limpar todos</button>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      {!window.location.host.includes('21200') && !window.location.host.includes('99') && (
        <Card style={{ border: 'none' }}>
          <Card.Header style={{ backgroundColor: '#FFF' }}>
            <Accordion.Toggle variant="link" eventKey="1" className="product-type-button">
              Tipos de Veículo
          </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {vehicleTypes.map((vehicleType, i) => {
                return (
                  <section key={vehicleType.id}>
                    <input
                      id={`sub-filter-item${vehicleType.id}`}
                      checked={vehicleType.checked}
                      onChange={() => handleChangeVehicleTypes(i)}
                      type="checkbox"
                      className="pull-right"
                      style={{ marginTop: '7px' }}
                    />
                    <label htmlFor={`sub-filter-item${vehicleType.id}`} style={{ fontSize: '20px' }}>{vehicleType.description}</label>
                  </section>
                )
              })}
              <button className="clear-button btn-link" onClick={handleClearVehicleTypesFilter}>Limpar todos</button>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}
    </Accordion>

  )
}

export default MobileFilters