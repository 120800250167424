import api from "../services/api"

const getAllActiveCompanies = async () => {
  try {

    const response = await api.get(`companies?isActive=1`)
    return response.data

  } catch (err) {
    throw err
  }
}

const getAllByCity = async city => {
  try {

    const response = await api.get(`companies?city=${city}&isActive=1`)
    return response.data

  } catch (err) {
    throw err
  }
}

const getAllWithDistance = async (address, vehicleTypeId) => {
  try {

    const response = await api.get(`companieswithdistance?address=${address}&IsActive=true&vehicleType=${vehicleTypeId}`)
    return response.data

  } catch (err) {
    throw err
  }
}

const getAllWithDistanceByCity = async (address, city, vehicleTypeId) => {
  try {

    const response = await api.get(`companieswithdistance?address=${address}&city=${city}&IsActive=true&vehicleType=${vehicleTypeId}`)
    return response.data

  } catch (err) {
    throw err
  }
}

const create = async company => {
  try {

    const response = await api.post('companies', company)
    return response.data

  } catch (err) {
    throw err
  }
}

const update = async (id, company) => {
  try {

    await api.put(`companies/${id}`, company)

  } catch (err) {
    throw err
  }
}

const getById = async id => {
  try {

    const response = await api.get(`companies/${id}`)

    return response.data

  } catch (err) {
    throw err
  }
}

const getAll = async () => {
  try {
    const response = await api.get('companies')
    return response.data
  } catch (err) {
    throw err
  }
}

const findByTypeProduct = async (typeProduct) => {
  try {
    const response = await api.post('companies/typeProduct', typeProduct)
    return response.data

  } catch (err) {
    throw err
  }
}

const findCompaniesByCityAndVehicleType = async (obj) => {
  try {
    const response = await api.post('companies/cityandtypeProduct', obj)
    return response.data

  } catch (err) {
    throw err
  }
}

const findCompaniesByTypeProduct = async (typeProduct) => {
  try {
    const response = await api.post('companies/listCompaniesByTypeProduct', typeProduct)
    return response.data

  } catch (err) {
    throw err
  }
}

const findByEmail = async (email) => {
  try {
    const response = await api.get(`companies/by-email/${email}`);
    return response.data;
  } catch (err) {
    throw err
  }
}


export default {
  getAllActiveCompanies,
  getAllByCity,
  getAll,
  create,
  update,
  getById,
  getAllWithDistance,
  getAllWithDistanceByCity,
  findByTypeProduct,
  findCompaniesByCityAndVehicleType,
  findCompaniesByTypeProduct,
  findByEmail,
}