import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faQrcode, faPlus } from '@fortawesome/free-solid-svg-icons';
import { cpfMask, onlyNumbers } from '../../../../utils/normalize';
import LoadingScreen from '../../components/Loading';
import { getCookie } from '../../../../utils/cookies';
import AlertModal from '../../../Store/components/ModalAlertMsg';
import salesRepository from '../../../../repositories/Sales';
import { useHistory } from 'react-router-dom';

import './styles.css';

function ManualCheckIn() {

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [sales, setSales] = useState([])
  const [getClient, setGetClient] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [message, setMessage] = useState('');

  const history = useHistory();

  useEffect(() => {

    loadClients()

  }, [])

  const QRCodeWrapper = {
    position: 'absolute',
    bottom: '11%',
    right: '2%',
    height: '10%',
    width: '22%',
    marginBottom: '34%',
    bottom: '0',
    right: '5%'

  }

  const QRCodeIcon = {
    backgroundColor: '#0069BE',
    height: 80,
    width: 80,
    borderRadius: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  }

  const newQRCodeIcon = {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '30%',
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 35,
    paddingTop: '1%',
    paddingLeft: '1%',
    backgroundColor: '#FFF'
  }

  const loadClients = async () => {

    let id = getCookie('APP_COMPANYID')
    try {
      setLoading(true)
      if (id) {
        const sales = await salesRepository.getByCompany(id)
        setSales(sales)
        setLoading(false)
      }
    } catch (err) {
      setMessage('Ocorreu um erro ao carregar os clientes. Por favor, tente novamente')
      setIsAlertModalOpen(true)
    } finally {
      setLoading(false)
    }
  }

  function validationInput() {
    if (!search) {
      setMessage('Ops, informe o CPF para prosseguir com a pesquisa.')
      setIsAlertModalOpen(true)
    }
    filterSalesByQuery()
    if (!filterSalesByQuery().length) {
      setMessage('Não encontramos venda para o cliente informado.')
      setIsAlertModalOpen(true)
    }
    setGetClient(true)
  }

  const filterSalesByQuery = () => {
    return sales.filter(sale =>
      sale['Clients']['cpf'].toLowerCase().includes(search.toLowerCase()))
  }

  function getSaleDatails(sale) {
    history.push({
      pathname: '/partners/saledetails',
      state: {
        saleClient: sale,
      }
    });
  }

  const handleOpenQrCodeScanner = () => {
    history.push('/partners/qrcode')
  }

  return (
    <div className='manual-checkin-container'>
      <div className='search-input-checkin'>
        <FontAwesomeIcon
          icon={faSearch}
          size='2x'
          className="iconSearch"
          aria-hidden="false"
          onClick={() => validationInput()} />
        <input
          placeholder='Pesquisar por CPF'
          value={cpfMask(search)}
          onChange={value => setSearch(onlyNumbers(value.target.value))}
        />
      </div>
      {(!search || !getClient)
        ? <div />
        :
        (
          <>
            <div className='client-container'>
              {
                sales
                  .filter((x, index) => x['Clients']['cpf'].toLowerCase().includes(search.toLowerCase()))
                  .map(sale => (
                    <div className='client-content' onClick={() => getSaleDatails(sale)}>
                      <h1>{sale.Clients.name}</h1>
                      <label>{'CPF: ' + cpfMask(sale.Clients.cpf)}</label>
                      <label>{'E-mail: ' + sale.Clients.email}</label>
                      <label>{'Pedido n°: ' + sale.code}</label>
                    </div>
                  )
                  )}
            </div>
          </>
        )
      }
      <div style={QRCodeWrapper} onClick={(e) => handleOpenQrCodeScanner()}>
        <div style={QRCodeIcon}>
          <FontAwesomeIcon icon={faQrcode} size='3x' color='white' />
          <div style={newQRCodeIcon}>
            <FontAwesomeIcon icon={faPlus} size='1x' color='black' />
          </div>
        </div>
      </div>
      {loading && (
        <LoadingScreen />
      )
      }
      {isAlertModalOpen && (
        <AlertModal text={message} onClose={e => setIsAlertModalOpen(false)} />
      )
      }
    </div>
  );
}

export default ManualCheckIn;