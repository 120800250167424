import React, { useState, useEffect, useCallback } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import ReactTable from 'react-table-6';
import { useHistory, Link } from 'react-router-dom';
import productsRepository from '../../../../repositories/Products'
import productTypesRepository from '../../../../repositories/ProductTypes'
import { currency } from '../../../../utils/normalize'

function Products() {
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [productTypeId, setProductTypeId] = useState('')
  const [isActive, setIsActive] = useState('')
  const [products, setProducts] = useState([])
  const [productsFiltered, setProductsFiltered] = useState([])
  const [productTypes, setProductTypes] = useState([])

  const history = useHistory()

  useEffect(() => {
    loadProducts()
    document.title = 'Rclub - Produtos'
  }, [])

  useEffect(() => {
    loadProductTypes()
  }, [])

  const setProductsFilteredByIsActive = useCallback(products => {
    let productsFilteredByIsActive = products

    if(isActive) {
      const productIsActiveBoolean = isActive === '1'
      productsFilteredByIsActive = productsFilteredByIsActive.filter(product => product.isActive === productIsActiveBoolean)
      console.log(productsFilteredByIsActive)
    }

    setProductsFiltered(productsFilteredByIsActive)
  }, [isActive])

  const getProductsFilteredByProductTypes = useCallback(() => {
    if(productTypeId !== '') {
      return products.filter(product => product.productTypeId.toString() === productTypeId)
    }

    return products
  }, [products, productTypeId])

  useEffect(() => {
    const productsFilteredByProductTypes = getProductsFilteredByProductTypes()

    setProductsFilteredByIsActive(productsFilteredByProductTypes)

  }, [productTypeId, isActive, setProductsFilteredByIsActive, getProductsFilteredByProductTypes])
  

  const loadProducts = async () => {
    setLoading(true)

    try {
      const products = await productsRepository.getAll()
      const serializedProducts = products.map(product => ({
        ...product,
        productTypeDescription: product.ProductTypes.description,
        vehicleTypeDescription: product.VehicleTypes.description,
      }))

      setProducts(serializedProducts)
      setProductsFiltered(serializedProducts)

    } catch(err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar os produtos. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const loadProductTypes = async () => {
    setLoading(true)

    try {
      const productTypes = await productTypesRepository.getAll()
      setProductTypes(productTypes)

    } catch(err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar os tipos de produto. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div id='admin-page-products'>
      <header>
        <BreadCrumb path={['home', null]} data={['Início', 'Produtos']} />
        <section>
          <button className='btn btn-success' onClick={() => history.push('product')}>+ Novo Produto</button>
          <div>
            <FontAwesomeIcon icon={faSearch} />
            <input
              className='form-control foco-input'
              title='Pesquisar por Descrição ou Tipo de Produto'
              placeholder='Pesquisar por Descrição ou Tipo de Produto'
              type='text'
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor='product-type'>Tipo de Produto:</label>
            <select className='form-control foco-input' name="product-type" id="product-type" value={productTypeId} onChange={e => setProductTypeId(e.target.value)}>
              <option value="">Selecione</option>
              {productTypes.map(productType => {
                return <option key={productType.id} value={productType.id}>{productType.description}</option>
              })}
            </select>
          </div>

          <div>
            <label htmlFor='isActive'>Status:</label>
            <select className='form-control foco-input' name="isActive" id="isActive" value={isActive} onChange={e => setIsActive(e.target.value)}>
              <option value="">Ambos</option>
              <option value="1">Ativo</option>
              <option value="0">Inativo</option>
            </select>
          </div>
        </section>
      </header>

      <br />
      <ReactTable
        style={{ textAlign: 'center' }}
        data={!!query
          ? productsFiltered.filter(x =>
            x['description'].toString().toLowerCase().includes(query.toLowerCase()) ||
            x['productTypeDescription'].toString().toLowerCase().includes(query.toLowerCase())
          )
          : productsFiltered
        }
        columns={[
          {
            Header: 'Código',
            accessor: 'code',
            width: 150
          },
          {
            Header: 'Tipo de Produto',
            accessor: 'productTypeDescription'
          },
          {
            Header: 'Tipo de Veículo',
            accessor: 'vehicleTypeDescription'
          },
          {
            Header: 'Produto',
            accessor: 'description'
          },
          {
            Header: 'Valor',
            accessor: 'price',
            Cell: props => <>{currency(props.value)}</>
          },
          {
            Header: 'Status',
            accessor: 'isActive',
            Cell: props => (
              Boolean(props.value)
              ? <button className='btn btn-success'>Ativo</button>
              : <button className='btn btn-danger'>Inativo</button>
            )
          },
          {
            Header: 'Ações',
            accessor: 'id',
            width: 150,
            Cell: props => (
              <Link to={{ pathname: `product#${props.value}` }}>
                <FontAwesomeIcon icon={faEdit} color='black' />
              </Link>
            )
          }
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
    </div>
  )
}

export default Products