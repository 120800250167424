import React from 'react';

import './styles.css';

function SecurePaymentMessage({typePayment}) {
    return <div className="container-secure-payment-message">
        <span>O pagamento em <strong>{typePayment}</strong> é realizado através da <strong>Cielo E-commerce.</strong></span>
        <span>Saiba mais em <a href="https://www.cielo.com.br/e-commerce/" target="_blank"> https://www.cielo.com.br/e-commerce/</a></span>
    </div>;
}

export default SecurePaymentMessage;