import React from "react";
import "./index.css";
import 'react-table-6/react-table.css'
import { Switch, Route, Redirect } from "react-router-dom";

import AdminLogin from './layouts/Admin/pages/Login'
//import GaragePanelLogin from './layouts/GaragePanel/pages/Login'


import AdminLayout from './layouts/Admin'
import StoreLayout from './layouts/Store'
import GaragePanelLayout from './layouts/GaragePanel'
import PDF from './layouts/Store/pages/Document'
import ReactGa from 'react-ga';
import ReactPixel from 'react-facebook-pixel'

ReactGa.initialize('UA-69638641-2')
ReactPixel.init("202114038062452")

const App = () => (
  <Switch>
    <Redirect exact from="/" to="/store" />
    <Route path="/store" component={StoreLayout} />
    <Route exact path="/admin/login" component={AdminLogin} />
    <Route path="/admin" component={AdminLayout} />
    {/* <Route exact path="/app/login" component={GaragePanelLogin} /> */}
    <Redirect exact from="/partners" to="/partners/dashboard" />
    <Route path="/partners" component={GaragePanelLayout} />
    <Route path="/document/:id" component={PDF} />
  </Switch>
);

export default App
