import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import ConfigForm from './BankSlipConfig'
import BreadCrumb from '../../components/BreadCrumb'
import { cnpjMask } from '../../../../utils/normalize'
import bankSlipConfigRepository from '../../../../repositories/BankSlipConfigs'


function BundleBankSlipConfig({ match }) {
  const [loading, setLoading] = useState(false)
  const [configs, setConfigs] = useState([])
  const [configId, setConfigId] = useState(null)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    loadConfig()
    document.title = 'Rclub - Nova Configuração'
  }, [])

  async function loadConfig() {
    setLoading(true)

    try {
      const config = await bankSlipConfigRepository.getAll();
      setConfigs(config)

      if (config) {
        setConfigId(config.id)
        dispatch([
          change('config', 'id', config.id),
          change('config', 'accountId', config.accountId),
          change('config', 'assignorId', config.assignorId),
          change('config', 'agreementId', config.agreementId),
          change('config', 'registerNumber', cnpjMask(config.registerNumber)),
          change('config', 'companyName', config.companyName),
          change('config', 'bankCode', config.bankCode),
          change('config', 'bankName', config.bankName),
          change('config', 'agreementDescription', config.agreementDescription),
          change('config', 'agreementBankBook', config.agreementBankBook),
          change('config', 'agreementNumber', config.agreementNumber),
          change('config', 'bankBranch', config.bankBranch),
          change('config', 'accountNumber', config.accountNumber),
          change('config', 'useBbIntegration', config.useBbIntegration),
        ])
      }

    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao carregar cedente')
    } finally {
      setLoading(false)
    }
  }

  function handleCancel() {
    window.location.reload();
  }

  function handleSubmit(values) {
    if (configs) {
      update(values)
    } else {
      create(values)
    }
  }

  async function create(values) {
    const { assignorId, accountId, agreementId, useBbIntegration } = values

    setLoading(true)

    try {
      await bankSlipConfigRepository.create({
        assignorId,
        accountId,
        agreementId,
        useBbIntegration
      });

      toastr.success('Configuração cadastrado com sucesso.')
      history.push('configbankSlips')

    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao salvar configuração. Por favor, tente novamente')
    } finally {
      setLoading(false)
      loadConfig()
    }
  }

  async function update(values) {
      const { assignorId, accountId, agreementId, useBbIntegration } = values
      setLoading(true)
  
      try {
        await bankSlipConfigRepository.update(configId, {
          assignorId,
          accountId,
          agreementId,
          useBbIntegration
        });
  
        toastr.success('Configuração cadastrado com sucesso.')
        history.push('configbankSlips')
  
      } catch (err) {
        console.log(err.response);
        toastr.warning('Ocorreu um erro ao atualizar configuração. Por favor, tente novamente')
      } finally {
        setLoading(false)
        loadConfig()
      }
  }

  const initialValues = {
    assignor: '',
    account: '',
    agreement: ''
  }

  return (
    <>
      <BreadCrumb path={['home', null]} data={['Início', 'Config. Boleto']} />

      <ConfigForm
        initialValues={initialValues}
        assignorId={null}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        loading={loading}
      />
    </>
  )
}

export default BundleBankSlipConfig