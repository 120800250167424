import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import QrReader from 'react-qr-reader';
import { isQrCodeValid } from '../../../../utils/validation';
import LoadingScreen from '../../components/Loading';
import { getCookie } from '../../../../utils/cookies';
import AlertModal from '../../../Store/components/ModalAlertMsg';
import QRCodeRepository from '../../../../repositories/QRCode';
import QRCodeScanSuccessModal from './Modals/QRCodeScanSuccess';
import QRCodeAlreadyCheckedModal from './Modals/QRCodeAlreadyChecked';
import QRCodeScanInvalidModal from './Modals/QRCodeScanInvalid';

import './styles.css';

function QrCode() {
  const [loading, setLoading] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isManualCheckinSuccess, setIsManualCheckinSuccess] = useState(false);
  const [isAlreadyChecked, setIsAlreadyChecked] = useState(false);
  const [isCheckinFail, setIsCheckinFail] = useState(false);
  const [checkedDate, setCheckedDate] = useState();

  const handleQrCodeScan = async (data) => {
    const QRCode = JSON.parse(data)

    if (!isQrCodeValid(QRCode)) {
      return alert('Ops, QR code inválido.')
    }

    setLoading(true)

    try {
      const QRCodeValidation = await QRCodeRepository.validate(QRCode)
      if (!QRCodeValidation.status) {
        if (QRCodeValidation.errorStatus === 'QRCODE_ALREADY_VALIDATED') {
          setCheckedDate(QRCodeValidation.checkedDate)
          setIsAlreadyChecked(true)
        } else {
          setIsCheckinFail(true)
        }
      } else {
        setIsManualCheckinSuccess(true)
      }
    } catch (err) {
      setMessage('Ocorreu um erro ao realizar o check-in. Por favor, tente novamente')
      setIsAlertModalOpen(true)
    } finally {
      setLoading(false)
    }
  }

  const handleQrCodeScanError = () => {
    alert('Ops, ocorreu um erro ao ler QR code. Tente novamente.')
  }

  return (
    <><div className='checkin-app-page'>
      <Header title='ESCANEAR' />
      
      <div className='checkin-container-qrcode'>
        <QrReader
          delay={300}
          onError={handleQrCodeScanError}
          onScan={handleQrCodeScan}
          className='checkin-qrcode-camera'
        />
      </div>
      <Footer />
      </div>
      {loading && (
        <LoadingScreen />
      )
      }
      {isAlertModalOpen && (
        <AlertModal text={message} onClose={e => setIsAlertModalOpen(false)} />
      )
      }
      {isAlreadyChecked && (
        <QRCodeAlreadyCheckedModal date={checkedDate} />
      )
      }
      {isManualCheckinSuccess && (
        <QRCodeScanSuccessModal onCancel={() => setIsManualCheckinSuccess(false)} isQrCode={true} />
      )
      }
      {isCheckinFail && (
        <QRCodeScanInvalidModal />
      )
      }
    </>
  );
}

export default QrCode;