import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import "./styles.css";
import Companies from '../../components/Companies';
import CompanyAddress from '../../components/CompanyAddress';
import companiesRepository from '../../../../repositories/Companies';
import { isMobile } from '../../../../utils/validation';
import WithdrawMobile from './WithdrawMobile'
import SubHeader from '../../components/SubHeader'
import img from '../../../../assets/img/withdraw/withdraw_Header.png'
import img_99 from '../../../../assets/img/withdraw/withdraw_Header_99.png'

import initGa from '../../../../utils/googleAnalytics';
import WithdrawDesktop from './WithdrawDesktop';

const Withdraw = () => {
  initGa(window.location.href);

  const [companies, setCompanies] = useState([])
  const [hasCompanies, setHasCompanies] = useState(false)
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    isMobile()
    const mobileAcess = isMobile()
    if (!mobileAcess) {
      setMobile(false)
    } else {
      setMobile(true)
    }
    loadCompanies()
    document.title = 'Rclub - Onde Retirar'
  }, [])

  useEffect(() => {
    handleCompanies()
  }, [companies])

  const loadCompanies = async () => {
    try {
      const companies = await companiesRepository.getAllActiveCompanies()
      let companiesFiltered;

      if (!window.location.host.includes('21200') && !window.location.host.includes('99')) {
        companiesFiltered = companies.filter(company => (company.latitude) && (company.longitude))
      } else {
        let companiesCars = companies.filter(company => company.vehicleTypeId === 2)
        companiesFiltered = companiesCars.filter(company => (company.latitude) && (company.longitude))
      }

      setCompanies(companiesFiltered)

    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar as empresas. Por favor, tente novamente')
    }
  }

  const handleCompanies = () => {
    setHasCompanies(true)
  }

  return (
    <>
      <div id="withdraw-page">
        <SubHeader
          title="ONDE RETIRAR"
          subTitle="Mais perto, sem demora."
          position='right'
          img={!window.location.host.includes('21200') && !window.location.host.includes('99') ? img : img_99} />
        {mobile ?
          <WithdrawMobile listCompanies={companies} />
          :
          <WithdrawDesktop listCompanies={companies} />
        }
      </div>
    </>
  )
}

export default Withdraw;