import api from "../services/api"

const create = async salesIndication => {
    try {
      const response = await api.post('sales-indications/', salesIndication)
      return response.data
  
    } catch (err) {
      throw err
    }
}

export default {
    create
  }