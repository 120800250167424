import React, { useEffect, useState } from 'react';
import "./styles.css";
import { isMobile } from '../../../../utils/validation'

import initGa from '../../../../utils/googleAnalytics';
import SubHeader from '../../components/SubHeader'
import register_Header from '../../../../assets/img/register/register_Header.png';
import ResendMobile from './ResendMobile';
import ResendDesktop from './ResendDesktop';

const Resend = () => {
  initGa(window.location.href);

  const [mobileUser, setMobileUser] = useState(false)

  useEffect(() => {
    const mobileAcess = isMobile()
    if (!mobileAcess) {
      setMobileUser(false)
    } else {
      setMobileUser(true)
    }
  }, []);

  return (
    <>
      <div id="resend-page">
        <SubHeader title="2ª VIA DE BOLETO" subTitle="Tire agora mesmo sua segunda via." position='left' img={register_Header} />
        {!mobileUser ? <ResendDesktop /> : <ResendMobile />}
      </div>

    </>
  )
}

export default Resend;