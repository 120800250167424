import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faCubes, faQrcode, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { eraseCookie, getCookie } from '../../../../utils/cookies'


import './styles.css';

function Footer() {

  const history = useHistory()

  function logout() {
    confirmAlert({
      title: '',
      message: 'Deseja realmente sair do aplicativo?',
      buttons: [
        {
          label: 'Não',
          onClick: () => ('Click No')
        },
        {
          label: 'Sim',
          
          onClick: () => { logoutfinished() }
        }
      ]
    });
  }

  const logoutfinished = async () => {
    await eraseCookie('APP_ID')
    await eraseCookie('APP_NAME')
    await eraseCookie('APP_COMPANYID')
    history.push('/partners/login')
  }

  async function goToPage(name) {

    if (name === 'dashboard') {
      history.push('/partners/dashboard')
      var elementos = await document.getElementsByClassName('iconeMenu');
      elementos[0].setAttribute('style', 'color:' + '#0069BE' + '!important');

      var elementos = document.getElementsByClassName('footer-button');
      elementos[0].setAttribute('style', 'color:' + '#0069BE' + '!important');
    }

    if (name === 'stock') {
      history.push('/partners/stock')
      var elementos = await document.getElementsByClassName('iconeMenu');
      console.log(elementos[1])
      elementos[1].setAttribute('style', 'color:' + '#0069BE' + '!important');

      var elementos = document.getElementsByClassName('footer-button');
      elementos[1].setAttribute('style', 'color:' + '#0069BE' + '!important');
    }

    if (name === 'checkin') {
      history.push('/partners/checkin')
      var elementos = await document.getElementsByClassName('iconeMenu');
      if (elementos[2]) {
        elementos[2].setAttribute('style', 'color:' + '#0069BE' + '!important');
        var elementos = document.getElementsByClassName('footer-button');
        elementos[2].setAttribute('style', 'color:' + '#0069BE' + '!important');
      }
    }
  }

  return (
    <div className="footer-container">
      <button className="footer-button" onClick={() => { goToPage('dashboard') }}>
        <FontAwesomeIcon icon={faChartLine} className="iconeMenu" style={{ backgroundColor: 'yellow !important' }} aria-hidden="false" />
        <label>Painel</label>
      </button>
      <button className="footer-button" onClick={() => { goToPage('stock') }}>
        <FontAwesomeIcon icon={faCubes} color='white' className="iconeMenu" style={{ backgroundColor: 'yellow !important' }} aria-hidden="false" />
        <label>Estoque</label>
      </button>
      <button className="footer-button" onClick={() => { goToPage('checkin') }}>
        <FontAwesomeIcon icon={faQrcode} color='white' className="iconeMenu" style={{ backgroundColor: 'yellow !important' }} aria-hidden="false" />
        <label>Check-In</label>
      </button>
      <button className="footer-button" onClick={() => { logout() }}>
        <FontAwesomeIcon icon={faSignOutAlt} backgroundColor='white' color='white' className="iconeMenu" style={{ backgroundColor: 'yellow !important' }} aria-hidden="false" />
        <label>Sair</label>
      </button>
    </div>
  );
}

export default Footer;