import api from "../services/api";

const create = async accountDatas => {
  try {

    const response = await api.post('bankAccount/create', accountDatas)
    return response
  } catch (err) {
    throw err
  }
}

const update = async (idAccount, accountDatas) => {
  try {

    const response = await api.put(`bankAccount/update/${idAccount}`, accountDatas)
    return response
  } catch (err) {
    throw err
  }
}

const getAll = async () => {
  try {

    const response = await api.get('bankAccount/getall')
    return response.data

  } catch (err) {
    throw err
  }
}

const getById = async id => {
  try {

    const response = await api.get(`bankAccount/${id}`)
    return response.data

  } catch (err) {
    throw err
  }
}

const exclude = async (params) => {
  try {

    const response = await api.post('bankAccount/delete', params)
    return response.data

  } catch (err) {
    throw err
  }
}

const getByAssignorId = async (assignorId) => {
  try {
    const response = await api.get(`bankAccount/getByAssignorId/${assignorId}`)
    return response.data

  } catch (err) {
    throw err
  }
}
          
const getAllActive = async (assignorId) => {
  try {

    const response = await api.get(`bankAccount-active/${assignorId}`)
    return response.data

  } catch (err) {
    throw err
  }
}

export default {
  create,
  update,
  getAll,
  getByAssignorId,
  getById,
  exclude,
  getAllActive
} 