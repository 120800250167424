import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import convertBufferToUint from '../../../../utils/convertBufferToUint';
import salesRepository from '../../../../repositories/Sales'

import './styles.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDF = ({ match: { params: { id } } }) => {
  const [numPages, setNumPages] = useState(null);
  const [document, setDocument] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    getSalePDF()
    window.document.title = `Rclub - Impressos Venda #${id}`
  }, [])

  const getSalePDF = async () => {
    setLoading(true)
    try {
      const response = await salesRepository.getSalePDF(id)

      const arrayUint = convertBufferToUint(response.data)

      setDocument(arrayUint)

    } catch (err) {
      console.log(err)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  async function handlePrint() {
    window.print()
  }

  if (loading) return (
    <div className='div-loading'>
      <span>Aguarde, gerando seu documento...</span>
    </div>
  )

  if (error) return (
    <div className='div-loading'>
      <span>Ocorreu um erro ao buscar os dados da venda.</span>
    </div>
  )

  return (
    <div id='print-page'>
      <button className='btn btn-primary' onClick={handlePrint}>Imprimir</button>
      <br />
      <main id='print'>
        <Document
          file={{ data: document }}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
        >
          {new Array(numPages).fill().map((_, i) => {
            return (
              <>
                <span>Página {i + 1} de {numPages}</span>
                <Page height={1350} pageNumber={i + 1} />
                <br />
              </>
            )
          })}
        </Document>
      </main>

      <main id='print-mobile'>
        <Document
          file={{ data: document }}
          onLoadError={console.error}
        >
          {new Array(numPages).fill().map((_, i) => {
            return (
              <>
                <span>Página {i + 1} de {numPages}</span>
                <Page height={1550} width={350} pageNumber={i + 1} />
                <br />
              </>
            )
          })}
        </Document>
      </main>
    </div>
  )
}

export default PDF