import React from 'react';
import "./styles.css";
import ButtonWhatsappColumn from '../../components/ButtonWhatsappColumn'
import HowBuy from './HowBuy'
import CardPageImgLeft from '../../components/CardPageImgLeft'
import CardPageImgRight from '../../components/CardPageImgRight'
import SeeLocationAvailable from './SeeLocationAvailable'

import card1 from '../../../../assets/img/how-it-works/Site_RClub_web_2021_Imagens_Separadas-17.png'
import card2 from '../../../../assets/img/how-it-works/Site_RClub_web_2021_Imagens_Separadas-18.png'
//import card2 from '../../../../assets/img/how-it-works/about_Motorcycles.png'
import card3 from '../../../../assets/img/how-it-works/Site_RClub_web_2021_Imagens_Separadas-19.png'
import card3_99 from '../../../../assets/img/how-it-works/Site_RClub_web_2021_Separados_99-10.png'

function HowItWorksMobile() {
  return (
    <>
      <div className="how-buy">
        <HowBuy />
      </div>
      <div className="card-page-img">
        <CardPageImgLeft img={card1} title='1.' text='Escolha o seu produto e faça a compra pelo site.' />
        <CardPageImgRight img={card2} title='2.' text='Receba os cupons com o QR Code em seu E-mail.' />
        <CardPageImgLeft img={!window.location.host.includes('21200') && !window.location.host.includes('99') ? card3 : card3_99} title='3.' text='Após 48h vá para a oficina escolhida e apresente o QR Code.' />
        <SeeLocationAvailable />
      </div>
      {!window.location.host.includes('21200') && !window.location.host.includes('99') ?
        < ButtonWhatsappColumn />
        :
        null
      }
    </>
  );
}

export default HowItWorksMobile;