import React, { useEffect, useState } from 'react';
import "./styles.css";
import { isMobile } from '../../../../utils/validation'

import initGa from '../../../../utils/googleAnalytics';
import SubHeader from '../../components/SubHeader'
import register_Header from '../../../../assets/img/register/register_Header.png';
import RegisterMobile from './RegisterMobile';
import RegisterDesktop from './RegisterDesktop';

const Register = () => {
  initGa(window.location.href);

  const [mobileUser, setMobileUser] = useState(false)

  useEffect(() => {
    const mobileAcess = isMobile()
    if(!mobileAcess) {
      setMobileUser(false)
      }else{
      setMobileUser(true)
      }
  }, []);

  return (
    <>
        <div id="register-page">
            <SubHeader title="CADASTRE-SE" subTitle="Receba de forma exclusiva as novidades!" position='left' img={register_Header} />
            {!mobileUser ? <RegisterDesktop/> : <RegisterMobile/>}
        </div>

  </>
  )
}

export default Register;