import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Field, reduxForm, change } from 'redux-form'

import CardForm from '../../components/CardForm'
import FormSubmitButtons from '../../components/FormSubmitButtons'
import renderField from '../../../../components/RenderField'
import validate from './validate'
import { maxLength } from '../../../../utils/normalize'

import './styles.css'
import "react-toggle/style.css"

function AssignorCertificateForm({ handleSubmit, assignorId, loading, onCancel }) {
  const { values } = useSelector(state => state).form.assignorCertificate
  const dispatch = useDispatch()
  const [wrongFileType, setWrongTypeFile] = useState(false)

  const handleChange = selectedFile => {
    const file = selectedFile

    if (file.type !== "application/x-pkcs12") {
      setWrongTypeFile(true)
      return
    }

    setWrongTypeFile(false)

    dispatch([
      change('assignorCertificate', 'file', file),
    ])
  }

  return (
    <form id='admin-page-assignor-form' onSubmit={handleSubmit}>
      <CardForm
        title='Certificado'
        show
      >
        <Row>
          <Col xs={12} sm={12} md={4} lg={4}>
            <label htmlFor="file">Novo Certificado</label>
            <br />
            <input
              id='file'
              type='file'
              onChange={e => handleChange(e.target.files[0])}
              required
            />
            {
              wrongFileType && (
                <label style={{ color: 'red' }}>Tipo do arquivo deve ser .pfx</label>
              )
            }
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='password'
              type='password'
              component={renderField}
              label='Senha Certificado'
              normalize={value => maxLength(value, 20)}
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='nickname'
              type='text'
              component={renderField}
              label='Apelido Certificado'
              normalize={value => maxLength(value, 20)}
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='certificateEmail'
              type='text'
              component={renderField}
              label='Email Certificado'
              normalize={value => maxLength(value, 50)}
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='expireAt'
              type='text'
              component={renderField}
              label='Expira Em'
              disabled
            />
          </Col>
        </Row>
      </CardForm>

      <FormSubmitButtons hasId={!!assignorId} loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
    </form>
  )
}

AssignorCertificateForm.prototype = {
  handleSubmit: PropTypes.func.isRequired,
  assignorId: PropTypes.string,
  assignorFile: PropTypes.string,
  loading: PropTypes.bool.isRequired
}

export default reduxForm({
  form: 'assignorCertificate',
  validate: validate
})(AssignorCertificateForm)