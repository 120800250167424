import React, { useState, useEffect, useCallback } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import SmsModal from '../../components/SmsModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons'

import { toastr } from 'react-redux-toastr';

import './styles.css'
import ReactTable from 'react-table-6';
import salesRepository from '../../../../repositories/Sales'

function BankSlips(props) {
  const [loading, setLoading] = useState(false)
  const [slips, setSlips] = useState([])
  const [salesFiltered, setSalesFiltered] = useState([])
  const [clientName, setClientName] = useState('')
  const [isOpenSmsModal, setIsOpenSmsModal] = useState(false)
  const [isEnabledButtonSms, setIsEnabledButtonSms] = useState(true)

  useEffect(() => {
    loadContacts()
    document.title = 'Rclub - Boletos'
  }, [])

  useEffect(() => {
    let countTrue = 0
    for (let i in salesFiltered) {
      if (salesFiltered[i].checked === true) {
        countTrue = countTrue + 1
        if (!countTrue) {
          setIsEnabledButtonSms(true)
        } else {
          setIsEnabledButtonSms(false)
        }
      }
      if (countTrue === 0) {
        setIsEnabledButtonSms(true)
      }
    }
  }
    , [salesFiltered])

  const setSalesFilteredByName = useCallback(() => {
    let salesFilteredByName = slips

    salesFilteredByName = salesFilteredByName
      .filter(contact => contact.clientName.toLowerCase().includes(clientName.toLowerCase()))

    setSalesFiltered(salesFilteredByName)
  }, [clientName, slips])

  useEffect(() => {
    setSalesFilteredByName()
  }, [clientName, setSalesFilteredByName])

  const loadContacts = async () => {
    setLoading(true)
    try {
      const responseSales = await salesRepository.getAwaitingPaymentSlip()

      let salesInfo = [];

      if (props.location.state) {
        salesInfo = props.location.state.obj.map(obj => ({ ...obj, checked: false }))
      } else {
        salesInfo = responseSales.map(obj => ({ ...obj, checked: false }))
      }

      setSlips(salesInfo)
      setSalesFiltered(salesInfo)

    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar os contatos. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenSmsModal = () => {
    setIsOpenSmsModal(true)
  }

  const handleSelectedClient = (item) => {
    setSalesFiltered(salesFiltered.map(n => {
      if (n.id === item.id) return { ...n, checked: !item.checked }
      return { ...n };
    }));
  }

  return (
    <>
      <div id='admin-page-contacts-reports'>
        <header>
          <BreadCrumb path={['home', 'BankSlips']} data={['Início', 'Boletos']} />
          <section>
            <div>
              <input
                type="text"
                id='initialDate'
                className='form-control foco-input'
                onChange={e => setClientName(e.target.value)}
                value={clientName}
                placeholder="Pesquisar por Nome"
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <button 
              className='btn btn-success'
              onClick={handleOpenSmsModal}
              disabled={isEnabledButtonSms}
              >
                <FontAwesomeIcon icon={faComment} style={{ width: '25px' }}></FontAwesomeIcon>
              Enviar SMS
              </button>
            </div>
          </section>
        </header>

        <br />
        <ReactTable
          style={{ textAlign: 'center' }}
          data={salesFiltered}
          columns={[
            {
              Header: 'Selecionar',
              width: 120,
              accessor: 'checked',
              Cell: props => (
                <input
                  type="checkbox"
                  checked={props.value}
                  onChange={() => handleSelectedClient(props.original)}
                    />
                )
            },
            {
              Header: 'N. Venda',
              accessor: 'code',
              width: 120
            },
            {
              Header: 'Data Venda',
              accessor: 'createdAt',
              width: 150,
              Cell: props => <>{new Date(props.value).toLocaleString().split(' ')[0]}</>
            },
            {
              Header: 'Data Venc. Boleto',
              accessor: 'expirationDate',
              width: 200,
              Cell: props => {
                if (props.value) {
                  return <> {new Date(props.value).toLocaleString().split(' ')[0]}</>
                }
                return <> {'---'} </>
              }
            },
            {
              Header: 'Empresa',
              accessor: 'companyName',
            },
            {
              Header: 'Nome',
              accessor: 'clientName',
            },
          ]}
          defaultPageSize={10}
          loading={loading}
          showPagination={true}
          sortable={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          previousText='Anterior'
          nextText='Próximo'
          loadingText='Carregando...'
          noDataText='Não há informação'
          pageText='Página'
          ofText='de'
          rowsText='linhas'
        />
      </div>
      {isOpenSmsModal &&
        (
          <SmsModal
            show={isOpenSmsModal}
            onHide={() => setIsOpenSmsModal(false)}
            listSales={salesFiltered} />
        )
      }
    </>
  )
}

export default BankSlips