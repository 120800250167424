import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Toggle from 'react-toggle'
import { useSelector, useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import PropTypes from 'prop-types'
import { Field, reduxForm, change } from 'redux-form'

import CardForm from '../../components/CardForm'
import FormSubmitButtons from '../../components/FormSubmitButtons'
import renderField from '../../../../components/RenderField'
import validate from './validate'
import { onlyNumbers, maxLength } from '../../../../utils/normalize'
import bankAccountRepository from '../../../../repositories/BankAccount'

import './styles.css'
import "react-toggle/style.css"
import SelectInput from '@material-ui/core/Select/SelectInput'

function AgreementForm({ handleSubmit, assignorsList, assignor, loading, onCancel }) {
  const { values } = useSelector(state => state).form.agreement
  const { agreementDailyRestart, agreementInstantRegister, agreementOurNumberBank} = values
  const dispatch = useDispatch()

  const [assignorId, setAssignorId] = useState(null)
  const [accountId, setAccountId] = useState(null)
  const [accountsList, setAccountsList] = useState([])

  useEffect(() => {
    if(assignor !== null || assignor !== undefined){
      setAssignorId(assignor)
    } 
  }, [assignor])
 
  useEffect(() => {
  }, [])
 
  useEffect(() => {
    if(assignorId !== null || assignorId !== undefined){
      loadAccounts()
    }
  }, [assignorId])

  async function loadAccounts() {
    try {
      const accounts = await bankAccountRepository.getByAssignorId(assignorId)
      setAccountsList(accounts)
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao carregar o convênio')
    }
  }

  return (
    <form id='admin-page-companie-form' onSubmit={handleSubmit}>
      <CardForm
        title='Convênio'
        show
      >
        <Row>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='assignorId'
              type='text'
              component={renderField}
              as='select'
              label='Cedente'
              onChange={props => setAssignorId(props.target.value)}
            >
              <option disabled="true" value="">Selecione</option>
              {assignorsList.map(assignor => {
                return <option key={assignor.id} value={assignor.id}>{assignor.companyName}</option>
              })}
            </Field>
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='account'
              type='text'
              component={renderField}
              as='select'
              label='Conta'
              onChange={props => setAccountId(props.target.value)}
            >
              <option disabled="true" value="">Selecione</option>
              {accountsList.map(account => {
                return <option key={account.id} value={account.assignorAccountId}>{account.accountNumber}</option>
              })}
            </Field>
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='agreementNumber'
              type='text'
              component={renderField}
              label='N° Convênio'
            />
          </Col>
          
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='agreementDescription'
              type='text'
              component={renderField}
              label='Descrição Convênio'
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='agreementBankBook'
              type='text'
              component={renderField}
              label='Carteira'
            />
          </Col>

          <Col xs={12} sm={12} md={4} lg={2}>
            <Field
              name='agreementType'
              type='text'
              component={renderField}
              as='select'
              label='Espécie'
            >
              <option disabled="true" value="">Selecione</option>
              <option value="R$">R$</option>
              <option value="US$">US$</option>
              <option value="IGPM">IGPM</option>
            </Field>
          </Col>
        </Row>
        
        <Row>
          <Col xs={12} sm={12} md={4} lg={2}>
            <Field
              name='agreementCNABPattern'
              type='text'
              component={renderField}
              as='select'
              label='Padrão CNAB'
            >
              <option disabled="true" value="">Selecione</option>
              <option value="240">240</option>
              <option value="400">400</option>
            </Field>
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='agreementDeliveryNumber'
              type='text'
              component={renderField}
              label='N° Atual da Remessa'
            />
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <label htmlFor="agreementDailyRestart">Reiniciar Diariamente?</label>
            <br />
            <Toggle checked={agreementDailyRestart} onChange={() => dispatch(change('agreement', 'agreementDailyRestart', !agreementDailyRestart))} />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <label htmlFor="agreementOurNumberBank">Carteira Escritural?</label>
            <br />
            <Toggle checked={agreementOurNumberBank} onChange={() => dispatch(change('agreement', 'agreementOurNumberBank', !agreementOurNumberBank))} />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <label htmlFor="agreementInstantRegister">Registro Instantâneo?</label>
            <br />
            <Toggle checked={agreementInstantRegister} onChange={() => dispatch(change('agreement', 'agreementInstantRegister', !agreementInstantRegister))} />
          </Col>
        </Row>
      </CardForm>
      <FormSubmitButtons loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
    </form>
  )
}

AgreementForm.prototype = {
  handleSubmit: PropTypes.func.isRequired,
  agreementId: PropTypes.string,
  loading: PropTypes.bool.isRequired
}

export default reduxForm({
  form: 'agreement',
  validate: validate
})(AgreementForm)