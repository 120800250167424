import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faInfoCircle, faEdit, faFileAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import ReactTable from 'react-table-6';
import { phoneMask, cpfMask } from '../../../../utils/normalize';
import salesRepository from '../../../../repositories/Sales'
import assignorsRepository from '../../../../repositories/Assignors'
import { Modal } from 'react-bootstrap';
import AlertModal from '../../../../components/AlertModal'
import { withRouter, Link } from 'react-router-dom';

const Assignors = ({ history }) => {
  const [loading, setLoading] = useState(false)
  const [isAssignorDetailsModalOpen, setIsAssignorDetailsModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isInfoCertificateModalOpen, setIsInfoCertificateModalOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [assignors, setAssignors] = useState([])
  const [assignor, setAssignor] = useState([])
  const [assignorCertificate, setAssignorCertificate] = useState('')
  const [assignorFantasyName, setAssignorFantasyName] = useState('')
  
  useEffect(() => {
    loadAssignors()
    document.title = 'Rclub - Cedentes'
  }, [])

  const loadAssignors = async () => {
    setLoading(true)

    try {
      const responseAssignors = await assignorsRepository.getAll()

      const serializeAssignors = responseAssignors.map(assignor => ({
        ...assignor,
        id: assignor.id,
        code: assignor.assignorId,
        date: assignor.createdAt,
        companyName: assignor.companyName,
        status: assignor.status,
      }))
      setAssignors(serializeAssignors)

    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar os cedentes. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenAssignorDetailsModal = assignor => {
    setAssignor(assignor)
    setIsAssignorDetailsModalOpen(true)
    if(assignor.certificateStatus) {
      setAssignorCertificate('Sim')
    } else {
      setAssignorCertificate('Não')
    }
    if(assignor.fantasyName != "null") {
      setAssignorFantasyName(assignor.fantasyName)
    } else {
      setAssignorFantasyName('Não possui')
    }
  }

  const handleOpenAssignorDisableModal = assignor => {
    setAssignor(assignor)
    setIsCancelModalOpen(true)
  }

  const handleDisableAssignor = async () => {
    setIsCancelModalOpen(false)
    setLoading(true)

    try {

      await assignorsRepository.disable({
        id: assignor.id,
        status: false
      })
      toastr.success('Cedente inativado com sucesso.')
      loadAssignors()

    } catch (err) {
      toastr.warning('Ocorreu um erro ao inativar cedente. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenInfoModal = assignor => {
    setAssignor(assignor)
    setIsInfoModalOpen(true)
  }

  const handleOpenInfoCertificateModal = assignor => {
    setAssignor(assignor)
    setIsInfoCertificateModalOpen(true)
  }

  return (
    <div id='admin-page-assignors'>
      <header>
        <BreadCrumb path={['home', null]} data={['Início', 'Cedentes']} />
        <section>
          <button className='btn btn-success' onClick={() => history.push('assignor')}>+ Novo Cedente</button>
          <div>
            <FontAwesomeIcon icon={faSearch} />
            <input
              className='form-control foco-input'
              title='Pesquisar Razão Social'
              placeholder='Pesquisar por Razão Social'
              type='text'
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        </section>
      </header>

      <br />
      <ReactTable
        style={{ textAlign: 'center' }}
        data={!!query
          ? assignors.filter(x =>
            x['companyName'].toString().toLowerCase().includes(query.toLowerCase())
          )
          : assignors
        }
        columns={[
          {
            Header: 'Cód.',
            accessor: 'code',
            width: 90,
            Cell: props => props.value ? Number(props.value) : "---"
          },
          {
            Header: 'Data Cadastro',
            accessor: 'date',
            width: 100,
            Cell: props => <>{new Date(props.value).toLocaleDateString()}</>
          },
          {
            Header: 'Razão Social',
            accessor: 'companyName'
          },
          {
            Header: 'Status',
            accessor: 'status',
            width: 150,
             Cell: props => (
               Boolean(props.value)
                 ? <button className='btn btn-success'>Ativo</button>
                 : <button className='btn btn-danger'>Inativo</button>
             )
          },
          {
            Header: 'Detalhes',
            width: 100,
            accessor: 'id',
            Cell: props => (
              <>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  cursor='pointer'
                  onClick={() => handleOpenAssignorDetailsModal(props.original)}
                  color='black'
                />
                <Link to={{ pathname: `assignor#${props.value}` }}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    cursor='pointer'
                    onClick={() => handleOpenInfoModal(props.original)}
                    color='black'
                    style={{ marginLeft: 5 }}
                  />
                </Link>
                <Link to={{ pathname: `assignorCertificate#${props.value}` }}>
                  <FontAwesomeIcon
                    icon={faFileAlt}
                    cursor={props.original.status ? 'pointer' : 'not-allowed'}
                    onClick={() => props.original.status && handleOpenInfoCertificateModal(props.original)}
                    color='black'
                    style={{ marginLeft: 5 }}
                  />
                </Link>
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  cursor={props.original.status ? 'pointer' : 'not-allowed'}
                  color='red'
                  style={{ marginLeft: 5 }}
                  onClick={() => props.original.status && handleOpenAssignorDisableModal(props.original)}
                />
              </>
            )
          }
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        defaultSorted={[{
          id: 'code',
          desc: false,
        }]}
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />

      {isAssignorDetailsModalOpen &&
        (<Modal show={isAssignorDetailsModalOpen} onHide={() => setIsAssignorDetailsModalOpen(false)} animation={true} >
          <Modal.Header closeButton id="assignor-details-modal-button" >
            <div id='assignor-details-modal-header'>
              <h5><strong>{assignor.companyName}</strong></h5>
              <h6><strong>Tipo Cadastro: </strong>{assignor.RegisterTypes.description}</h6>
              <h6><strong>Nome Fantasia: </strong>{assignorFantasyName}</h6>
              {assignor.registerTypeId === 2 ?
                <h6><strong>CNPJ: </strong>{cpfMask(assignor.registerNumber)}</h6>
                :
                <h6><strong>CPF: </strong>{cpfMask(assignor.registerNumber)}</h6>
              }
              <h6><strong>E-mail: </strong>{assignor.email}</h6>
              <h6><strong>Telefone: </strong>{phoneMask(assignor.phone)}</h6>
              <h6><strong>Endereço: </strong>{`${assignor.address}, ${assignor.number} - ${assignor.city}/${assignor.state}`}</h6>
              <h6><strong>Possui Certificado: </strong>{`${assignorCertificate}`}</h6>

            </div>
          </Modal.Header>
        </Modal>
        )}

      {
        isCancelModalOpen &&
        <AlertModal
          show={isCancelModalOpen}
          message='Deseja inativar o Cedente?'
          onHide={() => setIsCancelModalOpen(false)}
          onCancel={() => setIsCancelModalOpen(false)}
          onSubmit={handleDisableAssignor}
        />
      }
    </div>
  )
}

export default withRouter(Assignors)