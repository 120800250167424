import api from "../services/api"

const getAll = async () => {
  try {

    const response = await api.get('register-types')
    return response.data

  } catch (err) {
    throw err
  }
}

export default {
  getAll,
}