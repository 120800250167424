import Main from './pages/Main'
import Products from './pages/Products'
import Items from './pages/Items'
import About from './pages/About'
import Partners from './pages/Partners'
import HowItWorks from './pages/HowItWorks'
import ProductDetails from './pages/ProductDetails'
import Withdraw from './pages/Withdraw'
import Register from './pages/Register'
import RegisterCampaign from './pages/RegisterCampaign'
import ResendBankSlip from './pages/ResendBankSlip'
import ResendQrCode from './pages/ResendQrCode'


const routes = [
  {
    path: "/",
    component: Main,
    layout: "/store",
    name: "Início",
  },
  {
    path: "/products",
    component: Products,
    layout: "/store",
    name: "Produtos",
  },
  {
    path: "/items",
    component: Items,
    layout: "/store",
    name: "Carrinho",
  },
  {
    path: "/about",
    component: About,
    layout: "/store",
    name: "Sobre Nós",
  },
  {
    path: "/partners",
    component: Partners,
    layout: "/store",
    name: "Parceiros",
  },
  {
    path: "/how-it-works",
    component: HowItWorks,
    layout: "/store",
    name: "Como funciona",
  },
  {
    path: "/product-details/:id",
    component: ProductDetails,
    layout: "/store",
    name: "Detalhes do Produto",
  },
  {
    path: "/withdraw",
    component: Withdraw,
    layout: "/store",
    name: "Onde Retirar",
  },
  {
    path: "/register",
    component: Register,
    layout: "/store",
    name: "Registre-se",
  },
  {
    path: "/registercampaign",
    component: RegisterCampaign,
    layout: "/store",
    name: "Registre-se",
  },
  {
    path: "/resend",
    component: ResendBankSlip,
    layout: "/store",
    name: "2ª via Boleto",
  },
  {
    path: "/resendqrcode",
    component: ResendQrCode,
    layout: "/store",
    name: "2ª via Qr Code",
  }
]

export default routes