import React, { useEffect, useState } from "react";
import './styles.css'
import { useDropzone } from 'react-dropzone'
import { useHistory } from 'react-router-dom'
import s3Repository from '../../../../repositories/s3'
import crypto from "crypto";
import { toastr } from 'react-redux-toastr'

function ListVideos({ filesArray }) {

  const history = useHistory()

  const [imageDesktop, setImageDesktop] = useState([]);
  const [idDesktop, setIdDesktop] = useState('')
  const [videoToUpload, setVideoToUpload] = useState([])
  const [videoInfo, setVideoInfo] = useState([])

  const { getRootProps, getInputProps } = useDropzone({ accept: 'image/*' | 'video/*' })

  useEffect(() => {
    if (imageDesktop.length !== 0) {
      createFileObjectDesktop(imageDesktop)
    }
  }, [imageDesktop])

  useEffect(() => {
    if(videoToUpload.length !== 0){
      uploadMidia(videoToUpload, videoInfo)
      setVideoToUpload([])
    }
  }, [videoToUpload])

  const createFileObjectDesktop = file => {
    const imageUpload = ({
      file: file,
      name: `${crypto.randomBytes(16).toString('hex')}-${file.name}`,
      preview: URL.createObjectURL(file)
    })
  }

  async function uploadMidia (newVideo, currentVideo){

    var file = newVideo[0]

    const formData = new FormData();
    formData.append('image', file, file.name)

    try {
      await s3Repository.singleUploadBanner(formData)
      .then((resp) => {
        
        var url = resp

        const updateInfo = {
            id: videoInfo.id,
            isVideo: true,
            name: file.name,
            url: resp
        }
        
        const updateArchive = s3Repository.updateFiles(updateInfo)
        .then((updateArchive) => {

          //var index = filesArray.findIndex(x => x.id === currentVideo.id);
          toastr.success('Vídeo alterado com sucesso.')
        })
        return history.push('products')
      })
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao atualizar a mídia. Por favor, tente novamente')
    }
  }

  return (

    <div>
    <h3 style={{fontSize: '14px', paddingLeft: '2px', marginTop: '20px', marginBottom:'0px'}}><strong>Vídeos</strong></h3>
    <div className="listImagesContainer">
      {(filesArray.length === 0) ? 
      <div>
        <h1 style={{fontSize: '14px', paddingLeft: '10px', paddingTop: '25px'}}>Nenhum arquivo selecionado</h1>
      </div>
        : 
        <>
        {filesArray?.map(videos => (
          <li style={{display: 'flex'}} key={videos?.id}>
            <div className="listImage-info">
              <div className="images-preview">
              <video controls
                  src={videos?.imageUrl}
                  type="video/mp4"
                  height={140}
                  width={160}>
                </video>
                <div className="submitInputButton">
                { videos?.notUploadedYet 
                  ? 
                  <div className='submitInputUpdate' style={{ justifyContent: 'center', paddingLeft: '30px'}} >
                    <h1 style={{color: 'red'}}>Não registrado</h1>
                  </div>
  
                  : 
  
                  <div {...getRootProps()} className='submitInputUpdate' style={{ justifyContent: 'center', paddingLeft: '30px'}} >
                    <h1 onClick={() => setVideoInfo(videos)}>Alterar vídeo</h1>
                    <input {...getInputProps()} onChange={value => setVideoToUpload(value?.target.files)} multiple={false} />
                  </div> 
                }
                </div>
              </div>
            </div>
          </li>
        ))}
        </>
      }
    </div>
  </div>
  )
};

export default ListVideos;
