import React, { useState, useEffect } from 'react';
import "./styles.css";
import './styles99.css';

import logoYPF from '../../../../assets/img/partners/YPF-logo-mobile.png'
import ButtonWhatsappRow from '../../components/ButtonWhatsappRow'
import ButtonWhatsappColumn from '../../components/ButtonWhatsappColumn'
import SeeOptions from '../../components/ButtonSeeOptions'


function PartnersMobile() {
  const [classes, setClasses] = useState(null)

  useEffect(() => {
    loadClasses()
  }, [])

  const loadClasses = () => {
    if (!window.location.host.includes('21200') && !window.location.host.includes('99')) {
      setClasses('partners-button')
    } else {
      setClasses('partners-button-99')
    }
  }

  function goToYPFSite() {
    window.open('https://ypf.com.br/')
  }

  return (
    <>
      <div id='partners-page'>
        <div className='partners-mobile-image'>
          <img src={logoYPF} />
        </div>
        <p style={{ textAlign: 'left' }}>
          A YPF é uma das maiores e mais tecnológicas fabricantes de óleo do mundo e faz todo esse investimento em
          tecnologia porque é parceira das montadoras no desenvolvimento de produtos de ponta, e, somente dessa forma,
          conseguem entregar as melhores soluções e garantir resultados excelentes aos motores mais modernos do mundo.
          Se é YPF, pode confiar, porque aqui o futuro é agora!
                </p>
        <iframe src="https://www.youtube.com/embed/Kpgta562Vy4" title='YPF demonstração' allowFullScreen />
        <div style={{ paddingTop: '20px' }} >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes} onClick={goToYPFSite} style={{ justifyContent: 'center' }}>
              <h5>Visite o site da YPF</h5>
            </div>
          </div>
        </div>
        <div class='about-wpp-mobile-footer' style={{ paddingTop: '40px' }}>
          {!window.location.host.includes('21200') && !window.location.host.includes('99') ?
            < ButtonWhatsappColumn />
            :
            null
          }
        </div>
      </div>
    </>
  )
}

export default PartnersMobile;