import React from 'react';
import "./styles.css";

import about_Driver from '../../../../assets/img/about/about_Driver.png';
import about_Motorcycles from '../../../../assets/img/about/about_Motorcycles.png';
import about_Cars from '../../../../assets/img/about/about_Cars.png';

import CardPageImgLeft from '../../components/CardPageImgLeft'
import CardPageImgRight from '../../components/CardPageImgRight'
import ButtonWhatsappColumn from '../../components/ButtonWhatsappColumn'
import SeeOptions from '../../components/ButtonSeeOptions'

function AboutMobile() {

  return (
    <>
      <div className='about-page-content-mobile'>
        <div className='top-page-mobile'>
          <p>O Clube de Reparação surgiu com o intuito de levar produtos direto de fábrica para os motociclistas.
          Sabemos que cuidar da moto é importante para você e não gastar muito e ainda ter acesso a
                produtos de extrema qualidade é melhor ainda.</p>
          <p>Com uma compra simplificada, você faz o seu pedido online e retira quando quiser na loja mais
                próxima de você, sem interferir no seu dia a dia ou na sua rotina.</p>
          <SeeOptions />
          <div className='about-mobile-images' style={{ paddingTop: '30px', paddingLeft: '21px', paddingRight: '23px' }}>
            <CardPageImgLeft img={about_Driver} title='SEM CUSTO' text='Você não paga nada para fazer parte do RClub.' />
          </div>
          <div className='about-mobile-images' style={{ paddingTop: '10px', paddingLeft: '21px', paddingRight: '23px' }}>
            <CardPageImgRight
              img={!window.location.host.includes('21200') && !window.location.host.includes('99') ? about_Motorcycles : about_Cars}
              title='PREÇO BAIXO SEMPRE'
              text='Aqui, preço baixo é todo o ano, é como uma oferta que nunca expira!' />
          </div>
        </div>
      </div>
      <div class='about-wpp-mobile-footer' style={{}}>
        {!window.location.host.includes('21200') && !window.location.host.includes('99') ?
          < ButtonWhatsappColumn />
          :
          null
        }
      </div>
    </>
  )
}

export default AboutMobile;