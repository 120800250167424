import api from "../services/api";

const getAll = async () => {
  try {

    const response = await api.get('bankSlipConfig')
    return response.data

  } catch (err) {
    throw err
  }
}

const create = async config => {
  try {

    const response = await api.post('bankSlipConfig', config)
    return response.data

  } catch (err) {
    throw err
  }
}

const update = async (id, config) => {
  try {

    const response = await api.put(`bankSlipConfig/${id}`, config)
    return response.data

  } catch (err) {
    throw err
  }
}

export default {
  getAll,
  create,
  update,
} 