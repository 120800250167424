import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import ProductTypeForm from './ProductType'
import BreadCrumb from '../../components/BreadCrumb'
import productTypesRepository from '../../../../repositories/ProductTypes'

function BundleFormProductType({ match }) {
  const [loading, setLoading] = useState(false)
  const [productTypeId, setProductTypeId] = useState(null)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if(!!match.params.id) {
      setProductTypeId(match.params.id)
    }
  }, [])
  
  useEffect(() =>{
    if(!productTypeId) {
      loadLastCode()
    } else {
      loadProductType()
    }
  }, [productTypeId])

  async function loadLastCode() {
    try {
      const code = await productTypesRepository.getLastCode()

      dispatch(change('productType', 'code', code))
    } catch(err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao buscar o código do tipo de produto. Por favor, tente novamente')
    }
  }

  async function loadProductType() {
    setLoading(true)

    try {
      const productType = await productTypesRepository.getById(productTypeId)

      dispatch([
        change('productType', 'code', productType.code),
        change('productType', 'description', productType.description)
      ])

    } catch(err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao carregar o tipo de produto')
    } finally {
      setLoading(false)
    }
  }

  function handleCancel() {
    history.push('product-types')
  }

  function handleSubmit(values) {
    if(!productTypeId) {
      create(values)
    } else {
      update(values)
    }
  }

  async function create(values) {
    const { code, description } = values

    setLoading(true)

    try {
      await productTypesRepository.create({
        code,
        description
      })

      toastr.success('Tipo de Produto cadastrado com sucesso.')
      history.push('product-types')

    } catch(err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao salvar o tipo de produto. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  async function update(values) {
    const { code, description } = values

    setLoading(true)

    try {
      await productTypesRepository.update(productTypeId, {
        code,
        description
      })

      toastr.success('Tipo de Produto atualizada com sucesso.')
      history.push('product-types')

    } catch(err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao atualizar o tipo de produto. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <BreadCrumb path={['home', 'product-types', null]} data={['Início', 'Tipos de Produto', !productTypeId ? 'Novo Tipo de Produto' : 'Editar Tipo de Produto' ]} />
     
      <ProductTypeForm
        productTypeId={productTypeId} 
        onSubmit={handleSubmit} 
        onCancel={handleCancel}
        loading={loading}
      />
    </>
  )
}

export default BundleFormProductType