import React from 'react';
import "./styles.css";
import ButtonWhatsappColumn from '../../components/ButtonWhatsappColumn'
import ResendForm from '../../components/ResendBankSlipForm'


function ResendMobile() {
  return (
    <>
      <div id='resend-page'>
        <div className="resend-page">
          <h1>Segunda via de Boleto</h1>
          <h2>Preencha abaixo seu cpf</h2>
        </div>
        <div className='resend-mobile-form'>
          <ResendForm />
        </div>

        {!window.location.host.includes('21200') && !window.location.host.includes('99') ?
          <ButtonWhatsappColumn />
          :
          null
        }
      </div>
    </>
  )
}

export default ResendMobile;