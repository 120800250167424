import React, { useEffect, useState } from 'react';
import "./styles.css";
import { useDispatch, useSelector } from 'react-redux'
import { removeItemCart, handleQuantity, handleChange, addCart } from '../../../../store/actions/cart'
import { useParams } from "react-router-dom";
import productRepository from '../../../../repositories/Products';
import { withRouter, useHistory } from 'react-router-dom'
import { clearProductTypesFilter, clearVehicleTypesFilter, changeValue, fetchProducts } from '../../../../store/actions/list'
import ClientData from '../../pages/Items/steps/ClientData'
import PurchaseFooter from "../Items/PurchaseFooter";
import { Step } from '@material-ui/core';
import { isMobile } from '../../../../utils/isMobile';
import ModalProductsAlert from '../../../Store/components/ModalProductsAlert'

function ProductBuyInformation({ information }) {
    const history = useHistory()
    const { id } = useParams();
    const { listReducer, cartReducer } = useSelector(state => state)
    const { productTypes, vehicleTypes } = listReducer
    const { selectedProducts } = cartReducer
    const dispatch = useDispatch()

    const [value, setValue] = useState()
    const [productSelect, setProductSelect] = useState(false)
    const [mobile, setMobile] = useState(false)
    const [openAlertModal, setOpenAlertModal] = useState(false)
    const [vehicleType, setVehicleType] = useState(0)


    useEffect(() => {
        isMobile()
        const mobileAcess = isMobile()
        if (!mobileAcess) {
          setMobile(false)  
        } else {
          setMobile(true)
        }
      }, []);

    async function changeQuantity(value) {

        if (!productSelect) {

            dispatch(addCart(information, selectedProducts))
            setProductSelect(true)
        }

        if (selectedProducts) {
            dispatch(handleQuantity((value), selectedProducts[0], selectedProducts))
        }
    }

    const handleBuyItem = () => {
        if (selectedProducts != 0) {
           if(information?.vehicleTypeId !== selectedProducts[0].vehicleTypeId){ 
                handleModalMessage(information?.vehicleTypeId)
            } else {
                dispatch([
                    clearProductTypesFilter(productTypes),
                    clearVehicleTypesFilter(vehicleTypes),
                    changeValue('', 'search'),
                    dispatch(handleChange(2, 'step'))
                ])
                history.push('/store/items')
            }
        } else {
            changeQuantity(information?.minimumQuantity)
            dispatch([
                clearProductTypesFilter(productTypes),
                clearVehicleTypesFilter(vehicleTypes),
                changeValue('', 'search'),
                dispatch(handleChange(2, 'step'))
            ])
            history.push('/store/items')
        }
    }

    const handleModalClose = () => {
        setOpenAlertModal(false)
    }
    
    const handleModalMessage = (vehicleTypeId) => {
        if(vehicleTypeId === 1){
            setVehicleType(1)
            setOpenAlertModal(true)
        } else {
            setVehicleType(2)
            setOpenAlertModal(true)
        }
    } 


    return (
        <>
            <div className="productDetailsBuyInformation">

                <div className="InfosProductDetailsBuyInformation">
                    {!mobile?
                    <h1>{information?.description}</h1>
                    :
                    null
                }
                    <h2>{'R$ ' + information?.price.replace('.', ',')}</h2>
                    <h3>{information?.complementaryDescription}</h3>
                </div>
                <div className="select">
                    <h1>Quantidade:</h1>
                    < select
                        className='form-control foco-input'
                        name="select-items"
                        id="select-items"
                        value={value}
                        onChange={value => changeQuantity(value?.target.value)}>
                        {Array(1 + ([information?.maximumQuantity] - [information?.minimumQuantity])).fill().map((_, i) => i).map(value => {
                            return <option value={value + information?.minimumQuantity}>{value + information?.minimumQuantity} </option>
                        })}
                    </select>
                </div>
                <div className="buttonBuy">
                    <button onClick={() => handleBuyItem()}>Comprar Agora</button>
                </div>

            </div>
            
            { openAlertModal ? 
            <ModalProductsAlert vehicleType={vehicleType} onClose={handleModalClose} />
            :
            null
            }
        </>
    );
}

export default ProductBuyInformation;