import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import ReactTable from 'react-table-6';
import bankAccountRepository from '../../../../repositories/BankAccount'
import { Modal } from 'react-bootstrap';
import AlertModal from '../../../../components/AlertModal'
import { useHistory, Link, withRouter } from 'react-router-dom';
import InfoModal from '../../components/InfoModal'

const BankAccounts = ({ }) => {
  const [loading, setLoading] = useState(false)
  const [isBankAccountDetailsModalOpen, setIsBankAccountDetailsModalOpen] = useState(false)
  const [bankAccounts, setBankAccounts] = useState([])
  const [bankAccount, setBankAccount] = useState([])
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [bankAccountInfo, setBankAccountInfo] = useState([])



  const history = useHistory()

  useEffect(() => {
    loadBankAccounts()
    document.title = 'Rclub - Contas'
  }, [])

  const loadBankAccounts = async () => {
    setLoading(true)

    try {
      const responseBankAccount = await bankAccountRepository.getAll()

      setBankAccounts(responseBankAccount)

    } catch (err) {
      toastr.error('Ocorreu um erro ao carregar os cedentes. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenBankAccountDetailsModal = bankAccount => {
    setBankAccount([bankAccount])
    setIsBankAccountDetailsModalOpen(true)
  }

  const handleOpenBankAccountExcludeModal = bankAccount => {
    setBankAccountInfo(bankAccount)
    setIsCancelModalOpen(true)
  }

  const handleExcludeBankAccount = async () => {
    setIsCancelModalOpen(false)
    setLoading(true)

    try {

      const response = await bankAccountRepository.exclude({
        accountId: bankAccountInfo.id,
        registerNumber: bankAccountInfo.registerNumber
      })
      if (response.status === 'sucesso') {
        toastr.success('Conta excluida com sucesso.')
        loadBankAccounts()
      }

    } catch (err) {
      toastr.warning('Ocorreu um erro ao excluir conta. ' + err.response.data.dados.mensagem)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='admin-page-bank-account'>
      <header>
        <BreadCrumb path={['home', null]} data={['Início', 'Contas']} />
        <section>
          <button className='btn btn-success' onClick={() => history.push('createAccount')}>+ Nova Conta</button>
          {/* <div>
            <FontAwesomeIcon icon={faSearch} />
            <input
              className='form-control foco-input'
              title='Pesquisar CNPJ ou Razão Social'
              placeholder='Pesquisar por CNPJ ou Razão Social'
              type='text'
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </div> */}
        </section>
      </header>

      <br />
      <ReactTable
        style={{ textAlign: 'center' }}
        data={bankAccounts}
        columns={[
          {
            Header: 'Cód.',
            accessor: 'id',
            Cell: props => props.value ? Number(props.value) : "---"
          },
          {
            Header: 'Cedente',
            accessor: 'assignor',
            Cell: props => props.value ? props.value : "---"
          },
          {
            Header: 'Data Cadastro',
            accessor: 'createdAt',
            Cell: props => props.value ? <>{new Date(props.value).toLocaleDateString()}</> : "---"
          },
          {
            Header: 'Banco',
            accessor: 'bankName',
            Cell: props => props.value ? props.value : "---"
          },
          {
            Header: 'Agência',
            accessor: 'bankBranch',
            Cell: props => props.value ? props.value : "---"
          },
          {
            Header: 'Conta',
            accessor: 'accountNumber',
            Cell: props => props.value ? props.value : "---"
          },
          {
            Header: 'Cód. Beneficiário',
            accessor: 'accountBeneficiaryCode',
            Cell: props => props.value ? Number(props.value) : "---"
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: props => (
              Boolean(props.value)
                ? <button className='btn btn-success'>Ativo</button>
                : <button className='btn btn-danger'>Inativo</button>
            )
          },
          {
            Header: 'Detalhes',
            accessor: 'id',
            Cell: props => (
              <>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  cursor='pointer'
                  onClick={() => handleOpenBankAccountDetailsModal(props.original)}
                  color='black'
                />
                <Link to={{ pathname: `editeAccount#${props.value}` }}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    cursor='pointer'
                    color='black'
                    style={{ marginLeft: 5 }}
                  />
                </Link>
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  cursor={'pointer'}
                  color='red'
                  style={{ marginLeft: 5 }}
                  onClick={() => handleOpenBankAccountExcludeModal(props.original)}
                />
              </>
            )
          }
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        defaultSorted={[{
          id: 'code',
          desc: false,
        }]}
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />

      {isBankAccountDetailsModalOpen &&
        (<Modal show={isBankAccountDetailsModalOpen} onHide={() => setIsBankAccountDetailsModalOpen(false)} animation={true} >
          <Modal.Header closeButton id="assignor-details-modal-button">
            <div className='bank-account-details-modal-header'>
              <h6><strong>Cód.: </strong>{bankAccount[0].id}</h6>
              <h6><strong>Cedente: </strong>{bankAccount[0].assignor}</h6>
              <h6><strong>Data Cadastro: </strong>{new Date(bankAccount[0].createdAt).toLocaleDateString()}</h6>
              <h6><strong>Cód. Banco: </strong>{bankAccount[0].bankCode}</h6>
              <h6><strong>Banco: </strong>{bankAccount[0].bankName}</h6>
              <h6><strong>Agência: </strong>{bankAccount[0].bankBranch}</h6>
              <h6><strong>Conta: </strong>{bankAccount[0].accountNumber}</h6>
              <h6><strong>Cód. Beneficiário: </strong>{bankAccount[0].accountBeneficiaryCode}</h6>
            </div>
          </Modal.Header>
        </Modal>
        )}
      {
        isCancelModalOpen &&
        <AlertModal
          show={isCancelModalOpen}
          message='Deseja excluir a Conta?'
          onHide={() => setIsCancelModalOpen(false)}
          onCancel={() => setIsCancelModalOpen(false)}
          onSubmit={handleExcludeBankAccount}
        />
      }
    </div>
  )
}

export default withRouter(BankAccounts)