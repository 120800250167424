import React, { useState, useEffect } from 'react';
import "./styles.css";
import './styles99.css';

function CardPageImgLeft({ img, title, text }) {
  const [classes, setClasses] = useState(null)

  useEffect(() => {
    loadClasses()
  }, [])

  const loadClasses = () => {
    if (!window.location.host.includes('21200') && !window.location.host.includes('99')) {
      setClasses("body-card-left")
    } else {
      setClasses("body-card-left-99")
    }
  }

  return (
    <div className="card-page-img-left-container">
      <div className="img-card-left" style={{ backgroundImage: `url(${img})`, backgroundSize: '100% 100%', overflow: 'hidden', backgroundRepeat: 'no-repeat' }}>
      </div>
      <div className={classes}>
        <h1>{title}</h1>
        <h2>{text}</h2>
      </div>
    </div>
  );
}

export default CardPageImgLeft;