import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import BreadCrumb from '../../components/BreadCrumb'
import stockEntriesRepository from '../../../../repositories/StockEntries'
import AlertModal from '../../../../components/AlertModal'

import StockEntryForm from './StockEntry'

function BundleFormStockEntry({ match }) {
  const [loading, setLoading] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [stockEntryId, setStockEntryId] = useState(null)
  const [valuesToCreate, setValuesToCreate] = useState({})

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!match.params.id) {
      setStockEntryId(match.params.id)
    }
  }, [])

  useEffect(() => {
    if (!!stockEntryId) {
      loadStockEntry()
      document.title = 'Rclub - Visualizar Entrada'
    } else {
      document.title = 'Rclub - Nova Entrada'
    }
  }, [stockEntryId])

  async function loadStockEntry() {
    setLoading(true)

    try {
      const stockEntry = await stockEntriesRepository.getById(stockEntryId)

      const company = stockEntry.Companies
      const stockEntryItems = stockEntry.StockEntryItems.map(item => ({
        id: item.Products.id,
        quantity: item.quantity,
        description: item.Products.description,
        code: item.Products.code,
        ProductTypes: {
          description: item.Products.ProductTypes.description
        },
        VehicleTypes: {
          description: item.Products.VehicleTypes.description
        },
      }))

      dispatch([
        change('stockentry', 'companyName', company.companyName),
        change('stockentry', 'companyCnpj', company.cnpj),
        change('stockentry', 'companyCity', company.city),
        change('stockentry', 'companyState', company.state),
        change('stockentry', 'stockItems', stockEntryItems),
        change('stockentry', 'visualizationMode', true),
      ])

    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao carregar a entrada de estoque. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  function handleCancel() {

    if(!!stockEntryId) {
      return history.push('stockentries')
    }

    setCancelModal(true)
  }

  function handleSubmit(values) {

    if (!values.stockItems.length) {
      return toastr.warning('Insira ao menos um item de entrada')
    }

    const valuesToCreate = {
      stockEntryItems: values.stockItems.map(item => ({
        id: item.id,
        quantity: item.quantity
      })),
      companyId: values.companyId
    }

    setValuesToCreate(valuesToCreate)
    setConfirmModal(true)
  }

  async function create() {
    setConfirmModal(false)
    setLoading(true)

    try {
      await stockEntriesRepository.create({
        ...valuesToCreate,
        date: new Date()
      })


      toastr.success('Entrada confirmada com sucesso.')
      history.push('stockentries')
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao gravar a entrada de estoque. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const initialValues = {
    stockItems: []
  }

  return (
    <>
      <BreadCrumb path={['home', 'stockentries', null]} data={['Início', 'Entradas', !stockEntryId ? 'Nova Entrada' : 'Visualizar Entrada']} />

      <StockEntryForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        loading={loading}
        initialValues={initialValues}
      />

      {
        cancelModal && (
          <AlertModal
            show={cancelModal}
            animation
            message='Deseja realmente cancelar a entrada de produtos ?'
            onCancel={() => setCancelModal(false)}
            onHide={() => setCancelModal(false)}
            onSubmit={() => history.push('stockentries')}
          />
        )
      }

      {
        confirmModal && (
          <AlertModal
            show={confirmModal}
            animation
            message='Deseja confirmar  a entrada de produtos ?'
            onCancel={() => setConfirmModal(false)}
            onHide={() => setConfirmModal(false)}
            onSubmit={create}
          />
        )
      }
    </>
  )
}

export default BundleFormStockEntry