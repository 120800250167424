import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { Col, Modal, Row, Spinner } from 'react-bootstrap'

import { handleChange } from '../../../../../../store/actions/cart'
import { cpfMask, phoneMask, cepMask, onlyNumbers } from '../../../../../../utils/normalize'
import companiesRepository from '../../../../../../repositories/Companies'
import contactsRepository from '../../../../../../repositories/Contacts'
import getAddressByCep from '../../../../../../utils/viaCep'

import Card from '../../../../components/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faHourglassHalf } from '@fortawesome/free-solid-svg-icons'
import ModalAddressListCompanies from '../../../../components/ModalAddressListCompanies'
import { validateCpf } from '../../../../../../utils/validation'

const ClientData = () => {
  const [isNoCompaniesAtCityModalOpen, setIsNoCompaniesAtCityModalOpen] = useState(false)
  const { cartReducer } = useSelector(state => state)
  const { client, companyIdAtSelectedCity, companiesAtSelectedCity, bankSlipAddress, selectedProducts, checkCompanyStockLoading } = cartReducer
  const { cpf, name, email, phone, zipCode, city } = client
  const { postalCode, bankSlipAddressInfo, bankSlipNeighborhood, bankSlipState, bankSlipCity } = bankSlipAddress
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [byCity, setByCity] = useState(false)
  const [isLoadingCity, setIsLoadingCity] = useState(false)
  const [cpfNotEmpty, setCpfNotEmpty] = useState(false)
  const [isInvalidCpf, setIsInvalidCpf] = useState(false)
  const [nameNotEmpty, setNameNotEmpty] = useState(false)
  const [emailNotEmpty, setEmailNotEmpty] = useState(false)
  const [phoneNotEmpty, setPhoneNotEmpty] = useState(false)
  const [zipCodeNotEmpty, setZipCodeNotEmpty] = useState(false)
  const [cityNotEmpty, setCityNotEmpty] = useState(false)
  const [listCitys, setListCitys] = useState([])
  const [isOpenListCompanies, setIsOpenListCompanies] = useState(false)
  const [listCompanies, setListCompanies] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    window.document.title = 'Rclub - Dados Pessoais'
  }, [])

  useEffect(() => {
    getCitys()
  }, [])

  async function getCitys() {
    let obj = {
      "vehicleTypeId": selectedProducts[0].vehicleTypeId
    }
    const citys = await companiesRepository.findByTypeProduct(obj)
    setListCitys(citys)
  }

  async function getCompanies() {


  }

  async function handleSelectCompaniesByCity() {
    if (!cpf || !name || !email || !phone || !zipCode || Boolean(isInvalidCpf)) {
      handleValidationFields()
      return
    }

    // if (!!cpf) {
    //   handleValidCpf()
    // }

    // if (Boolean(isInvalidCpf)) {
    //   return
    // }

    if (!zipCode) {
      return toastr.warning('Por favor, informe o CEP para prosseguir.')
    }

    const cep = onlyNumbers(zipCode)

    if (cep.length < 8 || cep === '111111111') {
      return toastr.warning('Por favor, informe um CEP válido para prosseguir.')
    }

    setIsLoadingCity(true)

    try {
      const addressData = await getAddressByCep(cep)
      const newClientData = {
        ...client,
        ...addressData
      }

      const bankSlipAddress = {
        postalCode: cep,
        bankSlipAddressInfo: addressData.address,
        bankSlipNeighborhood: addressData.neighborhood,
        bankSlipState: addressData.state,
        bankSlipCity: addressData.city
      }

      await dispatch(handleChange(newClientData, 'client'))
      await dispatch(handleChange(bankSlipAddress, 'bankSlipAddress'))

      getAllCompaniesWithDistance(addressData.address, addressData.city, addressData.state, newClientData)

    } catch (err) {
      toastr.warning(err.message)
      setIsLoadingCity(false)
    }
  }
  async function getAllCompaniesWithDistance(address, city, state, client) {
    try {
      const allCompanies = await companiesRepository.getAllActiveCompanies(city)
      if (!allCompanies.length) {
        try {
          const fullAddress = address + ', ' + city + '-' + state
          const companies = await companiesRepository.getAllWithDistanceByCity(fullAddress, city, selectedProducts[0].vehicleTypeId)
          dispatch(handleChange(companies, 'companiesAtSelectedCity'))
          setIsLoadingCity(false)
          setByCity(true)
          setIsModalOpen(true)

        } catch (err) {
        }
        return createContactWithNoCompaniesInCity(fullAddress, client)
      } else {
        createContact(client)
      }
      const fullAddress = address + ', ' + city + '-' + state
      const companies = await companiesRepository.getAllWithDistanceByCity(fullAddress, city, selectedProducts[0].vehicleTypeId)
      if (companies.length) {
        setIsLoadingCity(false)
        setByCity(true)
        setIsModalOpen(true)
        dispatch(handleChange(companies, 'companiesAtSelectedCity'))
      } else {
        return createContactWithNoCompaniesInCity(fullAddress, client)
      }
    } catch (err) {
      toastr.warning('Ocorreu um erro ao buscar as empresas. Por favor, tente novamente')
    }
  }

  async function createContactWithNoCompaniesInCity(address, client) {
    try {
      await contactsRepository.create({
        ...client,
        cpf: onlyNumbers(cpf),
        phone: onlyNumbers(phone),
        zipCode: onlyNumbers(zipCode),
      })

    } catch (err) {
      console.log(err)

    } finally {
      //setIsNoCompaniesAtCityModalOpen(true)
      validation100km(address)
    }
  }
  async function validation100km(fullAddress) {
    const companies = await companiesRepository.getAllWithDistance(fullAddress, selectedProducts[0].vehicleTypeId)
    if (companies.length) {
      let count = 0
      let i = 0
      for (i in companies) {
        if (companies[i].distance <= 100000) {
          count = count + 1
        }
      }
      if (count >= 1) {
        dispatch(handleChange(companies, 'companiesAtSelectedCity'))
        setIsLoadingCity(false)
        setByCity(false)
        setIsModalOpen(true)
      } else {
        setIsLoadingCity(false)
        setIsNoCompaniesAtCityModalOpen(true)
      }
    } else {
      setIsLoadingCity(false)
      setIsNoCompaniesAtCityModalOpen(true)
    }
  }

  async function createContact(client) {
    try {
      await contactsRepository.create({
        ...client,
        cpf: onlyNumbers(cpf),
        phone: onlyNumbers(phone),
        zipCode: onlyNumbers(zipCode),
      })
    } catch (err) {
      console.log(err)
    }
  }

  function handleChangeClientValue(e) {
    const newClientData = {
      ...client,
      [e.target.name]: e.target.value
    }
    dispatch(handleChange(newClientData, 'client'))
  }

  function handleChangeSelectedCompany(e) {
    const selectedCompanyId = e.target.value

    const company = companiesAtSelectedCity.find(company => Number(company.id) === Number(selectedCompanyId))

    dispatch([
      handleChange(e.target.value, 'companyIdAtSelectedCity'),
      handleChange(company, 'companySelected')
    ])
  }

  function handleCpfNotEmpty() {
    if (!cpf) {
      setCpfNotEmpty(true)
    } else {
      setCpfNotEmpty(false)
      handleValidCpf()
    }
  }

  function handleValidCpf() {
    if (cpf) {
      let validCpf = validateCpf(cpf.replace(/([.-])/g, ""))
      if (Boolean(validCpf)) {
        setIsInvalidCpf(false)
      }
      else {
        setIsInvalidCpf(true)
      }
    }
  }

  function handleNameNotEmpty() {
    if (!name) {
      setNameNotEmpty(true)
    } else {
      setNameNotEmpty(false)
    }
  }

  function handleEmailNotEmpty() {
    if (!email) {
      setEmailNotEmpty(true)
    } else {
      setEmailNotEmpty(false)
    }
  }

  function handlePhoneNotEmpty() {
    if (!phone) {
      setPhoneNotEmpty(true)
    } else {
      setPhoneNotEmpty(false)
    }
  }

  function handleZipCodeNotEmpty() {
    if (!zipCode) {
      setZipCodeNotEmpty(true)
    } else {
      setZipCodeNotEmpty(false)
    }
  }

  function handleCityNotEmpty() {
    if (!city) {
      setCityNotEmpty(true)
    } else {
      setCityNotEmpty(false)
    }
  }

  function handleValidationFields() {
    handleCpfNotEmpty()
    handleNameNotEmpty()
    handleEmailNotEmpty()
    handlePhoneNotEmpty()
    handleZipCodeNotEmpty()
    handleValidCpf()
    handleCityNotEmpty()
  }

  async function handleOpenListCompanies() {
    setIsLoadingCity(true)
    let obj = {
      "vehicleTypeId": selectedProducts[0].vehicleTypeId
    }
    try {
      const companies = await companiesRepository.findCompaniesByTypeProduct(obj)
      setIsOpenListCompanies(true);
      setListCompanies(companies);
      setIsLoadingCity(false);
    } catch (error) {
      setIsLoadingCity(false);
      toastr.warning('Ocorreu um erro ao buscar as empresas. Por favor, tente novamente');
    }
  }


  return (
    <div id='client-wrapper'>
      <Card title='Dados Pessoais'>
        <Row>
          <Col xs={12} sm={12} md={3} lg={3}>
            <label>CPF</label>
            <input
              className='form-control foco-input'
              style={cpfNotEmpty || isInvalidCpf ? { borderColor: 'red' } : { borderColor: '#ced4da' }}
              type="text"
              name='cpf'
              value={cpfMask(onlyNumbers(cpf))}
              onChange={handleChangeClientValue}
              onBlur={handleCpfNotEmpty}
              maxLength={14}
            />
            {
              cpfNotEmpty && (
                <label style={{ color: 'red' }}>O campo CPF é obrigatório</label>
              )
            }
            {
              isInvalidCpf && (
                <label style={{ color: 'red' }}>CPF inválido, verifique</label>
              )
            }
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={4} lg={4}>
            <label>Nome</label>
            <input
              className='form-control foco-input'
              style={nameNotEmpty ? { borderColor: 'red' } : { borderColor: '#ced4da' }}
              type="text"
              name='name'
              value={name}
              onChange={handleChangeClientValue}
              onBlur={handleNameNotEmpty}
            />
            {
              nameNotEmpty && (
                <label style={{ color: 'red' }}>O campo Nome é obrigatório</label>
              )
            }
          </Col>

          <Col xs={12} sm={12} md={4} lg={4}>
            <label>E-mail</label>
            <input
              className='form-control foco-input'
              style={emailNotEmpty ? { borderColor: 'red' } : { borderColor: '#ced4da' }}
              type="text"
              name='email'
              value={email}
              maxLength='50'
              onChange={handleChangeClientValue}
              onBlur={handleEmailNotEmpty}
            />
            {
              emailNotEmpty && (
                <label style={{ color: 'red' }}>O campo E-mail é obrigatório</label>
              )
            }
          </Col>

          <Col xs={12} sm={12} md={3} lg={3}>
            <label>Celular</label>
            <input
              className='form-control foco-input'
              style={phoneNotEmpty ? { borderColor: 'red' } : { borderColor: '#ced4da' }}
              type="text"
              name='phone'
              value={phoneMask(phone)}
              onChange={handleChangeClientValue}
              onBlur={handlePhoneNotEmpty}
            />
            {
              phoneNotEmpty && (
                <label style={{ color: 'red' }}>O campo Celular é obrigatório</label>
              )
            }
          </Col>
        </Row>

        {/* <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <label>Informe o CEP para escolher o local de retirada</label>
            <Col xs={12} sm={12} md={6} lg={4} style={{ padding: 0, minWidth: '290px' }}>
              <div className="input-group">
                <input
                  className='form-control foco-input'
                  style={zipCodeNotEmpty ? { borderColor: 'red' } : { borderColor: '#ced4da' }}
                  type="text"
                  name='zipCode'
                  value={cepMask(zipCode)}
                  onChange={handleChangeClientValue}
                  onBlur={handleZipCodeNotEmpty}
                />
                <div className="input-group-append">
                  <button onClick={() => handleSelectCompaniesByCity()} type='button' className="input-group-text">Ok</button>
                </div>
                <div className='dont-know-zipcode'>
                  <a href='http://www.buscacep.correios.com.br/sistemas/buscacep/' rel="noopener noreferrer" target='_blank'>Não sei o CEP <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                </div>
              </div>
              {
                zipCodeNotEmpty && (
                  <label style={{ color: 'red' }}>O campo CEP é obrigatório</label>
                )
              }
            </Col>
          </Col>
        </Row> */}
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <label>Selecione qual cidade deseja retirar o produto/serviço</label>
            <Col xs={12} sm={12} md={6} lg={4} style={{ padding: 0, minWidth: '290px' }}>
              <div className="input-group">
                <select
                  className='form-control foco-input'
                  style={cityNotEmpty ? { borderColor: 'red' } : { borderColor: '#ced4da' }}
                  type="text"
                  name='city'
                  value={city}
                  onChange={handleChangeClientValue}
                  onBlur={handleCityNotEmpty}
                >
                  <option disabled="true" value="">Selecione</option>
                  {listCitys.map((companie, key) => {
                    return <option key={key} value={companie.city}>{companie.city}</option>
                  })}
                </select>
              </div>
              {
                cityNotEmpty && (
                  <label style={{ color: 'red' }}>O campo da cidade é obrigatório</label>
                )
              }
            </Col>
          </Col>
        </Row>

        {/* <Row>
          <Col xs={12} sm={12} md={9} lg={9}>
            <label>Onde deseja retirar o produto ?</label>
            <select
              name='companyIdAtSelectedCity'
              className='form-control foco-input'
              value={companyIdAtSelectedCity}
              defaultValue=''
              onChange={handleChangeSelectedCompany}
              disabled={!companiesAtSelectedCity.length}
            >
              <option value=''>Selecione</option>
              {companiesAtSelectedCity.map(company => {
                return <option key={company.id} value={company.id}>{`${company.tradingName} - ${company.address}, ${company.addressNumber} - ${company.city}/${company.state}`} </option>
              })}
            </select>
          </Col>
        </Row> */}
        <Row>
          <Col xs={12} sm={12} md={12} lg={10}>
            <button style={{paddingLeft: '0'}} className="btn btn-link" onClick={() => handleOpenListCompanies()}>Ver todos os pontos de retirada</button>
          </Col>
        </Row>
      </Card>

      <Modal style={{ marginTop: "10%" }} show={isNoCompaniesAtCityModalOpen} onHide={() => setIsNoCompaniesAtCityModalOpen(false)} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>RClub</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <center>
            :( Ops, infelizmente o RCLUB ainda não chegou na sua região, em breve teremos locais de retirada em {client.city}.
            <br />
            <br />
            <strong>Cadastre-se</strong> gratuitamente e sem custos, recebe sempre em primeira mão, dicas, avisos e promoções.
          </center>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center", width: '100%' }}>
            <button className='btn btn-success' name="Sim" style={{ width: "100%" }} onClick={() => window.location.pathname = 'store/register'}>Cadastre-se</button>
          </div>
        </Modal.Footer>
      </Modal>
      {isModalOpen ?
        <ModalAddressListCompanies list={companiesAtSelectedCity} onClose={() => { setIsModalOpen(false) }} city={byCity} onOpenModal={() => { setIsNoCompaniesAtCityModalOpen(true) }} />
        :
        null
      }
      {
        isLoadingCity ?
          <div className="div-loading">
            <Spinner animation="border" role="status" variant="success" />
            <FontAwesomeIcon icon={faHourglassHalf} color='green' className="animated-money bounce-money" />
          </div>
          :
          null
      }

      <Modal style={{}} show={isOpenListCompanies} onHide={() => setIsOpenListCompanies(false)} animation={true}>
        <Modal.Header closeButton={() => setIsOpenListCompanies(false)}>
          <Modal.Title>
            <strong>RCLUB</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <span style={{ width: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '4%', fontSize: '18px' }}>Veja abaixo os locais de retirada</span>
            {listCompanies.map((company, index) => {
              return (
                <div className="list-company-withdraw">
                  <span><strong>{company?.tradingName}</strong></span>
                  <span>{company?.address}, {company?.addressNumber} - {company?.city}/{company?.state}</span>
                  {/* <span style={{ color: '#5FAAF9' }}>O local está {convertToKm(company.distance)} km de você</span> */}
                </div>
              )
            }
            )}
        </Modal.Body>
        <Modal.Footer style={{borderTop: 'none', paddingTop:'0'}}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center", width: '100%' }}>
            <button className='btn btn-danger' name="Sim" style={{ width: "100%" }} onClick={() => setIsOpenListCompanies(false)}>Voltar</button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ClientData