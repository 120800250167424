import React from 'react';
import "./styles.css";


function SubHeader({ title, subTitle, position, img }) {
    return (
        <>
            {position === 'left' ?

                <div className="subheader-left">
                    <img
                        src={img}
                        width='100%'
                        height={250}
                    ></img>
                    <div className="text-subHeader-left">
                        <h1 >{title}</h1>
                        <h2 >{subTitle}</h2>
                    </div>
                </div>
                :
                null
            }
            {position === 'center' ?

                <div className="subheader-center">
                    <img
                        src={img}
                        width='100%'
                        height={250}
                    ></img>
                    <div className="text-subHeader">
                        <h1 >{title}</h1>
                        <h2 >{subTitle}</h2>
                    </div>
                </div>
                :
                null
            }
            {position === 'right' ?

                <div className="subheader-right">
                    <img
                        src={img}
                        width='100%'
                        height={250}
                    ></img>
                    <div className="text-subHeader-right">
                        <h1 >{title}</h1>
                        <h2 >{subTitle}</h2>
                    </div>
                </div>
                :
                null
            }
        </>

    )
}
export default SubHeader