import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import './styles.css';

function SuccessPasswordRecovery(onClose) {
  const history = useHistory();

  return (
    <div>
      <div className='modal-alert-msg'>
        <Modal style={{ marginTop: "15%", display: 'flex', alignItems: 'center', justifyContent: 'center' }} show={true} onHide={() => history.push('/partners/login')} animation={true}  >
          <div class="modal-content" style={{}}>
            <Modal.Header closeButton style={{}}>
              <div id='company-details-modal-header' className='app-modal-title-font'>
                <h5><strong>RCLUB</strong></h5>
              </div>
            </Modal.Header >
            <Modal.Body style={{}}>
              <h1 className='app-modal-text-font'>Já enviamos a sua senha para o e-mail informado. Por favor, verifique sua caixa de entrada.</h1>
            </Modal.Body>
            <Modal.Footer>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <button className='btn btn-success' name="Sim" style={{ marginLeft: 5, background: '#00FF00', border: 'none' }} onClick={() => history.push('/partners/login')}>Ok</button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default SuccessPasswordRecovery;