import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux'

import { handleChange } from '../../../../store/actions/cart'

import "./styles.css";

function ModalAddressListCompanies({ list, onClose, city, onOpenModal }) {

  const { cartReducer } = useSelector(state => state)
  const { step, selectedProducts, subTotal, amount, client, discountValue, creditCard,
    companyIdAtSelectedCity, companiesAtSelectedCity, checkCompanyStockLoading, companySelected } = cartReducer
  const { cpf, name, email, phone, zipCode } = client
  const [locationClient, setLocationClient] = useState()
  const [latClient, setLatClient] = useState()
  const [lngClient, setLngClient] = useState()
  const [dist, setDist] = useState()
  const dispatch = useDispatch()

  function handleChangeSelectedCompany(companyparm) {
    const selectedCompanyId = companyparm.id

    const company = companiesAtSelectedCity.find(company => Number(company.id) === Number(selectedCompanyId))

    dispatch([
      handleChange(companyparm.id, 'companyIdAtSelectedCity'),
      handleChange(company, 'companySelected')
    ])
  }

  const nextPage = () => {
    dispatch(handleChange(step + 1, 'step'));
    onClose()
  }

  function convertToKm(value) {
    const InKm = Math.round(value / 1000)
    return InKm
  }
  return (
    <div>
      <div className='modal-alert-msg'>
        {city ? <Modal  show={true} onHide={() => onClose()} animation={true}  >
          <div class="modal-content" style={{}}>
            <Modal.Header closeButton style={{}}>
              <div id='company-details-modal-header'>
                <h5><strong>RCLUB</strong></h5>
              </div>
            </Modal.Header >
            <Modal.Body style={{}}>
              <span style={{ width: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '4%' }}>Selecione onde deseja retirar o produto</span>
              {list/*.sort((a, b) => a.distance > b.distance ? 1 : -1)*/.map((company, index) => {
                return <>
                  <div className="modal-list-company">
                    <div className="input-modal-list-company">
                      <input
                        type="radio"
                        name="company"
                        value={company}
                        onChange={() => handleChangeSelectedCompany(company)}
                      />
                    </div>

                    <div className="list-company">
                      <span><strong>{company?.tradingName}</strong></span>
                      <span>{company?.address}, {company?.addressNumber} - {company?.city}/{company?.state}</span>
                      {/* <span style={{ color: '#5FAAF9' }}>O local está {convertToKm(company.distance)} km de você</span> */}
                    </div>
                  </div>
                </>
              }
              )}
            </Modal.Body>
            <Modal.Footer >
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center", width: '100%' }}>
                <button className='btn btn-success' name="Sim" style={{ width: "100%" }} disabled={(!cpf || !name || !email || !phone || !companyIdAtSelectedCity) /*&& step !=1*/} onClick={() => nextPage()}>Avançar</button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
          :
          <Modal  show={true} onHide={() => onClose()} animation={true}  >
            <div class="modal-content" style={{}}>
              <Modal.Header closeButton style={{}}>
                <div id='company-details-modal-header'>
                  <h5><strong>RCLUB</strong></h5>
                </div>
              </Modal.Header >
              <Modal.Body style={{}}>
                <span style={{ width: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '4%' }}>Selecione onde deseja retirar o produto</span>
                {list.sort((a, b) => a.distance > b.distance ? 1 : -1).slice(0, 3).map((company, index) => {
                  return (
                    company.distance <= 100000 ?
                      <>
                        <div className="modal-list-company">
                          <div className="input-modal-list-company">
                            <input
                              type="radio"
                              name="company"
                              value={company}
                              onChange={() => handleChangeSelectedCompany(company)}
                            />
                          </div>

                          <div className="list-company">
                            <span><strong>{company?.tradingName}</strong></span>
                            <span>{company?.address}, {company?.addressNumber} - {company?.city}/{company?.state}</span>
                            <span style={{ color: '#5FAAF9' }}>O local está {convertToKm(company.distance)} km de você</span>
                          </div>
                        </div>
                      </>
                      :
                      //onClose(),
                      //onOpenModal()
                      null

                  )
                }
                )}
              </Modal.Body>
              <Modal.Footer >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center", width: '100%' }}>
                  <button className='btn btn-success' name="Sim" style={{ width: "100%" }} disabled={(!cpf || !name || !email || !phone || !companyIdAtSelectedCity) /*&& step !=1*/} onClick={() => nextPage()}>Avançar</button>
                </div>
              </Modal.Footer>
            </div>
          </Modal>
        }
      </div>
    </div>
  )
}

export default ModalAddressListCompanies;
