import React, { useState } from 'react';

import './styles.css'

import PopUpInputDesktop from '../PopUpInputDesktop'
import PopUpInputMobile from '../PopUpInputMobile'

function PopUpSubmit({ title }) {

  const [upload, setUpload] = useState(false) 

  function goSave() {
    setUpload(true)
  }

  return (
    <div className="imageContainerPopUp">
      <div className="title">
        <span className={`fa fa-angle-down`} aria-hidden="true" style={{ marginRight: 5 }} />
        <span>{title}</span>
      </div>
      <div className="submiteImagePopUp">

        <div className="submitImputPopUp">

          <PopUpInputDesktop upload={upload}/>

          <PopUpInputMobile upload={upload}/>

          <div className="buttonsImagePopUp">
            <button className="btn btn-success" onClick={() => goSave()}>Salvar</button>
          
          </div>
        </div>


      </div>


    </div>

  );
}

export default PopUpSubmit;