import api from '../services/api';

const recovery = async (email) => {
  try {
    const response = await api.post('email/recoveryPassword', {
      email: email
    })
    return response.data
  } catch (err) {
    throw err
  }
}

export default {
  recovery,
}