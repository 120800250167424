import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import './styles.css'

function QRCodeScanInvalid() {
  const history = useHistory();

  const handleNavigation = () => {
    history.push('/partners/checkin')
  }

  return (
    <div className='modal-success-container'>
      <div className='modal-success-contant'>
        <h1>Código Validado com sucesso.</h1>
        <h1>Este código já foi validado ou</h1>
        <h1>apresenta erro de leitura.</h1>
        <h1>Faça o Check-In manualmente.</h1>
        <FontAwesomeIcon color='red' size='10x' className='icon-button-modal' icon={faWindowClose} />
        <button onClick={() => handleNavigation()}>
          Check-In Manual
        </button>
      </div>
    </div>
  )
}

export default QRCodeScanInvalid;