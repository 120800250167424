import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import AssignorForm from './Assignor'
import BreadCrumb from '../../components/BreadCrumb'
import { onlyNumbers, cnpjMask, phoneMask, cepMask } from '../../../../utils/normalize'
import assignorsRepository from '../../../../repositories/Assignors'
import AlertModal from '../../../Store/components/ModalAlertMsg'


function BundleFormAssignor({ match }) {
  const [loading, setLoading] = useState(false)
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const [assignorId, setAssignorId] = useState(null)
  const [message, setMessage] = useState(null)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!match.params.id) {
      setAssignorId(match.params.id)
    }
  }, [])

  useEffect(() => {
    if (!!assignorId) {
      loadCompany()
    } else {
      document.title = 'Rclub - Novo Cedente'
    }
  }, [assignorId])

  async function loadCompany() {
    setLoading(true)

    try {
      const assignor = await assignorsRepository.getById(assignorId)

      dispatch([
        change('assignor', 'registerTypeId', assignor.registerTypeId),
        change('assignor', 'registerNumber', cnpjMask(assignor.registerNumber)),
        change('assignor', 'companyName', assignor.companyName),
        change('assignor', 'fantasyName', assignor.fantasyName),
        change('assignor', 'status', assignor.status),
        change('assignor', 'email', assignor.email),
        change('assignor', 'phone', phoneMask(assignor.phone)),
        change('assignor', 'zipCode', cepMask(assignor.zipCode)),
        change('assignor', 'address', assignor.address),
        change('assignor', 'number', assignor.number),
        change('assignor', 'complement', assignor.complement),
        change('assignor', 'neighborhood', assignor.neighborhood),
        change('assignor', 'state', assignor.state),
        change('assignor', 'city', assignor.city),
      ])

      document.title = `Rclub - ${assignor.fantasyName}`
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao carregar cedente')
    } finally {
      setLoading(false)
    }
  }

  function handleCancel() {
    history.push('configAssignors')
  }

  function handleSubmit(values) {
    if (!assignorId) {
      create(values)
    } else {
      update(values)
    }
  }

  async function create(values) {
    const { address, number, city, registerNumber, companyName, email, status, neighborhood, phone,
      state, fantasyName, zipCode, registerTypeId, complement } = values

    setLoading(true)

    try {
      await assignorsRepository.create({
        registerTypeId,
        address,
        number,
        city,
        registerNumber: onlyNumbers(registerNumber),
        companyName,
        email,
        status,
        neighborhood,
        phone: onlyNumbers(phone),
        state,
        zipCode: onlyNumbers(zipCode),
        fantasyName,
        complement,
      });

      toastr.success('Cedente cadastrado com sucesso.')
      history.push('configAssignors')

    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao salvar cedente. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  async function update(values) {
    const { address, number, city, registerNumber, companyName, email, status, neighborhood, phone,
      state, fantasyName, zipCode, registerTypeId, complement } = values

    setLoading(true)

    try {
      await assignorsRepository.update(assignorId,
        {
          registerTypeId,
          address,
          number,
          city,
          registerNumber: onlyNumbers(registerNumber),
          companyName,
          email,
          status,
          neighborhood,
          phone: onlyNumbers(phone),
          state,
          zipCode: onlyNumbers(zipCode),
          fantasyName,
          complement,
        })

      toastr.success('Cedente atualizado com sucesso.')
      history.push('configAssignors')

    } catch (err) {
      console.log(err);
      toastr.warning('Ocorreu um erro ao salvar cedente. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const initialValues = {
    zipCode: '',
    status: true,
    registerTypeId: 2
  }

  return (
    <>
      <BreadCrumb path={['home', 'configAssignors', null]} data={['Início', 'Cedentes', !assignorId ? 'Novo Cedente' : 'Editar Cedente']} />

      <AssignorForm
        initialValues={initialValues}
        assignorId={assignorId}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        loading={loading}
      />
      {isAlertModalOpen && (
        <AlertModal text={message} onClose={() => setIsAlertModalOpen(false)} />
      )
      }
    </>
  )
}

export default BundleFormAssignor