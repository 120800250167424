import React from 'react'
import { Modal } from 'react-bootstrap'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import './styles.css'
import { toastr } from 'react-redux-toastr'

const QrCodeInfoModal = ({ show, onHide, base64 }) => {

  const downloadBankSlip = async () => {
    if (base64) {
      downloadPDF(base64)
      function downloadPDF(bankSplipBase64) {

        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + bankSplipBase64;
        a.download = `Lista_Qr_Codes_Pedidos.pdf`;
        a.click();
        toastr.success('Downlaod do Boleto feito com sucesso.')
      }
    } else {
      toastr.error('Ocorreu um erro ao gerar o boleto. Por favor, tente novamente.')
    }
  }

  const handleQrCode = () => {
    var blob = base64toBlob(base64);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, "Lista_Qr_Codes_Pedidos.pdf");
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  }

  const base64toBlob = (base64Data) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
  }

  return (
    <>
      <Modal show={show} onHide={onHide} style={{ top: '25%' }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Rclub</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id='info-modal-options'>
          <div>
            <button className='btn btn-primary mobile' onClick={() => downloadBankSlip()} style={{ backgroundColor: '#008000' }}>
              <FontAwesomeIcon icon={faQrcode} />
              <span>DOWNLOAD QR-CODES</span>
            </button>
          </div>
          <div>
            <button className='btn btn-primary mobile' onClick={() => handleQrCode()} style={{ backgroundColor: '#008000' }}>
              <FontAwesomeIcon icon={faQrcode} />
              <span>MOSTRAR QR-CODES</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

QrCodeInfoModal.defaultProps = {
  onHide: () => { }
}

QrCodeInfoModal.propTypes = {
  show: propTypes.bool.isRequired,
  onHide: propTypes.func.isRequired,
  base64: propTypes.string.isRequired
}

export default QrCodeInfoModal