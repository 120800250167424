import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import 'react-credit-cards/es/styles-compiled.css';
import ReactPixel from 'react-facebook-pixel';
import QRCode from 'qrcode';
import pixRepository from '../../../../../../repositories/Pix';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastr } from "react-redux-toastr";
import { clearReducer } from '../../../../../../store/actions/cart'

import './styles.css';

const PixPayment = () => {
    const { cartReducer: { saleId } } = useSelector(state => state)
    const dispatch = useDispatch()
    const [qrCode, setQrCode] = useState('')
    const [sale, setSale] = useState('')

    useEffect(() => {
        ReactPixel.fbq('track', 'AddPaymentInfo');
        window.document.title = 'Rclub - Pagamento'
        getSale()

        return () => {
            dispatch(clearReducer())
          }
    }, [])

    async function getSale() {

        try {
            const saleResponse = await pixRepository.getSaleById(saleId);
            setSale(saleResponse)
            createQRCode(saleResponse.qrCodeImageText)
        } catch (error) {
            console.log(error)
        }
    }

    async function createQRCode(code) {
        try {
            let qrCode = await QRCode.toDataURL(code)
            setQrCode(qrCode)
        } catch (err) {
            console.error(err)
        }
    }

function copyText() {
    navigator.clipboard.writeText(sale.qrCodeImageText);
    toastr.success('Pix Copiado, faça o pagamento no aplicativo do banco');
}

    return (
        <div className='container-pix'>
            <div className='content-pix'>
                    <div className='text-pix'>
                        <h1>Digitalize o código PIX QR com seu telefone celular</h1>
                        <label>Você está tendo problemas para ler o código QR? Copie o código abaixo e insira-o manualmente.</label>
                    </div>
                    <div className='qrcode-pix'>
                        <img src={qrCode} />
                        <p className='qrcode-text'>{sale.qrCodeImageText}</p>
                        <button 
                        onClick={() => {copyText()}}>
                            <FontAwesomeIcon icon={faCopy} color='black' className="iconeMenu" aria-hidden="false" />
                        </button>
                    </div>
            </div>
        </div>
    )
}

export default PixPayment