import React from 'react';
import "./styles.css";

import about_Driver from '../../../../assets/img/about/about_Driver.png';
import about_Motorcycles from '../../../../assets/img/about/about_Motorcycles.png';
import about_Cars from '../../../../assets/img/about/about_Cars.png';

import ButtonWhatsappRow from '../../components/ButtonWhatsappRow'
import SeeOptions from '../../components/ButtonSeeOptions'
import CardPageImgLeft from '../../components/CardPageImgLeft'
import CardPageImgRight from '../../components/CardPageImgRight'

function AboutDesktop() {

  return (
    <>
      <div class='top-page'>
        <div class='top-page-content'>
          <p>O Clube de Reparação surgiu com o intuito de levar produtos direto de fábrica para os motociclistas.
          Sabemos que cuidar da moto é importante para você e não gastar muito e ainda ter acesso a
            produtos de extrema qualidade é melhor ainda.</p>
          <br />
          <p>Com uma compra simplificada, você faz o seu pedido online e retira quando quiser na loja mais
            próxima de você, sem interferir no seu dia a dia ou na sua rotina.</p>
          <SeeOptions />
        </div>
        <div className='about-desktop-images'>
          <CardPageImgLeft img={about_Driver} title='SEM CUSTO' text='Você não paga nada para fazer parte do RClub.' />
          <CardPageImgRight
            img={!window.location.host.includes('21200') && !window.location.host.includes('99') ? about_Motorcycles : about_Cars}
            title='PREÇO BAIXO SEMPRE'
            text='Aqui, preço baixo é todo o ano, é como uma oferta que nunca expira!' />
        </div>
      </div>
      <div className='about-wpp-desktop-footer'>
        {!window.location.host.includes('21200') && !window.location.host.includes('99') ?
          < ButtonWhatsappRow />
          :
          null
        }
      </div>
    </>
  )
}

export default AboutDesktop;