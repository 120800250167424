import React, { useState, useEffect } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { getCookie } from '../../../../utils/cookies';
import companyStockRepository from '../../../../repositories/CompanyStock'
import { toastr } from 'react-redux-toastr';
import { isAppLogged } from '../../../../utils/validation';
import LoadingScreen from '../../components/Loading';
import AlertModal from '../../../Store/components/ModalAlertMsg';

import './styles.css'

const CompanyDetails = () => {

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('');
  const [stockOverview, setStockOverview] = useState([])
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (isAppLogged()) {
      getCompanyStockOverview()
    }
  }, []);

  const getCompanyStockOverview = async () => {
    setLoading(true)
    try {
      let id = getCookie('APP_COMPANYID')
      const stockByCompany = await companyStockRepository.getCompanyStockOverview(id)
      setStockOverview(stockByCompany)
    } catch (err) {
      setMessage('Ocorreu um erro ao carregar as informações. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='stock-container'>
      <input
        placeholder='Buscar'
        value={search}
        onChange={value => setSearch(value.target.value)}
      />
      {
        (!!search) && !stockOverview.filter(x => x['description'].toString().toLowerCase().includes(search.toLowerCase())).length
          ? <center><strong>Produto não encontrado</strong></center>
          : (
            <>
              <div className='product-container'>
                {
                  stockOverview
                    .filter(x => x['description'].toString().toLowerCase().includes(search.toLowerCase()))
                    .map(product => (
                      <div className='product-content'>
                        <h1>{(product.description)}</h1>
                        <label>{product.soldAmount + ' produtos vendidos / ' + product.quantity + ' produtos em estoque'}</label>
                        <label>{product.checkins + ' produtos retirados / ' + product.soldAmount + ' produtos para retirar'}</label>
                      </div>
                    )
                    )}
              </div>
            </>
          )
      }
      {loading && (
        <LoadingScreen />
      )
      }
      {isAlertModalOpen && (
        <AlertModal text={message} onClose={e => setIsAlertModalOpen(false)} />
      )
      }
    </div>
  )
}

export default CompanyDetails