import React from 'react';
import "./styles.css";

function HowBuy() {
  return (
    <div className="how-buy-container">
      <di className="how-buy-title">
        <h1>Como comprar</h1>
      </di>
      <div className="how-buy-body">
        <h2>O RClub trabalha com diversas oficinas parceiras, é só selecionar seu óleo, o local onde
        você quer retirar e fazer a compra.
</h2>
      </div>
    </div>
  );
}

export default HowBuy;