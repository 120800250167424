import React, { memo } from 'react'
import Chart from "react-google-charts";

const LineChart = ({ data, isSalesSeparatedByMonth }) => {
  return (
    <div>
      <Chart
        width='100%'
        height='500px'
        style={{ margin: 0, border: 0, padding: 0, width: '100%', maxWidth: '700px' }}
        chartType="LineChart"
        data={data}
        chartLanguage="pt-br"
        options={{
          hAxis: {
            title: 'Tempo (dias)',
            
            format: isSalesSeparatedByMonth ? 'Mês ' : null,
          },
          vAxis: {
            title: 'Valor (R$)',
            format: 'currency'
          },          
        }}
      />
    </div>
  )
}

export default memo(LineChart)