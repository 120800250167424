import api from "../services/api";

const getAll = async () => {
  try {
    const response = await api.get(`publicityclicks`)
    return response.data
  } catch (err) {
    throw err
  }
}

const create = async (publicity) => {
  try {
    const response = await api.post('publicityclicks', publicity)
    return response.data
  } catch (err) {
    throw err
  }
}

export default {
  getAll,
  create,
}