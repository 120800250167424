import React from 'react';

import './styles.css';

function Header({title}) {
  return (
    <div className="header-container">
      <h1>{title}</h1>

    </div>
  );
}

export default Header;