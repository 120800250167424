import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faBarcode, faQrcode } from "@fortawesome/free-solid-svg-icons";

import Card from '../../../../components/Card'
import { handleChange } from '../../../../../../store/actions/cart'
import { toastr } from 'react-redux-toastr'

import '../../styles.css'

const PaymentMethods = () => {
  const dispatch = useDispatch();

  const { cartReducer } = useSelector(state => state)
  const { paymentMethod } = cartReducer
  const [creditCard, setCreditCard] = useState(false)
  const [debitCard, setDebitCard] = useState(false)
  const [bankSlip, setBankSlip] = useState(false)
  const [pix, setPix] = useState(false)

  useEffect(() => {
    window.document.title = 'Rclub - Meios de Pagamento'
    dispatch(handleChange('', 'paymentMethod'))
  }, [])

  useEffect(() => {
    if (creditCard) {
      setPaymentMethod()
      setBankSlip(false)
      setDebitCard(false)
      setPix(false)
    }

  }, [creditCard])

  useEffect(() => {
    if (bankSlip) {
      setPaymentMethod()
      setCreditCard(false)
      setDebitCard(false)
      setPix(false)
    }
  }, [bankSlip])

  useEffect(() => {
    if (debitCard) {
      setPaymentMethod()
      setBankSlip(false)
      setCreditCard(false)
      setPix(false)
    }
  }, [debitCard])

  useEffect(() => {
    if (pix) {
      setPaymentMethod()
      setBankSlip(false)
      setCreditCard(false)
      setPix(true)
    }
  }, [pix])

  function setPaymentMethod(value, type) {
    console.log(paymentMethod)
    if (type === 'creditCard') {
      setPaymentMethod()
      setBankSlip(false)
      setDebitCard(false)
      setCreditCard(true)
      setPix(false)
      dispatch(handleChange('creditCard', 'paymentMethod'))
    }
    if (type === 'bankSlip') {
      setPaymentMethod()
      setBankSlip(true)
      setDebitCard(false)
      setCreditCard(false)
      setPix(false)
      dispatch(handleChange('bankSlip', 'paymentMethod'))
    }
    if (type === 'debitCard') {
      setPaymentMethod()
      setBankSlip(false)
      setDebitCard(true)
      setCreditCard(false)
      setPix(false)
      dispatch(handleChange('debitCard', 'paymentMethod'))
    }
    if (type === 'pix') {
      setPaymentMethod()
      setBankSlip(false)
      setDebitCard(false)
      setCreditCard(false)
      setPix(true)
      dispatch(handleChange('pix', 'paymentMethod'))
    }
    return 0;
  }

  return (
    <>
      <div id='payment-wrapper'>

        <Card title='Como prefere pagar?'>

          <div className='payment-methods-container'>

            <div className='credit-card-method' style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px', paddingBottom: '10px' }}>
              <input type='radio' name='payment-select' checked={creditCard} onClick={value => setPaymentMethod(value?.target.checked, 'creditCard')} />
              <div style={{ paddingLeft: '20px' }}>
                <div className='payment-methods-conf'>
                  <FontAwesomeIcon icon={faCreditCard} style={{ color: '#3f7ade' }} />
                </div>
              </div>
              <span style={{ color: 'black', paddingLeft: '10px', fontSize: '15px', fontWeight: '600' }}>Cartão de Crédito</span>
            </div>

            <div className='debit-card-method' style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px', paddingBottom: '10px' }}>
              <input type='radio' name='payment-select' checked={debitCard} onClick={value => setPaymentMethod(value?.target.checked, 'debitCard')} />
              <div style={{ paddingLeft: '20px' }}>
                <div className='payment-methods-conf'>
                  <FontAwesomeIcon icon={faCreditCard} style={{ color: '#3f7ade' }} />
                </div>
              </div>

              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ color: 'black', paddingLeft: '10px', fontSize: '15px', fontWeight: '600' }}>Cartão de Débito</span>
                {/*<h5 style={{ color: 'gray', fontSize: '12px', paddingLeft: '10px' }}>Bancos: Itaú, Santander, Caixa, Bradesco e Banco do Brasil.</h5>*/}
              </div>
            </div>

            <div className='pix-method' style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px', paddingBottom: '10px' }}>
              <input type='radio' name='payment-select' checked={pix} onClick={value => setPaymentMethod(value?.target.checked, 'pix')} />
              <div style={{ paddingLeft: '20px' }}>
                <div className='payment-methods-conf'>
                  <FontAwesomeIcon icon={faQrcode} style={{ color: '#3f7ade' }} />
                </div>
              </div>
              <span style={{ color: 'black', paddingLeft: '10px', fontSize: '15px', fontWeight: '600' }}>Pix</span>
            </div>

            <div className='bank-slip-method' style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px' }}>
              <input type='radio' name='payment-select' checked={bankSlip} onClick={value => setPaymentMethod(value?.target.checked, 'bankSlip')} />
              <div style={{ paddingLeft: '20px' }}>
                <div className='payment-methods-conf'>
                  <FontAwesomeIcon icon={faBarcode} style={{ color: '#3f7ade' }} />
                </div>
              </div>

              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ color: 'black', paddingLeft: '10px', fontSize: '15px', fontWeight: '600' }}>Boleto Bancário</span>
                <h5 style={{ color: 'gray', fontSize: '12px', paddingLeft: '10px' }}>Será aprovado em 1 ou 2 dias úteis.</h5>
              </div>
            </div>

          </div>

        </Card>
      </div>
    </>
  )
}

export default PaymentMethods