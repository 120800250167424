import React, { useState, useEffect } from "react";
import './styles.css';
import './styles99.css';

import { cpfMask, onlyNumbers } from '../../../../utils/normalize';
import { validadeEmail } from '../../../../utils/validation';
import qrCodeRepository from '../../../../repositories/QRCode';
import ModalAlertMsg from '../ModalAlertMsg';
import QrCodeOptionsModal from '../../components/QrCodeInfoModal';

import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ResendQrCodeForm = () => {
  const [classes, setClasses] = useState(null);
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQrCodeModalOptionsOpen, setIsQrCodeModalOptionsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [base64, setBase64] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadClasses()
  }, [])

  const loadClasses = () => {
    if (!window.location.host.includes('21200') && !window.location.host.includes('99')) {
      setClasses('resend-qr-form-input')
    } else {
      setClasses('resend-qr-form-input-99')
    }
  }

  function validation() {
    if (!cpf) {
      setModalMessage('Por favor, informe o CPF para prosseguir.')
      setIsModalOpen(true);
      return
    }

    if (!email) {
      setModalMessage('Por favor, informe o E-MAIL para prosseguir.')
      setIsModalOpen(true);
      return
    }

    if (!validadeEmail(email)) {
      setModalMessage('Por favor, informe um E-MAIL válido para prosseguir.')
      setIsModalOpen(true);
      return
    }

    submit()
  }

  async function submit() {
    setIsLoading(true)
    try {

      const response = await qrCodeRepository.resend(onlyNumbers(cpf), email);
      setBase64(response)
      setIsLoading(false)
      return setIsQrCodeModalOptionsOpen(true)

    } catch (err) {
      setIsLoading(false)
      if (err.response.data.message) {
        setModalMessage(err.response.data.message)
        setBase64('')
        return setIsModalOpen(true)
      }
      setModalMessage('Ocorreu um erro ao realizar o processo. Por favor, tente novamente.')
      setBase64('')
      return setIsModalOpen(true)
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleQrCodeModalClose = () => {
    setIsQrCodeModalOptionsOpen(false)
  }

  return (
    <>
      <div className='resend-qr-form-content'>
        <div style={{ paddingBottom: '10px' }}>
          <input className={classes}
            type="text"
            placeholder="SEU CPF..."
            value={cpf}
            onChange={event => setCpf(cpfMask(event.target.value))}
          />
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <input className={classes}
            type="text"
            placeholder="SEU E-MAIL..."
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
        </div>

        <div className='resend-qr-form-button' style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='resend-qr-form-button' onClick={() => validation()}>
            <h5>GERAR SEGUNDA VIA</h5>
          </div>
        </div>

        <div style={{ paddingTop: '30px' }}>
        </div>

      </div>
      {isModalOpen ?
        <ModalAlertMsg text={modalMessage} onClose={handleModalClose} />
        :
        null
      }

      {isQrCodeModalOptionsOpen ?
        <QrCodeOptionsModal base64={base64.base64} onHide={handleQrCodeModalClose} show={isQrCodeModalOptionsOpen} />
        :
        null
      }

      { 
        isLoading ?
          <div className="div-loading">
            <Spinner animation="border" role="status" variant="success" />
          </div>
          :
          null
      }
    </>
  )
}

export default ResendQrCodeForm;