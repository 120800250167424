import React from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { currency, phoneMask } from '../../../../utils/normalize';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

import ModalDropOut from '../../components/ModalDropOut'
import ModalAlertMsg from '../../components/ModalAlertMsg'
import { removeItemCart } from '../../../../store/actions/cart'
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons'

const PurchaseFooter = ({ previousPage, handleCouponModal, handleNextPage, openModal }) => {
  const [listItems, setListItems] = useState([])

  const history = useHistory()
  const { cartReducer } = useSelector(state => state)
  const { step, selectedProducts, subTotal, amount, client, discountValue, creditCard, debitCard,
    companyIdAtSelectedCity, companiesAtSelectedCity, couponId, checkCompanyStockLoading, companySelected, paymentMethod, bankSlipAddress } = cartReducer

  const { address, addressNumber, city, state, tradingName } = companySelected
  const { cpf, name, email, phone, zipCode, cityClient } = client
  const { brand, isValid, month, year, cvv, cardNumber } = creditCard
  const { postalCode, bankSlipAddressInfo, bankSlipNeighborhood, bankSlipAddressNumber, bankSlipState, bankSlipCity } = bankSlipAddress

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenMsg, setIsModalOpenMsg] = useState(false)
  const [removeItem, setRemoveItem] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {

    const listItems = []
    selectedProducts.forEach(product => {
      listItems.push(product)

      if (product.hasPrize) {
        listItems.push({
          ...product,
          quantity: 1,
          description: product.description + ' (Brinde)',
          amount: 0
        })
      }
    })
    setListItems(listItems)
  }, [selectedProducts])

  useEffect(() => {
    if (removeItem) {
      dispatch(removeItemCart(selectedProducts[0], selectedProducts))
      history.push('/')
    }
  }, [removeItem])

  function msgModalOpen() {
    setIsModalOpenMsg(true)
  }

  const FooterButtons = () => (
    <div>
      {
        step === 1 || step === 2
          ? null
          : <button className='btn btn-danger' onClick={previousPage}>Voltar</button>
      }

      {
        step === 2
          ? <button className='btn btn-success' disabled={(!cpf || !name || !email || !phone || !client.city || !companiesAtSelectedCity || checkCompanyStockLoading)} onClick={openModal}>
            Avançar
            </button>
          : null
      }
      {
        checkCompanyStockLoading && step === 2 ?
          <div className="div-loading">
            <Spinner animation="border" role="status" variant="success" />
            <FontAwesomeIcon icon={faHourglassHalf} color='green' className="animated-money bounce-money" />
          </div>
          :
          null
      }
      {
        step === 1
          ? <button className='btn btn-success' disabled={(!cpf || !name || !email || !phone || !zipCode || !companyIdAtSelectedCity || checkCompanyStockLoading) && step === 2} onClick={handleNextPage}>
            <span className={checkCompanyStockLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
              Avançar
            </button>
          : null // <button className='btn btn-success' disabled={!isValid || !brand || !month || !year || !cvv || !cardNumber} onClick={handleNextPage}>Confirmar a Compra</button>
      }

      {
        // step === 3 && paymentMethod !== 'pix'
        //   ? <button className='btn btn-success' disabled={(!paymentMethod && step === 3)} onClick={handleNextPage}>
        //     <span className={checkCompanyStockLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
        //       Avançar
        //     </button>
        //   : null
      }

      {
        step === 3
          ? paymentMethod !== 'pix' ? <button className='btn btn-success' disabled={(!paymentMethod && step === 3)} onClick={handleNextPage}>
            <span className={checkCompanyStockLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
              Avançar
            </button>
            :
            <button className='btn btn-success' disabled={(paymentMethod !== 'pix' && step === 3)} onClick={handleNextPage}>
              {/* <span className={checkCompanyStockLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''} /> */}
            Confirmar a Compra
            </button>
          : null
      }

      {
        // step === 3 && paymentMethod === 'pix'
        //   ? <button className='btn btn-success' disabled={(paymentMethod !== 'pix')} onClick={handleNextPage}>
        //     {/* <span className={checkCompanyStockLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''} /> */}
        //     Confirmar a Compra
        //     </button>
        //   : null
      }

      {
        step === 4 && paymentMethod === 'bankSlip'
          ? <button className='btn btn-success' disabled={(!postalCode || !bankSlipAddressNumber) && step === 4} onClick={handleNextPage}>
            Confirmar a Compra
            </button>
          : null
      }

      {
        step === 4 && paymentMethod === 'creditCard'
          ? <button className='btn btn-success' disabled={(!isValid || !brand || !month || !year || !cvv || !cardNumber) && step === 4} onClick={handleNextPage}>
            Confirmar a Compra
            </button>
          : null
      }

      {
        step === 4 && paymentMethod === 'debitCard'
          ? <button
            className='btn btn-success'
            disabled={(!debitCard.isValid || !debitCard.brand || !['mastercard', 'visa', 'elo'].includes(debitCard.brand) ||
              !debitCard.month || !debitCard.year || !debitCard.cvv || !debitCard.cardNumber) && step === 4}
            onClick={handleNextPage}>
            Confirmar a Compra
            </button>
          : null
      }

    </div>
  )


  return (
    <footer style={{ display: step === 5 ? 'none' : 'sticky' }}>
      <section id='purchase-resume'>
        <strong>Resumo da Compra</strong>
        <hr />
        <section style={{ justifyContent: step > 3 ? 'space-evenly' : '' }}>
          {
            Boolean(selectedProducts.length) && (
              <div>
                <strong>Itens</strong>
                {
                  listItems.map(product => (
                    <div id='purchase-summary-product' style={{ color: product.amount === 0 ? 'green' : 'gray' }}>
                      <span>{product.quantity}x {product.description}</span>
                      <span>{currency(product.amount)}</span>
                    </div>
                  ))}
              </div>
            )
          }
          {
            step > 3 && (
              <>
                <div>
                  <strong>Dados Pessoais</strong>
                  <div className='purchase-resume-data-span'>
                    <span>{name}</span>
                    <span>{email}</span>
                    <span>{phoneMask(phone)}</span>
                  </div>
                </div>

                <div>
                  <strong>Local selecionado de troca ou retirada do produto</strong>
                  <div className='purchase-resume-data-span'>
                    <span>{tradingName}</span>
                    <span>{`${address}, ${addressNumber} - ${city}/${state}`}</span>
                  </div>
                </div>
              </>
            )
          }

        </section>
      </section>
      {!couponId && step === 4 && <button onClick={handleCouponModal} className='btn btn-link'>Inserir cupom de desconto</button>}
      {step !== 5 && (
        <>
          <main>
            {!!couponId && (
              <>
                <label>
                  <h4>Subtotal</h4>
                  <h4>{currency(subTotal)}</h4>
                </label>
                <label>
                  <h4>Cupom</h4>
                  <h4 style={{ color: 'green' }}>- {currency(discountValue)}</h4>
                </label>
              </>
            )}
            <label>
              <h4>Total</h4>
              <h4>{currency(amount)}</h4>
            </label>
          </main>
          <FooterButtons />
        </>
      )}
      {isModalOpen && step === 1 ?
        <ModalDropOut onClose={() => { setIsModalOpen(false) }} msgModalOpen={msgModalOpen} />
        :
        null
      }
      {isModalOpenMsg && step === 1 ?
        <ModalAlertMsg text='Recebemos o seu feedback, muito obrigado pela sua contribuição.' onClose={() => { setIsModalOpenMsg(false); setRemoveItem(true) }} />
        :
        null
      }
    </footer>

  )
}

export default withRouter(PurchaseFooter)