const renderCheckedDate = (date) => {
  let checkedDate = 'Check-in realizado em 00/00/0000 às 00:00Hs'

  if (date) {
    const checkedFullDate = new Date(date)
    // checkedFullDate.setHours(checkedFullDate.getHours() - 3)
    // const checkedFullDateOffset = new Date(checkedFullDate.getTime() + checkedFullDate.getTimezoneOffset() * 60000)
    const checkedFullDateOffset = new Date(checkedFullDate)

    const day = checkedFullDateOffset.getDate()
    const month = checkedFullDateOffset.getMonth() + 1
    const year = checkedFullDateOffset.getFullYear()
    const hours = checkedFullDateOffset.getHours()
    const minutes = checkedFullDateOffset.getMinutes()

    checkedDate = `Check-in realizado em  ${day}/${month < 10 ? '0' + month : month}/${year} às ${hours}:${minutes}Hs`
  }

  return checkedDate
}

export default renderCheckedDate