import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import './styles.css'

function QRCodeScanSuccessModal({ onCancel, isQrCode }) {
  const history = useHistory();

  const handleNavigation = () => {
    history.push('/partners/checkin')
  }

  return (
    <div className='modal-success-container'>
      <div className='modal-success-contant'>
        <h1>Código Validado com sucesso.</h1>
        <h1>Parabéns! Seu cliente agora pode retirar o produto.</h1>
        <FontAwesomeIcon color='green' size='10x' className='icon-button-modal' icon={faCheckSquare} />
        {isQrCode ?
          <button onClick={() => { handleNavigation() }}>
            Ok
          </button>
          :
          <button onClick={() => { onCancel() }}>
            Ok
          </button>
        }
      </div>
    </div>
  )
}

export default QRCodeScanSuccessModal;