import React, { useState, useEffect } from "react";
import "./styles.css";
import { isMobile } from '../../../../utils/validation'

import logoYPF from '../../../../assets/img/partners/YPF-logo.png'
import initGa from '../../../../utils/googleAnalytics';
import SubHeader from '../../components/SubHeader'
import SeeOptions from '../../components/ButtonSeeOptions'
import partners_Header from '../../../../assets/img/partners/partners_Header.png';
import PartnersMobile from './PartnersMobile';
import PartnersDesktop from './PartnersDesktop';

const Partners = () => {
  initGa(window.location.href);

  const [mobileUser, setMobileUser] = useState(false)

  useEffect(() => {
    const mobileAcess = isMobile()
    if(!mobileAcess) {
      setMobileUser(false)
      }else{
      setMobileUser(true)
      }
  }, []);

  return (
    <>
    <div id="partners-page">
    <SubHeader title="A FÁBRICA" subTitle="O melhor, mais perto de você!" position='right' img={partners_Header} />
      {!mobileUser ? <PartnersDesktop/> : <PartnersMobile/>}
    </div>

  </>
  )
  }
export default Partners;