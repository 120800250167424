import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'

import BankAccountForm from './CreateBankAccount'
import BreadCrumb from '../../components/BreadCrumb'
import { onlyNumbers, cnpjMask, phoneMask, cepMask } from '../../../../utils/normalize'
import bankAccountRepository from '../../../../repositories/BankAccount'
import AssignorsRepository from '../../../../repositories/Assignors'

function BundleFormCompany({ match }) {
  const [loading, setLoading] = useState(false)
  const [bankAccountId, setBankAccountId] = useState(null)
  const [assignorsList, setAssignorsList] = useState([])

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!match.params.id) {
      setBankAccountId(match.params.id)
    }
  }, [])

  useEffect(() => {
    if (!!bankAccountId) {
      loadBankAccount()
      loadAssignors()
    } else {
      document.title = 'Rclub - Nova Conta'
      loadAssignors()
    }
  }, [bankAccountId])

  async function loadBankAccount() {
    setLoading(true)

    try {
      const account = await bankAccountRepository.getById(bankAccountId)
      dispatch([
        change('account', 'registerNumber', account.registerNumber),
        change('account', 'bankCode', account.bankCode),
        change('account', 'bankBranch', account.bankBranch),
        change('account', 'bankBranchVd', account.bankBranchVd),
        change('account', 'accountNumber', account.accountNumber),
        change('account', 'accountNumberVd', account.accountNumberVd),
        change('account', 'accountType', account.accountType),
        change('account', 'accountBeneficiaryCode', account.accountBeneficiaryCode),
        change('account', 'accountCompanyCode', account.accountCompanyCode),
        change('account', 'activeValidationAccount', account.activeValidationAccount),
        change('account', 'updatedPrintingAccount', account.updatedPrintingAccount),
        change('account', 'status', account.status)
      ])

      document.title = `Rclub - ${account.tradingName}`
    } catch (err) {
      toastr.warning('Ocorreu um erro ao carregar a conta')
    } finally {
      setLoading(false)
    }
  }

  async function loadAssignors() {
    setLoading(true)

    try {
      const assignors = await AssignorsRepository.getAll()
      setAssignorsList(assignors)
    } catch (err) {
      toastr.warning('Ocorreu um erro ao carregar a conta')
    } finally {
      setLoading(false)
    }
  }

  function handleCancel() {
    history.push('configAccounts')
  }

  function handleSubmit(values) {
    if (!bankAccountId) {
      create(values)
    } else {
      update(values)
    }
  }

  async function create(values) {
    const { registerNumber, bankCode, bankBranch, bankBranchVd, accountNumber, accountNumberVd, accountType,
      accountBeneficiaryCode, accountCompanyCode, activeValidationAccount, updatedPrintingAccount, status } = values

    setLoading(true)

    try {
      const response = await bankAccountRepository.create({
        registerNumber,
        bankCode,
        bankBranch,
        bankBranchVd,
        accountNumber,
        accountNumberVd,
        accountType,
        accountBeneficiaryCode,
        accountCompanyCode,
        activeValidationAccount,
        updatedPrintingAccount,
        status
      })
      toastr.success(response.data.mensagem)
      history.push('configAccounts')

    } catch (err) {
      toastr.warning('Ocorreu um erro ao criar o convênio. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  async function update(values) {
    const { registerNumber, bankCode, bankBranch, bankBranchVd, accountNumber, accountNumberVd, accountType,
      accountBeneficiaryCode, accountCompanyCode, activeValidationAccount, updatedPrintingAccount, status } = values
    setLoading(true)

    try {
      await bankAccountRepository.update(bankAccountId, {
        registerNumber,
        bankCode,
        bankBranch,
        bankBranchVd,
        accountNumber,
        accountNumberVd,
        accountType,
        accountBeneficiaryCode,
        accountCompanyCode,
        activeValidationAccount,
        updatedPrintingAccount,
        status
      })

      toastr.success('Conta atualizada com sucesso.')
      history.push('configAccounts')

    } catch (err) {
      toastr.warning('Ocorreu um erro ao criar o convênio. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const initialValues = {
    status: true,
    activeValidationAccount: false,
    updatedPrintingAccount: false,
    accountCompanyCode: null
  }

  return (
    <>
      <BreadCrumb path={['home', 'configAccounts', null]} data={['Início', 'Contas', !bankAccountId ? 'Nova Conta' : 'Editar Conta']} />

      <BankAccountForm
        initialValues={initialValues}
        assignorsList={assignorsList}
        bankAccountId={bankAccountId}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        loading={loading}
      />
    </>
  )
}

export default BundleFormCompany