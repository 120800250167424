import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'

import BreadCrumb from '../../components/BreadCrumb'
import companyStockRepository from '../../../../repositories/CompanyStock'
import salesRepository from '../../../../repositories/Sales'
import AlertModal from '../../../../components/AlertModal'

import SaleForm from './Sale'
import { onlyNumbers } from '../../../../utils/normalize'

const BundleFormSale = () => {
  const [loading, setLoading] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [valuesToCreate, setValuesToCreate] = useState({})

  const history = useHistory()

  useEffect(() => {
    document.title = 'Rclub - Nova Venda'
  }, [])

  function handleCancel() {
    history.push('sales')
  }

  async function handleSubmit(values) {
    if (!values.items.length) {
      return toastr.warning('Insira ao menos um item para venda')
    }

    try {
      const response = await companyStockRepository.checkCompanyStock(values.companyId, values.items)

      if (!response.status) {
        return toastr.warning(`Ops, infelizmente a empresa está sem estoque para o produto ${response.productDescription}`)
      }
    } catch (err) {
      console.log(err)
      return toastr.warning('Ocorreu um erro ao verificar o estoque da empresa. Por favor, tente novamente')
    }

    const valuesToCreate = {
      date: new Date(),
      items: values.items,
      companyId: values.companyId,
      client: {
        ...values.client,
        zipCode: onlyNumbers(values.client.zipCode),
        cpf: onlyNumbers(values.client.cpf),
        phone: onlyNumbers(values.client.phone),
      }
    }

    setValuesToCreate(valuesToCreate)
    setConfirmModal(true)
  }

  async function create() {
    setConfirmModal(false)
    setLoading(true)

    try {
      await salesRepository.createFromAdmin(valuesToCreate)

      toastr.success('Venda confirmada com sucesso.')
      history.push('sales')
    } catch (err) {
      console.log(err)
      toastr.warning('Ocorreu um erro ao criar a venda. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  const initialValues = {
    items: [],
    client: {
      name: '',
      zipCode: '',
      cpf: '',
      email: '',
      phone: ''
    }
  }

  return (
    <>
      <BreadCrumb path={['home', 'sales', null]} data={['Início', 'Vendas', 'Nova Venda']} />

      <SaleForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        loading={loading}
        initialValues={initialValues}
      />

      {
        confirmModal && (
          <AlertModal
            show={confirmModal}
            animation
            message='Deseja realmente registrar a venda ?'
            onCancel={() => setConfirmModal(false)}
            onHide={() => setConfirmModal(false)}
            onSubmit={create}
          />
        )
      }
    </>
  )
}

export default BundleFormSale