import React, { useState, useEffect, useCallback } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import ReactTable from 'react-table-6';
import contactsRepository from '../../../../repositories/Contacts'
import { phoneMask } from '../../../../utils/normalize'
import DownloadXls from '../../components/DownloadXlsButton';

function ContactsReports() {
  const [loading, setLoading] = useState(false)
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [contacts, setContacts] = useState([])
  const [contactsToExport, setContactsToExport] = useState([])
  const [contactsFiltered, setContactsFiltered] = useState([])

  const xlsColumns = [
    {
      name: 'Data',
      acessor: 'date'
    },
    {
      name: 'Nome',
      acessor: 'name'
    },
    {
      name: 'E-mail',
      acessor: 'email'
    },
    {
      name: 'Telefone',
      acessor: 'phone'
    },
    {
      name: 'Cidade',
      acessor: 'city'
    },
    {
      name: 'UF',
      acessor: 'state'
    }
  ]

  useEffect(() => {
    loadContacts()
    document.title = 'Rclub - Relatórios'
  }, [])

  const updateXlsDataToDownload = useCallback(() => {
    setLoading(true)
    const xlsData = contactsFiltered.map(contact => ({
      date: new Date(contact.createdAt).toLocaleString().split(' ')[0],
      name: contact.name,
      email: contact.email,
      phone: phoneMask(contact.phone),
      city: contact.city,
      state: contact.state,
    }))

    setContactsToExport(xlsData)
    setLoading(false)
  }, [contactsFiltered])

  const isDateInvalid = useCallback(() => new Date(finalDate).getTime() < new Date(initialDate).getTime(), [initialDate, finalDate])

  const setContactsFilteredByDate = useCallback(() => {
    let contactsFilteredByDate = contacts

    if (Boolean(initialDate) && Boolean(finalDate)) {
      if (isDateInvalid()) {
        setContactsFiltered(contacts)
        return toastr.warning('Data inválida')
      }
    }

    if (Boolean(initialDate)) {
      contactsFilteredByDate = contactsFilteredByDate
        .filter(contact => new Date(contact.createdAt).getTime() > new Date(initialDate).getTime())
    }

    if (Boolean(finalDate)) {
      const finalDatePlusOneDay = new Date(new Date().setDate(new Date(finalDate).getDate() + 2)).getTime()

      contactsFilteredByDate = contactsFilteredByDate
        .filter(contact => new Date(contact.createdAt).getTime() < new Date(finalDatePlusOneDay).getTime())
    }


    setContactsFiltered(contactsFilteredByDate)
  }, [initialDate, finalDate, contacts, isDateInvalid])

  useEffect(() => {
    updateXlsDataToDownload()
  }, [contactsFiltered, updateXlsDataToDownload])

  useEffect(() => {
    if (Boolean(initialDate) || Boolean(finalDate)) {
      setContactsFilteredByDate()
    }
  }, [initialDate, finalDate, setContactsFilteredByDate])

  const loadContacts = async () => {
    setLoading(true)

    try {
      const responseContacts = await contactsRepository.getAll()

      setContacts(responseContacts)
      setContactsFiltered(responseContacts)
    } catch (err) {
      console.log(err)
      toastr.error('Ocorreu um erro ao carregar os contatos. Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div id='admin-page-contacts-reports'>
      <header>
        <BreadCrumb path={['/admin/home', null, null]} data={['Início', 'Relatórios', 'Relatório de Pesquisa de CEP']} />
        <section>
          <div>
            <label htmlFor='initialDate'>Data Inicial:</label>
            <input
              type="date"
              id='initialDate'
              className='form-control foco-input'
              onChange={e => setInitialDate(e.target.value)}
              value={initialDate}
            />
          </div>

          <div>
            <label htmlFor='finalDate'>Data Final:</label>
            <input
              type="date"
              id='finalDate'
              className='form-control foco-input'
              value={finalDate}
              onChange={e => setFinalDate(e.target.value)}
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <DownloadXls
              archiveName={`consultascep${new Date().toLocaleDateString()}`}
              tablesNames={['Consultas CEPs']}
              data={[contactsToExport]}
              className='btn btn-export'
              disabled={loading}
              columns={[xlsColumns]}
            >
              <FontAwesomeIcon color='white' icon={faCloudDownloadAlt} /> Exportar .xsl
            </DownloadXls>
          </div>
        </section>
      </header>

      <br />
      <ReactTable
        style={{ textAlign: 'center' }}
        data={contactsFiltered}
        columns={[
          {
            Header: 'Data',
            accessor: 'createdAt',
            width: 130,
            Cell: props => <>{new Date(props.value).toLocaleString().split(' ')[0]}</>
          },
          {
            Header: 'Nome',
            accessor: 'name'
          },
          {
            Header: 'E-mail',
            accessor: 'email'
          },
          {
            Header: 'Telefone',
            accessor: 'phone',
            Cell: props => <>{phoneMask(props.value)}</>,
            width: 200
          },
          {
            Header: 'Cidade',
            accessor: 'city',
            width: 150
          },
          {
            Header: 'UF',
            accessor: 'state',
            width: 90
          }
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText='Anterior'
        nextText='Próximo'
        loadingText='Carregando...'
        noDataText='Não há informação'
        pageText='Página'
        ofText='de'
        rowsText='linhas'
      />
    </div>
  )
}

export default ContactsReports