import React from 'react'
import { currency } from '../../../../utils/normalize'
import ReactTable from 'react-table-6'
import { renderCheckedDate } from '../../../../utils/formatDates'

const SaleDetails = ({ items }) => {
  return (
    <ReactTable
      style={{ textAlign: 'center' }}
      data={items}
      columns={[
        {
          Header: "Item",
          accessor: props => Number(props.index), // FUNÇÃO PARA SETAR EM ORDEM NUMÉRICA
          id:'id',
          width: 84,
          Cell: props => <span>{props.index + 1}</span>,
        },
        {
          Header: "Produto",
          accessor: "Products.description",
        },
        {
          Header: "Valor Unitário",
          accessor: "price",
          Cell: props => <span>{currency(props.value)}</span>,
        },
        {
          Header: "Quantidade",
          accessor: "quantity",
          Cell: props => <span>{props.value || "1"}</span>,

        },
        {
          Header: "Valor Total",
          accessor: "price",
          Cell: props => <span>{currency(props.value)}</span>
        },
        {
          Header: "Check-in",
          accessor: "checkedDate",
          render: (rowInfo) => {
            return (
              <span>
               {rowInfo.items.map(date => (<span>{date.checkedDate}</span>))}
              </span>
            )},
          Cell: props => {
            if(props.value) {
            return <span style={{color:'green'}}>{renderCheckedDate(props.value)}</span>
            }
          return <span >{renderCheckedDate('---')}</span>
          },
        }
      ]}
      defaultPageSize={5}
      showPagination={true}
      sortable={true}
      showPaginationTop={false}
      showPaginationBottom={true}
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      previousText="Anterior"
      nextText="Próximo"
      loadingText="Carregando..."
      noDataText="Não há informação"
      pageText="Página"
      ofText="de"
      rowsText="linhas"
    />
  )
}

export default SaleDetails