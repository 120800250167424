import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import './styles.css'

const SearchInput = ({ placeholder, name, value, suggestions, disabled, onChange }) => {
  const fieldId = `id_${name}`;
  const hasSuggestions = Boolean(suggestions.length);

  return (
    <div id='search-input-wrapper'>
      <FontAwesomeIcon icon={faSearch} />
      <input
        list={hasSuggestions ? `suggestionFor_${fieldId}` : undefined}
        autoComplete={hasSuggestions ? 'off' : 'on'}
        id={fieldId}
        className='form-control foco-input'
        title={placeholder}
        placeholder={placeholder}
        type='text'
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      {
        hasSuggestions && (
          <datalist id={`suggestionFor_${fieldId}`}>
            {
              suggestions.map((suggestion) => (
                <option value={suggestion} key={`suggestionFor_${fieldId}_option${suggestion}`}>
                  {suggestion}
                </option>
              ))
            }
          </datalist>
        )
      }
    </div>
  )
}

SearchInput.defaultProps = {
  suggestions: []
}

export default SearchInput