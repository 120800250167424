import React from 'react'
import { Col, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

import CardForm from '../../components/CardForm'
import FormSubmitButtons from '../../components/FormSubmitButtons'
import renderField from '../../../../components/RenderField'
import validate from './validate'

const ProductTypeForm = ({ handleSubmit, productTypeId, loading, onCancel }) => {
  return (
    <form id='admin-page-product-form' onSubmit={handleSubmit}>
      <CardForm
        title='Tipo de Produto'
        show
      >
        <Row>
          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name='code'
              type='text'
              component={renderField}
              label='Código'
              disabled
            />
          </Col>

          <Col xs={12} sm={12} md={4} lg={4}>
            <Field
              name='description'
              type='text'
              component={renderField}
              label='Descrição'
              required
            />
          </Col>
        </Row>
      </CardForm>

      <FormSubmitButtons hasId={!!productTypeId} loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
    </form>
  )
}

ProductTypeForm.prototype = {
  handleSubmit: PropTypes.func.isRequired, 
  productTypeId: PropTypes.number, 
  loading: PropTypes.bool.isRequired
}

export default reduxForm({
  form: 'productType',
  validate: validate
})(ProductTypeForm)