import React, { useState, } from 'react'
import { Modal } from 'react-bootstrap'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faQrcode, faBarcode } from '@fortawesome/free-solid-svg-icons'
import AlertModal from '../../../../components/AlertModal'
import convertBufferToUint from '../../../../utils/convertBufferToUint';
import salesRepository from '../../../../repositories/Sales'
import './styles.css'
import { toastr } from 'react-redux-toastr'

const InfoModal = ({ show, onHide, message, sale }) => {
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false)
  const [isBankSlipModalOpen, setIsBankSlipModalOpen] = useState(false)
  const [isResendEmailModalOpen, setIsResendEmailModalOpen] = useState(false)
  const [sales, setSales] = useState({})

  const handleOpenQRCodeSaleModal = sale => {
    setSales(sale)
    setIsQRCodeModalOpen(true)
  }

  const handleOpenBankSlipSaleModal = sale => {
    setSales(sale)
    setIsBankSlipModalOpen(true)
  }

  const handleOpenResendEmailSaleModal = sale => {
    setSales(sale)
    setIsResendEmailModalOpen(true)
  }

  const handleQRCode = async () => {
    setIsQRCodeModalOpen(false)
    try {
      const response = await salesRepository.getSalePDF(sales.id)
      const arrayUint = convertBufferToUint(response.data)

      var blob = new Blob([arrayUint], { type: "application/pdf" });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      var fileName = `QRCode_Pedido_#${sales.code}`;
      link.download = fileName;
      link.click();
      toastr.success('Downlaod do QRCode feito com sucesso.')
    } catch (err) {
      toastr.error('Ocorreu um erro ao gerar o QRCode. Por favor, tente novamente.')
    }
  }

  const handleBankSlip = async () => {
    setIsBankSlipModalOpen(false)

    const idIntegration = sales.paymentId

    try {
      const response = await salesRepository.consultBankSlip(idIntegration)
      const bankSplipBase64 = response.base64slip;

      if (bankSplipBase64) {
        downloadPDF(bankSplipBase64)
        function downloadPDF(bankSplipBase64) {

          var a = document.createElement("a");
          a.href = "data:application/pdf;base64," + bankSplipBase64;
          a.download = `Boleto_Pedido_#${sales.code}.pdf`;
          a.click();
          toastr.success('Downlaod do Boleto feito com sucesso.')
        }
      } else {
        toastr.error('Ocorreu um erro ao gerar o boleto. Por favor, tente novamente.')
      }
    } catch (error) {
      toastr.error('Ocorreu um erro ao buscar o boleto. Por favor, tente novamente.')
    }
  }

  const handleResendEmail = async () => {
    setIsResendEmailModalOpen(false)

    if (sales.saleStatusId === 1 || sales.saleStatusId === 2) {
      const objectResendEmail = {
        createdSale: sales,
        createdItems: sales.SalesItems,
        createdClient: sales.Clients,
        companyId: sales.companyId,
      }
      try {
        await salesRepository.resendEmail(objectResendEmail)
          .then(toastr.success('Email reenviado com sucesso.'))

      } catch (err) {
        toastr.error('Ocorreu um erro ao reenviar o email. Por favor, tente novamente.')
      }
    }

    if (sales.saleStatusId === 4) {
      try {
        let createdSlip = await salesRepository.getBankSlipBySaleId(sales.id)

        const objectResendEmail = {
          createdSale: sales,
          createdClient: sales.Clients,
          createdSlip,
        }
        await salesRepository.resendPendingPaymentEmail(objectResendEmail)
          .then(toastr.success('Email reenviado com sucesso.'))

      } catch (err) {
        toastr.error('Ocorreu um erro ao reenviar o email. Por favor, tente novamente.')
      }
    }

    if (sales.saleStatusId === 3) {
      toastr.error('Venda cancelada. Selecioone outra venda e tente novamente.')
    }
  }

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Rclub</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id='info-modal-options'>
          {message} <br />

          <div>
            <button className='btn btn-primary' onClick={() => handleOpenResendEmailSaleModal(sale)} style={{ backgroundColor: '#008000' }}>
              <FontAwesomeIcon icon={faEnvelopeOpenText} />
              <span>Reenviar E-mail</span>
            </button>
          </div>
          <div>
            <button className='btn btn-primary' onClick={() => handleOpenQRCodeSaleModal(sale)} style={{ backgroundColor: '#0000FF' }}>
              <FontAwesomeIcon icon={faQrcode} />
              <span>Download QR Code</span>
            </button>
          </div>
          <div>
            <button className='btn btn-primary' onClick={() => handleOpenBankSlipSaleModal(sale)} style={{ backgroundColor: '#8B008B' }}>
              <FontAwesomeIcon icon={faBarcode} />
              <span>Download Boleto</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {
        isQRCodeModalOpen &&
        <AlertModal
          show={isQRCodeModalOpen}
          message='Deseja realmente realizar o download do QR Code?'
          onHide={() => setIsQRCodeModalOpen(false)}
          onCancel={() => setIsQRCodeModalOpen(false)}
          onSubmit={handleQRCode}
        />
      }
      {
        isBankSlipModalOpen &&
        <AlertModal
          show={isBankSlipModalOpen}
          message='Deseja realmente realizar o download do Boleto?'
          onHide={() => setIsBankSlipModalOpen(false)}
          onCancel={() => setIsBankSlipModalOpen(false)}
          onSubmit={handleBankSlip}
        />
      }
      {
        isResendEmailModalOpen &&
        <AlertModal
          show={isResendEmailModalOpen}
          message='Deseja realmente enviar o e-mail da compra?'
          onHide={() => setIsResendEmailModalOpen(false)}
          onCancel={() => setIsResendEmailModalOpen(false)}
          onSubmit={handleResendEmail}
        />
      }
    </>
  )
}

InfoModal.defaultProps = {
  onHide: () => { },
  message: '',
}

InfoModal.propTypes = {
  show: propTypes.bool.isRequired,
  onHide: propTypes.func.isRequired,
  message: propTypes.string.isRequired,
  sale: propTypes.any.isRequired
}

export default InfoModal