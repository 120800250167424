const validate = values => {
  const errors = {}

  if (!values.assignorId) {
    errors.assignorId = 'Obrigatório'
  }
  if (!values.accountId) {
    errors.accountId = 'Obrigatório'
  }
  if (!values.agreementId) {
    errors.agreementId = 'Obrigatório'
  }

  return errors
}

export default validate