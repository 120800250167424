import Dashboard from './pages/Dashboard';
import CheckIn from './pages/CheckIn';
import QrCode from './pages/CheckIn/QrCodeCheckIn';
import Login from './pages/Login';
import FirstLogin from './pages/Login/FirstLogin';
import PasswordRecovery from './pages/PasswordRecovery';
import Stock from './pages/Stock';
import SalesDetails from './pages/CheckIn';

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
    layout: "/partners",
    name: "Dashboard",
  },
  {
    path: "/checkin",
    component: CheckIn,
    layout: "/partners",
    name: "CheckIn",
  },
  {
    path: "/saledetails",
    component: SalesDetails,
    layout: "/partners",
    name: "SalesDatails",
  },
  {
    path: "/login",
    component: Login,
    layout: "/partners",
    name: "Login",
  },
  {
    path: "/passwordrecovery",
    component: PasswordRecovery,
    layout: "/partners",
    name: "PasswordRecovery",
  },
  {
    path: "/stock",
    component: Stock,
    layout: "/partners",
    name: "Stock",
  },
  {
    path: "/createaccount",
    component: FirstLogin,
    layout: "/partners",
    name: "FirstLogin",
  },
  {
    path: "/qrcode",
    component: QrCode,
    layout: "/partners",
    name: "QrCode",
  },
]

export default routes