import React, {useEffect, useState} from 'react';
import { toastr } from 'react-redux-toastr';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import companiesRepository from '../../../../repositories/Companies';

function Companies({listCompanies, height, width}) {
    const [locations, setLocations] = useState([])
    const [firstLocation, setFisrtLocation] = useState()
  
    useEffect(() => {
      allLocations()
    }, [listCompanies])

    useEffect(() => {
        handleFirstLocation()
      }, [locations])

    const allLocations = () => {        
        const allLocations = listCompanies.map(c => {
            return {
                id: c.id,
                location:{
                    lat: parseFloat(c.latitude),
                    lng: parseFloat(c.longitude)
                }
            }
        })

        setLocations(allLocations)
    }

    const handleFirstLocation = () => {
        setFisrtLocation(locations[0])
    }

    const defaultMapOptions = {
        fullscreenControl: false,
        disableDefaultUI: true
    };

    const AllCompaniesMap = withGoogleMap((props) => (
        <GoogleMap
        defaultCenter = {!(firstLocation) ? null : firstLocation.location }
        defaultZoom = { 8 }
        defaultOptions={defaultMapOptions}
        >
            {locations.map(local =>{
                return (
                    <Marker key={local.id} position={local.location} />
                )
            })}
        </GoogleMap>
    ));
  
    return (
        <div>
            <AllCompaniesMap
            containerElement={ <div style={{ height: `${height}vh`, width: `${width}vw` }} /> }
            mapElement={ <div style={{ height: `100%` }} /> }
            />
        </div>
    )
  }
  
  export default Companies