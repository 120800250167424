import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { setCookie } from '../../../../utils/cookies';
import { useHistory } from 'react-router-dom';
import usersRepository from '../../../../repositories/Users';
import AlertModal from '../../../Store/components/ModalAlertMsg';
import LoadingScreen from '../../components/Loading';

import logo from "../../../../assets/img/app-login/app-logo.png";

import './styles.css';

function FirstLogin() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [companyId, setCompanyId] = useState();
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setCompanyId(location.state.companyId);
    setEmail(location.state.companyEmail);
  }, [location])

  const submit = () => {
    if (email && password) {

      setCookie('APP_ID', email)
      setCookie('APP_NAME', password)
      setCookie('APP_COMPANYID', companyId)
      setLoading(false)
      history.push('/partners/dashboard')
    }
  }

  const handleCreateAccount = async () => {
    if (password.length < 6) {
      setMessage('Ops, a senha deve ter o mínimo de 6 caracteres');
      setIsAlertModalOpen(true);
      return;
    }

    if (password !== confirmedPassword) {
      setMessage('Ops, as senhas não coincidem. Por favor, verifique.');
      setIsAlertModalOpen(true);
      return;
    }

    try {
      setLoading(true)
      var returnedUser = await usersRepository.create({
        companyId,
        email,
        password
      });

      submit()
    } catch (err) {
      setMessage('Ops! ocorreu um erro ao criar o usuário. Por favor, tente novamente.');
      setIsAlertModalOpen(true);
      return;
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <div className='app-login-container'>
        <div className='app-login'>
          <div className="app-login-card">
            <div style={{ display: 'flex', height: '200px', width: '200px', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
              <img className="logo-img" src={logo} alt="Loja WorkMotor" />
            </div>

            <div className="app-login-form">
              <div className="app-login-field">
                <input
                  type="password"
                  value={password}
                  placeholder="Senha"
                  maxLength={16}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>

              <div className="app-login-field">
                <input
                  type="password"
                  value={confirmedPassword}
                  placeholder="Confirmar Senha"
                  maxLength={16}
                  onChange={e => setConfirmedPassword(e.target.value)}
                />
              </div>

              <div className="app-login-form"
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center"
                }}>
                <button className='btn btn-sucesso' onClick={e => handleCreateAccount()} disabled={!password || !confirmedPassword}>CRIAR CONTA</button>
              </div>

            </div>
          </div>
        </div>
      </div>

      {isAlertModalOpen && (
        <AlertModal text={message} onClose={e => setIsAlertModalOpen(false)} />
      )
      }

      {loading && (
        <LoadingScreen />
      )
      }

    </div>
  );
}

export default FirstLogin;