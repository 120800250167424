import React from 'react'
import ReactExport from "react-export-excel";
import PropTypes from 'prop-types'

const DownloadXls = ({ data, columns, children, tablesNames, archiveName, ...rest }) => {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        <ExcelFile element={<button {...rest}>{children}</button>} filename={archiveName}>
            {
                tablesNames.map((name, i) => (
                    <ExcelSheet key={i} data={data[i]} name={name}>
                        {columns[i].map(c => <ExcelColumn label={c.name} key={c.acessor} value={c.acessor} />)}
                    </ExcelSheet>
                ))
            }
        </ExcelFile>
    )
}

DownloadXls.defaultProps = {
    data: [[]],
    columns: [[{}]],
    archiveName: 'excel-file',

}

DownloadXls.propTypes = {
    data: PropTypes.arrayOf(PropTypes.array).isRequired,
    columns: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
    archiveName: PropTypes.string.isRequired,
    tablesNames: PropTypes.arrayOf(PropTypes.string)
}

export default DownloadXls;