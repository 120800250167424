import React, { useState, useEffect } from "react";
import './styles.css';
import './styles99.css';

import { cpfMask, onlyNumbers } from '../../../../utils/normalize';
import bankSlipRepository from '../../../../repositories/BankSlip';
import ModalAlertMsg from '../ModalAlertMsg'
import { toastr } from "react-redux-toastr";

import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ResendForm = () => {
  const [classes, setClasses] = useState(null);
  const [cpf, setCpf] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasClient, setHasClient] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadClasses()
  }, [])

  const loadClasses = () => {
    if (!window.location.host.includes('21200') && !window.location.host.includes('99')) {
      setClasses('resend-form-input')
    } else {
      setClasses('resend-form-input-99')
    }
  }

  function validation() {
    if (!cpf) {
      setModalMessage('Por favor, informe o CPF para prosseguir.')
      setIsModalOpen(true);
    }
    else {
      submit()
    }
  }

  async function submit() {
    try {
      setIsLoading(true)
      const response = await bankSlipRepository.getBankSlipInfo(onlyNumbers(cpf));

      setClients(response)
      setHasClient(true)
      setIsLoading(false)
      // setModalMessage('Recebemos o seu contato, a partir de agora você receberá todas as novidades do RCLUB.')
      // return setIsModalOpen(true)

    } catch (err) {
      setIsLoading(false)
      if (err.response.data.message) {
        setModalMessage(err.response.data.message)
        setHasClient(false)
        setClients([])
        return setIsModalOpen(true)
      }
      setModalMessage('Ocorreu um erro ao realizar o processo. Por favor, tente novamente.')
      setHasClient(false)
      setClients([])
      return setIsModalOpen(true)
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  function copyText(text) {
    navigator.clipboard.writeText(text);
    toastr.success('Código de barras copiado com sucesso.');
  }

  function downloadSlip(base64, code) {
    if (base64) {
      downloadPDF(base64, code)
      function downloadPDF(bankSplipBase64, saleCode) {

        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + bankSplipBase64;
        a.download = `Boleto_Pedido_#${saleCode}.pdf`;
        a.click();
      }
    } else {
      toastr.error('Ocorreu um erro ao gerar o boleto. Por favor, tente novamente.')
    }
  }

  return (
    <>
      <div className='resend-form-content'>
        <div style={{ paddingBottom: '10px' }}>
          <input className={classes}
            type="text"
            placeholder="SEU CPF..."
            value={cpf}
            onChange={event => setCpf(cpfMask(event.target.value))}
          />
        </div>

        <div className='resend-form-button' style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='resend-form-button' onClick={() => validation()}>
            <h5>GERAR SEGUNDA VIA</h5>
          </div>
        </div>

        <div style={{ paddingTop: '30px' }}>
        </div>

      </div>

      {hasClient ?
        <div className='resend-text'>
          <h2>Dados do Boleto</h2>
          {clients.map(client => {
            return (
              <>
                <div className='resend-form-text'>
                  <hr />
                  <h2>Nome: {client.name}</h2>
                  <h2>Vencimento em: {new Date(client.expirationDate).toLocaleDateString()}</h2>
                  <h2>Situação do Boleto: Pendente Pagamento</h2>
                  <div className='resend-digitable-line'>
                    <h2>{`${client.digitableLine}`}</h2>
                  </div>
                  <div className='resend-download-button' onClick={() => downloadSlip(client.base64slip, client.code)}>
                    <button>DOWNLOAD BOLETO</button>
                  </div>
                  <div className='resend-copy-button' onClick={() => copyText(client.digitableLine)}>
                    <button>COPIAR CÓDIGO DE BARRAS</button>
                  </div>
                </div>
              </>
            )
          })}
        </div>
        :
        null
      }

      {isModalOpen ?
        <ModalAlertMsg text={modalMessage} onClose={handleModalClose} />
        :
        null
      }

      {
        isLoading ?
          <div className="div-loading">
            <Spinner animation="border" role="status" variant="success" />
          </div>
          :
          null
      }
    </>
  )
}

export default ResendForm;