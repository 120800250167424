import Main from './pages/Main';
import Sales from './pages/Sales'
import Sale from './pages/Sale'
import Companies from './pages/Companies'
import Company from './pages/Company'
import ProductTypes from './pages/ProductTypes'
import ProductType from './pages/ProductType'
import Products from './pages/Products'
import Product from './pages/Product'
import StockEntries from './pages/StockEntries'
import StockEntry from './pages/StockEntry'
import ContactReport from './pages/ContactReport'
import SalesReport from './pages/SalesReport'
import Coupons from './pages/Coupons'
import Coupon from './pages/Coupon'
import Imagens from './pages/Images'
import RegisterContact from './pages/RegisterContact'
import ConsultClient from './pages/ConsultClient'
import BankSlip from './pages/BankSlips'
import TransactionsReports from './pages/TransactionsReport'
import Assignors from './pages/Assignors'
import BankAccounts from './pages/BankAccounts'
import CreateBankAccount from './pages/CreateBankAccount'
import Assignor from './pages/Assignor'
import AssignorCertificate from './pages/AssignorCertificate'
import BankAgreements from './pages/BankAgreements'
import CreateBankAgreement from './pages/CreateBankAgreement'
import BankSlipConfig from './pages/BankSlipConfig'


const dashboardRoutes = [
  {
    path: "/home",
    name: "Página Inicial",
    component: Main,
    layout: "/admin"
  },
  {
    path: "/company",
    name: "Nova Empresa",
    component: Company,
    layout: "/admin"
  },
  {
    path: "/company#:id",
    name: "Editar Empresa",
    component: Company,
    layout: "/admin"
  },
  {
    path: "/companies",
    name: "Empresas",
    component: Companies,
    layout: "/admin"
  },
  {
    path: "/sales",
    name: "Vendas",
    component: Sales,
    layout: "/admin"
  },
  {
    path: "/sale",
    name: "Nova Venda",
    component: Sale,
    layout: "/admin"
  },
  {
    path: "/product-types",
    name: "Tipos de Produto",
    component: ProductTypes,
    layout: "/admin"
  },
  {
    path: "/product-type",
    name: "Novo Tipo de Produto",
    component: ProductType,
    layout: "/admin"
  },
  {
    path: "/product-type#:id",
    name: "Editar Tipo de Produto",
    component: ProductType,
    layout: "/admin"
  },
  {
    path: "/products",
    name: "Produtos",
    component: Products,
    layout: "/admin"
  },
  {
    path: "/product",
    name: "Novo Produto",
    component: Product,
    layout: "/admin"
  },
  {
    path: "/product#:id",
    name: "Editar Produto",
    component: Product,
    layout: "/admin"
  },
  {
    path: "/stockentries",
    name: "Entradas de Estoque",
    component: StockEntries,
    layout: "/admin"
  },
  {
    path: "/stockentry",
    name: "Nova Entrada de Estoque",
    component: StockEntry,
    layout: "/admin"
  },
  {
    path: "/stockentry#:id",
    name: "Visualizar Entrada de Estoque",
    component: StockEntry,
    layout: "/admin"
  },
  {
    path: "/reports/contacts",
    name: "Relatório de Pesquisa de CEP",
    component: ContactReport,
    layout: "/admin"
  },
  {
    path: "/reports/sales",
    name: "Relatório de Vendas",
    component: SalesReport,
    layout: "/admin"
  },
  {
    path: "/coupons",
    name: "Cupons",
    component: Coupons,
    layout: "/admin"
  },
  {
    path: "/coupon",
    name: "Novo Cupom",
    component: Coupon,
    layout: "/admin"
  },
  {
    path: "/coupon#:id",
    name: "Editar Cupom",
    component: Coupon,
    layout: "/admin"
  },
  {
    path: "/image",
    name: "Imagens",
    component: Imagens,
    layout: "/admin"
  },
  {
    path: "/register-contacts",
    name: "Contatos Registrados",
    component: RegisterContact,
    layout: "/admin"
  },
  {
    path: "/clients-consultation",
    name: "Consulta de Clientes",
    component: ConsultClient,
    layout: "/admin"
  },
  {
    path: "/bankslips",
    name: "Consulta de Boletos",
    component: BankSlip,
    layout: "/admin"
  },
  {
    path: "/reports/pagtos-transactions",
    name: "Relatório de Transações PAGTOS",
    component: TransactionsReports,
    layout: "/admin"
  },
  {
    path: "/configAssignors",
    name: "Exibição de Cedentes",
    component: Assignors,
    layout: "/admin"
  },
  {
    path: "/configAccounts",
    name: "Exibição de Contas",
    component: BankAccounts,
    layout: "/admin"
  },
  {
    path: "/configAgreements",
    name: "Exibição de Convênios",
    component: BankAgreements,
    layout: "/admin"
  },
  {
    path: "/configbankSlips",
    name: "Configuração do Emissor de Boleto",
    component: BankSlipConfig,
    layout: "/admin"
  },
  {
    path: "/createAccount",
    name: "Nova Conta",
    component: CreateBankAccount,
    layout: "/admin"
  },
  {
    path: "/editeAccount#:id",
    name: "Editar Conta",
    component: CreateBankAccount,
    layout: "/admin"
  },
  {
    path: "/assignor",
    name: "Novo Cedente",
    component: Assignor,
    layout: "/admin"
  },
  {
    path: "/assignor#:id",
    name: "Editar Cedente",
    component: Assignor,
    layout: "/admin"
  },
  {
    path: "/assignorCertificate",
    name: "Novo Certificado",
    component: AssignorCertificate,
    layout: "/admin"
  },
  {
    path: "/assignorCertificate#:id",
    name: "Editar Certificado",
    component: AssignorCertificate,
    layout: "/admin"
  },
  {
    path: "/agreement",
    name: "Novo Convênio",
    component: CreateBankAgreement,
    layout: "/admin"
  },
  {
    path: "/editAgreement#:id",
    name: "Editar Convênio",
    component: CreateBankAgreement,
    layout: "/admin"
  },
];

export default dashboardRoutes;