import React from 'react'
import { Col, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

import CardForm from '../CardForm'
import FormSubmitButtons from '../FormSubmitButtons'
import renderField from '../../../../components/RenderField'
import validate from './validate'

const VehicleTypeForm = ({ handleSubmit, loading, onCancel }) => {
  return (
    <form onSubmit={handleSubmit}>
      <CardForm
        title='Tipo de Veículo'
        show
      >
        <Row>
          <Col xs={12} sm={12} md={3} lg={3}>
            <Field
              name='code'
              type='text'
              component={renderField}
              label='Código'
              disabled
            />
          </Col>

          <Col xs={12} sm={12} md={9} lg={9}>
            <Field
              name='description'
              type='text'
              component={renderField}
              label='Descrição'
              required
            />
          </Col>
        </Row>
      </CardForm>

      <FormSubmitButtons loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
    </form>
  )
}

VehicleTypeForm.prototype = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default reduxForm({
  form: 'vehicleType',
  validate: validate
})(VehicleTypeForm)